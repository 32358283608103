/**
 * 传入数据
 * @param {Boolean} isValidate 是否需要校验
 */
export function initCodeScan(isValidate) {
  let timer
  let codeArr = []
  let isValidateBase = true
  if (isValidate === false) {
    isValidateBase = false
  }
  let codeScanedTime
  let inputPreviousVal // 在页面文本框中定义 code-scaned="disable"
  // 创建扫码接收器
  // let currentScanInput
  // {
  //   const hideNode = document.querySelector('#inputHide')
  //   if (hideNode) {
  //     document.body.removeChild(hideNode)
  //   }
  //   // 将#inputHide置于body之外，仅用于接受条码（样式）
  //   currentScanInput = document.createElement('input')
  //   currentScanInput.setAttribute('type', 'text')
  //   currentScanInput.setAttribute('id', 'inputHide')
  //   currentScanInput.setAttribute('code-scaned', 'disable')
  //   document.body.appendChild(currentScanInput)
  // }

  function codeToString(arr) {
    const stringArr = []
    arr.forEach((item) => {
      if (item.keyType === 'keypress') {
        stringArr.push(String.fromCharCode(item.keyCode))
      }
    })
    return stringArr.join('').trim()
  }

  function windowKeyEvent(e) {
    // console.log(e,document.activeElement.tagName)
    if (document.activeElement.tagName == "INPUT") {
      return
    }
    // 是否校验
    if (!isValidateBase) {
      return
    }
    const isInputDom = e.target.tagName.toLowerCase() === 'input'
    if (isInputDom && inputPreviousVal === undefined) {
      inputPreviousVal = e.target.value
      // 定时销毁保存的input值
      setTimeout(() => {
        inputPreviousVal = undefined
      }, 300)
    }

    // 如果刚刚扫码完成 在200ms内阻止其他事件的触发
    if (codeScanedTime && Date.now() - codeScanedTime < 200) {
      e.stopPropagation()
      e.preventDefault()
      return
    }
    let codeResult

    const keyCode = e.charCode || e.keyCode
    if (/^(9|17|18|20)$/.test(keyCode)) {
      return
    }
    if (keyCode === 13 && e.type === 'keyup') {
      clearTimeout(timer)
      if (codeArr.length >= 6) {
        const { classList } = e.target
        codeResult = codeToString(codeArr)
        codeScanedTime = Date.now()
        if (isInputDom && !classList.contains('disabled') && !e.target.getAttribute('disabled')) {
          if (e.target.getAttribute('code-scaned') === 'disable') {
            // e.target.value = inputPreviousVal
            console.log('后台在非scan框中扫码', e.target.id, inputPreviousVal)
            // eventBus.$emit('scanInputError', e.target.id, inputPreviousVal)
            inputPreviousVal = undefined
          } else {
            e.target.value = codeResult
          }
        }
        console.log('后台扫码成功，分发 scanInputed 事件', codeResult)
        let adminScanEvent = new CustomEvent('adminScanEvent', { detail: { code: codeResult } })
        // 黑屏时 禁用按键件套
        if (document.getElementById('pageMask')) {
          return;
        }
        window.dispatchEvent(adminScanEvent)
      }
      codeArr = []
      setTimeout(() => {
        codeScanedTime = undefined
      }, 200)
      return
    }
    codeArr.push({
      keyCode,
      keyType: e.type,
    })
    clearTimeout(timer)
    timer = setTimeout(() => {
      codeArr = []
    }, 100)
  }

  window.addEventListener('keydown', windowKeyEvent, true)
  window.addEventListener('keyup', windowKeyEvent, true)
  window.addEventListener('keypress', windowKeyEvent, true)

  function baseStopEventFn(e) {
    if (codeScanedTime && Date.now() - codeScanedTime < 200) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  // 防止扫码时click事件触发
  window.addEventListener('touchend', baseStopEventFn, true)
  window.addEventListener('mouseup', baseStopEventFn, true)
  window.addEventListener('click', baseStopEventFn, true)

  // 这个一定要有，用于销毁，否者会被不断的绑定。
  return function removeHolder() {
    window.removeEventListener('keydown', windowKeyEvent, true)
    window.removeEventListener('keyup', windowKeyEvent, true)
    window.removeEventListener('keypress', windowKeyEvent, true)
    window.removeEventListener('touchend', baseStopEventFn, true)
    window.removeEventListener('mouseup', baseStopEventFn, true)
    window.removeEventListener('click', baseStopEventFn, true)
  }
}
