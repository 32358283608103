<template>
  <div class="diffReason">
    <geek-flex-container align="center" justify="start" class="mt mb">
<!--      <geek-text fontWeight="bold" :text="$t('差异原因录入')"></geek-text>-->
<!--      <el-button size="small" icon="el-icon-sort" plain @click="changeReason">{{!type ? $t('切换为多原因') : $t('切换为单原因')}}</el-button>-->
      <el-radio v-model="type" :label="0">{{$t('lang.wms.fed.singleReason')}}</el-radio>
      <el-radio v-model="type" :label="1">{{$t('lang.wms.fed.multiReason')}}</el-radio>
    </geek-flex-container>

    <el-form ref="ruleForm" :model="ruleForm" label-width="80px" label-position="top" class="diffReason__form" >
      <geek-container :cols="3" :gutter="30" :key="Date.now()">
        <el-form-item :label="$t('lang.wms.fed.skuCode')">
          <el-input class="w-full" v-model="ruleForm.skuCode" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.wms.fed.skuName')">
          <el-input class="w-full" v-model="ruleForm.skuName" disabled></el-input>
        </el-form-item>
        <el-form-item :label="$t('lang.wms.fed.differenceAmount')">
          <el-input class="w-full diffReason__form--qty" v-model="ruleForm.receivingLackAmount" disabled></el-input>
        </el-form-item>

        <!--多原因-->
        <template v-if="type === 1">
          <el-form-item :label="item.label" v-for="item in exceptions" :prop="item.value" :key="item.value">
            <el-input-number class="w-full" v-model="ruleForm[item.value]" :min="0" :precision="0"/>
          </el-form-item>
        </template>
        <!--单原因-->
        <template v-else>
          <el-form-item :label="$t('lang.wes.fed.diffReason')" prop="singleReasonValue">
            <el-select class="w-full" v-model="ruleForm.singleReasonValue" clearable :placeholder="$t('lang.wms.fed.pleaseChoose')">
              <el-option
                v-for="item in exceptions"
                :key="item.value"
                :label="$t(item.label)"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </template>
      </geek-container>
      <el-form-item>
<!--        <el-button icon="el-icon-sort" plain @click="changeReason">{{!type ? $t('切换为多原因') : $t('切换为单原因')}}</el-button>-->
<!--        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
<!--        <el-button type="primary" @click="submit">保存</el-button>-->
      </el-form-item>
    </el-form>

  </div>
</template>

<script>
import {POST} from "../../utils/http";
import {API} from "leivii";

export default {
  name: "GeekDiffReason",
  options: {
    name: '差异原因录入',
    events: {
      submit: 'lang.action.submit',
      reset: 'lang.action.reset'
    }
  },
  props: {
    $classes: {
      type: String,
      clazz: 'Theme',
      label: 'lang.prop.$classes',
      default: ''
    }
  },
  data () {
    return {
      type: 0, // false 单原因 true多原因
      ruleForm: {
        skuCode: '',
        skuName: '',
        receivingLackAmount: '',
        singleReasonValue: ''
      },
      exceptions: [],
    }
  },
  mounted() {
    if (!this.dataSource.scopeData) return
    this.initForm()
    this.getExpression()
  },
  methods: {
    @API.doc(false)
    initForm () {
      Object.keys(this.ruleForm).forEach(code => {
        this.$set(this.ruleForm, code, this.dataSource.scopeData[code])
      })
      this.$set(this.ruleForm, 'receivingLackAmount', Number(this.dataSource.scopeData.receivedAmount) - Number(this.dataSource.scopeData.planAmount))
    },
    @API.doc(false)
    async getExpression() {//获取差异原因列表
      POST('/api_v1/dict/batchQuery', {
        objectCodes: ['task_difference_reason']
      }).then(data => {
        // 差异原因
        this.exceptions = data.data?.task_difference_reason?.details?.map(
          ({ fieldCode, fieldValue }) => ({
            value: fieldCode,
            label: this.$t(fieldValue)
          })
        )
        let receivingLackRemarks = this.dataSource.scopeData.receivingLackRemarks
        // 前后端数据格式不一致，需要转换
        if (typeof receivingLackRemarks == 'string') receivingLackRemarks = JSON.parse(receivingLackRemarks || "[]")
        this.type = receivingLackRemarks?.length > 1 ? 1 : 0
        if (this.type === 1) {
          this.exceptions?.forEach(item => {
            this.$set(this.ruleForm, item.value, receivingLackRemarks.find(l => l.reason == item.value)?.num || 0)
          })
        } else {
          this.$set(this.ruleForm, 'singleReasonValue', receivingLackRemarks[0]?.reason)
        }
      })
    },
    @API.doc('lang.action.reset')
    resetForm() {
      // this.$refs[formName].resetFields();
      this.exceptions?.forEach(item => {
        this.ruleForm[item.value] = undefined
      })
      this.ruleForm.singleReasonValue = null
      this.$emit('reset')
      this.$nextTick(() => this.$refs.ruleForm.clearValidate())
    },
    @API.doc('lang.action.submit')
    submit () {
      let form = {
        receivingLackAmount: this.ruleForm.receivingLackAmount,
        receiptDetailId: this.dataSource.scopeData.id
      }
      let remarks = []
      // 多原因
      if (this.type === 1) {
        this.exceptions?.forEach(item => {
          remarks.push({reason: item.value, num: this.ruleForm[item.value] || 0})
        })
        // 计算总差异数量
        let total = remarks.reduce((total, item) => {
          return total + Number(item.num || 0)
        }, 0)
        if (total != Math.abs(this.ruleForm.receivingLackAmount)) {
          this.$message.error(this.$t('lang.wes.fed.commonLackErrorReason'))
          return
        }
        form.receivingLackRemarks = remarks
      } else {
        if (!this.ruleForm.singleReasonValue) {
          this.$message.error(this.$t('lang.wms.fed.pleaseChoose') + this.$t('lang.wes.fed.diffReason'))
          return
        }
        form.receivingLackRemarks = [{reason: this.ruleForm.singleReasonValue, num: Math.abs(this.ruleForm.receivingLackAmount)}]
      }
      // this.$emit('submit', form)
      console.log(form, this.type);
      return form
    }
  }
}
</script>

<style lang="scss" scoped>
.diffReason{
  .diffReason__form {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 20px 20px 0;

    .diffReason__form--qty {
      ::v-deep .el-input__inner {
        color: #f56c6c;
        font-weight: bold;
      }
    }
    ::v-deep .el-form-item__label {
      padding: 0;
    }
  }
}

</style>