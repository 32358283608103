<template>
  <div class="geek-web-view">
    <iframe v-if="showLoading" class="iframe" frameborder="0" :src="url" :name="name" width="100%" height="100%" :scrolling="scrolling" />
  </div>
</template>

<script>
export default {
  name: 'GeekWebView',
  options: {
    name: 'lang.component.webview',
    parents: ['all'] // all
  },
  props: {
    width: {
      type: String,
      label: 'lang.prop.width',
      default: '100%'
    },
    height: {
      type: String,
      label: 'lang.prop.height',
      default: '100%'
    },
    scrolling: {
      type: String,
      label: 'lang.prop.scroll',
      options: [{ label: 'lang.prop.auto', value: 'auto' }, { label: 'lang.prop.yes', value: 'yes' }, { label: 'lang.prop.yes', value: 'no' }]
    }
  },
  data() {
    return {
      showLoading: true,
    }
  },
  computed: {
    url() {
      if (['/strategicCenter/snStrategyManagement', '/strategicCenter/cancelStrategyRouteConfig', '/strategicCenter/shelfScheduleConfig'].includes(this.$route.path)) {
        return window.location.origin + process.env.VUE_APP_STATION_PATH + this.$route.path
      }
      const url = this.$route.query.url.replace('/station/#', `/station/index.html?v=${new Date().getTime()}#`)
      return `${url}${this.$route.query.url.includes('?') ? '&' : '?'}v=${new Date().getTime()}`
    },
    name() {
      return this.$route.query.tagsName
    }
  },
  mounted() {
    let p = this.$el.parentNode
    if (!p.className) {
      if (p.childElementCount > 1) {
        p.style.height = 'auto'
      } else {
        p.style.height = '100%'
      }
    }
    this.$nextTick(() => {
      window.frames[this.name].onload = function() {
        console.log(this.name, 'focus')
        window.frames[this.name].focus()
      }
    })
  },
  activated() {
    this.showLoading = false
    this.$nextTick(() => {
      this.showLoading = true
    })
    window.frames[this.name].onload = function () {
      console.log(this.name, 'focus')
      window.frames[this.name].focus()
    }
  }

}
</script>

<style lang="scss" scoped>
.geek-web-view {
  width: 100%;
  height: 100%;
}
</style>
