<template>
<!-- 新版本废弃 -->
  <div class="composite-menu" @mousedown.stop>
    <div @click.stop.prevent>
      <el-aside class="geek-aside-wrapper" :class="menuCollapse ? 'open-sidebar' : 'hide-sidebar'">
        <el-scrollbar style="height:100%;" wrap-class="menu-list-scrollbar" :native="false">
          <el-menu
            class="el-menu-vertical-list"
            router
            unique-opened
            :default-active="defaultActive"
            @open="handleOpen"
            @close="handleClose"
            @select="handleMenuSelect">
            <side-item v-for="(route, index) in menuList" :key="index" :route-obj="route"/>
          </el-menu>
          <!-- <switch-menu-mode :mode="isFullModelMenu" /> -->
        </el-scrollbar>
      </el-aside>
    </div>
  </div>
</template>

<script>
import { constantRoutes } from '@/router'
import SideItem from './SideItem'

export default {
  name: 'CompositeMenu',
  components: {
    SideItem
  },
  data() {
    return {
      menuCollapse: false,
      isFullModelMenu: false,
      menuList: []
    }
  },
  props: {
    iscollapse: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    defaultActive() {
      return this.$route.meta?.inAppEditor ? this.$route.meta.path : this.$route.path
    }
  },
  watch: {
    iscollapse: {
      handler(val) {
        this.menuCollapse = val
      },
      immediate: true
    }
  },
  mounted() {
    // EventBus.$on('onSwitchMenuMode', (val) => {
    //     this.isFullModelMenu = val
    // })
  },
  beforeDestory() {
    // EventBus.$off('onSwitchMenuMode')
  },
  created() {
    this.menuList = constantRoutes// .filter(item => item.meta && item.meta.affix)[0].children
    // const modelCode = localStorage.getItem('menuMode')
    // if (modelCode) {
    //   this.isFullModelMenu = modelCode === 'full'
    // }
  },
  methods: {
    handleClose() {

    },
    handleOpen() {

    },
    handleMenuSelect(e) {
      // this.$parent.drawer = false
      // if(window.location.hash.includes(e)){
      //   this.$router.go(0)
      // }
    }
  }
}
</script>

<style lang="scss">
.composite-menu {
  z-index: 2001;
  margin-top: 85px;
  height: 100%;
  > div{
    height: 100%;
  }
  .geek-aside-wrapper {
    // position: absolute;
    // left: 0;
    height: 100%;
    z-index: 2008;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.31);
    &.el-aside {
      width: 210px !important;
    //   background: #545c64;
      background: rgb(70,76,91);
      height: calc(100% - 85px);
      transition: width 0.3s ease-in;
      user-select:none;
      .el-menu{
        background: rgb(70,76,91);
      }
      .el-menu-vertical-list {
        left: 0;
        border-right: 0 none;
      }
      .el-menu-vertical-list:not(.el-menu--collapse) {
        min-height: 400px;
      }
      .el-submenu__title, .el-menu-item {
        color: #fff;
        font-weight: bold;
      }
      .el-menu-item:focus, .el-menu-item:hover, .el-submenu__title:hover{
        background: #ecf5ffA1 !important;
        span{
          text-shadow: 0px 0px 1px #fff;
        }
      }
      &.hide-sidebar {
        width: 0 !important;
        .menu-list-scrollbar{
          overflow: hidden;
        }
        .logo {
          img {
            margin-right: 0;
          }
        }
      }
    }
    .menu-list-scrollbar{
      overflow: auto;
    }
  }
}
</style>
