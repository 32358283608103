<template>
  <div v-if="parseInt(row) && parseInt(column)" class="geek-seedWall" :class="$classes">
      <div v-for="(item, i) in Array(row)" :key="i" class="seedWall-row">
        <div v-for="(ele, j) in Array(column)" :key="j" class="seedWall-column" :style="style">{{i*column + j + 1}}</div>
      </div>
  </div>
  <div class="geek-seedWall-placeholder" v-else-if="$store.state.leivii.mode == 1">seedWall</div>
</template>

<script>

export default {
  name: 'GeekSeedWall',
  options: {
    name: '播种墙',
    parents: ['all'], // all
    groups: ['container'], // none
    events: {
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    $classes: {
      type: String,
      clazz: 'Theme',
      label: 'lang.prop.$classes',
      default: 'mb-sm'
    },
    color: {
      type: String,
      isExpression: true,
      label: 'lang.prop.color',
      default: '#45e79f'
    },
    height: {
      type: String,
      label: 'lang.prop.height',
      default: '40px'
    },
    row: {
      type: [String, Number],
      isExpression: true,
      label: 'lang.prop.rows',
      default: '0'
    },
    column: {
      type: [String, Number],
      isExpression: true,
      label: 'lang.prop.cols',
      default: '0'
    }
  },
  computed: {
    style() {
      return { background: this.color, height: this.height, width: `calc(${100 / this.column}% - ${(this.column * 4 - 4) + 'px'})` }
    }
  },
  watch: {

  },
  data() {
    return {
      loading: false
    }
  },
  async created() {
    this.init();
  },
  methods: {
    init() {
    }
  }
}
</script>

<style lang="scss" scoped>
.geek-seedWall{
  .seedWall-row{
    display: flex;
    .seedWall-column{
        margin: 2px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
    }
  }
}
.geek-seedWall-placeholder{
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 20px;
  text-align: center;
}
</style>
