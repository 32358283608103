import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    homeLoading: false,
    isShowStation: false,
    isShowMenu: false,
    isAddCommon: false,
    isShowPassWord: false,
    menuSrollIndex: '',
    stationId: '',
    commonMenu: JSON.parse(localStorage.getItem('commonMenu')) || [],
    stationIdList: [],
    menuType: '0',
    ctxScript: {},
    changeStorage: false
  },
  mutations: {
  },
  actions: {
  }
})
