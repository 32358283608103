// 生成随机字符串
function generateRandomString(length) {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
// 对字符串进行 sha256 哈希运算
async function sha256(plain) {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest('SHA-256', data);
}
// 对字符串进行 base64url 编码
function base64urlencode(str) {
  // Convert to base64
  let base64 = btoa(String.fromCharCode.apply(null, new Uint8Array(str)));
  // Convert to base64url
  base64 = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/[=]+$/, '');
  return base64;
}
// 生成 Code Verifier 和 Code Challenge
export const generateCodeChallenge = async() => {
  const codeVerifier = generateRandomString(64);
  const hashBuffer = await sha256(codeVerifier);
  const codeChallenge = base64urlencode(hashBuffer);
  return { codeVerifier, codeChallenge };
}
