<template>
  <el-form-item class="geek-input geek-form-item" :label="$transMsg(label)" :prop="prop" :class="$classes" :rules="getRule($transMsg(label), rules)">
    <template v-if="(inputType == 3 || inputType == 4) && propertyInfo.propertyOption != 2">
      <el-date-picker
        v-model="multiValueProxy"
        class="w-full"
        :type="inputType == 3 ? 'date' : 'datetime'"
        :size="size"
        :format="inputType == 3 ? 'yyyy-MM-dd' :'yyyy-MM-dd HH:mm:ss'"
        :value-format="'timestamp'"
        :disabled="disabled"
        :picker-options="pickerOptions"
        @input="$emit('change', $event)">
      </el-date-picker>
    </template>
    <template v-else-if="propertyInfo.propertyOption == 2">
      <el-select
        style="width: 100%"
        v-model="valueProxy"
        :placeholder="$transMsg(placeholder)"
        :popperClass="popperClass"
        :size="size"
        :clearable="clearable"
        :disabled="disabled"
        @change="selectAll">
        <!-- <el-option :label="$transMsg('lang.prop.select.all')" value="all" v-if="isSelectAll && dictList.length"></el-option> -->
        <el-option
          v-for="item in dictList"
          :key="item.value"
          :label="$transMsg(item.label)"
          :value="item.value"
          :disabled="item.disabled">
        </el-option>
      </el-select>
    </template>
    <template v-if="inputType == 1 && propertyInfo.propertyOption != 2">
        <el-input-number v-model="valueProxy" class="w-full" :precision="precision" :size="size" :min="min" :max="max" :placeholder="$transMsg(placeholder)" :disabled="disabled" :controls-position="controlsPosition" @input="$emit('input', $event)" @keyup.enter.native="$emit('enter', $event)"></el-input-number>
    </template>
    <template v-if="inputType == 2 && propertyInfo.propertyOption != 2">
      <el-input v-model="valueProxy" class="w-full" type="text" :rows="rows" :size="size" :maxlength="maxlength || Number.MAX_SAFE_INTEGER" :clearable="clearable" :placeholder="$transMsg(placeholder)" :disabled="disabled" @input="$emit('input', $event)" @change="$emit('change', $event)" @keyup.enter.native="handleEnter">
        <geek-component v-if="prepend" :data="prepend" slot="prepend" />
        <geek-component v-if="append" :data="append" slot="append" />
      </el-input>
    </template>
  </el-form-item>
</template>

<script>
// import getRule from './validate'
// import formItem from './mixins/formItem'
// import { doc } from '@/api'
import {API, Dict} from 'leivii'


export default {
  name: 'GeekInputDynamic',
//   mixins: [formItem],
  options: {
    name: '动态输入框',
    parents: ['geek-form'], // all
    groups: ['form'], // none
    events: {
      input: 'lang.action.input',
      change: 'lang.action.change',
      enter: 'lang.action.enter'
    }
  },
  props: {
    valueProcessor: {
      type: String,
      clazz: 'FunctionInput',
      placeholder: 'val => val',
      label: 'lang.prop.valueProcessor'
    },
    label: {
      type: String,
      clazz: 'I18n',
      default: 'lang.prop.text',
      label: 'lang.prop.label'
    },
    prop: {
      type: String,
      label: 'lang.prop.field'
    },
    // dictForSelect: {
    //   type: String,
    //   label: '下拉框字典'
    // },
    placeholder: {
      type: String,
      clazz: 'I18n',
      default: 'lang.prop.pleaseEnter',
      label: 'lang.prop.placeholder'
    },
    popperClass: {
      type: String,
      label: 'lang.prop.selectClass',
      clazz: 'Theme'
    },
    // type: {
    //   type: String,
    //   label: 'lang.prop.type',
    //   default: 'text',
    //   options: [{label: 'lang.prop.textInput', value: 'text'}, 
    //             {label: 'lang.component.select', value: 'select'}, 
    //             {label: 'lang.component.datepicker', value: 'date'}],
    // },
    separator: {
      type: String,
      default: '-',
      label: 'lang.prop.separator'
    },
    limitDay: {
      type: Number,
      label: 'lang.prop.picker.option',
      isExpression: true
    },
    rows: {
      type: Number,
      label: 'lang.prop.rows',
      visible: `'{{type}}' == 'textarea'`
    },
    size: {
      type: String,
      options: [{label: 'lang.prop.size.mini', value: 'mini'}, {label: 'lang.prop.size.small', value: 'small'}, {label: 'lang.prop.size.medium', value: 'medium'}],
      label: 'lang.prop.size',
      visible: `'{{type}}' != 'textarea'`
    },
    maxlength: {
      type: Number,
      label: 'lang.prop.maxlength'
    },
    clearable: {
      type: Boolean,
      label: 'lang.prop.clearable'
    },
    prepend: {
      type: Object,
      clazz: 'ComponentDragger',
      placeholder: 'lang.prop.prependPlaceholder',
      label: 'lang.prop.prepend'
    },
    append: {
      type: Object,
      clazz: 'ComponentDragger',
      placeholder: 'lang.prop.appendPlaceholder',
      label: 'lang.prop.append'
    },
    disabled: {
      type: Boolean,
      isExpression: true,
      default: null,
      label: 'lang.prop.disable'
    },
    // rules: {
    //   type: Array,
    //   label: 'lang.prop.rules',
    //   clazz: 'Validator'
    // },
    value: {
      type: [String, Number],
      isExpression: true,
      label: 'lang.prop.default'
    },
  },
  data() {
    return {
      choiceDate: '',
      propertyInfo: {},
      pickerOptions: {
				onPick: ({minDate,maxDate }) => {
					this.choiceDate = minDate.getTime()
					if (maxDate) this.choiceDate = ''
				},
				// 限制日期可选跨度范围
				disabledDate: time => {
					if (!this.limitDay || typeof this.limitDay !== 'number') return
					if (this.choiceDate) {
						const one = (this.limitDay - 1)*24*3600*1000
						const minTime = this.choiceDate
						const maxTime = this.choiceDate + one
						return (time.getTime() < minTime || time.getTime() > maxTime)
					}
				}
			}
    }
  },
  
  computed: {
    multiValueProxy: {
      get() {
        if ((this.inputType == 3 || this.inputType == 4) && this.propertyInfo.propertyOption != 2) {
          return [this.valueProxy].flat().filter(Boolean)
        }
        return this.valueProxy
      },
      set(v) {
        this.valueProxy = v
      }
    },
    valueProxy: {
      get() {
        if (this.prop) {
          if (this.$scopeData) {
            return this.$scopeData?.[this.prop] ?? this.value
          }
          return this.dataSource.form?.data[this.prop] ?? this.value
        }
        // return (this.prop ? this.dataSource.form?.data[this.prop] : null) ?? this.value
      },
      set(v) {
        if (this.prop) {
          this.$emit('change', v)
          // TODO change未触发？
          if (!this.$scopeData && this.dataSource.form?.data) {
            this.$set(this.dataSource.form.data, this.prop, v)
            this.$set(this.get(this.dataSource.form.id).form, this.prop, v)
            this.get(this.dataSource.form.id).setDataSource('form', this.dataSource.form)
          }
        // } else if (this.$prop) {
        //   this.setDataSource(this.$prop, v)
        }
      }
    },
    inputType() {
      // 默认值2 文本
      const inputMap = {
        inputDate: 'input_date',
        inventoryStatus: 'inventory_status',
      }
      const key = inputMap[this.prop] ||  this.prop;
      this.propertyInfo = this.list ? this.list.filter(v => v.batchPropertyId == key)?.[0] || {} : {};
      return this.propertyInfo?.propertyType || 2;
    },
    list() { // datasource value
      let data = this.getDataSource();
      return data || [];
    },
    dictList() {
      console.log(this.dataSource, this.propertyInfo)
      if (!(this.dataSource && this.propertyInfo?.propertyOptionDict)){return []};
      const dict = this.dataSource[this.propertyInfo?.propertyOptionDict] || [];
      return dict;
    }
  },
  mounted() {
    // console.log(this.dataSource, this.dictForSelect)
    // this.propertyInfo.propertyOption = 2;
  },
  methods: {
      // getRule,
    @API.doc(false)
    handleEnter(e) {
      this.$emit('enter', e)
      this.$emit('change', e.target.value)
    },
    @API.doc(false)
    getValue() {
        if (this.valueProcessor) {
            try {
                let funStr = `(${this.valueProcessor}).call(null, ${JSON.stringify(this.valueProxy)})`
          return eval(funStr)
        } catch {
            warn('GEEK::value processor error', this.valueProcessor)
        }
      }
      return this.valueProxy;
    },
    @API.doc(false)
    setValue(v) {
        this.valueProxy = v;
    },
    @API.doc(false)
    getRule(label, rules) {
        const validate = Dict?.validator || {};
        if (this.propertyInfo.inputControl == 2) {
          // 输入控制必填
          return validate['required'].call(this, label)
        }
        // return rules?.length && rules.map(item => {
        //     if (item in validate) return validate[item].call(this, label)
        //     try {
        //     const v = eval(`(function(scope) {\nreturn (${item}).call(scope, '${label}')\n})`)(this)
        //     if (('validator' in v) && ('trigger' in v)) return v
        //     } catch {}
        // }).filter(Boolean)
    },
    @API.doc(false)
    selectAll (val) {
      let flag = false
      if (!Array.isArray(val)) return
      for (let key of val) {
        if (key === 'all') {
          flag = true
          break;
        }
      }
      if (flag) {
        this.multiValueProxy = this.options.map(item => item.value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.geek-form-item {
  // padding-top: 15px;
  ::v-deep .el-input-group__append, 
  ::v-deep .el-input-group__prepend {
    padding: 0 10px;
  }
}
.el-date-editor.w-full {
    width: 100%;
  }
</style>