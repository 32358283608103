<template>
  <div class="geek-copy-btn" :style="styles">
    <el-button icon="el-icon-copy-document" type="text" size="mini" v-clipboard:copy="text" v-clipboard:success="onCopy"></el-button>
  </div>
</template>

<script>

export default {
  name: 'GeekCopyBtn',
  options: {
    name: '复制',
    parents: ['all'], // all
    groups: ['container']
  },
  components: {
  },
  methods: {
    onCopy(e) {
      if (this.$storeData.state.mode == 1) {
        this.$message.success(this.$t('lang.wms.fed.copySuccess'))
      }
    }
  },
  computed: {
    styles() {
      return [
        'display: flex',
        `justify-content: ${this.justify}`,
        `align-items: ${this.align}`,
        `width: ${this.width}`,
        `height: ${this.height}`
      ].join(';')
    }
  },
  props: {
    $body: {
      ignore: true
    },
    text: {
      type: String,
      isExpression: true,
      label: '复制值',
      default: ''
    },
    justify: {
      type: String,
      default: 'center',
      label: 'lang.prop.justify'
    },
    align: {
      type: String,
      default: 'top',
      label: 'lang.prop.align'
    },
    width: {
      type: String,
      default: 'auto',
      label: 'lang.prop.width'
    },
    height: {
      type: String,
      default: '100%',
      label: 'lang.prop.height'
    }
  }
}
</script>
