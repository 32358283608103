<script>
export default {
  beforeCreate() {
    this.$router.replace(this.$route.query.p)
  },
  render() {
    return null
  }
}
</script>
