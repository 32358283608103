import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login";
import Home from "../views/Home";
import Page from "../views/Page";
import HomePage from "../views/HomePage";
import TempUpdate from "../views/TempUpdate";
import Station from "../views/Station";
import Redirect from "../views/Redirect";
import Base from "../views/layouts/Base";
import Editor from "../views/Editor";
import Layout from "../views/layouts/Layout";
import i18n from "../i18n";
import { handleGetCurLanguage } from "@/utils/langs";
Vue.use(VueRouter);

let curLanguage = localStorage.getItem("curLanguage");
let menuList = JSON.parse(localStorage.getItem("MenuList")) || [];
// debugger
let routeInited = false;
let constantRoutes = [];
let editorRoutes = [];
let menuCodeList = [];

const parseRoute = (menuList) => {
  function sortSysList(list) {
    if (Object.prototype.toString.call(list) == "[object Array]") {
      list.sort((pre, next) => {
        if (pre.sort < next.sort) return -1;
        else return 1;
      });
      list.map((res) => {
        if (res.children) {
          sortSysList(res.children);
        }
      });
    }
  }
  sortSysList(menuList);
  const getPath = (menuList, nodePath = []) => {
    return [menuList]
      .flat()
      .filter(Boolean)
      .filter((menu) => menu.type === 1)
      .map((menu) => {
        let _nodePath = [...nodePath, menu];
        let permissionCode = menu.code.replace(/^\//, "").replace(/\//g, "-");
        let children = menu.children?.length
          ? getPath(menu.children, _nodePath)
          : [];
        if (menu.code == "index") {
          children = [];
        }
        if (!children.length) {
          menuCodeList.push("/" + _nodePath.map((n) => n.code).join("/"));
        }
        let route = {
          path: nodePath.length ? permissionCode : `${permissionCode}`,
          name: menu.name,
          nameI18: menu.name,
          component: menu.isStation ? Station : Page,
          children,
          meta: {
            id: permissionCode,
            path: _nodePath.map((n) => n.code).join("/"),
            auth: _nodePath
              .slice(-2)
              .map((n) => n.code)
              .join("/"),
            breadcrumb: _nodePath.slice(-2).map((n) => n.name),
            cache: true,
          },
        };
        if (menu.url) {
          route.meta.url = menu.url;
        }
        editorRoutes.push({
          name: `[${i18n.t(route.name)}]`,
          path: `editor/${route.meta.id}`,
          component: Editor,
          meta: {
            id: route.meta.id,
            name: route.name,
            path: "/" + _nodePath.map((n) => n.code).join("/"),
            auth: route.meta.auth,
            cache: false,
            inAppEditor: true,
            status: 1,
          },
        });
        return route;
      });
  };
  constantRoutes = getPath(menuList);
  localStorage.setItem("menuCodeList", JSON.stringify(menuCodeList));
};

const addRoutesIfNot = () => {
  if (!routeInited) {
    // 打印配置页面需要手动添加路由，菜单栏不需要展示
    menuList = JSON.parse(localStorage.getItem("MenuList")) || [];
    routeInited = true;
    parseRoute(menuList);
    router.addRoute({
      path: "/",
      component: Layout,
      children: [...constantRoutes, ...editorRoutes],
    });
  }
  return routeInited;
};

const routes = [
  {
    name: "lang.wms.fed.signIn",
    path: "/login",
    component: Login,
    meta: {},
  },

  {
    path: "/",
    // name: '首页',
    component: Layout,
    redirect: "/homePage",
    children: [
      {
        name: "lang.wms.fed.first", //首页
        path: "homePage",
        component: HomePage,
        meta: {
          cache: true,
          auth: "homePage",
        },
      },

      {
        name: "lang.wms.fed.workstation", //工作站
        path: "station",
        component: Station,
        meta: {
          // cache: true
        },
      },
      {
        name: "lang.wms.common.permission.printModelConfig", //打印模板设置
        path: "printModelConfig",
        component: Page,
        meta: {
          id: "printModelConfig",
          cache: false,
        },
      },
      {
        name: "货架入场离场", // 货架离场入场
        path: "shelfEnterAndExit",
        component: Page,
        meta: {
          id: "shelfEnterAndExit",
          cache: true,
        },
      },
      {
        name: "redirect",
        path: "redirect",
        component: Redirect,
      },
    ],
  },
  {
    name: "模板导入",
    path: "/upload",
    component: TempUpdate,
    meta: {},
  },
];

const router = new VueRouter({ routes });

//控制台报错信息去除
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const whiteList = ["/login", "/redirect"];
router.beforeEach((to, from, next) => {
  if (menuList.length === 0) {
    menuList = JSON.parse(localStorage.getItem("MenuList")) || [];
  }
  if (to.path == "/login") {
    const logoutUrl = localStorage.getItem('logoutUrl') || ''
    sessionStorage.removeItem('fe-GPS-style')
    if (logoutUrl) {
      //第三方登出业务逻辑
      const url = window.location.href
      localStorage.removeItem('logoutUrl')
      window.location.href = `${logoutUrl}?redirect_uri=${encodeURIComponent(url)}`
    } else {
      if (process.env.NODE_ENV === "development") {
        next();
      } else {
        window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
      }
    }
    return;
  }
  if (from.meta?.cache) {
    const $content = document.querySelector(".geek-page");
    from.meta.scrollTop = $content?.scrollTop || 0;
  }
  if (whiteList.includes(to.path)) {
    next();
  } else if (to.path == "/" || to.path == "/index") {
    //没模块时自动跳转到首页
    next({ ...to, path: "/homePage" });
  } else {
    // 当有新增国际化的地方设置一下sessionStorage
    if (sessionStorage.getItem("needLangReset") == "1" && curLanguage) {
      sessionStorage.setItem("needLangReset", "");
      handleGetCurLanguage(curLanguage, true);
    }
    if (routeInited) {
      return next();
    } else if (addRoutesIfNot()) {
      return next({ ...to });
    }
  }

});
router.afterEach((to) => {
  setTimeout(() => {
    // 页面标题
    document.title = i18n.t(to?.name || "Geek+");
  }, 0);
});

export { constantRoutes, editorRoutes };

export default router;
