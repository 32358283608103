<template>
    <div class="import-dialog">
        <el-form :model="importForm" :label-position="'left'" label-width="auto">
            <el-row>
                <el-col :span="12">
                    <el-form-item>
                        <el-button type="text" @click="handleTemplateDownload">{{$t('lang.wms.fed.templateDownload')}}</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item :label="$t('lang.wms.fed.strategyType')">
                        <el-select v-model="importForm.strategyType">
                            <el-option
                            v-for="item in dict.taskStrategyType.filter((item) => item.value === '1')"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item :label="$t('lang.wms.fed.updateStationId')">
                        <el-select v-model="importForm.siteCodes" multiple @change="handleSelectChange">
                            <el-option
                            v-for="(item, index) in stationListArr"
                            :key="index"
                            :label="item"
                            :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item :label="$t('lang.wms.fed.exportUpdate')">
                        <el-upload
                        ref="upload"
                        :class="{'geek-export-language__upload--disabled': !stationId.length}"
                        accept=".xlsx,.xls"
                        :file-list="fileList"
                        action=""
                        :on-change="handleChange"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :on-success="handleSuccess"
                        :auto-upload="false">
                        <el-button v-if="!stationId.length" slot="tip" :disabled="true" size="small" type="primary">{{$t('lang.wms.fed.selectTheFile')}}</el-button>
                        <el-button v-else slot="trigger" size="small" type="primary">{{$t('lang.wms.fed.selectTheFile')}}</el-button>
                        </el-upload>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="10">
                    <el-form-item :label="$t('lang.wms.fed.importResults')">
                        <template>
                            <!--导入loading-->
                            <geek-flex-container align="center" v-if="loading"><i class="el-icon-loading fs-xxl"></i>{{$t('lang.wms.fed.pleaseWaitToLoading')}}</geek-flex-container>
                            <!--成功-->
                            <div v-else-if="code === 0" class="c-success">{{$t('lang.wms.fed.importSuccess2')}}</div>
                            <!--导入失败 下载错误原因-->
                            <div v-else-if="code !== 0 && code !== ''" >
                                <span class="c-danger">{{emptyError===''?$t('lang.wms.fed.baseSet.title.stationAndShelf.importFailed'):emptyError}}</span>
                                <el-button type="text" v-if="emptyError===''" @click="handleErrorDownload">{{$t('lang.wms.downloadCheckReason')}}</el-button>
                            </div>
                            </template>
                    </el-form-item>
                </el-col>
            </el-row>
            <geek-flex-container align="center" justify="end" class="mt-lg">
                <el-button type="primary" @click="submitUpload" :disabled="disabeldUpload">{{$t('lang.wms.fed.startExport')}}</el-button>
            </geek-flex-container>
        </el-form>
    </div>
</template>
<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
import exportDownload from '@/utils/exportDownload'
export default {
    props: {
        dict: {
            type: Object,
            default: function() {
                return {};
            }
        },
        drop: {
            type: Object,
            default: function() {
                return {};
            }
        },
        stationList:{
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data() {
        return {
            importForm:{
                strategyType:'1',
                siteCodes:'',
            },
            stationId:[],
            fileList: [],
            stationListArr:[],
            code: '',
            loading: false,
            disabeldUpload:true,
            emptyError:'',
        }
    },
    watch:{
        fileList:{
            handler(val){
                if(val.length>0){
                    this.disabeldUpload = false
                }else{
                    this.disabeldUpload = true
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created(){
        this.stationListArr = this.stationList.concat()
        this.stationListArr.unshift(this.$t('lang.wms.fed.whole'));
    },
    methods: {
        handleSelectChange(val){
            if(val[val.length-1]===(String(this.$t('lang.wms.fed.whole')))){
                this.stationId = this.stationList;
                this.importForm.siteCodes = [String(this.$t('lang.wms.fed.whole'))]
            }else{
                this.stationId = val;
                this.importForm.siteCodes = val.filter(item=>item!==this.$t('lang.wms.fed.whole'));
            }
        },
        handleTemplateDownload(){
            exportDownload({path:'/beetle/api/v1/siteStrategyQuote/download/templates'},
            );
        },
        handleErrorDownload(){
            exportDownload({path:'/beetle/api/v1/siteStrategyQuote/downloadImportFailureFile'},
            );
        },
        handleChange(file, fileList) {
            if(file.name.indexOf('.xlsx')===-1&&file.name.indexOf('.xls')===-1){
                this.$message.error(this.$t('lang.wms.onlyXLSXFileAllow'))
                this.fileList = []
                return
            }
            this.fileList = [fileList[fileList.length-1]]
            this.code= ''
            this.loading= false
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
            this.fileList = fileList;
        },
        handlePreview(file) {
            console.log(file.raw);
        },
        handleSuccess(data) {
            this.loading = false
            this.code = data.code
        },
        submitUpload(){
            if(!this.stationId.length){
                this.$message.error(this.$t('lang.wms.fed.pleaseChooseUpdateStationId'))
                return 
            }
            this.loading = true
            let params = new FormData()
            params.append('fileName', this.fileList[0].raw)
            params.append('strategyType', Number(this.importForm.strategyType))
            params.append('siteCodes', this.stationId.join(','))
            window.needErrorTip = true;
            POST('/api/v1/siteStrategyQuote/siteStrategyQuoteImport/excel',params).then(res => {
                // 上传文件
                this.loading = false
                console.log('导入文件',res)
                if (res.code === 0) {
                    this.emptyError = ''
                    if(res.data&&res.data.fileName){
                        this.code = 1
                    }else{
                        this.code = 0
                    }
                }else{
                    if(res.msg.indexOf('lang.wms.common.enum.store.item0021')>-1){
                        this.code = res.code
                        this.emptyError = this.$transMsg(res.msg)
                    }
                }
            }).finally(() => {
                window.needErrorTip = false
            })
        }
    }
}
</script>

<style lang="scss">
.import-dialog{
    padding: 0 20px;
    .el-upload-list {
        display: table;
    }
    .el-form-item__label-wrap{
        margin-left: 0px !important;
    }
    .el-form-item__content{
        width: 200%;
    }
    .el-upload-list__item-name{
        // display: inline;
        white-space: normal;
    }
}
</style>