import i18n from '../i18n';

export function transMsg(text) {
  if (!text) return text;
  if(arguments.length > 1) {// 匹配类型：i18n原生支持的类型   未找到编码为{0}的货主信息！; 未找到编码为{name}的货主信息！; 未找到编码为%{name}的货主信息！
    if(i18n.t(text).match(/%(?<=%)\d/g)?.length) { // 特殊匹配：未找到编码为%1的货主信息！
      return i18n.t(text).replace(/%(?<=%)\d/g, () => {
        return transMsg(arguments[1].shift());
      });
    }
    return i18n.t(text, arguments[1]);
  }
  let item;
  try {
    item = JSON.parse(text);
    if (Object.prototype.toString.call(item) !== "[object Object]") {
      return i18n.t(text);
    }
  } catch (error) {
    return i18n.t(text);
  }

  if (item.p) {
    let rawMsg = i18n.t(item.c);
    let params = item.p;
    return rawMsg.replace(/\{\d*\}/g, () => {
      return transMsg(params.shift());
    });
    // return i18n.t(item.c, item.p.map(x => transMsg(x)));
  }
  return i18n.t(item.c);
}

export function getUrlparams(URL) {
  // \w+ 表示匹配至少一个(数字、字母及下划线), [\u4e00-\u9fa5]+ 表示匹配至少一个中文字符
  let pattern = /(\w+|[\u4e00-\u9fa5]+)=(\w+|[\u4e00-\u9fa5]+)/ig;
  let result = {};
  URL.replace(pattern, ($, $1, $2) => {
    result[$1] = $2;
  })
  return result
}
