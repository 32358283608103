<template>
    <div>
        <div class="shelf-dialog-main">
            <div>
                <el-radio-group v-model="copyFrom">
                    <el-radio-button v-for="(side, index) in copySides" :key="index" :label="side">{{side}}</el-radio-button>
                </el-radio-group>
            </div>
            <div>
              <el-button type="text" icon="el-icon-bottom"></el-button>
            </div>
            <div>
                <el-checkbox-group v-model="copyTo">
                    <el-checkbox-button v-for="(side, index) in copyToSidesCalc" :label="side" :key="index" size="large" :disabled="side === copyFrom">{{side}}</el-checkbox-button>
                </el-checkbox-group>
            </div>
        </div>
        <div class="shelf-dialog-footer">
            <el-button size="large" plain @click="handleCancel()">{{$t('lang.wms.fed.cancel')}}</el-button>
            <el-button size="large" type="primary" @click="save()">{{$t('lang.wms.fed.save')}}</el-button>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    shelfDataSides: {
      type: Object,
      function: () => {
        return {};
      }
    },
    sideNum: {
      type: Number,
      default: 2,
    },
  },
  data () {
    return {
      copyFrom: '',
      copyTo: [],
      copyToSides: ['F', 'B', 'L', 'R'],
      copySides: ['F', 'B'],
    }
  },
  computed: {
    copyToSidesCalc() {
      return this.copyToSides.slice(0, this.sideNum);
    }
  },
  watch: {
    shelfDataSides: {
      handler(val) {
        if (val) {
          const arr = [];
          Object.keys(val).forEach(side => {
            if(val[side]?.shelfSize && val[side]?.shelfSize.isCreated) {
              arr.push(side);
            }
          })
          this.copySides = arr;
          this.copyFrom = arr.length ? arr[0] : '';
        }
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    handleCancel() {
      this.$emit('cancel', {})
    },
    save() {
      if (!this.copyFrom || !this.copyTo.length) {
        this.$message({ type: 'error', message:this.$t('lang.wms.fed.shelfClass.selectSideToCopy')});
        return;
      }
      this.$emit('copySides', {copyTo: this.copyTo, copyFrom: this.copyFrom});
    }
  },
}
</script>

<style lang="scss" scoped>
.shelf-dialog-main{
  display: flex;
  flex-direction: column;
  flex:1;
  justify-content: center;
  align-items: center;
  ::v-deep {
    .el-icon-bottom{
      font-size: 60px;
    }
    .el-checkbox-button{
      .el-checkbox-button__inner{
        font-size: 30px;
      }
      &.is-checked .el-checkbox-button__inner{
        color: #FFF;
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
      &.is-focus .el-checkbox-button__inner{
        border-color:#dcdfe6;
      }
    }
    .el-radio-button{
      .el-radio-button__inner{
        font-size: 30px;
      }
      .el-radio-button__orig-radio:checked+.el-radio-button__inner{
        color: #FFF;
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
    }
  }
  > div {
    margin-block: 30px;
    height: 60px;
  }
}
.shelf-dialog-footer{
  margin-top: 20px;
  text-align: center;
}
</style>