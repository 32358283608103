<template>
  <div id="flowApp" class="geek">
    <G6Editor :model="getMode" :list="list" :data="initData" @saveFlowData="saveFlow" @dblclickNode="dblclickNode" @dblclickLine="dblclickLine" @globalSet="globalSet"></G6Editor>
    <el-dialog
        :title="$transMsg('lang.wms.fed.lineRuleInfo')"
        :visible.sync="dialogVisible"
        :modal="false"
        :modal-append-to-body="false"
        width="70%">
      <step-rule :ruleData="ruleForm" :fieldList="resolveData" ref="stepRule" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$transMsg('lang.wes.fed.commonCancelBtn')}}</el-button>
        <el-button type="primary" @click="saveRule">{{$transMsg('lang.wms.fed.determine')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="$transMsg('lang.wms.fed.globalParamEdit')"
        :visible.sync="setDialogVisible"
        :modal="false"
        :modal-append-to-body="false"
        width="70%">
        <el-form ref="ruleForm" label-width="80px" label-position="top" class="diffReason__form" >
          <geek-container :cols="3" :gutter="30">
            <el-form-item :label="item.label" v-for="(item, index) in globalSetList" :key="index">
              <el-input class="w-full" v-model="item.value" ></el-input>
            </el-form-item>
          </geek-container>
        </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setDialogVisible = false">{{$transMsg('lang.wes.fed.commonCancelBtn')}}</el-button>
        <el-button type="primary" @click="saveRuleSet">{{$transMsg('lang.wms.fed.determine')}}</el-button>
      </span>
    </el-dialog>
    <el-dialog
        :title="selectNodeName"
        :modal-append-to-body="false"
        :visible.sync="dialogVisibleNode"
        :modal="false"
        width="70%">
      <el-form :model="nodeForm" ref="nodeForm" label-width="150px" class="demo-ruleForm">
        <el-form-item  v-for="(item,index) in columns" :key="index" :label="item.columnName">
          <el-select :clearable="true" v-if="item.columnDataType === 'list'" v-model="nodeForm[item.columnCode]" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
            <el-option v-for="(_item,index) in item.columnDataDesp.enumeration.list" :key="index" :label="_item.name" :value="_item.code"></el-option>
          </el-select>
          <el-input v-model="nodeForm[item.columnCode]" v-else-if="item.columnDataType === 'text'"></el-input>
          <el-input v-model="nodeForm[item.columnCode]" type="textarea" v-else></el-input>
        </el-form-item>

        <el-form-item  v-for="(item,index) in inputParams" :key="'_' + index" :label="item">
          <el-select :clearable="true" v-model="mapForm[item]" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
            <el-option v-for="(_item,_index) in resolveData" :key="_index"  :label="_item.fieldDescription" :value="_item.fieldName" ></el-option>
          </el-select>
          <!--          <el-input v-model="nodeForm[item.columnCode]" v-else></el-input>-->
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleNode = false">{{$transMsg('lang.wes.fed.commonCancelBtn')}}</el-button>
        <el-button type="primary" @click="saveNodeRule">{{$transMsg('lang.wms.fed.determine')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import stepRule from './stepRule';
import { updateAllGlobalParam, getAllGlobalParam, entityTypeTree, findOneFlow, listByCode, updateFlowGraph, getNodeRule, updateRule, saveRule, nodeListInFlow } from './api'
export default {
  name: 'GeekFlowChart',
  options: {
    name: '流程图',
    parents: ['all'], // all
    groups: ['container'], // none
    events: {
      save: '保存数据'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    mode: {
      type: Boolean,
      default: false,
      label: '可编辑'
    }
  },
  data() {
    return {
      id: '',
      sceneCode: '',
      nodeId: '',
      dialogVisible: false,
      initData: {},
      ruleForm: {},
      fieldList: [],
      unPutId: [],
      list: [],
      dialogVisibleNode: false,
      setDialogVisible: false,
      globalSetList: [],
      columns: [],
      inputParams: [],
      collectParams: [],
      nodeForm: {},
      mapForm: {},
      clickData: {},
      selectNodeName: ''
    }
  },
  created() {
    let obj = { id: this.getDataSource() };
    if (obj.id) {
      this.id = obj.id;
      nodeListInFlow().then(res => {
        if (res.code === 0 && res.data.length > 0) {
          res.data.forEach(item => {
            // 列表节点数据
            this.list.push({
              name: this.$transMsg(item.nodeName),
              label: this.$transMsg(item.nodeName),
              size: '170*34',
              type: 'node',
              x: 0,
              y: 0,
              shape: 'customNode',
              color: '#1890ff',
              inPoints: [[0, 0.5], [0.5, 0]],
              outPoints: [[1, 0.5], [0.5, 1]],
              nodeType: item.type,
              nodeId: item.id,
              groupName: item.groupName,
              inputParams: item.inputParams,
              outputParams: item.outputParams
            })
          });
          findOneFlow({
            id: obj.id
          }).then(res => {
            if (res.data.graphJson != '') {
              let obj = JSON.parse(res.data.graphJson);
              this.sceneCode = res.data.sceneCode;
              obj.nodes = obj.nodes.map(item => {
                // 列表节点
                let initObj = this.list.filter(_item => _item.nodeType === item.nodeType)[0];
                if (!initObj) {
                  console.log('未知nodeType，请先处理', item, this.list)
                }
                return { ...initObj, ...item, ...{ outputParams: initObj.outputParams, inputParams: initObj.inputParams } }
              })
              this.initData = obj;
            }
            // 列表选项
            listByCode({
              sceneCode: res.data.sceneCode
            }).then(res => {
              if (res.code === 0) {
                this.fieldList = res.data
              }
            })
          })
        }
      })

    }
  },
  computed: {
    getMode() {
      return !this.dataSource?.scopeData?.mode || !!this.mode
    },
    resolveData() {
      // console.log('collectParams', this.collectParams, this.fieldList)
      return [...this.fieldList, ...this.collectParams];
    }
  },
  methods: {
    getAllNodes(data, graph, checkLine) {
      let parentItem = [];
      if (checkLine) {
        const id = data._cfg.model.sourceId
        if (this.unPutId.indexOf(id) != -1) {
          return ;
        }
        if (id) {
          this.unPutId.push(id);
          parentItem.push(graph.findById(id));
        }
      } else {
        const item = data;
        item._cfg.edges.forEach(line => {
          if (line._cfg.model.targetId === item._cfg.id) {
            const id = line._cfg.model.sourceId;
            if (this.unPutId.indexOf(id) != -1) {
              return ;
            }
            if (id) {
              this.unPutId.push(id);
              parentItem.push(graph.findById(id));
            }
          }
        });
      }
      // 改成起始type
      // const check = parentItem.filter((e) => e._cfg.model.nodeType === "start").length;
      parentItem.forEach(e => {
        const model = e.getModel();
        if (model.nodeType === 'waiting') {
          this.getClickData({}, model.id).then(res => {
            if (res.data && res.data.autoJudgeRule) {
              const obj = JSON.parse(res.data.autoJudgeRule);
              if (obj.config.output) {
                obj.config.output.split(',').forEach(outputParams => {
                  // console.log(outputParams, model.label)
                  this.collectParams.push({
                    fieldDescription: outputParams + '-' + model.label,
                    fieldName: model.id + '-' + outputParams
                  })
                })
              }
            }
          })
        }
        if (model.outputParams) {
          model.outputParams.split(',').forEach(outputParams => {
            // console.log(outputParams, model.label)
            this.collectParams.push(
              {
                fieldDescription: outputParams + '-' + model.label,
                fieldName: model.id + '-' + outputParams
              }
            );
          })
        }
        this.getAllNodes(e, graph)
      })
      // if(check){
      //   this.collectParams = this.collectParams.map((item) =>console.log( {
      //     if(item.fieldDescription){
      //       return {
      //         fieldDescription: item.fieldDescription,
      //         fieldName: item.fieldName
      //       }
      //     }
      //     console.log(item.outputParams, item.label)
      //     return {
      //       fieldDescription: item.outputParams + '-' + item.label,
      //       fieldName: item.id + '-' + item.outputParams
      //     }
      //   })
      // }

    },
    saveNodeRule() {
      const config = this.nodeForm;
      const mapping = this.mapForm;
      const clickData = this.clickData;
      clickData.g6nodeId = this.nodeId;
      clickData.nodeType = 'node';
      clickData.flowId = this.id;
      clickData.autoJudgeRule = JSON.stringify({
        config,
        mapping
      })
      if (clickData.id > 0 || clickData.id === 0) {
        updateRule(clickData).then(() => {
          this.$message.success(this.$transMsg('lang.wms.fed.item0102'));
          this.dialogVisibleNode = false;
        })
      } else {
        saveRule(clickData).then(() => {
          this.$message.success(this.$transMsg('lang.wms.fed.saveSuccess'));
          this.dialogVisibleNode = false;
        })
      }
    },
    saveRule() {
      let obj = this.$refs.stepRule.ruleForm;
      obj.autoJudgeRule = JSON.stringify(obj.autoJudgeRule)
      obj.g6nodeId = this.nodeId;
      obj.nodeType = 'edge';
      obj.flowId = this.id;
      if (obj.id > 0) {
        updateRule(obj).then(() => {
          this.$message.success(this.$transMsg('lang.wms.fed.item0102'));
          this.dialogVisible = false;
        })
      } else {
        saveRule(obj).then(() => {
          this.$message.success(this.$transMsg('lang.wms.fed.saveSuccess'));
          this.dialogVisible = false;
        })
      }
      // console.log(this.$refs.stepRule.ruleForm);
    },
    getClickData(data, id) {
      let { target } = data
      let nodeId = target ? target.cfg.parent.cfg.id : id;
      this.nodeId = nodeId;
      this.$nextTick(() => {
        this.ruleForm = {}
      })
      return getNodeRule({
        flowId: this.id,
        nodeId
      })
    },
    dblclickNode(data, graph) {
      this.collectParams = [];
      this.unPutId = [];
      this.$nextTick(() => {
        this.getAllNodes(data.item, graph)
        const model = data.item.getModel();
        this.selectNodeName = model.name + this.$transMsg('lang.wms.fed.ruleConfiguration');
        const entityTypeCode = model.nodeType
        entityTypeTree({ entityTypeCode }).then(res => {
          if (res.code === 0) {
            this.columns = res.data ? res.data.columns : [];
            this.inputParams = model.inputParams ? model.inputParams.split(',') : [];
            this.getClickData(data).then(res => {
              let config = {};
              let map = {};
              if (res.data && res.data.autoJudgeRule) {
                const obj = JSON.parse(res.data.autoJudgeRule)
                config = obj.config;
                map = obj.mapping;
              }
              this.clickData = res.data || {};
              this.nodeForm = config;
              this.mapForm = map;
            })
            if (this.columns.length || this.inputParams.length) {
              this.dialogVisibleNode = true;
            }
          } else {
            this.columns = [];
            this.inputParams = [];
          }
        })
      })
    },
    saveRuleSet() {
      let globalParam = {}
      this.globalSetList.forEach(item => {
        globalParam[item.label] = item.value;
      })
      updateAllGlobalParam({sceneCode: this.sceneCode, globalParam: JSON.stringify(globalParam)}).then(res => {
        if(res.code === 0){
          this.$message.success(this.$transMsg('lang.wms.fed.saveSuccess'));
          this.setDialogVisible = false;
        }
      })

    },
    globalSet() {
      getAllGlobalParam({sceneCode: this.sceneCode}).then(res => {

        if(res.code === 0 && Object.keys(res.data).length){
          let arr = [];
          Object.keys(res.data).forEach(key => {
            arr.push({
              label: key,
              value: res.data[key]
            })
          })
          this.globalSetList = arr;
          this.setDialogVisible = true;
        }
      })
    },
    dblclickLine(data, graph) {
      this.collectParams = [];
      this.unPutId = [];
      this.$nextTick(() => {
        this.dialogVisible = true;
        this.getAllNodes(data.item, graph, true);
        this.getClickData(data).then(res => {
          if (res.code === 0) {
            this.dialogVisible = true;
            if (res.data) {
              this.ruleForm = Object.assign({}, res.data);
              this.ruleForm.autoJudgeRule = JSON.parse(this.ruleForm.autoJudgeRule);
              this.$nextTick(() => {
                this.$refs.stepRule.ruleForm = this.ruleForm;
              })
            } else {
              this.$refs.stepRule.ruleForm = {
                autoJudgeRule: {
                  executeType: 'default',
                  config: {
                    ruleItems: [
                      {
                        type: '0',
                        target: 'true',
                        field: 'mark',
                        symbol: '='
                      }
                    ],
                    statisfyType: '0'
                  }
                }
              };
            }
          }
        })
      })
    },
    saveFlow(data) {
      let id = this.id;
      updateFlowGraph({
        graphJson: JSON.stringify(data),
        id
      }).then(res => {
        if (res.code === 0) {
          this.$message.success(this.$transMsg('lang.wms.fed.saveSuccess'));
          this.$emit('save')
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    getId() {
      const src = location.href;

      const getQueryString = url => {
        if (!url.includes('?')) {
          return null;
        }
        const [, search] = url.split('?');
        const obj = {};
        search.split('&').forEach(item => {
          if (item.includes('=')) {
            const [key, val] = item.split('=');
            Reflect.set(obj, key, val);
          }
        });
        return obj;
      };

      return getQueryString(src) || {};
    },

    handleSave(data) {
      console.log('data----saved', data)
    }
  },
  components: {
    stepRule
  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.geek {
  color: #58666e;
  font-size: .875rem;
}
.flex-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(150, 150, 150, 0.3);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0);
}
#flowApp{
  border: 1px solid #1890ff;
  background: white;
}
</style>

