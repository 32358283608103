<template>
  <div class="geek-table-card" :class="$classes" :style="$storeData.state.mode == 1 ? 'margin-bottom: 10px' : ''">
    <div class="geek-card-heading" :style="search || (actions && actions.length) ? 'margin-bottom: 15px' : ''">
      <div class="search">
        <geek-component v-if="search" :data="search" />
      </div>
      <div class="actions">
        <template v-if="actions">
          <geek-component v-for="action in actions" :key="action.$id" :data="action" />
        </template>
      </div>
    </div>
    <div class="geek-card-body">
      <slot v-if="!$body || !$body.length" />
      <geek-component :data="$body" :style="bodyStyle" style="height: 100%" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'GeekTableCard',
  options: {
    name: '表格卡片',
    parents: ['all'], // all
    groups: ['container'],
    events: {
      click: '点击'
    }
  },
  props: {
    bodyStyle: {
      type: Object,
      label: '包裹样式'
    },
    search: {
      type: Object,
      clazz: 'ComponentDragger',
      placeholder: '拖放搜索表单',
      label: '搜索区'
    },
    actions: {
      type: Array,
      itemClazz: 'ComponentDragger',
      label: '操作区'
    }
  }
}
</script>


<style lang="scss" scoped>
.geek-table-card {
  display: flex;
  flex-flow: column;
  padding: 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  overflow: hidden;
  .geek-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      align-items: center;
      > * {
        margin-left: 0;
        margin-right: 18px;
        cursor: pointer;
      }
    }
    ::v-deep {
      .el-form-item {
        margin: 0;
      }
    }
  }
  .geek-card-body {
    height: 0;
    flex: 1;
    overflow: auto;
    ::v-deep {
      .geek-table {
        height: 100%;
        margin-bottom: 0;
        .el-table {
          max-height: 100% !important;
          position: relative;
          .el-table__header-wrapper {
            .cell {
              position: relative;
            }
          }
          .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            max-height: 100% !important;
            position: absolute;
            top: 48px;
            bottom: 0;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
</style>
