<template>
  <div class="auth-wrapper w-full">
    <el-autocomplete
      class="auth-selector"
      v-model="text"
      :size="size"
      :fetch-suggestions="querySearch"
      :trigger-on-focus="true"
      highlight-first-item
      placeholder="请输入权限code"
      @change="handleChange"
      @select="handleSelect"
    >
      <template slot-scope="{ item }">
        <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis" :title="`${item.label} ${item.value}`">
          {{item.label}}
          <label class="fs-xs text-color-base">{{item.value}}</label>
        </div>
      </template>
    </el-autocomplete>
  </div>
</template>

<script>
import { Leivii } from 'leivii-editor'

export default {
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: String,
    size: {
      type: String,
      default: 'mini'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showExpression: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      text: null,
      authMap: {}
    }
  },
  created() {
    this.initAuth()
    this.authMap = Leivii.Dict.authMap[this.$route.meta.auth]

    for (let code in this.authMap) {
      if (code === this.value) {
        this.text = this.$transMsg(this.authMap[code])
      }
    }
    if (!this.text) this.text = this.value
  },
  methods: {
    initAuth() {
      let authMap = Leivii.Dict.authMap
      if (Object.keys(authMap).length) return

      let menuList = JSON.parse(localStorage.getItem('MenuList')) || []
      const getPermission = (tar, obj) => {
        if (obj.btn) {
          obj.btn.forEach(b => tar[b.code] = b.name)
        }
        if (obj.children) {
          obj.children.forEach(item => {
            getPermission(tar, item)
          })
        }
      }

      for (let i = 0; i < menuList.length; i += 1) {
        const item = menuList[i]
        if (item.type !== 1 && item.type !== 3) {
          console.log(`name:${item.name}, type:${item.type}, - not a btn item. -`)
        } else {
          for (let k = 0; k < item.children.length; k += 1) {
            const children = item.children[k]
            const g = children.children
            const key = `${item.code.trim()}/${children.code.trim()}`
            if (key === 'strategicCenter/restockConfig') {
              authMap[key] = []
              getPermission(authMap[key], children)
            } else if (g && g.length > 0) {
              for (let j = 0; j < g.length; j += 1) {
                const el = g[j]
                authMap[`${children.code.trim()}/${el.code.trim()}`] ||= {}
                el.btn.forEach(b => authMap[`${children.code.trim()}/${el.code.trim()}`][b.code] = b.name)
              }
            } else {
              authMap[key] ||= {}
              children.btn.forEach(b => authMap[key][b.code] = b.name)
            }
          }
        }
      }
    },
    querySearch(queryString, cb) {
      let list = []
      for (let code in this.authMap) {
        let label = this.$transMsg(this.authMap[code])
        let value = code
        if ((label + value).indexOf(queryString) > -1 || !queryString) {
          list.push({ label: this.$transMsg(this.authMap[code]), value: code })
        }
      }
      cb(list)
    },
    handleChange(e) {
      this.$emit('change', e)
    },
    handleSelect(e) {
      this.text = e.label
      this.$emit('change', e.value)
    }
  }
}
</script>
<style lang="scss" scoped>
.auth-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .auth-selector {
    flex: 1;
  }
}
</style>
