<template>
    <div class="shelf-maintain" v-if="showPage">
        <div class="shelf-steps">
          <el-steps :active="active"  align-center>
            <el-step :class="{'step-in-progress': active === 1}" :title="$t('lang.wms.fed.mainInformation')" description=""></el-step>
            <el-step :class="{'step-in-progress': active === 2}" :title="$t('lang.wms.fed.shelfInformation')" description=""></el-step>
            <el-step :class="{'step-in-progress': active === 3}" :title="$t('lang.wms.fed.shelf.binInformation')" description=""></el-step>
          </el-steps>
        </div>
        <div class="shelf-container" v-if="active === 1">
          <el-form :model="formOne" ref="formOne" label-position="top" :ruleForm="ruleStep1"  :disabled="isDetail" style="overflow:auto;">
            <div class="info-block">
              <div class="info-title"><span>{{$t('lang.wms.fed.shelfClass.basicInformation')}}</span></div>
              <div class="info-form">
                <div class="info-row">
                  <el-form-item prop="classCode" :label="$t('lang.wms.fed.specificationCoding')" :rules="ruleStep1.classCode">
                      <el-input v-model="formOne.classCode" :placeholder="$t('lang.wms.fed.pleaseEnter')"  :disabled="isEdit" @blur="classCodeInput"></el-input>
                  </el-form-item>
                  <el-form-item prop="shelfCategory" :label="$t('lang.wms.fed.shelfSpecies')"  :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.shelfCategory" @change="shelfCateChange" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                        <el-option
                        v-for="(item, index) in (dict.shelfCategory)"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item prop="shelfType" :label="$t('lang.wms.fed.shelfType')"  :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.shelfType" @change="shelfTypeChange" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                        <el-option
                          v-for="(item, index) in (dict.shelfTypeNew)"
                          :key="index"
                          :label="$t(item.field_value)"
                          :value="Number(item.field_code)">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item  prop="sideNum" :label="$t('lang.wms.common.dict.sideNums')"  :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.sideNum" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                        <el-option
                          v-for="(item, index) in (dict.sideNums || [])"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item  prop="selfWeight" :label="$t('lang.wms.fed.shelfClass.weight') + '(kg)'" :rules="ruleStep1.decimalThreeAndMax999999999">
                      <el-input v-model="formOne.selfWeight" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                </div>
                <div class="info-row">
                  <el-form-item  prop="length" :label="$t('lang.wms.fed.length') + '(cm)'" :rules="ruleStep1.decimalOneAnd0Max999999999">
                      <el-input v-model="formOne.length" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item  prop="width" :label="$t('lang.wms.fed.width') + '(cm)'" :rules="ruleStep1.decimalOneAnd0Max999999999">
                      <el-input v-model="formOne.width" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item  prop="height" :label="$t('lang.wms.fed.height') + '(cm)'" :rules="ruleStep1.decimalOneAnd0Max999999999">
                      <el-input v-model="formOne.height" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item  prop="footHeight" :label="$t('lang.wms.fed.shelfClass.bottomHeight') + '(cm)'" :rules="ruleStep1.decimalOneAnd0Max999999999">
                      <el-input v-model="formOne.footHeight" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <!-- <el-form-item  prop="bearWeight" :label="$t('lang.wms.fed.bearing') + '(kg)'" :rules="ruleStep1.decimalThreeAndMax999999999">
                      <el-input v-model="formOne.bearWeight" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item> -->
                </div>
              </div>
            </div>
            <div class="info-block">
              <div class="info-title"><span>{{$t('lang.wms.fed.ruleCoding')}}</span></div>
              <div class="info-form">
                <div class="info-row">
                  <el-form-item  prop="prefix" :label="$t('lang.wms.fed.shelfClass.binCodePrefix')" :rules="ruleStep1.decimalTen">
                      <el-input v-model="formOne.prefix" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item  prop="shelfCodeLen" :label="$t('lang.wms.fed.shelfClass.numOfShelfCode')" :rules="ruleStep1.onToNine">
                      <el-input v-model="formOne.shelfCodeLen" :placeholder="$t('lang.wms.fed.pleaseEnter')" :disabled="disableShelfCodeLen"></el-input>
                  </el-form-item>
                  <!-- <el-form-item  prop="shelfSideCodeLen" :label="$t('lang.wms.fed.shelfClass.numOfShelfSideCode')" :rules="ruleStep1.onToNine">
                      <el-input v-model="formOne.shelfSideCodeLen" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item> -->
                  <!-- <el-form-item  prop="shelfLen" :label="$t('lang.wms.fed.shelfClass.depthIndex')" :rules="ruleStep1.onToNine">
                      <el-input v-model="formOne.shelfLen" :placeholder="$t('lang.wms.fed.pleaseEnter')" @change="changeDeep"></el-input>
                  </el-form-item> -->
                <!-- </div>
                <div class="info-row"> -->
                  <el-form-item  prop="shelfFloorRule" :label="$t('lang.wms.fed.shelfClass.shelfFloorRule')" :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.shelfFloorRule" :placeholder="$t('lang.wms.fed.pleaseChoose')" @change="shelfRuleChange('formOne', 'shelfFloorMaxNum', formOne.shelfFloorRule)">
                        <el-option
                        v-for="(item, index) in (dict.columnAndFloorRule || [])"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item prop="shelfFloorMaxNum" :label="$t('lang.wms.fed.shelfClass.shelfMaxFloor')"  :rules="shlefGenRules(formOne.shelfFloorRule)">
                      <el-input v-model="formOne.shelfFloorMaxNum" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item> -->
                  <el-form-item prop="shelfColumnRule" :label="$t('lang.wms.fed.shelfClass.shelfColumnRule')"  :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.shelfColumnRule" :placeholder="$t('lang.wms.fed.pleaseChoose')" @change="shelfRuleChange('formOne', 'shelfColumnMaxNum', formOne.shelfColumnRule)">
                        <el-option
                        v-for="(item, index) in (dict.columnAndFloorRule || [])"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item prop="shelfColumnMaxNum" :label="$t('lang.wms.fed.shelfClass.shelfMaxColumn')"  :rules="shlefGenRules(formOne.shelfColumnRule)">
                      <el-input v-model="formOne.shelfColumnMaxNum" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item> -->
                  <!-- <el-form-item prop="columnAndFloorOrderRule" :label="$t('lang.wms.fed.shelfClass.columnAndFloorOrderRule')"  :rules="ruleStep1.pleaseCheckOut">
                    <el-select filterable v-model="formOne.columnAndFloorOrderRule" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                        <el-option
                        v-for="(item, index) in (dict.columnAndFloorOrderRule || [])"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item> -->
                </div>
                <div class="info-row" style="padding-left:20px">
                  <span style="margin-right:10px">{{$t('lang.wms.fed.shelfClass.binCodeRule')}}</span>
                  <!-- <el-checkbox v-model="formOne.checked" :disabled="formOne.shelfLen < 2" @change="deepCheck">{{$t('lang.wms.fed.shelfClass.depthIndex')}}</el-checkbox> -->
                </div>
                <div class="info-row">
                  <div class="shelf-expression">
                      <draggable v-model="shelfExpress"  :options="{
                        draggable: '.sitem',
                        filter: '.ignore'
                      }">
                        <span v-for="(tag, tidx) in shelfExpress"
                            class="sitem"
                            :class="{
                              'sitem': tag.key !== 'layerCode' && tag.key !== 'columnCode',
                              'ignore': tag.key === 'layerCode' || tag.key === 'columnCode'
                            }"
                            :key="tidx">
                          <el-tag
                          :closable="(tag.key === 'connect' || tag.key === 'sideAliasCode') && !isDetail"
                          :class="{'item-close': tag.key === 'connect' || tag.key === 'sideAliasCode'}"
                          @close="handleClose(tag, tidx, 'shelf')">{{tag.label}}</el-tag>
                        </span>
                      </draggable>
                  </div>
                  <el-form-item prop=""  style="width:140px">
                    <el-select filterable v-model="formOne.connector" :disabled="shelfConnectLength > 2" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                        <el-option
                        v-for="(item, index) in (dict.binCodeRuleSeparator || [])"
                        :key="index"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                  </el-form-item>
                  <el-button  style="margin-left:20px" size="mini" type="primary" @click="handleSplit()" :disabled="shelfConnectLength > 2 || isDetail">{{$t('lang.wms.fed.shelfClass.addSeparator')}}</el-button>
                  <el-button  style="margin-left:20px" size="mini" type="primary" @click="resetShelfExpress()" :disabled="isDetail">{{$t('lang.wms.fed.reset')}}</el-button>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="shelf-container" v-else-if="active === 2">
          <el-tabs v-model="formTwo.shelfSide" @tab-click="handleShelfSideClick" :before-leave="tabBeforeLeave">
            <el-tab-pane :label="`${tab + $t('lang.wms.fed.shelfClass.side')}`" :name="tab" v-for="(tab, index) in sides" :key="index"></el-tab-pane>
          </el-tabs>
          <div class="shelf-infos">
            <div class="shelf-infos-left">
              <el-form :model="formTwo" ref="formTwo" :rules="ruleStep1" label-position="top"  :disabled="isDetail">
                  <el-form-item  prop="sideAliasCode" :label="$t('lang.wms.fed.shelfClass.sideAliasCode')" :rules="ruleStep1.threeKey">
                    <el-input v-model="formTwo.sideAliasCode" :disabled="isEdit" :placeholder="$t('lang.wms.fed.pleaseEnter')" @change="shelfAlias"></el-input>
                  </el-form-item>
                  <el-form-item  prop="layer" :label="$t('lang.wms.fed.shelfClass.numOfShelfFloor')"  :rules="shlefGenRules(formOne.shelfFloorMaxNum, 'shelfFloorMaxNum')">
                      <el-input v-model="formTwo.layer" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <el-form-item  prop="column" :label="$t('lang.wms.fed.shelfClass.numOfShelfColumn')" :rules="shlefGenRules(formOne.shelfColumnMaxNum, 'shelfColumnRule')">
                      <el-input v-model="formTwo.column" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                  </el-form-item>
                  <div style="text-align:center;margin-top:10px;">
                    <el-button size="medium" type="primary" @click="createShelf" style="width:auto;min-width:100px">{{$t('lang.wms.fed.shelfClass.createShelfStyle')}}</el-button>
                  </div>
                  <div style="text-align:center;margin-top:10px;">
                    <el-button el-button size="medium" type="warning" @click="dialogCancel" style="width:auto;min-width:100px">{{$t('lang.wes.fed.copy')}}</el-button>
                  </div>
                  <!-- <el-button size="medium" type="primary">{{$t('lang.wms.fed.save')}}</el-button> -->
              </el-form>
            </div>
            <div class="shelf-infos-center">
              <!-- <el-form style="display: flex;justify-content: center;" ref="formTwoRight" :model="formTwoRight" label-position="top" :disabled="isDetail">
                  <el-form-item prop="sideAliasCode" :label="$t('lang.wms.fed.shelfClass.sideAliasCode')" style="width:40%;text-align: center" :rules="ruleStep1.threeKey" :disabled="isEdit || isDetail">
                      <el-input v-model="formTwoRight.sideAliasCode" :disabled="dataSource.scopeData" :placeholder="$t('lang.wms.fed.pleaseEnter')" @change="shelfAlias"></el-input>
                  </el-form-item>
              </el-form> -->
              <shelf-editor ref="shelf"
                :shelfData="currentShelfSide"
                :threeDimensionRules="threeDimensionRules"
                v-show="currentShelfSide.shelfSize"
                :canChoose="false"
                :isDetail="isDetail">
              </shelf-editor>
            </div>
            <!-- <div class="shelf-infos-right">
              <div class="" style="disply:flex; flex-direction:column;">
                <div>
                  货格
                </div>
                <el-input
                  placeholder=""
                  v-model="formTwo.layer">
                </el-input>
              </div>
              <div class="" style="disply:flex; flex-direction:column;">
                <div>
                  偏移量X
                </div>
                <el-input
                  placeholder=""
                  v-model="formTwo.comlumn">
                </el-input>
              </div>
              <div class="" style="disply:flex; flex-direction:column;">
                <div>
                  偏移量Y
                </div>
                <el-input
                  placeholder=""
                  v-model="formTwo.comlumn">
                </el-input>
              </div>
            </div> -->
          </div>
        </div>
        <div class="shelf-container" v-else-if="active === 3">
          <el-tabs v-model="formThree.shelfSide" @tab-click="handleShelfSideClick">
            <el-tab-pane :label="`${tab} ${$t('lang.wms.fed.shelfClass.side')}`" :name="tab" v-for="(tab, index) in sides" :key="index"></el-tab-pane>
          </el-tabs>
          <div class="shelf-infos">
            <div class=""></div>
            <div class="shelf-infos-left-big">
              <shelf-editor
                ref="shelfThree"
                :threeDimensionRules="threeDimensionRules"
                :shelfData="currentShelfSide"
                :saveChoosedBins="saveChoosedBins"
                :disableEdit="true"
                :canChoose="true"
                :singleChoose="isDetail"
                :isDetail="isDetail"
                @singleChooseEvent="singleChoose"
                @chosedCells="chosedCells"></shelf-editor>
            </div>
            <div class="shelf-infos-right-big">
              <el-form :model="formThree" ref="formThree" :rules="ruleStep1" label-position="top" :disabled="isDetail">
                <div class="info-block">
                  <div class="info-form">
                    <!-- <el-form-item prop="shelfLen" :label="' '">
                      <el-checkbox v-model="formThree.show3Drule">{{$t('显示3D货位编码公式')}}</el-checkbox>
                    </el-form-item> -->
                    <div class="info-row">
                      <el-form-item prop="columnAndFloorOrderRule" :label="$t('lang.wms.fed.shelfClass.DDDBinCodeFormula')" style="width: 60%">
                        <div class="shelf-expression bin-express">
                          <draggable v-model="binExpress" draggable=".sitem" :disabled="true">
                            <span v-for="(tag, tidx) in binExpress"
                                class="sitem"
                                :key="tidx">
                              <el-tag
                              :closable="tag.key === 'connect'"
                              @close="handleClose(tag, tidx, 'bin')">{{tag.label}}</el-tag>
                            </span>
                          </draggable>
                        </div>
                      </el-form-item>
                    </div>
                    <div class="info-row">
                      <el-form-item prop="sideAliasCode" :label="$t('lang.wms.fed.shelfClass.sideAliasCode')">
                          <el-input v-model="formTwo.sideAliasCode" :disabled="true" :placeholder="$t('lang.wms.fed.specificationCoding')"></el-input>
                      </el-form-item>
                      <el-form-item style="width:70%" prop="cells" :label="$t('lang.wms.fed.shelfBinCode')">
                        <el-input v-model="formThree.cells" :placeholder="$t('lang.wms.fed.pleaseEnter')" :disabled="true"></el-input>
                      </el-form-item>
                      <!-- <el-button type="warning">批量复制</el-button> -->
                    </div>
                    <!-- <div class="info-row">
                      <el-form-item prop="length" :label="$t('lang.wms.fed.shelfClass.binLength') + '(cm)'" :rules="ruleStep1.decimalOneAndMax999">
                          <el-input v-model="formThree.length" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                      <el-form-item prop="width" :label="$t('lang.wms.fed.shelfClass.binWidth') + '(cm)'" :rules="ruleStep1.decimalOneAndMax999">
                          <el-input v-model="formThree.width" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                      <el-form-item prop="height" :label="$t('lang.wms.fed.shelfClass.binHeight') + '(cm)'" :rules="ruleStep1.decimalOneAndMax999">
                          <el-input v-model="formThree.height" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                      <el-form-item prop="volumn" :label="$t(' lang.wms.fed.shelfClass.maxVolumeOfBin') + '(cm3)'" :rules="ruleStep1.decimalOneAndMax999999999">
                          <el-input v-model="formThree.volumn" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                    </div> -->
                    <div class="info-row">
                      <!-- <el-form-item prop="binBearWeight" :label="$t('lang.wms.fed.shelfClass.bearWeight') + '(kg)'" :rules="ruleStep1.decimalThreeAndMax9999">
                        <el-input v-model="formThree.binBearWeight" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item> -->
                      <el-form-item prop="shelfBinType" :label="$t('lang.wms.fed.shelfBinTypes')">
                        <el-select filterable v-model="formThree.shelfBinType" :placeholder="$t('lang.wms.fed.pleaseEnter')"  @change="changeBinType" :disabled="!isBinCanEdit">
                          <el-option
                          v-for="(item, index) in (dict.shelfBinType || [])"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <!-- <el-form-item prop="bindContainer" :label="$t('lang.wms.fed.shelfClass.containerSpecif')">
                          <el-select filterable multiple v-model="formThree.bindContainer" :disabled="formThree.shelfBinType != 200" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                            <el-option
                            v-for="(item, index) in (dict.containerSpec || [])"
                            :key="index"
                            :label="$t(item.fieldValue)"
                            :value="item.shelfType">
                            </el-option>
                        </el-select>
                      </el-form-item> -->
                      <!-- <el-form-item>
                        <el-button size="medium" type="primary" @click="saveCells" style="margin-top: 50px;">{{$t('lang.wms.fed.shelfClass.saveShelfBin')}}</el-button>
                      </el-form-item> -->
                    </div>
                    <div class="info-row" v-if="formThree.shelfBinType == 300">
                      <el-form-item prop="binLayerRule" :label="$t('lang.wms.fed.shelfClass.binGroupFloorRule')">
                        <el-select filterable v-model="formThree.binLayerRule" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                          <el-option
                          v-for="(item, index) in (dict.columnAndFloorRule || [])"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="layer" :label="$t('lang.wms.fed.shelfClass.numberOfLayers')" :rules="shlefGenRules(formThree.binLayerRule)">
                          <el-input v-model="formThree.layer" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                      <el-form-item prop="binColRule" :label="$t('lang.wms.fed.shelfClass.binGroupColumnRule')">
                        <el-select filterable v-model="formThree.binColRule" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                          <el-option
                          v-for="(item, index) in (dict.columnAndFloorRule || [])"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <el-form-item prop="column" :label="$t('lang.wms.fed.shelfClass.numberOfColumns')" :rules="shlefGenRules(formThree.binColRule)">
                          <el-input v-model="formThree.column" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                      </el-form-item>
                    </div>
                    <div class="info-row" style="padding-left:20px">
                      <!-- <span style="margin-right:10px">{{$t('lang.wms.fed.shelfClass.DDDBinCodeFormula')}}</span> -->
                      <!-- <el-form-item prop="classCode"  style="width:140px">
                        <el-select filterable v-model="formThree.connector" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                            <el-option
                            v-for="(item, index) in (dict.binCodeRuleSeparator || [])"
                            :key="index"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                      </el-form-item>
                      <el-button  style="margin-left:20px" size="mini" type="primary" @click="handleSplit('binExpress')">{{$t('添加分隔符')}}</el-button> -->
                    </div>
                    <div class="info-row">
                      <el-form-item v-if="formThree.shelfBinType == 300" prop="columnAndFloorOrderRule" :label="$t('lang.wms.fed.shelfClass.columnAndFloorOrderRule')">
                        <el-select filterable v-model="formThree.columnAndFloorOrderRule" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                          <el-option
                          v-for="(item, index) in (dict.columnAndFloorOrderRule  || [])"
                          :key="index"
                          :label="item.label"
                          :value="item.value">
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="info-row" style="justify-content:center;">
                      <el-button size="medium" type="primary" @click="saveCells">{{$t('lang.wms.fed.shelfClass.saveShelfBin')}}</el-button>
                    </div>
                  </div>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <!-- <div class="shelf-container" v-if="preview">
          <shelf-editor
            ref="shelfPreview"
            :shelfData="currentShelfSide"
            :disableEdit="true"
            @chosedCells="chosedCells"></shelf-editor>
        </div> -->
        <div class="shlef-operates">
          <el-button size="medium" type="primary" :disabled="active === 1" @click="prevStep()">{{$t('lang.wms.fed.prevStep')}}</el-button>
          <el-button size="medium" type="primary" @click="nextStep()" :disabled="active === 3">{{$t('lang.wms.fed.nextStep')}}</el-button>
          <!-- <el-button size="medium" type="primary" v-if="active === 3 && !isDetail" @click="submit(0)">{{$t('lang.wms.fed.save')}}</el-button> -->
          <el-button size="medium" type="primary" v-if="active === 3 && !isDetail" @click="submit(1)">{{$t('lang.wms.fed.save')}}</el-button>
          <el-button size="medium"  type="danger" plain @click="cancleEdit()">{{$t('lang.wms.fed.cancel')}}</el-button>
      </div>
      <el-dialog :title="$t('lang.wes.fed.copy')" :modal="true"  append-to-body :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="copyDialogVisible" :width="'60%'">
        <copy-dialog   v-if="copyDialogVisible" :shelfDataSides="shelfDataSides" :sideNum="formOne.sideNum"  @cancel="dialogCancel" @copySides="copySides" style="height: 80%; overflow: hidden;"></copy-dialog>
      </el-dialog>
    </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
import draggable from 'vuedraggable'
import shelfEditor from './shelf'
import copyDialog from './copyDialog'
const SIDES = ['F', 'B', 'L', 'R'];

export default {
  name: 'GeekShelfMaintain',
  options: {
    name: '货架规格维护',
    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
      cancleEdit: '取消',
      closeEdit: '关闭'
    }
  },
  components: {
    shelfEditor,
    draggable,
    copyDialog
  },
  watch: {
    'dataSource.shelfInfoNew': {
      // 编辑回填
      handler(val) {
        if (val && val.classCode) {
          this.isEdit = true;
          this.isDetail = !!val.isDetail;
          this.dict.shelfTypeNew = this.dict.shelfType;
          this.previewShelf(val)
        }
      },
      immediate: true,
      deep: true
    },
    defaultBinType: {
      handler(val) {
        this.formThree.shelfBinType = val;
      },
      immediate: true
    }
  },
  data() {
    const checkClassCode = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.shelfClassCodeNotEmpty'));
      }
      setTimeout(() => {
        let reg = /^[A-Z]$/;
        if (value === 'T') {
          callback(new Error(this.$transMsg(this.$t('lang.wms.fed.shelfClass.TCodeIsNotAllowed'))));
        } else if (!reg.test(value)) {
          callback(new Error(this.$transMsg({ c: 'lang.wms.fed.shelfClass.inputCapitalLetter', p: [1] })));
        } else {
          callback();
        }
      }, 100);
    };
    const checkRequire = (rule, value, callback) => {
      if (!value && value !== 0 ) {
        callback(new Error(this.$t('lang.wms.fed.pleaseCheckOut')));
      } else {
        callback()
      }
    };
    const decimalOne = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      setTimeout(() => {
        let reg = /^[0-9]*(\.\d{0,1})?$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.limitOneDecimalPlaces')));
        } else {
          callback();
        }
      }, 100);
    };
    const decimalThree = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      setTimeout(() => {
        let reg = /^[1-9][0-9]*(\.\d{0,3})?$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.shelfClass.inputDecimalsLimit', [3])));
        } else {
          callback();
        }
      }, 100);
    };
    const onToNine = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      setTimeout(() => {
        let reg = /^[1-9]$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.shelfClass.inputPositiveInteger', ['1-9'])));
        } else {
          callback();
        }
      }, 100);
    };
    const onToNineAToZ = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      setTimeout(() => {
        let reg = /^[1-9A-Z]$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.shelfClass.enterLettersOrNumber', [1, '1-9、A-Z'])));
        } else {
          callback();
        }
      }, 100);
    };
    const onToNineAToZThree = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      setTimeout(() => {
        let reg = /^[1-9A-Z-]{0,3}$/;
        if (!reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.shelfClass.enterLettersOrCharacters', [3, '1-9、A-Z'])));
        } else {
          callback();
        }
      }, 100);
    };
    const onToNineAToZTen = (rule, value, callback) => {
      setTimeout(() => {
        let reg = /^[1-9A-Z][0-9A-Z]{0,9}$/;
        if (value && !reg.test(value)) {
          callback(new Error(this.$t('lang.wms.fed.shelfClass.enterNoMoreThan10LettersOrNumbers', [10, '1-9、A-Z'])));
        } else {
          callback();
        }
      }, 100);
    };
    const positiveInteger1_999 = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      let reg = /^([1-9]|[1-9][0-9]{1,2})(\.\d+)?$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.shelfClass.inputPositiveNumber', ['1-999'])));
      } else {
        callback();
      }
    };
    const positiveInteger1_9999 = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      let reg = /^([1-9]|[1-9][0-9]{1,3})(\.\d+)?$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.shelfClass.inputPositiveNumber', ['1-9999'])));
      } else {
        callback();
      }
    };
    const positiveInteger1_999999999 = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      let reg = /^([1-9][0-9]{0,8})(\.\d+)?$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.shelfClass.inputPositiveNumber', ['1-999999999'])));
      } else {
        callback();
      }
    };
    const positiveInteger0_999999999 = (rule, value, callback) => {
      if (!value) {
        return callback(this.$t('lang.wms.fed.pleaseCheckOut'));
      }
      let reg = /^([0-9][0-9]{0,8})(\.\d+)?$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.shelfClass.inputPositiveNumber', ['0-999999999'])));
      } else {
        callback();
      }
    };
    return {
      copyDialogVisible: false,
      preview: false,
      showPage: false,
      active: 1,
      ruleStep1: {
        classCode: [{ required: true, validator: checkClassCode, trigger: 'change' }],
        oneKey: [{ required: true, validator: onToNineAToZ, trigger: 'change' }],
        threeKey: [{ required: true, validator: onToNineAToZThree, trigger: 'change' }],
        pleaseCheckOut: [{ required: true, validator: checkRequire, trigger: 'change' }],
        decimalOne: [{ required: true, validator: decimalOne, trigger: 'blur' }],
        decimalThree: [{ required: true, validator: decimalThree, trigger: 'blur' }],
        decimalTen: [{ validator: onToNineAToZTen, trigger: 'blur' }],
        onToNine: [{ required: true, validator: onToNine, trigger: 'blur' }],
        decimalOneAndMax999: [
          { validator: positiveInteger1_999, trigger: 'blur' },
          { validator: decimalOne, trigger: 'blur' }
        ],
        decimalThreeAndMax9999: [
          { validator: positiveInteger1_9999, trigger: 'blur' },
          { validator: decimalThree, trigger: 'blur' }
        ],
        decimalOneAnd0Max999999999: [
          { required: true, validator: positiveInteger0_999999999, trigger: 'blur' },
          { required: true, validator: decimalOne, trigger: 'blur' }
        ],
        decimalOneAndMax999999999: [
          { required: true, validator: positiveInteger1_999999999, trigger: 'blur' },
          { required: true, validator: decimalOne, trigger: 'blur' }
        ],
        decimalThreeAndMax999999999: [
          { required: true, validator: positiveInteger1_999999999, trigger: 'blur' },
          { required: true, validator: decimalThree, trigger: 'blur' }
        ],
        rule01_99: [{
          required: true,
          pattern: /^([1-9]|[1-9][0-9])$/,
          message: this.$t('lang.wms.fed.shelfClass.maximumNumberOfShelfFloor', [99]),
          trigger: 'blur'
        }],
        rule1_Z: [{
          required: true,
          pattern: /^(3[0-5]|[12][0-9]|[1-9])$/,
          message: this.$t('lang.wms.fed.shelfClass.maximumNumberOfShelfFloor', [35]),
          trigger: 'blur'
        }],
        ruleA_Z: [{
          required: true,
          pattern: /^(2[0-6]|[1-9]|1[0-9])$/,
          message: this.$t('lang.wms.fed.shelfClass.maximumNumberOfShelfFloor', [26]),
          trigger: 'blur'
        }]
      },
      // sides: ['F'],
      dict: {
        enable: [{ label: this.$t('lang.wms.fed.deactivate'), value: '0' }, { label: this.$t('lang.wms.fed.enable'), value: '1' }],
        shelfBinType: [
          { label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200 },
          { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 },
          { label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300 }
        ]
      },
      drop: {
        enable: {
          0: this.$t('lang.wms.fed.deactivate'),
          1: this.$t('lang.wms.fed.enable')
        }
      },
      formOne: {
        prefix: '',
        connector: '',
        checked: false,
        classCode: '',
        shelfCategory: '',
        shelfType: '',
        sideNum: 1,
        selfWeight: 0,
        bearWeight: 0,
        footHeight: 0,
        height: 0,
        length: 0,
        width: 0,
        shelfCodeLen: '',
        shelfSideCodeLen: 3,
        shelfLen: 1,
        binFormatType: 2, // 0旧 1新、2此次新
        shelfFloorRule: '',
        shelfColumnRule: '',
        shelfFloorMaxNum: '',
        shelfColumnMaxNum: '',
        columnAndFloorOrderRule: 1 // 默认值1
      },
      formThree: {
        connector: '',
        shelfSide: '',
        shelfBinType: '',
        classCode: '',
        shelfCategory: '',
        shelfType: '',
        sideNum: '',
        height: 0,
        length: 0,
        width: 0,
        volumn: 0,
        layer: '',
        column: '',
        binBearWeight: '',
        bindContainer: '',
        // shelfFloorRule: '',
        // shelfColumnRule: '',
        columnAndFloorOrderRule: 1,
        binLayerRule: '',
        binColRule: '',
        cells: '',
        sperateTwo: '',
        show3Drule: false
      },
      formTwo: {
        shelfSide: 'F',
        layer: '',
        column: '',
        sideAliasCode: ''
      },
      formTwoRight: {
        sideAliasCode: ''
      },
      shelfDataSides: {
        F: {},
        B: {},
        L: {},
        R: {}
      },
      currentShelfSide: {},
      shelfExpress: [
        { key: 'shelfCode', label: this.$t('lang.wms.fed.shelfCode1') },
        { key: 'sideAliasCode', label: this.$t('lang.wms.fed.shelfClass.ShelfSide') },
        // {key: 'connect', label: '-'},
        { key: 'layerCode', label: this.$t('lang.wms.fed.shelfClass.shelfLayerNumber') },
        { key: 'columnCode', label: this.$t('lang.wms.fed.shelfClass.numberOfShelfFloor') }
        // {key: 'connect', label: '-'},
      ],
      binExpress: [
        { key: 'binCode', label: this.$t('lang.wms.fed.item04393') },
        // {key: 'connect', label: '-'},
        { key: 'binLayerIndex', label: this.$t('lang.wms.fed.shelfClass.binLayerNumber') },
        // {key: 'connect', label: '-'},
        { key: 'binColumnIndex', label: this.$t('lang.wms.fed.shelfClass.binColumnNumber') }
      ],
      dragIndex: '',
      enterIndex: '',
      timeout: null,
      shelfForm: {},
      editData: {},
      saveChoosedBins: {},
      isEdit: false,
      isDetail: false,
      disableShelfCodeLen: false, // 禁用长度
      threeDimensionRules: {
        columnAndFloorOrderRule: 1,
        binLayerRule: '',
        binColRule: ''
      },
      defaultBinType: ''
    }
  },
  computed: {
    sides() {
      let arr = [...SIDES];
      return arr.slice(0, this.formOne.sideNum || 1);
    },
    isBinCanEdit() {
      // 1、货架类型(100-119，140-159,200-299，300-399,920-939),货位类型不可编辑，货位类型默认为动态货位。
      // 2、货架类型(120-139),货位类型可以编辑、货位类型默认为动态货位。货位类型数据源为(动态货位，固定货位)、
      // 3、货架类型(1、3、801-899)，货位类型可编辑，默认固定货位。货位类型数据源为(固定货位、抽屉式货位)
      // 4、货架类型(5)，货位类型不可编辑，默认为抽屉式货位。
      // 5、货架类型(2)，货位类型不可编辑，默认为固定货位。
      // 6、货架类型(900-919)，货位类型可编辑，默认固定货位。货位类型数据源为(固定货位、抽屉式货位)
      const val = Number(this.formOne.shelfType);
      let canEdit = false;
      this.dict.shelfBinType = [
        { label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200 },
        { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 },
        { label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300 }
      ]
      switch (true) {
      case val < 120 && val > 99 :
      case val < 160 && val > 139 :
      case val < 201 && val > 199 :
      case val < 400 && val > 299 :
      case val < 940 && val > 919 :
        this.dict.shelfBinType = [
          { label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200 },
          { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 },
          { label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300 }
        ]
        this.defaultBinType = 200;
        canEdit = false;
        break
      case val < 140 && val > 119 :
        this.dict.shelfBinType = [
          { label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200 },
          { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 }
          // {label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300},
        ]
        this.defaultBinType = 200;
        canEdit = true;
        break
      case val == 1 :
      case val == 3 :
      case val < 900 && val > 800 :
        this.defaultBinType = 100;
        this.dict.shelfBinType = [
          // {label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200},
          { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 },
          { label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300 }
        ]
        canEdit = true;
        break
      case val == 5 :
      case val == 2 :
        this.defaultBinType = 300;
        canEdit = false;
        break;
      case val < 920 && val > 899 :
        this.formThree.shelfBinType = 100;
        this.dict.shelfBinType = [
          // {label: this.$t('lang.wms.fed.shelfClass.dynamic'), value: 200},
          { label: this.$t('lang.wms.fed.shelfClass.fixed'), value: 100 },
          { label: this.$t('lang.wms.fed.shelfClass.DDD'), value: 300 }
        ]
        canEdit = true;
        break;
      }
      return canEdit;
    },
    shelfTypeByCategory() {
      const shelfCategory = this.formOne.shelfCategory ? Number(this.formOne.shelfCategory) : '';
      const shelfTypeDict = this.dict.shelfType || [];
      // 虚拟货架：2
      // P标准货架(0-99):
      // --	1=P-隔板货、3=P-托盘货架、5=P-3D货架。
      // PoP货架(100-199)：
      // --	100=PoP-料箱货架、120=PoP-组合货架、140=PoP-料箱缓存货架。
      // RS货架(200-299):
      // --	200=R-料箱货架
      // 4Way货架(300-399)：
      // --	300=4WAY-料箱货架、320=4WAY-托盘货架。
      // 容器货架(801-899):
      // --	801=周转箱
      // 人工区货架(900-999):
      // --	900=人工-流利式货架、901=人工-隔板货架、902=人工-重型货架、903=人工-地面货架、920=人工-容器货架。
      let dict = [];
      switch (shelfCategory) {
      case 0 :
        // P
        dict = shelfTypeDict.filter(v => v.fieldCode >= 0 && v.fieldCode <= 99);
        break;
      case 1 :
        // Pop
        dict = shelfTypeDict.filter(v => v.fieldCode >= 100 && v.fieldCode <= 199);
        break;
      case 2 :
        // rs
        dict = shelfTypeDict.filter(v => v.fieldCode >= 200 && v.fieldCode <= 299);
        break;
      case 3 :
        // 4way
        dict = shelfTypeDict.filter(v => v.fieldCode >= 300 && v.fieldCode <= 399);
        break;
      case 8 :
        // 容器货架
        dict = shelfTypeDict.filter(v => v.fieldCode >= 801 && v.fieldCode <= 899);
        break;
      case 9 :
        // 人工区
        dict = shelfTypeDict.filter(v => v.fieldCode >= 900 && v.fieldCode <= 999);
        break;
      case '' :
        dict = [];
        break;
      default:
        dict = shelfTypeDict;
        return;
      }
      return dict;
    },
    shelfConnectLength() {
      return this.shelfExpress.filter(v => v.key === 'connect').length;
    }
  },
  async created() {
    await this.getDicts();
    this.showPage = true;
    console.log(this.dataSource, 'this.dataSource');
    let shelfInfoNew = this.dataSource?.shelfInfoNew;
    // 编辑回填
    if (shelfInfoNew && shelfInfoNew.classCode) {
      this.isEdit = true;
      this.isDetail = !!shelfInfoNew.isDetail;
      this.previewShelf(shelfInfoNew)
      // this.formOne = {
      //   ...this.formOne,
      //   ...scopeData,
      //   width: scopeData.width / 10 || 0,
      //   length: scopeData.length / 10 || 0,
      //   width: scopeData.width / 10 || 0,
      //   bearWeight: scopeData.bearWeight / 100 || 0,
      //   weight: scopeData.width / 100 || 0,
      // }
    }
  },
  methods: {
    classCodeInput() {
      if (!this.formOne.prefix) {
        this.formOne.prefix = this.formOne.classCode;
      }
    },
    // 货位类型联动
    shelfCateChange(val, refill) {
      const shelfCategory = val || val === 0 ? Number(val) : '';
      const shelfTypeDict = this.dict.shelfType || [];
      GET('/api_v1/shelfClass/getShelfTypeByShelfCategory', { fieldCode: shelfCategory }).then(res => {
        // 可绑定容器类型
        this.dict = Object.assign({ ...this.dict, shelfTypeNew: res.data || [] });
      })
      // 虚拟货架：2
      // P标准货架(0-99):
      // --	1=P-隔板货、3=P-托盘货架、5=P-3D货架。
      // PoP货架(100-199)：
      // --	100=PoP-料箱货架、120=PoP-组合货架、140=PoP-料箱缓存货架。
      // RS货架(200-299):
      // --	200=R-料箱货架
      // 4Way货架(300-399)：
      // --	300=4WAY-料箱货架、320=4WAY-托盘货架。
      // 容器货架(801-899):
      // --	801=周转箱
      // 人工区货架(900-999):
      // --	900=人工-流利式货架、901=人工-隔板货架、902=人工-重型货架、903=人工-地面货架、920=人工-容器货架。
      // let dict = [];
      // switch (shelfCategory) {
      //   case 0 :
      //     // P
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 0 && v.fieldCode <= 99);
      //     break;
      //   case 1 :
      //     // Pop
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 100 && v.fieldCode <= 199);
      //     break;
      //   case 2 :
      //     // rs
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 200 && v.fieldCode <= 299);
      //     break;
      //   case 3 :
      //     // 4way
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 300 && v.fieldCode <= 399);
      //     break;
      //   case 8 :
      //     // 容器货架
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 801 && v.fieldCode <= 899);
      //     break;
      //   case 9 :
      //     // 人工区
      //     dict = shelfTypeDict.filter(v => v.fieldCode >= 900 && v.fieldCode <= 999);
      //     break;
      //   case '' :
      //     dict = [];
      //     break;
      //   default:
      //     dict = shelfTypeDict;
      //     return;
      // }
      // this.dict.shelfTypeNew = dict;
      if (!refill) {
        this.formOne.shelfType = '';
      }
    },
    // 货位类型联动
    shelfTypeChange(val, refill) {
      const shelfType = val || val === 0 ? Number(val) : '';
      this.formOne.shelfCodeLen = '';
      this.disableShelfCodeLen = false;
      if (!shelfType) return;
      GET('/api_v1/shelfClass/getShelfCodeLen', { shelfType }).then(res => {
        // 回填货位长度并禁止修改
        this.formOne.shelfCodeLen = res.data || '';
        this.disableShelfCodeLen = !!res.data;
      })
    },
    async getDicts() {
      let params = {
        objectCodes: [
          'shelf_category', // 货架种类
          'shelf_type', // 货架类型
          'column_and_floor_order_rule', // 层列排序规则
          'column_and_floor_rule', // 格口规则
          'side_nums', // 面数
          'bin_code_rule_separator' // 分隔符
        ],
        enable: true,
        sortKey: 'sortOrder'
      };
      await POST('/api_v1/dict/batchQuery', params).then(res => {
        if (res.code === 0) {
          const toCamel = e => e.replace(/_(\w)/g, ($0, $1) => $1.toUpperCase());
          // 字典
          Object.keys(res.data).forEach(e => {
            const key = toCamel(e);
            if (!this.drop[key]) {
              this.drop[key] = {}
            }
            if (!this.dict[key]) {
              this.dict[key] = []
            }
            this.dict[key] = (res.data[e].details || []).map(v => {
              return {
                ...v,
                label: this.$t(v.fieldValue),
                value: e === 'bin_code_rule_separator' ? v.fieldCode : Number(v.fieldCode)
              }
            });
            // console.log(res.data[e].details, key);
            (res.data[e].details || []).map(v => {
              this.drop[key][v.fieldCode] = this.$t(v.fieldValue);
              return v;
            });
            // console.log(this.drop[key]);
          });
        }
      });
    },
    async nextStep() {
      const formArr = ['formOne', 'formTwo', 'formThree'];
      // console.log(this[formArr[this.active - 1]], formArr[this.active - 1]);
      if (this.active === 1) {
        let continueConnect = 0;
        this.shelfExpress.forEach(v => {
          if (v.key === 'connect') {
            continueConnect++;
          } else if (continueConnect === 1) {
            continueConnect--;
          }
        });
        if (continueConnect > 1) {
          // 前后不能为连接符号
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.multipleConnectorsNotAllowed') });
          return;
        }
        if (this.shelfExpress[0].key === 'connect' || this.shelfExpress[this.shelfExpress.length - 1].key === 'connect') {
          // 前后不能为连接符号
          // TODO:
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.formulaCannotStartOrEndWithAConnector') });
          return;
        }
        if (this.formOne.classCode && !this.isEdit && !this.isDetail) {
          await GET('api_v1/shelfClass/checkClassCode', {
            classCode: this.formOne.classCode,
            prefix: this.formOne.prefix,
            shelfCategory: this.formOne.shelfCategory,
            shelfType: this.formOne.shelfType
          }).then(res => {
            if (!res.code && res.data) {
              this.$refs[formArr[this.active - 1]].validate(valid => {
                if (valid) {
                  this.active = this.active + 1;
                  // 第二步默认值
                  const shelfSize = this.formTwo.shelfSide && this.shelfDataSides[this.formTwo.shelfSide].shelfSize || {};
                  // this.formTwo.layer = shelfSize.layer || this.formOne.shelfColumnMaxNum || '';
                  // this.formTwo.column =  shelfSize.column || this.formOne.shelfFloorMaxNum || '';
                  this.formTwo.layer = shelfSize.layer || '';
                  this.formTwo.column = shelfSize.column || '';
                  this.formTwo.sideAliasCode = this.formTwo.shelfSide && this.shelfDataSides[this.formTwo.shelfSide].sideAliasCode || this.formTwo.shelfSide || '';
                  if (this.formTwo.shelfSide) {
                    this.shelfDataSides[this.formTwo.shelfSide].sideAliasCode = this.formTwo.sideAliasCode;
                  }
                } else {
                  console.log('error submit!!');
                  if (this.isDetail) this.active = this.active + 1;
                  return false;
                }
              })
            } else {
              // this.$message({ type: 'error', message:this.$t('lang.wms.fed.shelfClass.shelfCodeAlreadyExists')})
              return;
            }
          })
          return;
        } else {
          this.currentShelfSide = {
            ...this.formOne,
            shelfSize: this.shelfDataSides[this.formTwo.shelfSide].shelfSize,
            shelfSide: this.formTwo.shelfSide,
            sideNum: this.formOne.sideNum || 1,
            formThree: this.formThree,
            formTwo: this.formTwo
            // formTwoRight: this.formTwoRight,
          };
        }
      } else if (this.active === 2) {
        let count = 0;
        let sideAliasCode = true;
        let sideAliasCodeList = [];
        SIDES.forEach((side, index) => {
          !!this.shelfDataSides[side].shelfSize && this.shelfDataSides[side].shelfSize.isCreated && count++;
          const currentAilasCode = this.shelfDataSides[side].sideAliasCode;
          if (!currentAilasCode && index < this.formOne.sideNum) {
            sideAliasCode = false;
          }
          if (!sideAliasCodeList.includes(currentAilasCode)) {
            // 不存在当前货架面别名,则push
            sideAliasCodeList.push(currentAilasCode);
          }
        })
        if (count < this.formOne.sideNum) {
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.checkShelfSideIsMaintainedCompletely') });
          return;
        }
        if (!sideAliasCode) {
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.checkShelfSideAliasAreMaintained') });
          return;
        }
        if (sideAliasCodeList.length < this.formOne.sideNum) {
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.checkShelfSideAliasIsDuplicated') });
          return;
        }
        this.shelfForm = this.$refs.shelf.getShelfData();
      } else if (this.active === 3) {
      }
      // console.log(this.$refs[formArr[this.active - 1]], 'this.$refs[formArr[this.active - 1]]')
      this.$refs[formArr[this.active - 1]].validate(valid => {
        if (valid) {
          this.active = this.active + 1;
          if (this.active === 3) {
            const shelfData = JSON.stringify({ ...this.shelfForm, currSide: 'F' })
            this.handleShelfSideClick('F');
            this.formThree.shelfSide = 'F'
            this.$nextTick(() => {
              this.$refs.shelfThree && this.$refs.shelfThree.setShelfData(JSON.parse(shelfData));
            })
          }
        } else {
          console.log('error submit!!');
          if (this.isDetail) this.active = this.active + 1;
          return false;
        }
      })
    },
    prevStep() {
      // TODO: 上一步重置当前步
      if (this.active === 3) {
        this.prevStepStatus = true;
        // this.handleShelfSideClick('F');
        // console.log(this.formTwo.shelfSide, this.formThree.shelfSide, 'aaaaaaaa')
        // this.formThree.shelfSide = 'F'
        // this.$confirm(this.$t('lang.wms.fed.continueConfirm')).then(res => {
        //   this.handleShelfSideClick('F');
        //   if (res === 'confirm') {
        //     this.$refs.formThree.resetFields();
        //     this.$refs.shelfThree.resetChoose();
        //     this.saveChoosedBins = {};
        //   } else {
        //     return;
        //   }
        // })
      } else if (this.active === 2) {
        // this.$confirm(this.$t('lang.wms.fed.continueConfirm')).then(res => {
        //   if (res === 'confirm') {
        //     this.$refs.formTwo.resetFields();
        //     this.formTwo = {
        //       shelfSide: 'F',
        //       layer: '',
        //       column: '',
        //       sideAliasCode: ''
        //     };
        //     this.shelfForm = '';
        //     this.currentShelfSide = {};
        //     this.shelfDataSides = {
        //       F: {},
        //       B: {},
        //       L: {},
        //       R: {},
        //     };
        //     this.$refs.shelf.reset()
        //   } else {
        //     return;
        //   }
        // })
      }
      this.active = this.active - 1;
      if (this.active === 2) {
        setTimeout(() => {
          this.handleShelfSideClick('F');
        }, 200)
      }
    },
    cancleEdit(e) {
      this.$emit('cancleEdit', e)
    },
    tabBeforeLeave(active, oldActive) {
      console.log(active, oldActive)
      if (this.prevStepStatus) {
        this.prevStepStatus = false;
        return;
      }
      const shelfSizeCurrent = this.shelfDataSides[oldActive].shelfSize || {};
      const shelfSize = {
        layer: this.formTwo.layer,
        column: this.formTwo.column,
        isCreated: !!shelfSizeCurrent.isCreated || this.isDetail || false
      }
      this.shelfDataSides[oldActive].shelfSize = shelfSize;
      this.shelfDataSides[oldActive].sideAliasCode = this.formTwo.sideAliasCode;
    },
    handleShelfSideClick(tab, event) {
      // 换面，存取当前面数据
      if (this.active === 3) {
          // 切面重置右侧选择
          this.$refs.shelfThree?.resetChoose();
          this.formThree = {
            ...this.formThree,
            layer: '',
            column: '',
            binBearWeight: '',
            // shelfBinType: '',
            bindContainer: '',
            cells: ''
            // binLayerRule: '',
            // binColRule: '',
          };
      }
      const side = tab?.name || tab;
      const shelfSize = this.shelfDataSides[side].shelfSize || {};
      if ((this.detail || this.isEdit) && this.shelfDataSides[side].shelfSize) {
        this.shelfDataSides[side].shelfSize.isCreated = true;
      }
      // this.formTwo.layer = shelfSize.layer || this.formOne.shelfColumnMaxNum || '';
      // this.formTwo.column =  shelfSize.column || this.formOne.shelfFloorMaxNum || '';
      this.formTwo.layer = shelfSize.layer || '';
      this.formTwo.column = shelfSize.column || '';
      this.formTwo.sideAliasCode = this.shelfDataSides[side].sideAliasCode || side || '';
      this.formTwo.shelfSide = side || '';
      if (!this.shelfDataSides[side].sideAliasCode) {
        this.shelfDataSides[side].sideAliasCode = side;
      }
      // console.log(tab, event, shelfSize);
      this.currentShelfSide = {
        ...this.formOne,
        shelfSize: shelfSize.isCreated ? shelfSize : {},
        shelfSide: side,
        sideNum: this.formOne.sideNum || 1,
        formThree: this.formThree,
        formTwo: this.formTwo,
        tabClick: true
        // formTwoRight: this.formTwoRight,
      };
      if (this.isDetail) {
          // 详情单选格口，切面清空当前选择
          this.$refs.shelfThree?.resetChoose();
          this.formThree.cells = '';
      }
      this.$nextTick(() => {
        this.$refs.formTwo && this.$refs.formTwo.clearValidate();
        // this.$refs.formTwoRight &&this.$refs.formTwoRight.clearValidate();
      })
    },
    // 创建初始样式
    createShelf() {
      // 校验层列
      this.$refs.formTwo.validate(valid => {
        if (valid) {
          // 校验别名
          if (this.shelfDataSides[this.formTwo.shelfSide] || this.isEdit) {
            this.$confirm(this.$t('lang.wms.fed.shelfClass.confirmToRecreate'), this.$t('lang.wms.fed.tips'), {
              confirmButtonText: this.$t('lang.wms.fed.determine'),
              cancelButtonText: this.$t('lang.wms.fed.cancel'),
              type: 'warning'
            }).then(() => {
              this.createShelfData();
            }).catch(() => {

            });
          } else {
            this.createShelfData();
          }
        } else {
          console.log('shelf layout error!');
          return false;
        }
      })
    },
    createShelfData() {
      const shelfSize = {
        layer: this.formTwo.layer,
        column: this.formTwo.column,
        isCreated: true
      }
      this.shelfDataSides[this.formTwo.shelfSide].shelfSize = shelfSize;
      this.shelfDataSides[this.formTwo.shelfSide].created = true;
      this.currentShelfSide = {
        ...this.formOne,
        shelfSize,
        shelfSide: this.formTwo.shelfSide,
        sideNum: this.formOne.sideNum || 1,
        formThree: this.formThree,
        formTwo: this.formTwo,
        created: true
        // formTwoRight: this.formTwoRight,
      };
    },
    shelfAlias() {
      this.shelfDataSides[this.formTwo.shelfSide].sideAliasCode = this.formTwo.sideAliasCode;
    },
    // 勾选深位
    changeDeep(val) {
      this.formOne.checked = val > 1;
      // 触发勾选事件
      this.deepCheck(this.formOne.checked);
    },
    shlefGenRules(val, num) {
      if (!val) return this.ruleStep1.pleaseCheckOut;
      let rule = this.ruleStep1.pleaseCheckOut;
      if (num) {
        var checkNum = (rule, value, callback) => {
          if (!value) {
            return callback(new Error(this.$t('lang.wms.fed.pleaseCheckOut')));
          }
          const patten = /^[1-9][0-9]*$/;
          if (!patten.test(value)) {
            callback(new Error(this.$t('lang.wms.fed.pleaseEnterAPositiveInteger')));
          } else {
            if (Number(value) > Number(val)) {
              callback(new Error(this.$t('lang.wms.fed.shelfClass.maximumNumberOfShelfFloor', [val])));
            } else {
              callback();
            }
          }
        };
        return [{
          required: true,
          validator: checkNum
          // pattern: `/^([1-9][0-9]{0,1}|${Number(val)})$/`,
          // message:this.$t(`货架层数最高不能超过${val}层`),
        }];
      }
      switch (val) {
      case 1:
        rule = this.ruleStep1.rule01_99;
        break;
      case 2:
        rule = this.ruleStep1.rule1_Z;
        break;
      case 3:
        rule = this.ruleStep1.ruleA_Z;
        break;
      }
      return rule;
    },
    // 勾选深位，增加深位编码
    deepCheck(val) {
      if (val) {
        if (!this.shelfExpress.filter(v => v.key === 'depthIndex').length) {
          this.shelfExpress.push({ key: 'depthIndex', label: this.$t('货架深位') })
        }
      } else {
        this.shelfExpress = this.shelfExpress.filter(v => v.key !== 'depthIndex');
      }
    },
    // 添加分隔符
    handleSplit(key) {
      if (key) {
        if (!this.formThree.connector) return;
        if (this.binExpress.filter(v => v.key === 'connect').length > 1) return;
        this.binExpress.push({ key: 'connect', label: this.formThree.connector || '-' })
        return
      }
      if (!this.formOne.connector) return;
      const connectLen = this.shelfExpress.filter(v => v.key === 'connect').length;
      const itemLen = this.shelfExpress.filter(v => v.key !== 'connect').length;
      // 没深位最多3个连接符
      if (connectLen >= (itemLen - 1)) return;
      this.shelfExpress.push({ key: 'connect', label: this.formOne.connector || '-' })
    },
    // 重置货位编码
    resetShelfExpress() {
      this.shelfExpress = [
        { key: 'shelfCode', label: this.$t('lang.wms.fed.shelfCode1') },
        { key: 'sideAliasCode', label: this.$t('lang.wms.fed.shelfClass.ShelfSide') },
        { key: 'layerCode', label: this.$t('lang.wms.fed.shelfClass.shelfLayerNumber') },
        { key: 'columnCode', label: this.$t('lang.wms.fed.shelfClass.numberOfShelfFloor') }
      ]
    },
    handleClose(tag, index, type) {
      switch (type) {
      case 'shelf':
        this.shelfExpress.splice(index, 1);
        break;
      case 'bin':
        this.binExpress.splice(index, 1);
        break;
      }
    },
    chosedCells(choosedBins) {
      // 回填选中的格口号
      let binType = '';
      const cells = Object.values(choosedBins).map(v => {
        // return v === 'refill' || !v ? '' : '' + v.binGroupDisplayCode;
        binType = v?.shelfBinType || ''
        return v === 'refill' || !v ? '' : '' + v.cell.binLayer + v.cell.binColumn;
      }).filter(v => !!v)
      this.formThree.cells = cells.join(',')
      let cellOne = cells.length && choosedBins ? Object.values(choosedBins).filter(v => v?.binGroupDisplayCode === cells[0]) : [];
      if (cellOne.length) {
        // 选中回填选中第一个数据
        // this.formThree.shelfBinType = binType
        // this.threeDimensionRules = {
        //   columnAndFloorOrderRule: this.formThree.columnAndFloorOrderRule,
        //   binLayerRule: this.formThree.binLayerRule,
        //   binColRule: this.formThree.binColRule,
        // }
        if (cellOne[0].shelfBinType == 300) {
          cellOne[0] = {
            ...cellOne[0],
            binLayerRule: this.formThree.binLayerRule || this.threeDimensionRules.binLayerRule,
            binColRule: this.formThree.binColRule || this.threeDimensionRules.binColRule,
            columnAndFloorOrderRule: this.formThree.columnAndFloorOrderRule || this.threeDimensionRules.columnAndFloorOrderRule,
            layer: this.formThree.layer || cellOne[0].binGroupFloorNum || '',
            column: this.formThree.column || cellOne[0].binGroupColumnNum || ''
          }
        }
        this.formThree = Object.assign({ ...this.formThree }, cellOne[0]);
      }
      // 所有选中的货格数据
      this.formThree.choosedBins = choosedBins;
      if (choosedBins.refill) {
        this.saveCells('', choosedBins.refill);
      }
    },
    // 选中格口，右侧数据联动
    singleChoose(cell) {
      const formThree = this.saveChoosedBins[cell]?.formThree || this.saveChoosedBins[cell] || '';
      console.log('singleChoose', formThree, cell, this.saveChoosedBins)
      if (formThree) {
        this.formThree = Object.assign({ ...this.formThree }, formThree, {
          layer: formThree.layer || '',
          column: formThree.column || ''
        });
      }
    },
    saveCells(event, refill) {
      // 保存选中格口数据
      const savedBins = Object.keys(this.saveChoosedBins) || [];
      // if (!savedBins.length) return;
      // 过滤已保存的格口，剩下的就是本次新增格口
      // const newChoosedBin = Object.keys(this.formThree.choosedBins).filter(bin => !(savedBins.includes(bin))).map(v => {
      if (this.formThree.shelfBinType == 300) {
        // 保存全局抽屉货位成列关系，后续支持格口维度不同
        this.threeDimensionRules = {
          columnAndFloorOrderRule: this.formThree.columnAndFloorOrderRule,
          binLayerRule: this.formThree.binLayerRule,
          binColRule: this.formThree.binColRule
        }
      }
      const newChoosedBin = Object.keys(this.formThree.choosedBins).map(v => {
        // this.formThree.choosedBins[v] = {
        //   ...this.formThree.choosedBins[v],
        //   formThree: this.formThree,
        // }
        const data = {
          ...this.formThree.choosedBins[v],
          formThree: { ...this.formThree.choosedBins[v] }
        }
        if (!refill) {
          data.formThree = this.formThree;
          data.shelfBinType = this.formThree.shelfBinType;
          data.binGroupType = this.formThree.shelfBinType;
        }
        this.saveChoosedBins[v] = { ...data };
        return v;
      })
      // this.saveChoosedBins = {
      //   ...this.saveChoosedBins,
      //   ...newChoosedBin,
      // };
      // this.$refs.formThree.resetFields();
      // 重置右侧货位数据
      this.formThree = {
        ...this.formThree,
        layer: '',
        column: '',
        binBearWeight: '',
        // shelfBinType: '',
        bindContainer: '',
        cells: ''
        // binLayerRule: '',
        // binColRule: '',
      };
        this.$refs.shelfThree?.resetChoose();
    },
    // 组装回填数据
    previewShelf(val) {
      const shelfData = val;
      const shelfDetail = shelfData.bindesc && JSON.parse(shelfData.bindesc) || ''
      const form = {
        ...shelfData,
        classCode: shelfDetail.classCode,
        sideNum: shelfDetail.sides.length,
        shelfCategory: shelfData.shelfCategory,
        shelfType: shelfData.shelfType,
        // shelfLayer: 1,
        shelfTotalHeight: 0,
        robotHeight: shelfDetail.baseHeight / 10,
        footHeight: shelfDetail.baseHeight / 10,
        shelfHeight: shelfDetail.height / 10,
        shelfWidth: shelfDetail.width / 10,
        shelfLength: shelfDetail.length / 10,
        height: shelfDetail.height / 10,
        width: shelfDetail.width / 10,
        length: shelfDetail.length / 10,
        weight: shelfData.weight / 1000,
        selfWeight: shelfData.weight / 1000,
        bearWeight: (shelfData.bearWeight || shelfDetail.bearWeight || 0) / 1000,
        shelfLen: 1,
        weight: '',
        shelfNumber: '',
        shelfBinList: [],
        binLayerRule: shelfDetail.binLayerRule || '',
        binColRule: shelfDetail.binColRule || ''
      };
      this.threeDimensionRules = {
        columnAndFloorOrderRule: shelfDetail.binGroupColumnAndFloorOrderRule ? Number(shelfDetail.binGroupColumnAndFloorOrderRule) : 1,
        binLayerRule: shelfDetail.binGroupFloorRule ? Number(shelfDetail.binGroupFloorRule) : '',
        binColRule: shelfDetail.binGroupColumnRule ? Number(shelfDetail.binGroupColumnRule) : ''
      }
      const saveChoosedBins = {};
      const shelfExpress = [];
      const shelfLangMap = {
        shelfCode: this.$t('lang.wms.fed.shelfCode1'),
        sideAliasCode: this.$t('lang.wms.fed.shelfClass.ShelfSide'),
        columnCode: this.$t('lang.wms.fed.shelfClass.numberOfShelfFloor'),
        layerCode: this.$t('lang.wms.fed.shelfClass.shelfLayerNumber')
      }
        shelfDetail.binCodeRuleList?.forEach(rule => {
          shelfExpress.push({ key: rule.field, label: shelfLangMap[rule.field], index: rule.index });
          if (rule.connect) {
            shelfExpress.push({ key: 'connect', label: rule.connect });
          }
        });
        this.shelfExpress = shelfExpress.length ? shelfExpress : this.shelfExpress;
        shelfDetail.sides.forEach(side => {
          this.shelfDataSides[side.sideCode] = {
            sideAliasCode: side.sideAliasCode,
            shelfSize: {
              layer: side.layers.length || 1,
              column: 1,
              isCreated: true
            }
          }
          side.layers.forEach(layer => {
            layer.binGroups.forEach((binGroup, gindex) => {
              let binCols = gindex + 1;
              let binLayers = 1;
              // form.shelfLen = binGroup.depthIndex || 1;
              const regex = /^[1-9]\d*$/;
              const columnIndex = regex.test(binGroup.columnIndex) ? binGroup.columnIndex - 1 : gindex;
              saveChoosedBins[`${side.sideCode}${layer.layerIndex - 1}${columnIndex}`] = {
                ...binGroup,
                columnIndex,
                shelfBinType: binGroup.binGroupType || 100 // 货位类型
              }
              form.shelfLen = 1;
              binGroup.bins.forEach(bin => {
                if (bin.binLayerIndex > binLayers) binLayers = bin.binLayerIndex;
                if (bin.binCols > binLayers) binCols = bin.binCols;
              })
              form.shelfBinList.push({
                ...binGroup,
                side: side.sideCode,
                floor: layer.layerIndex,
                // binColumn: gindex,
                binColumn: binGroup.columnCode,
                binLayer: layer.layerCode,
                layerIndex: layer.layerIndex,
                height: binGroup.binGroupHeight,
                width: binGroup.binGroupWidth,
                column: binCols,
                layer: binLayers,
                binLayerRule: binGroup.binGroupFloorRule,
                binColRule: binGroup.binGroupColumnRule
                // binGroupFloorRule: this.shelfData.formThree.binLayerRule, // 格口层规则
                // binGroupFloorMaxNum: this.shelfData.formThree.layer, // 格口层数
                // binGroupColumnRule: this.shelfData.formThree.binColRule, // 格口列规则
                // binGroupColumnMaxNum: this.shelfData.formThree.column, // 格口列数
              });
              this.shelfDataSides[side.sideCode].shelfSize.column = binCols || 1;
              const binExpress = [];
              const langMap = {
                binCode: this.$t('lang.wms.fed.item04393'),
                binColumnIndex: this.$t('lang.wms.fed.shelfClass.numberOfShelfFloor'),
                binLayerIndex: this.$t('lang.wms.fed.shelfClass.numOfShelfFloor')
              }
                binGroup.threeDimensionalBinCodeRuleList?.forEach(rule => {
                  binExpress.push({ key: rule.field, label: langMap[rule.field], index: rule.index });
                  if (rule.connect) {
                    binExpress.push({ key: 'connect', label: rule.connect });
                  }
                });
                this.binExpress = binExpress.length ? binExpress : this.binExpress;
            })
          })
        });
        this.saveChoosedBins = Object.assign({}, saveChoosedBins);
        this.formOne = {
          ...this.formOne,
          ...form
        }
        this.shelfCateChange(this.formOne.shelfCategory, 'refill');
        const currentShelfSide = this.shelfDataSides[this.formTwo.shelfSide];
        this.formTwo = {
          ...this.formTwo,
          ...form,
          ...currentShelfSide.shelfSize
        }
        this.formTwo.sideAliasCode = currentShelfSide.sideAliasCode;
        this.formThree = {
          ...this.formThree,
          ...form
        }
        console.log('editForm', this.formOne, this.formTwo, this.formThree);
    },
    // 保存货架
    submit(enable) {
      // console.log(this.$refs.shelf._getXML())
      // this.shelfForm = this.$refs.shelfThree.getShelfData();
      let savedBinCount = Object.keys(this.saveChoosedBins).length;
      let binCount = (this.shelfForm.shelfBinList || []).length;
      if (savedBinCount < binCount) {
        // TODO:
        // this.$message({ type: 'error', message:this.$t('请检查各面货位数据是否维护完整！')});
        this.$confirm(this.$t('lang.wms.fed.shelfClass.defaultGenerate')).then(res => {
          if (res === 'confirm') {
            this.submitAfterVerify(enable);
          } else {
            return;
          }
        })
        return;
      }
      this.submitAfterVerify(enable);
    },
    submitAfterVerify(enable) {
      const shelf = this.$refs.shelfThree._getXML({
        shelfDataSides: this.shelfDataSides,
        shelfExpress: this.shelfExpress,
        binExpress: this.binExpress,
        shefData: this.formOne,
        binData: this.formThree
      })
      console.log('submit', this.formOne, this.formTwo, this.formThree, shelf);
      // 改为后端拼接
      // let sideAliasCode = [];
      // SIDES.forEach(side => {
      //   sideAliasCode.push('' + side + (this.shelfDataSides[side].sideAliasCode || ''));
      // })
      const params = {
        ...this.formOne,
        robotHeight: this.formOne.footHeight * 10,
        bottomHeight: this.formOne.footHeight * 10,
        height: this.formOne.height * 10,
        length: this.formOne.length * 10,
        width: this.formOne.width * 10,
        weight: this.formOne.selfWeight * 1000,
        // bearWeight: this.formOne.bearWeight *100,
        bindesc: shelf,
        enabled: enable
        // sideAliasCode: sideAliasCode.join(','),
      }
      if (this.isEdit) {
        PUT('/api_v1/shelfClass/editShelfClass', params).then(res => {
          // 保存货架
          if (res.code === 0) {
            this.$message({ type: 'success', message: this.$t('lang.wms.fed.saveSuccess') });
            this.$emit('closeEdit', {})
          }
        })
        return;
      }
      POST('/api_v1/shelfClass/saveShelfClass', params).then(res => {
        // 保存货架
        if (res.code === 0) {
          this.$message({ type: 'success', message: this.$t('lang.wms.fed.saveSuccess') });
          this.$emit('closeEdit', {})
        }
      })
    },
    // 货位类型联动
    changeBinType() {
      // if (this.formThree.shelfBinType != 200) {
      //   // 非动态货位
      //   this.formThree.bindContainer = '';
      // } else {
      //   // 固定、抽屉
      //   this.binExpress = [
      //     {key: 'binCode', label: this.$t('lang.wms.fed.item04393')},
      //     // {key: 'connect', label: '-'},
      //     {key: 'binLayerIndex', label: this.$t('lang.wms.fed.shelfClass.numOfShelfFloor')},
      //     // {key: 'connect', label: '-'},
      //     {key: 'binColumnIndex', label: this.$t('lang.wms.fed.shelfClass.numberOfShelfFloor')},
      //   ];
      // }
      if (this.formThree.shelfBinType == 300) {
        this.formThree = {
          ...this.formThree,
          ...this.threeDimensionRules
        }
      }
    },
    shelfRuleChange(key, subKey, val) {
      let count = 0;
      switch (val) {
      case 1:
        count = 99;
        break;
      case 2:
        count = 35;
        break;
      case 3:
        count = 26;
        break;
      }
      if (key && subKey && count) {
        // 重置对应key值
        this[key][subKey] = count;
      } else {
        this[key][subKey] = '';
      }
    },
    // 复制面
    copySides(copyData) {
      const copyTo = copyData.copyTo;
      const copyFrom = copyData.copyFrom;
      copyTo.forEach(side => {
        this.shelfDataSides[side] = Object.assign({}, {
          ...this.shelfDataSides[copyFrom],
          sideAliasCode: this.shelfDataSides[side].sideAliasCode || side || ''
        });
      })
      this.$refs.shelf.copyShlefData(copyData);
      this.copyDialogVisible = false;
    },
    dialogCancel() {
      if (!this.copyDialogVisible) {
        const arr = [];
        Object.keys(this.shelfDataSides).forEach(side => {
          if (this.shelfDataSides[side]?.shelfSize) {
            arr.push(side);
          }
        })
        if (!arr.length) {
          this.$message({ type: 'error', message: this.$t('lang.wms.fed.shelfClass.noShelfCanCopying') });
          return;
        }
      }
      this.copyDialogVisible = !this.copyDialogVisible;
    }
  },
  destroyed() {
  }
}
</script>


<style  lang="scss" scoped>
.shelf-maintain{
  display: flex;
  flex-direction: column;
  height:100%;
  background: #fff;
}
.shelf-container{
  flex:1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .shelf-infos{
    flex:1;
  }
}
.shelf-steps{
  margin-bottom: 15px;
  // padding-bottom: 40px;
  // border-bottom: 2px solid #eee;
  .el-step
  ::v-deep {
    .el-step__head.is-process{
      border-color: #c0c4cc;
      color: #c0c4cc;
    }
  }
  .step-in-progress ::v-deep {
    .el-step__icon{
      background: #409eff;
    }
    .el-step__icon-inner{
      color: white;
    }
  }
}
.info-title{
  border-bottom: 2px solid #eee;
  margin-bottom: 10px;
  span{
    display: inline-block;
    padding: 0 10px;
    line-height: 20px;
    font-size: 14px;
    color:#66b1ff;
    border-bottom: 2px solid #66b1ff;
  }
}
.info-row{
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}
.shelf-expression{
  margin-left: 20px;
  padding: 0 10px;
  border: 1px solid var(--border-color-base,#dcdfe6);
  border-radius: 4px;
  line-height: 40px;
  cursor: pointer;
  .ignore{
   cursor: not-allowed;
  }
  .el-tag{
    margin-right: 10px;
    &.item-close{
      padding: 0 20px;
      ::v-deep i {
        top: -7px;
        right: -15px;
        color: white;
        background-color: #F56C6C;
        &:hover{
          color: white;
          background-color: red;

        }
      }
    }
  }
  &.bin-express{
    margin-left: 0;
    border: none;
  }
}
.bin-expression{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .el-tag{
    margin: 0 10px;
  }
}
.info-form{
  .el-form-item{
    width: 20%;
    padding-left: 20px;
    margin-bottom:0;
    ::v-deep{
      .el-form-item__label{
        line-height: 20px;
      }
    }
  }
  .el-select{
    width: 100%;
  }
}
.shlef-operates{
  padding:20px 0;
  text-align: center;
}
.shelf-infos{
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #eee;
  min-height: 400px;
}
.shelf-infos-center{
  display:flex;
  flex-direction: column;
  flex:1;
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  overflow:auto;
}
.shelf-infos-left, .shelf-infos-right{
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  overflow:auto;
  >div {
    width: 100px;
  }
  .el-button{
    width: 100px;
  }
}
.shelf-infos-left {
  padding: 20px;
}
.shelf-infos-left-big{
  width: 40%;
  overflow: auto;
}
.shelf-infos-right-big{
  border-left: 2px solid #eee;
  width: 60%;
  overflow: auto;
}
</style>
<style  lang="scss">
.shelf-maintain-page>div{
  height:100%;
}
</style>
