import axios from 'axios'
const request = opts => {
  let url = opts.url

  return new Promise(resolve => axios({
    // ...opts, url: process.env.VUE_APP_BASE_API + opts.url
    ...opts, url
  }).then(data => {
    while (!('code' in data)) data = data.data
    resolve(data)
  }))
}

const GET = (url, params) => request({ method: 'GET', url, params })
const POST = (url, data) => request({ method: 'POST', url, data })

export const listByCode = param => GET('/api/process/sceneField/list', param)

export const getFlowList = param => GET('/api/process/flow/queryPage', param)

export const selectNodeParam = param => GET('/api/process/node/selectNodeParams', param)

export const updateFlowGraph = param => POST('/api/process/flow/updateFlowGraph', param)

export const findOneFlow = param => GET('/api/process/flow/findOne', param)

export const nodeListInFlow = param => GET('/api/process/node/list', param)

export const resetItem = param => GET('/api/process/flowitem/resetItem', param)

export const entityTypeTree = param => GET('/api/common/metadata/entityTypeTree/v1', param)

export const getAllGlobalParam = (param) => POST('/api/process/flow/getAllGlobalParam', param)

export const updateAllGlobalParam = (param) => POST('/api/process/flow/updateAllGlobalParam', param)
// ---------------流程规则接口 ---------------
export const saveRule = param => POST('/api/process/rule/saveOne', param)

export const getNodeRule = param => GET('/api/process/rule/getNodeRule', param)

export const updateRule = param => POST('/api/process/rule/updateRule', param)


