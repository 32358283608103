<template>
  <div class="shelf-exit-dialog">
    <div class="dialog-main">
      <div class="dialog-left">
        <el-form :model="formOne" ref="formOne" :rules="rules" label-position="top">
          <div class="steps-procedure">
              <div class="step-procedure">
                  <div class="step-indicator">
                      <div class="step-indicator_num active">1</div>
                      <div class="step-indicator_line"></div>
                  </div>
                  <div class="step-container">
                      <div class="step-container_title">
                          <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}1</span>
                          <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfOut.scanLocationCellCode')}}</span>
                      </div>
                      <div class="step-operate">
                        <el-row :gutter="20">
                            <el-col :span="16">
                              <el-form-item prop="destCellCode" :rules="rules.isRequireInput">
                                  <el-input v-model="formOne.destCellCode" ref="mapPoint" @keyup.enter.native="checkFocus('mapPoint')" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                              </el-form-item>
                            </el-col>
                        </el-row>
                      </div>
                  </div>
              </div>
              <div class="step-procedure">
                  <div class="step-indicator">
                      <div class="step-indicator_num active">2</div>
                      <div class="step-indicator_line"></div>
                  </div>
                  <div class="step-container">
                      <div class="step-container_title">
                          <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}2</span>
                          <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfOut.inputShelfCode')}}</span>
                          <el-tooltip class="item" effect="dark" placement="right">
                            <div slot="content">{{$t('lang.wms.fed.shelfout.checkCondition')}}<br/>{{$t('lang.wms.fed.shelfOutSizeLimit10')}}</div>
                            <el-button type="text" icon="el-icon-question" style="font-size:20px;"><i></i></el-button>
                          </el-tooltip>
                      </div>
                      <div class="step-operate">
                        <el-row :gutter="20" style="display: flex; align-items: center">
                          <el-col :span="16">
                              <el-form-item prop="shelfCode" :rules="rules.isRequireInputAndMaxTen">
                                  <el-input ref="shelfCode" clearable v-model="formOne.shelfCode" type="textarea" :rows="20"  :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                              </el-form-item>
                          </el-col>
                          <el-col :span="6">
                              <el-button size="medium" type="primary" @click="startDeliver()">{{$t('lang.wms.fed.shelf.start.deliver')}}</el-button>
                          </el-col>
                        </el-row>
                      </div>
                  </div>
              </div>
          </div>
        </el-form>
      </div>
      <div class="dialog-right">
        <div class="steps-procedure">
            <div class="step-procedure">
                <div class="step-indicator">
                    <div class="step-indicator_num active">3</div>
                    <div class="step-indicator_line"></div>
                </div>
                <div class="step-container">
                    <div class="step-container_title">
                        <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}3</span>
                        <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfOut.shelfCodeOrBinCode')}}</span>
                        <!-- <span>请确保RMS货架模型规格编码一致</span> -->
                    </div>
                    <div class="step-operate" style="height: 200px">
                      <el-form :model="formTwo" ref="formTwo" :rules="rules" label-position="top">
                        <el-row :gutter="20">
                          <el-col :span="16">
                            <el-form-item prop="shelfCodeExit" :rules="rules.isRequireInput">
                                <el-input ref="shelfCodeExit" clearable v-model="formTwo.shelfCodeExit" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                            </el-form-item>
                          </el-col>
                          <el-col :span="8">
                            <el-button size="medium" type="primary" @click="submitExit()">{{$t('lang.wms.fed.shelfOut.confirm')}}</el-button>
                            <el-button size="medium" type="info" @click="returnShelf()">{{$t('lang.wms.fed.shelfout.goReturn')}}</el-button>
                          </el-col>
                        </el-row>
                      </el-form>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <!-- <div class="dialog-footer">
        <el-button size="medium" plain @click="handleCancel()">{{$t('lang.wms.fed.cancel')}}</el-button>
        <el-button size="medium" type="primary" @click="save()">{{$t('lang.wms.fed.save')}}</el-button>
    </div> -->
  </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
export default {
  name: 'GeekShelfExit',
  options: {
    name: '货架离场',
    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
      click: '点击'
    }
  },
  props: {
    drop: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    const maxTen = (rule, value, callback) => {
      if (!value) {
        callback(new Error(this.$t('lang.wms.fed.pleaseCheckOut')));
      }
      let reg = /^(.*\n?){0,9}.*$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.shelfCheck.maxSize', ['10'])));
      } else {
        callback();
      }
    };
    return {
      rules: {
        shelfClassCode: [{ required: true, message: this.$t('lang.wms.fed.pleaseCheckOut'), trigger: 'change' }],
        isRequireInput: [{ required: true, message: this.$t('lang.wms.fed.pleaseCheckOut'), trigger: 'blur' }],
        isRequireInputAndMaxTen: [{ required: true, validator: maxTen, trigger: 'change' }],
      },
      shelfList: [],
      formOne: {
        shelfCode: '',
        destCellCode: '' // 离场地图点位
      },
      formTwo: {
        shelfCodeExit: ''
      },
      dict: {
        shelfAngle: [
          {label: '-90', value: -90},
          {label: '0', value: 0},
          {label: '90', value: 90},
          {label: '180', value: 180}
        ]
      },
      tableList: [],
      stationList: [],
      strategyTypes: []
    }
  },
  created() {
    
  },
  mounted() {
    if (this.$refs.mapPoint) {
      this.$nextTick(() => {
        this.$refs.mapPoint.focus()
      })
    }
  },
  methods: {
    // 搬运
    startDeliver() {
      this.$refs.formOne.validate((valid) => {
        const shelfCode = this.formOne.shelfCode.split(/[\r\n]/).map( item => item.replace(/\s*/g,""))
        if (valid) {
          let params = {
            shelfCode: shelfCode.join(','),
            destCellCode: this.formOne.destCellCode
          }
          POST('/api/shelf/operation/shelfOutDeliver', params).then(res => {
            if (res.code === 0) {
              this.$message({ type: 'success', message: this.$t(res.data || 'lang.wms.fed.success') });
              // this.handleCancel()
            } else {
              // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
            }
          })
        } else {

        }
      })
    },
    submitExit() {
      // 确认离场
      this.$refs.formTwo.validate((valid) => {
        if (valid) {
          let params = {
            shelfCode: this.formTwo.shelfCodeExit,
            destCellCode: this.formOne.destCellCode
          }
          if (!this.formOne.destCellCode) {
            this.$refs.mapPoint.focus()
            return;
          }
          window.hideErrorResponseMsg = true;
          POST('/api/shelf/operation/shelfOutConfirm', params).then(res => {
            if (res.code === 0) {
              this.$message({ type: 'success', message: this.$t(res.data || 'lang.wms.fed.shelfDispatch.success') });
              this.formTwo.shelfCodeExit = ''
              window.hideErrorResponseMsg = false
            } else {
              // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
            }
          }).finally(() => {
            window.hideErrorResponseMsg = false
          })
        }
      })
    },
    returnShelf() {
      // 回库
      this.$refs.formTwo.validate((valid) => {
        if (valid) {
          let params = {
            shelfCode: this.formTwo.shelfCodeExit,
            destCellCode: this.formOne.destCellCode
          }
          POST('/api/shelf/operation/shelfOutGoReturn', params).then(res => {
            if (res.code === 0) {
              this.$message({ type: 'success', message: this.$t(res.data || 'lang.wms.fed.success') });
              // this.handleCancel()
              this.formTwo.shelfCodeExit = ''
            } else {
              // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
            }
          })
        }
      })
    },
    handleCancel() {
      this.$emit('cancel', {})
    },
    checkFocus(type) {
      switch (type) {
        case 'mapPoint':
          this.$nextTick(() => {
            this.$refs.shelfCode.focus()
          })
          break;
      }
    },
    selectChange() {
      this.$nextTick(() => {
        if (!this.formOne.destCellCode) {
          this.$refs.mapPoint && this.$refs.mapPoint.focus()
        } else {
          this.$refs.shelfCode && this.$refs.shelfCode.focus()
        }
        this.$refs.formOne.clearValidate();
      })
    },
    setActicve(name) {
      console.log(name)
      this.name = name
      if (name === 'tab-exit') {
        this.selectChange();
      }
    },
    onScan(e) {
      if (!this.formOne.destCellCode) {
        this.formOne.destCellCode = e
      } else {
        this.formOne.shelfCode = e
      }
    }
  },
  activated() {
    console.log('aexit', this.name)
    if (this.$refs.mapPoint && this.name === 'tab-exit') {
      this.$nextTick(() => {
        this.$refs.mapPoint.focus()
      })
    }
    // window.addEventListener("adminScanEvent", this.onScan, false);
  },
  deactivated() {
    console.log('dexit', this.name)
    this.$refs.formOne.clearValidate();
    // window.removeEventListener("adminScanEvent", this.onScan, false);
  },
  destroyed() {
    // window.removeEventListener("adminScanEvent", this.onScan, false);
  },
}
</script>


<style lang="scss" scoped>
.shelf-exit-dialog{
  display: flex;
  flex-direction: column;
  flex:1;
  .dialog-main{
     display: flex;
    flex-direction: row;
    flex:1;
  }
  .dialog-left{
    flex:1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-right: 10px;
    overflow: auto;
  }
  .dialog-right{
    flex:1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-left: 10px
  }
}
.dialog-subTitle{
  font-size: 20px;
}
.step-choose-content{
  min-height: 100px;
  .el-tag{
    margin-right:10px;
  }
}
.steps-procedure{
    width: 100%;
}
.step-procedure{
    // min-height: 100px;
    display: flex;
    position: relative;
    flex-shrink: 1;
}
.step-indicator{
    position: relative;
    width:24px;
    flex-grow: 0;
    .step-indicator_num{
        position: relative;
        display: flex;
        width: 24px;
        height: 24px;
        font-size: 14px;
        border: 2px solid #c0c4cc;
        border-radius: 50%;
        background: #fff;
        justify-content: center;
        align-items: center;
        z-index:1;
        &.active {
            border-color:  #409eff;
            color:  #409eff;
        }
    }
    .step-indicator_line{
        position: absolute;
        top:0;
        bottom: 0;
        left: 11px;
        border: 2px solid #c0c4cc;
        &.active {
            border-color:  #409eff;
        }
    }
}
.step-container{
    padding-left: 10px;
    flex-grow: 1;
    .step-container_title{
        span{
            margin-right: 20px;
            font-size: 16px;
        }
        .step-container_subtitle {
            font-size: 14px;
        }
    }
}
.geek-step-procedure {
  display: flex;
  flex-flow: column;
  padding: 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  overflow: hidden;
  .geek-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      align-items: center;
      > * {
        margin-left: 0;
        margin-right: 18px;
        cursor: pointer;
      }
    }
    ::v-deep {
      .el-form-item {
        margin: 0;
      }
    }
  }
  .geek-card-body {
    height: 0;
    flex: 1;
    overflow: auto;
    ::v-deep {
      .geek-table {
        height: 100%;
        margin-bottom: 0;
        .el-table {
          max-height: 100% !important;
          position: relative;
          .el-table__header-wrapper {
            .cell {
              position: relative;
            }
          }
          .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            max-height: 100% !important;
            position: absolute;
            top: 48px;
            bottom: 0;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
.el-form{
  .el-select{
    width: 100%;
  }
}
.dialog-footer{
  margin-top: 20px;
  text-align: center;
}
</style>
