<template>
    <div class="exception-container">
		<el-tabs v-model="activeName" @tab-click="handleClick" style="padding: 0 20px">
				<el-tab-pane :label='$t("lang.common.exceptionManage.downloadLog")' name="first"></el-tab-pane>
				<el-tab-pane :label='$t("lang.common.exceptionManage.downloadResult")' name="second"></el-tab-pane>
		</el-tabs>
		<div v-if="activeName === 'first'">
			<div class="search-box">
				<el-form :model="form" ref="searchForm" :rules="rules" :label-position="'left'">
						<el-row>
								<el-col :span="6">
										<el-form-item prop="status" :label='$t("lang.common.exceptionManage.pleaseChooseSystem")'>
											<el-select clearable v-model="form.system" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
												<el-option
												v-for="(item, index) in (params.systemList || [])"
												:key="index"
												:label="$t(item.name)"
												:value="item.code">
												</el-option>
											</el-select>
										</el-form-item>
								</el-col>
								<el-col :span="10">
									<el-form-item prop="timeAtStr" :label='$t("lang.common.exceptionManage.extractTimeRange")'>
										<el-date-picker
											v-model="form.timeAtStr"
											type="datetimerange"
											range-separator="-"
											:start-placeholder="$t('lang.common.exceptionManage.startTime')"
											:end-placeholder="$t('lang.common.exceptionManage.endTime')">
										</el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="6" style="margin-top:40px;">
										<el-button size="medium" type="primary" @click="generate()">{{$t('lang.common.exceptionManage.analysisAndGenerateLog')}}</el-button>
								</el-col>
						</el-row>
				</el-form>
			</div>
			<div class="table-area" style="height:200px; display:flex;flex-direction: column; align-items: center; justify-content:center;">
				<div v-if="showTxt" style="margin-bottom: 40px;">{{$t('lang.common.exceptionManage.pleaseWaitLogAnalysis')}}......</div>
				<div v-if="showTxt">{{$t('lang.common.exceptionManage.logAnalysisFinish')}}</div>
			</div>
		</div>
		<div v-if="activeName === 'second'">
			<div class="search-box">
				<el-form :model="formReport" ref="searchForm" :rules="rules" :label-position="'left'">
					<el-row>
						<el-col :span="6">
							<el-form-item prop="sourceSystemStr" :label='$t("lang.common.exceptionManage.system")'>
								<el-select clearable v-model="formReport.system" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="status" :label='$t("lang.common.exceptionManage.status")'>
								<el-select clearable v-model="formReport.status" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.statusList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6" style="margin-top:40px;">
							<el-button size="medium" type="primary" @click="search()">{{$t('lang.common.exceptionManage.query')}}</el-button>
						</el-col>
					</el-row>
				</el-form>
			</div>
			<div class="table-area">
				<el-table
					ref="mainTable"
					:data="tableData"
					border
					height="500"
					style="width: 100%">
					<el-table-column
					prop="index"
					:label="$t('lang.common.exceptionManage.index')"
						width="50">
						<template slot-scope="scope">
							{{scope.$index + 1}}
					</template>
				</el-table-column>
				<el-table-column
					prop="system"
					width="120"
					:label="$t('lang.common.exceptionManage.sourceSystem')">
						<template slot-scope="scope">
							{{drop.systemList[scope.row.system] || scope.row.system}}
					</template>
				</el-table-column>
				<el-table-column
					prop="timeStartAt"
					:label="$t('lang.common.exceptionManage.startTime')">
					<template slot-scope="scope">
							{{format(scope.row.timeStartAt)}}
					</template>
				</el-table-column>
				<el-table-column
					prop="timeStartAt"
					:label="$t('lang.common.exceptionManage.endTime')">
					<template slot-scope="scope">
							{{format(scope.row.timeEndAt)}}
					</template>
				</el-table-column>
					<el-table-column
					prop="status"
					:label="$t('lang.common.exceptionManage.status')">
					<template slot-scope="scope">
						{{drop.statusList[scope.row.status] || scope.row.status}}
					</template>
					</el-table-column>
					<el-table-column
					prop="creator"
					width="150"
					:label="$t('lang.common.exceptionManage.creator')">
					</el-table-column>
					<el-table-column
					prop="createAt"
					width="150"
					:label="$t('lang.common.exceptionManage.createAt')">
					<template slot-scope="scope">
						{{format(scope.row.createAt)}}
					</template>
					</el-table-column>
					<el-table-column
					fixed="right"
					:label="$t('lang.common.exceptionManage.operation')"
					width="100">
					<template slot-scope="scope">
						<el-button  @click.stop="downLoad(scope.row)" type="text" size="small">{{$t('lang.common.exceptionManage.download')}}</el-button>
					</template>
					</el-table-column>
				</el-table>
				<div style="display:flex; justify-content:right;">
						<el-pagination
						style="padding: 10px"
						border
						:current-page="pageData.pageNo"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="pageData.pageSize"
						layout="total, sizes, prev, pager, next"
						:total="pageData.recordCount"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange">
						</el-pagination>
				</div>
			</div>
		</div>
    </div>
</template>

<script>
import axios from "axios";
import { initParams, getTable, generate } from '../../../api/exceptionLogs'
export default {
	name: 'GeekExceptionLog',
    options: {
        name: '异常日志',
        parents: ['all'], // all
        groups: ['自定义页面'],
        events: {
            cancleEdit: '取消',
            closeEdit: '关闭'
        }
    },
	data() {
		return {
            rules: {},
			activeName: 'first',
			form: {
				system: '',
				timeAtStr: [],
			},
			formReport: {
				system: '',
				status: ''
			},
			drop: {},
			pageData: {
				"pageNo": 1,
				"pageSize": 10,
				"recordCount": 0,
				"pageCount": 0,
			},
			detailData: '',
			editTableData: [],
			tableData: [],
			dialogTableVisible: false,
			params: {},
			showTxt: false,
		}
	},
	async created (){
		// 获取基础数据
		await initParams().then(res => {
			if(!res.code) {
				this.params = res.data || {};
				for (let key in this.params) {
					const keyMap = {}
					this.params[key].map(v => {
						keyMap[v.code] = this.$t(v.name);
						return v;
					})
					this.drop[key] = keyMap;
				}
			}
		})
		// this.initTable();
	},
	methods: {
		initTable(detailData) {
			const {pageSize, pageNo} = this.pageData;
			const params = {
				pageSize, 
				pageNo, 
				...this.formReport,
				// timeAtStr: this.form.timeAtStr.length ? this.form.timeAtStr.map(v => new Date(v).getTime()).join('-') : '',
				// updateAtStr: this.form.updateAtStr.length ? this.form.updateAtStr.map(v => new Date(v).getTime()).join('-') : '',
			};
			getTable(params).then(res => {
				console.log(res)
				if(!res.code) {
					this.tableData = res.data?.recordList || [];
					this.pageData.pageNo = res.data?.currentPage;
					this.pageData.recordCount = res.data?.recordCount;
				}
			})
		},
		search() {
            this.pageData.pageNo = 1;
            this.initTable();
		},
		reset() {
			this.$refs.searchForm.resetFields();
			// this.handleEditToggle(false);
			// this.initTable();
		},
		handleClick(tab) {
			if(tab.name === 'second') {
				this.initTable();
				this.showTxt = false;
			}
		},
		handleSizeChange(val) {
			this.pageData.pageSize = val;
			this.pageData.pageNo = 1;
			this.initTable();
		},
		handleCurrentChange(val) {
			this.pageData.pageNo = val;
			this.initTable();
		},
		generate() {
			generate({
				...this.form,
				timeAtStr: this.form.timeAtStr.length ? this.form.timeAtStr.map(v => new Date(v).getTime()).join('-') : '',
			}).then(res => {
				if(!res.code) {
					this.showTxt = true;
				}
			})
		},
		// 导出确定按钮
		downLoad(row) {
			if(!row.id) return;
			this.exportList(`/beetle/api/common/exception-manage/exception-log/download?id=${row.id}`);
		},
		// 下载
		exportList(url) {
			if (document.querySelector('#download-iframe')) {
				document.getElementById('download-iframe').remove();
			}
			const iframe = document.createElement('iframe');
			iframe.onload = (e) => {
				const data = JSON.parse(e.target.contentWindow.document.body.innerText);
				this.$message({
					message: this.$t(data.msg),
					type: 'error',
					duration: 3000,
					showClose: true,
				});
			};
			iframe.setAttribute('id', 'download-iframe');
			iframe.src = url;
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		},
		format(date, formatter='YYYY-MM-DD HH:mm:ss') {
			let d = date
			if (d === 0 || d === '') {
			return ''
			} else if (typeof d === 'string') {
			if (/^\d+$/.test(d)) {
				d = new Date(+d)
			} else {
				d = new Date(d)
			}
			} else if (Number.isInteger(d)) {
			d = new Date(d)
			}
		
			if (d instanceof Date && !isNaN(d.getTime())) {
			const year = d.getFullYear()
			const month = d.getMonth() + 1
			const day = d.getDate()
			const h = d.getHours()
			const m = d.getMinutes()
			const s = d.getSeconds()
		
			return formatter
				.replace(/yyyy/i, year)
				.replace(/yy/i, year.toString().substr(2))
				.replace('MM', month.toString().padStart(2, '0'))
				.replace('M', month)
				.replace(/dd/i, day.toString().padStart(2, '0'))
				.replace(/d/i, day)
				.replace(/hh/i, h.toString().padStart(2, '0'))
				.replace('mm', m.toString().padStart(2, '0'))
				.replace(/ss/i, s.toString().padStart(2, '0'))
			}
			return date ?? ''
		}
	},
	destroyed() {
	}
}
</script>

<style lang="scss">
.except-dialog{
	.el-dialog__body{
		padding-top: 0;
	}
}

.exception-message{
	z-index: 9999 !important;
}
.exception-container{
	background: #eee;
	.search-box{
		padding: 20px;
		background: #fff;
	}
	.el-tabs{
		margin-bottom: 10px;
		background: #fff;
	}
	.el-tabs__header{
		margin-bottom: 0;
	}
	.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
		width: 100% !important;
		line-height: 40px !important;
		height: 40px !important;
	}
	.export-key-list {
		margin-left: 1%;
		.el-checkbox{
			width: 16%;
			margin-right: 2%;
		}
	}
	.el-table .cell {
		white-space: nowrap;
		// overflow: inherit;
		text-overflow: initial;
		word-wrap: break-word;
		word-break: break-all;
	}
	.switch-up{
		color: #409eff;
		cursor: pointer;
	}
	.fold-wrapper{
		margin-left: 10px;
		line-height: 40px;
	}
}
</style>
<style lang="scss" scoped>
.exception-container{
	.dialog-footer{
		text-align: center;
	}
	.el-form-item{
		text-align: left;
	}
	.exception-container{
		background: #eee;
	}
	.search-box{
		padding: 20px;
		background: #fff;
		.el-form-item {
				padding-right: 20px;
		}
		::v-deep {
				.el-form--label-top .el-form-item__label{
						padding: 0;
						line-height:24px;
				}
		}
		.el-select{
				width: 100%;
		}
		.search-btns{
				display: flex;
				flex-direction: row;
				justify-content: right;
		}
	}
	.main-opts{
		margin-bottom: 10px;
	  display: flex;
	  justify-content: right;
	}
	.table-area{
		margin: 20px 0;
		padding: 20px;
		background: #fff;
		width: 100%;
		box-sizing: border-box;
	} 
	.el-table {
	}
	.except-title{
		padding-right: 40px;
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
	.except-detail-dialog{
	  display: flex;
	  flex-direction: column;
	  flex:1;
		.except-title{
			display: flex;
			justify-content: space-between;
		}
	  .dialog-main{
		 display: flex;
		flex-direction: row;
		flex:1;
	  }
	  .log-info{
		min-height: 300px;
		max-height: 600px;
		overflow: auto;
	  }
	}
	.el-form{
	  .el-select{
		width: 100%;
	  }
	}
	.dialog-footer{
	  margin-top: 20px;
	  text-align: center;
	}
}
</style>