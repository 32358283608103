<template>
  <div class="geek">
    <leivii-editor v-model="pageData" v-if="pageVisible" :key="key" @change="handleSave">
      <div slot="title">
        <div class="back-button" @click="$router.push({ path: $route.meta.path, query: {v: version.version }})">
          <i class="el-icon-back"></i> 返回
        </div>
      </div>
      <template slot="operations">
        <div class="operation" title="创建新版本" @click="handleCreate">
          <geek-icon icon-class="create" class="icon" />
        </div>
        <!-- <div class="operation" title="打开">
          <el-popover v-if="versions.length" v-model="versionVisible" placement="bottom-end" width="300" popper-class="choose-version" trigger="click">
            <div class="versions">
              <div class="version flex-middle" v-for="(t, i) in versions" :key="i" @click="chooseVersion(t.version)">
                <div class="dname">
                  <div class="ellipsis" :class="{'c-primary': version && t.version == version.version}">{{ t.remark }} v{{ t.version }} {{t.active ? '（已启用）' : ''}}</div>
                </div>
                <el-link v-if="!t.active" type="primary" class="fs" @click.stop="enableVersion(t)">启用</el-link>
              </div>
            </div>
            <div slot="reference" class="flex-middle">
              <geek-icon icon-class="open" class="icon" />
            </div>
          </el-popover>
          <geek-icon v-else icon-class="open" class="icon c-info" />
        </div> -->
        <div class="operation" title="导出页面数据" @click="saveChangeData">
          <geek-icon icon-class="download" class="icon" />
        </div>
        <div class="operation" title="上传git" @click="updateChangeData()" v-if="PRIVATETOKEN">
          <geek-icon icon-class="upload" class="icon" />
        </div>
      </template>
    </leivii-editor>
  </div>
</template>

<script>
import { Leivii } from 'leivii-editor'
import axios, { GET, POST, PUT, DELETE } from '@/utils/http'
import { format } from '@/utils/dataFormat'

export default {
  name: 'Editor',
  data() {
    return {
      key: 1,
      pageData: null,
      versions: [],
      version: null,
      versionVisible: false,
      pageId: this.$route.meta.id,
      pageVisible: false,
      PRIVATETOKEN: localStorage.getItem('PRIVATE-TOKEN')
    }
  },
  watch: {
    pageVisible() {
      this.key++
    }
  },
  mounted() {
    this.loadDataView(this.pageId)
    // this.initPage(this.$route.query?.v)
    // this.getVersions()
  },
  destroyed() {
    this.pageVisible = false
    this.cachePageData()
  },
  methods: {
    loadDataView(name) {
      this.pageVisible = false
      axios.create({ baseURL: '' })
        .get(`${process.env.VUE_APP_VIEW_PATH}${name}.json?v=${+new Date()}`)
        .then(data => this.pageData = Object.assign({}, data.data))
        .catch(err => {
          this.pageData = null
          this.$message({ type: 'warning', message: this.$t('lang.wms.fed.noThisPage') })
        }).finally(() => this.pageVisible = true)
    },
    initPage(version) {
      this.pageVisible = false
      let pageData = this.getPageData()
      if (pageData) return this.initData(pageData)

      GET(`/api/lowcode/${this.pageId}${version ? '?version=' + version : ''}`).then(data => {
        try {
          if (data.data?.content) {
            if (JSON.stringify(this.pageConfig) !== data.data.content) {
              let pageConfig = JSON.parse(data.data.content)
              this.pageData = pageConfig
              this.version = data.data
              this.$store.commit('leivii/SET_PAGE_CONFIG', pageConfig)
              this.$store.commit('leivii/SET_PAGE', { id: this.pageId, status: 1 })
              this.$store.dispatch('leivii/setSelected', { $id: pageConfig.$id, $type: pageConfig.$type })
              this.pageVisible = true
            }
          } else {
            this.handleCreate()
          }
        } catch {}
      })
    },
    getPageData() {
      const pageDatas = Leivii.Dict.pageMap.get(this.pageId) || []
      return pageDatas.find(item => item.version?.version === +(this.$route.query?.v || 1))
    },
    initData(pageData) {
      let { status, selected, pageConfig, version } = pageData
      this.pageData = pageConfig
      this.version = version
      this.$store.commit('leivii/SET_PAGE_CONFIG', pageConfig)
      this.$store.dispatch('leivii/setSelected', selected)
      this.$store.commit('leivii/SET_PAGE', { id: this.pageId, status })
      this.$nextTick(() => this.pageVisible = true)
    },
    getVersions() {
      POST(`/api/lowcode/${this.pageId}/list?pageNo=1&pageSize=100`).then(data => {
        this.versions = data.data.recordList || []
      })
    },
    chooseVersion(version) {
      if (this.$store.state.leivii.page.id && this.$store.state.leivii.page.status === 0) {
        this.$confirm('切换版本将不保存当前修改，请确认', '警告').then(() => {
          this.$router.push({ path: this.$route.path, query: { v: version } })
        })
      } else {
        this.$router.push({ path: this.$route.path, query: { v: version } })
      }
      this.versionVisible = false
    },
    enableVersion(version) {
      PUT(`/api/lowcode/${this.pageId}/active?version=${version.version}`).then(data => {
        this.$message({ type: 'success', message: '启用成功' })
        this.getVersions()
      })
    },
    cachePageData() {
      // 缓存数据
      let pageDatas = [Leivii.Dict.pageMap.get(this.pageId)].flat().filter(Boolean)
      let i = pageDatas.findIndex(item => item.version.version === this.version.version)
      let ss = {
        version: this.version,
        status: this.$store.state.leivii.page.status,
        selected: {
          $id: this.$store.state.leivii.selected.$id,
          $type: this.$store.state.leivii.selected.$type
        },
        pageConfig: JSON.parse(JSON.stringify(this.$store.state.leivii.pageConfig))
      }
      if (i === -1) {
        pageDatas.push(ss)
      } else {
        pageDatas.splice(i, 1, ss)
      }
      console.log('pageDatas', pageDatas)
      Leivii.Dict.pageMap.set(this.pageId, pageDatas)
    },
    handleCreate() {
      let content = JSON.stringify(this.$store?.state?.leivii?.pageConfig) || JSON.stringify({
        $id: 'geek-page',
        $type: 'geek-page',
        $visible: true
      })
      POST('/api/lowcode', {
        permissionCode: this.pageId,
        content,
        remark: this.$route.meta.name
      }).then(data => {
        this.chooseVersion(data.data.version)
        this.$message({ type: 'success', message: '创建成功' })
      })
    },
    handleSave(e) {
      console.log('save data', e)
      // this.saveChangeData(e);
      return POST(`${location.origin}/saveLowCodeToFile`, {
        filename: `${this.pageId}.json`,
        content: e
      }).then(res => {
        if (!res.code) {
          this.$message({ type: 'success', message: '保存成功' })
        } else {
          this.$message({ type: 'error', message: '保存失败' })
        }
      })
      PUT('/api/lowcode', {
        version: this.version.version,
        permissionCode: this.version.permissionCode,
        content: JSON.stringify(e),
        remark: this.version.remark
      })
      // localStorage.setItem(`leivii-${this.pageId}`, JSON.stringify(this.$store.state.pageConfig))
      setTimeout(() => {
        this.$store.commit('leivii/SET_PAGE', { id: this.pageId, status: 1 })
      }, 1)

      this.$message({ type: 'success', message: '保存成功' })

      this.updateChangeData('update')
    },
    // 保存数据到本地
    saveChangeData() {
      let content = this.$store?.state?.leivii?.pageConfig
      const elementA = document.createElement('a'); // 创建一个a标签用于下载
      // 页面名称_页面code_页面版本_导出时间
      elementA.download = `${this.version.remark}_${this.pageId}_${this.version.version}_${format(new Date())}`; //  设置要保存文件的名称
      elementA.style.display = 'none'; // 将a标签隐藏 防止影响页面，根据情况来定义

      const blob = new Blob([JSON.stringify(content)]); // 使用Blob对象将数据转换为类文件对象

      //  关于Blob对象的使用方式可以参考
      // https://developer.mozilla.org/zh-CN/docs/Web/API/Blob

      elementA.href = URL.createObjectURL(blob); //  将Blob类文件对象转换成包含文件信息的URL，并设置进a标签

      //  将a标签添加到DOM并触发点击事件就能将数据下载下来
      document.body.appendChild(elementA);
      elementA.click();
      document.removeChild(elementA);
    },
    // 上传数据到本地
    updateChangeData(action = 'create') {
      if (!this.PRIVATETOKEN) return
      let content = this.$store?.state?.leivii?.pageConfig
      let dataBody = {
        branch: 'eos-data-view-520', // TODO 存储数据分支
        commit_message: 'git自动上传' + `${this.version.remark}V${this.version.version}`,
        actions: [
          {
            action: action, // TODO filePath若存在action更改为“update”
            file_path: `dataview/${this.pageId}-${this.version.remark}-V${this.version.version}.json`,
            content: JSON.stringify(content, null, 2)
          }
        ]
      }
      axios.post('/gitlab', dataBody, {
        baseURL: '/',
        headers: {
          'Access-Control-Allow-Origin': '*', // 解决cors头问题
          'Content-Type': 'application/json',
          'PRIVATE-TOKEN': this.PRIVATETOKEN // 个人访问令牌
        }
      }).then(res => console.log('content', res))
    }
  }
}
</script>

<style>
html, body, .geek {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
</style>
