<template>
  <div>
    <el-form label-width="150px" class="demo-ruleForm">
      <el-form-item  :label="$transMsg('lang.wms.fed.toFlow')">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-select @change="changeFlow" :clearable="true" v-model="flow" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
              <el-option v-for="(_item,index) in flowArr" :key="index" :label="_item.flowName" :value="_item.id"></el-option>
            </el-select>
          </el-col>
        </el-row>

      </el-form-item>
      <el-form-item  :label="$transMsg('lang.wms.fed.toFlowNode')">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-input v-model="nodeModel.label"  :placeholder="$transMsg('lang.wms.fed.pleaseChoose')" disabled></el-input>
          </el-col>
          <el-col :span="8">
            <el-button @click="openFlow">{{$transMsg('lang.wms.fed.selectFlowNode')}}</el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item  v-for="(item,index) in params" :key="'_' + index" :label="$transMsg('lang.wms.fed.paramSetting') + (index + 1)">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-input v-model="item.paramLabel" disabled style="margin-bottom: 10px">
              <template slot="prepend">{{$transMsg('lang.wms.fed.paramName')}}</template>
            </el-input>
            <el-input :placeholder="$transMsg('lang.wms.fed.pleaseEnter')" v-model="item.paramValue">
              <template slot="prepend">{{$transMsg('lang.wms.fed.paramContent')}}</template>
            </el-input>
          </el-col>
        </el-row>

      </el-form-item>
    </el-form>
    <el-dialog
        :title="$transMsg('lang.wms.fed.selectFlowNode')"
        :visible.sync="dialogVisibleNode"
        :append-to-body="true"
        :modal-append-to-body="false"
        width="100%">
      <G6Editor :model="false" :list="[]" :data="initData" @clickNode="clickNode"></G6Editor>
    </el-dialog>
    <div slot="footer" class="dialog-footer">
      <el-button @click="$emit('close')">{{$transMsg('lang.wes.fed.commonCancelBtn')}}</el-button>
      <el-button type="primary" @click="save">{{$transMsg('lang.wms.fed.determine')}}</el-button>
    </div>
  </div>
</template>

<script>
import { getFlowList, selectNodeParam, resetItem } from '../flowChart/api'
export default {
  name: 'GeekResetChart',
  options: {
    name: '重置流程',
    parents: ['all'], // all
    groups: ['container'], // none
    events: {
      close: '关闭弹窗'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    flowId: {
      type: Number,
      isExpression: true,
      label: '流程id',
      default: 0
    }
  },
  data() {
    return {
      nodeModel: {
        label: '',
        id: ''
      },
      flow: '',
      flowArr: [],
      params: [],
      id: '',
      nodeId: '',
      dialogVisibleNode: false,
      initData: {},
      ruleForm: {}
    }
  },
  created() {
    getFlowList({ flowStatus: -1, pageSize: 10, currentPage: 1 }).then(res => {
      this.flowArr = res.data?.recordList;
    })
  },
  computed: {
    resolveData() {
      // console.log('collectParams', this.collectParams, this.fieldList)
      return [...this.fieldList, ...this.collectParams];
    }
  },
  methods: {
    save() {
      console.log(this.params, this.nodeModel, this.flow, this.flowId)
      let paramsJson = {};
      this.params.forEach(item => paramsJson[item.paramKey] = item.paramValue)
      resetItem({
        mainScene: 'pickingComplete',
        customId: 'consolidation-' + this.flowId,
        g6NodeId: this.nodeModel.id,
        flowId: this.flow,
        paramsJson: JSON.stringify(paramsJson)
      }).then(res => {
        this.$message.success(this.$transMsg('lang.wms.fed.implementationSuccess'));
        this.$emit('close')
      })
    },
    openFlow() {
      this.dialogVisibleNode = true
    },
    changeFlow(e) {
      let graphJson = this.flowArr.filter(item => item.id === e)[0]?.graphJson
      let obj = JSON.parse(graphJson);
      this.initData = obj;
    },
    getConfigList(g6nodeId, flowId) {
      selectNodeParam({
        g6nodeId,
        flowId,
        customId: 'consolidation-' + this.flowId
      }).then(res => {
        this.params = res.data;
      })
    },
    clickNode(data, graph) {
      let nodeModel = data.item._cfg.model;
      this.nodeModel = nodeModel;
      // console.log(data.item._cfg.id, graph)
      this.getConfigList(nodeModel.id, this.flow)
      this.dialogVisibleNode = false;
    },
    handleSave(data) {
      console.log('data----saved', data)
    }
  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.dialog-footer{
  text-align: right;
}
.geek {
  color: #58666e;
  font-size: .875rem;
}
.flex-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(150, 150, 150, 0.3);
}

::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(255, 255, 255, 0);
}

</style>
