import axios from 'axios'
import { Message } from 'element-ui'
import { transMsg } from 'leivii'
import { debounce } from 'throttle-debounce'
// import Config from './config'

const goLogin = debounce(200, error => {
  if (location.hash.startsWith('#/login')) return
  const logoutUrl = localStorage.getItem('logoutUrl') || ''
  if (logoutUrl) {
    //第三方登出业务逻辑
    const url = window.location.href
    localStorage.removeItem('logoutUrl')
    window.location.href = `${logoutUrl}?redirect_uri=${encodeURIComponent(url)}`
  } else {
    if (process.env.NODE_ENV === "development") {
      window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/admin/#/middle`
    } else {
      window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
    }
  }
  Message({
    message: transMsg(error.message),
    type: 'error',
    duration: 2 * 1000,
    showClose: true
  })
})

axios.defaults.timeout = 360000
// axios.defaults.baseURL = 'http://172.18.1.80/beetle'
axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH
axios.defaults.withCredentials = true


axios.interceptors.request.use(
  config => {
    if (location.hash.includes('_report') && config.url != '/api/coreresource/auth/janus-info/v1') {
      config.baseURL = '/report'
    }

    // api/kronos/tools/groovy/debug 此链接打头的在geek-script-editor中引用了,暂时先404掉
    if ((config.url.includes('_report') && config.url.includes('/api/lowcode/')) || config.url.includes('/api_v1/sysparam/query') || config.url.includes('/api_v1/basic/common/system/version') || config.url.includes('/api/v1/loginAuthMethod/findEnableList') || config.url.includes('/api/kronos/tools/groovy/debug') ) {
      config.baseURL = process.env.VUE_APP_BASE_PATH
    }
    if (config.url.includes('/apollo/ws/')) {
      config.url = config.url.replace('/apollo/ws', '')
      // config.baseURL = ''
    }
    if (config.dataType === 'FormData') {
      let formdata = new FormData
      if (config.data) {
        for (let p in config.data) {
          formdata.append(p, config.data[p])
        }
      }
      config.data = formdata
    }
    return config
  }
)

// http response 拦截器
axios.interceptors.response.use(
  response => {
    if ([2, 3].includes(response.data.code)) {
      const params = location.hash
      // eslint-disable-next-line
      const logoutUrl = localStorage.getItem('logoutUrl') || ''
      if (logoutUrl) {
        //第三方登出业务逻辑
        const url = window.location.href
        localStorage.removeItem('logoutUrl')
        window.location.href = `${logoutUrl}?redirect_uri=${encodeURIComponent(url)}`
      } else {
        if (process.env.NODE_ENV === "development") {
          // window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/admin/#/homePage`
        } else {
          window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
        }
      }
    }
    if (response.data.code == 106) {
      setTimeout(() => {
        window.open('', '_self').close()
      }, 1000)
    }
    if (!window.hideErrorResponseMsg && response.data.code && ![0, 2].includes(response.data.code)) {
      let message = transMsg(response.data.msg)
      !window.needErrorTip && message && Message({
        message,
        type: 'error',
        duration: 2 * 1000,
        showClose: true
      })
    }
    const res = response
    if (typeof res.data !== 'string') {
      // 统一处理页码返回0的问题
      if (res.data.data && res.data.data.currentPage === 0) {
        res.data.data.currentPage = 1
      }
      res.data.config = response.config
    }
    return res.data
  },
  error => {
    if (error.message === 'Network Error') {
      // window.location.href = 'http://localhost:8080/#/wes/login'
      goLogin(error)
    }
    Promise.reject(error)
  }
)


export default axios

export const GET = (url, params, options = {}) => axios({ url, method: 'GET', params, ...options })
export const POST = (url, data, options = {}) => axios({ url, method: 'POST', data, ...options })
export const DELETE = (url, data, options = {}) => axios({ url, method: 'DELETE', data, ...options })
export const PUT = (url, data, options = {}) => axios({ url, method: 'PUT', data, ...options })
