import { API, transMsg } from 'leivii'

API.registValidator('requireAll', label => {
  return {
    validator: (rule, value, callback) => {
      callback(!value ? new Error(label + transMsg('lang.wms.fed.requiredCheck')) : undefined)
    },
    trigger: ['change', 'blur']
  }
}, 'lang.wms.fed.allRequired')

API.registValidator('dayLimit15', label => {
  return {
    validator: (rule, value, callback) => {
      callback([value].flat().filter(Boolean).reduce((a, b) => b - a, 0) / 86400000 > 15 ? new Error(label + '不能大于15天') : undefined)
    },
    trigger: 'change'
  }
}, '少于15天')

API.registValidator('dayLimit30', label => {
  return {
    validator: (rule, value, callback) => {
      callback([value].flat().filter(Boolean).reduce((a, b) => b - a, 0) / 86400000 > 30 ? new Error(label + '不能大于30天') : undefined)
    },
    trigger: 'change'
  }
}, '小于30天')

API.registValidator('enterInteger', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value !== '' && !/^[1-9]\d*$/.test(value) ? new Error(transMsg('lang.wms.fed.pleaseEnterAPositiveInteger')) : undefined)
    },
    trigger: 'change'
  }
}, '请输入正整数')


API.registValidator('enterInteger0', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value !== '' && !/^[0-9]+$/.test(value) ? new Error(transMsg('lang.wms.fed.pleaseEnterAnInteger')) : undefined)
    },
    trigger: 'change'
  }
}, '请输入整数')

API.registValidator('enterLetter', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^[a-zA-Z]+$/.test(value) ? new Error(transMsg('lang.wms.fed.pleaseInputEnglish')) : undefined)
    },
    trigger: 'change'
  }
}, '请输入英文字母')

API.registValidator('enCodeLen32', label => {
  return {
    validator: (rule, value, callback) => {
      callback((value + '').length > 32 ? new Error(label + '不能大于32位') : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.lengthMustNotBeLongerThan\') + 32}}')

// 仅限英文数字
API.registValidator('enterEnOrNum', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^[a-zA-Z0-9]+$/.test(value) ? new Error(transMsg('lang.wms.fed.onlyInputNumberOrEnglishCharacters')) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.onlyInputNumberOrEnglishCharacters\') }}')

// 仅限英文(大写)数字
API.registValidator('enterUpEnOrNum', (label) => {
  return {
    validator: (rule, value, callback) => {
      callback( value && !/^[A-Z0-9]+$/.test(value) ? /[a-z]+/.test(value) ? new Error(transMsg('lang.wms.fed.toolsEnterCapitalLetters')) : new Error(transMsg('lang.wms.fed.onlyInputNumberOrEnglishCharacters')) : undefined)
    },
    trigger: ['change', 'blur']
  }
}, `{{this.$t('请输入英文(大写)或数字') }}`)

// 不允许特殊字符
API.registValidator('specialCharactersEntered', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^(a-z|A-Z|0-9)*[^~!@#$%^&*()']*$/.test(value) ? new Error(transMsg('lang.wms.fed.pleaseCheckIfSpecialCharactersAreEntered')) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'不允许输入特殊字符\') }}')

// 手机号验证
API.registValidator('phone', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^1[3456789]\d{9}$/.test(value) ? new Error(transMsg('lang.wms.fed.inputIsNotAValidPhoneNumber')) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.telephone\') }}')

// 传真验证
API.registValidator('fax', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^(\d{3,4}-)?\d{7,8}$/.test(value) ? new Error(transMsg('lang.wms.fed.inputIsNotValidFaxNumber')) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.fax\') }}')

// 邮箱验证
API.registValidator('EMAIL', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value) ? new Error(transMsg('lang.wms.fed.pleaseInputCorrectEmailAddress')) : undefined)
    },
    trigger: 'change'
  }
}, 'EMAIL')

// 网址验证
API.registValidator('HTTP', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^https?\:\/\/.*/.test(value) ? new Error(transMsg('lang.wms.fed.invalidUrl')) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.website\') }}')

// 金额验证
API.registValidator('Money', label => {
  return {
    validator: (rule, value, callback) => {
      callback(value && !/^[-\+]?\d+(\.\d{1,2})?$/.test(value) ? new Error(transMsg('lang.btc.common.gboot.dataInvalidError', [label])) : undefined)
    },
    trigger: 'change'
  }
}, '{{this.$t(\'lang.wms.fed.amountOfMoney\') }}')
