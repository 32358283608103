<template>
  <div class="geek-station-login">
    <div class="geek-logo">
      <img src="../assets/logo.png" alt="login-img" class="logo-img" />
    </div>
    <el-dropdown class="lang-change" @command="handleCommand">
      <span class="el-dropdown-link">
        {{ currentLangName }}<i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown" class="new-dropdown-menu">
        <el-dropdown-item
          class="el-dropdown-link"
          v-for="lang in langs"
          :key="lang.code"
          :command="lang.code"
        >
          {{ lang.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="login-box">
      <div class="login-title">
        <!--        <h1 class="login-tit-large">GPS</h1>-->
        <img src="../assets/GPS.png" alt="login-img" class="logo-img" />
        <h6 class="login-tit-small">Geek+ Picking System</h6>
      </div>
      <div class="login-type" v-if="loginType > 2">
        <div class="loginType">{{ $t("lang.wms.common.dict.login_auth") }}</div>
        <!--        <el-radio-group v-model="loginType" class="loginTypeRadioGroup">-->
        <!--          <el-tooltip class="item" effect="dark" :content="item.loginText" placement="top-start" v-for="(item, i) in loginTypeText" :index="i">-->
        <!--            <el-radio-button :label="item.method === 'Geek+Verification' ? 3 : 4">-->
        <!--              {{item.loginText}}-->
        <!--            </el-radio-button>-->
        <!--          </el-tooltip>-->
        <!--        </el-radio-group>-->
        <el-tabs v-model="loginType">
          <el-tab-pane
            v-for="(item, i) in loginTypeText"
            :key="i"
            :label="item.loginText"
            :name="item.method === 'Geek+Verification' ? '3' : '4'"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="0px"
      >
        <el-form-item prop="userName" v-if="loginType == 1 || loginType == 3">
          <el-input
            v-model.trim="ruleForm.userName"
            prefix-icon="el-icon-user"
            ref="userName"
            @keyup.enter.native="handleUsername"
            :placeholder="$t('lang.wms.fed.userName')"
          >
            <!-- <i slot="prefix" class="el-input__icon el-icon-user"></i> -->
          </el-input>
        </el-form-item>
        <!-- password -->
        <el-form-item prop="password" v-if="loginType == 1 || loginType == 3">
          <el-input
            type="password"
            prefix-icon="el-icon-lock"
            @keyup.enter.native="handlePassword"
            ref="password"
            :placeholder="$t('lang.wms.fed.password')"
            v-model.trim="ruleForm.password"
          >
            <!-- <i slot="prefix" class="el-input__icon el-icon-lock"></i> -->
          </el-input>
        </el-form-item>
        <!--        <el-form-item prop="lang" v-if="loginType === 1 || loginType === 3">-->
        <!--          <img class="lang-icon" src="@/assets/home/icon-lang.png" />-->
        <!--          &lt;!&ndash; <i class="el-icon-platform-eleme"></i> &ndash;&gt;-->
        <!--          <el-select v-model="currentLangCode" @change="handleCommand">-->
        <!--              <el-option v-for="(lang, i) in langs" :key="i" :value="lang.code" :label="lang.name"></el-option>-->
        <!--          </el-select>-->
        <!--        </el-form-item>-->
        <!-- sub btn -->
        <el-form-item class="login-btn">
          <el-button
            :disabled="code"
            :loading="loading"
            type="primary"
            ref="submit"
            @click="submitForm('ruleForm')"
            >{{ $t("lang.wms.fed.signIn") }}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { GET, POST } from "@/utils/http";
import { getUrlparams } from "@/utils/utils";
import { getVersion } from "@/utils/version";
import { generateCodeChallenge } from "@/utils/pkce";
import { getSystemParams } from '@/api/home'
import { handleGetCurLanguage } from '@/utils/langs';

export default {
  computed: {
    isStation() {
      return typeof this.$route.query.station === 'string';
    }
  },
  data() {
    return {
      PKEC: false,
      codeVerifier: '',
      codeChallenge: '',
      code: false,
      OAuth: [],
      loginTypeText: [],
      loginType: '1', // 1 :原始登录 2：只显示登录按钮 >2 可选登录 3可选原始登录 4 可选外部登录
      loading: false,
      langs: [],
      langMap: {},
      currentLangVal: '',
      currentLangCode: '',
      currentLangName: '',
      ruleForm: {
        userName: '',
        password: ''
      },
      mapMethod: {
        'Geek+Verification': '3',
        OAuth: '4'
      },
      rules: {
        userName: [
          {
            required: true,
            message: this.$t('lang.wms.fed.enterUserName'),
            trigger: 'blur'
          }
        ],
        password: [
          {
            required: true,
            message: this.$t('lang.wms.fed.pleaseInputAPassword'),
            trigger: 'blur'
          }
        ]
      },
      workingModes: ['APOLLO', 'REPORT']
    };
  },
  watch: {
    'ruleForm.userName': {
      handler(v) {
        let href = window.location.href;
        let params = href.replace(/.*\?/g, '');
        params = getUrlparams(params);
        params.userName = v;
        window.location.href =
          window.location.href.replace(/\?.*/g, '') +
          '?' +
          Object.entries(params)
            .map(res => `${res[0]}=${res[1]}`)
            .join('&');
      }
    }
  },
  async created() {
    const code = this.getParameterByName("code");
    this.setUserName();
    await this.initLang();
    this.initConfig(code);
    this.code = !!code;
  },
  methods: {
    async getPkce() {
      const { codeVerifier, codeChallenge } = JSON.parse(
        localStorage.getItem('codeVerifier') || '{}'
      );
      if (codeVerifier && codeChallenge) {
        this.codeVerifier = codeVerifier;
        this.codeChallenge = codeChallenge;
      } else {
        const { codeVerifier, codeChallenge } = await generateCodeChallenge();
        localStorage.setItem(
          'codeVerifier',
          JSON.stringify({ codeVerifier, codeChallenge })
        );
        this.codeVerifier = codeVerifier;
        this.codeChallenge = codeChallenge;
      }
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line
      const newName = name.replace(/[\[\]]/g, "\\$&");
      const regex = new RegExp(`[?&]${newName}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);

      if (!results) return '';
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    codeLogin(code, data, loading) {
      const { station } = this.$route.query;
      localStorage.removeItem('codeVerifier');
      this.$nextTick(() => {
        this.codeVerifier = '';
        this.codeChallenge = '';
      });
      const codeVerifier = this.codeVerifier;
      POST('/api/coreresource/auth/loginwithauth/v1', {
        codeVerifier,
        code,
        loginAuthVo: data,
        subsystemCode: 'ALL',
        curLanguage: this.currentLangVal
      }).then(res => {
        if (res.code === 0) {
          localStorage.removeItem('tagViews');
          localStorage.setItem('userName', res.data?.user?.userName);
          this.goHomePage(res, loading);
        } else {
          this.code = false;
          this.$router.push({ path: '/middle'});
          loading.close();
        }
      });
    },
    initConfig(code) {
      let loading = code && this.$loading();

      GET('/api/v1/loginAuthMethod/findEnableList').then(res => {
        if (res.data) {
          const OAuth = res.data.filter(item => item.method === 'OAuth');
          const GeekOAuth = res.data.filter(
            item => item.method === 'Geek+Verification'
          );
          this.loginTypeText = [...GeekOAuth, ...OAuth];
          this.OAuth = OAuth;
          this.PKEC = !!OAuth[0]?.list[0]?.codeVerifier;
          OAuth[0]?.list[0]?.codeVerifier && this.getPkce();
          if (OAuth.length && GeekOAuth.length) {
            this.loginType = this.mapMethod[res.data[0].method];
            if (OAuth[0].isdefault) {
              this.loginType = '4';
            }
            if (GeekOAuth[0].isdefault) {
              this.loginType = '3';
            }
          } else if (OAuth.length) {
            this.loginType = '2';
          } else if (GeekOAuth.length) {
            this.loginType = '1';
          }
          // 启动code外部登录
          code && this.codeLogin(code, OAuth[0], loading);
        }
      });
    },
    setUserName() {
      let href = window.location.href;
      let params = href.replace(/.*\?/g, '');
      params = getUrlparams(params);
      this.ruleForm.userName = params.userName || '';
    },
    async initLang() {
      // let sysParam = JSON.parse(localStorage.getItem("SYSTEM_CONFIG_FE")) || {};
      const res = await getSystemParams({paramKeyLike: 'default_language'});
      const defalutLang = !res.code ? res.data?.recordList?.find(res => res.paramKey == 'default_language')?.paramValue : '';
      this.currentLangVal =
        localStorage.getItem("curLanguage") || defalutLang ||  "zh_cn";
      localStorage.setItem("curLanguage", this.currentLangVal);
      GET("/api/coreresource/i18n/getLanguages/v1").then((data) => {
        if (!data.code && data.data.length) {
          data.data.forEach(lang => {
            this.langs.push(lang);
            this.langMap[lang.code] = lang;
          });
          this.currentLangCode = this.langMap[this.currentLangVal]?.code;
          this.currentLangName = this.langMap[this.currentLangVal]?.name;
        }
      });
    },
    async handleCommand(code) {
      let langs = await this.$storage.i18n.getItem(`LangStorage_${code}`);
      this.currentLangCode = code;
      if (code !== this.currentLangVal || !langs) {
        localStorage.setItem('curLanguage', code);
        handleGetCurLanguage(code, true);
      } else {
        getVersion();
      }
    },
    handleUsername() {
      if (this.ruleForm.userName) {
        this.$refs.password?.focus();
      }
    },
    handlePassword() {
      if (this.ruleForm.userName && this.ruleForm.password) {
        this.submitForm('ruleForm');
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.login();
        }
        return false;
      });
    },
    getMenuList(res) {
      let list = [];
      let menuList = [];
      const systemIdPermissionMap = res.data.user.systemIdPermissionMap;
      if (this.workingModes.length > 0 && systemIdPermissionMap) {
        for (let i = 0; i < this.workingModes.length; i += 1) {
          const el = this.workingModes[i];
          if (
            !systemIdPermissionMap[el] ||
            !systemIdPermissionMap[el][`${el}-PC-menu`]
          ) {
            // eslint-disable-next-line no-continue
            continue;
          }
          const elist = systemIdPermissionMap[el][`${el}-PC-menu`] || []; // APOLLO-PC-menu  // DEMETER-PC-menu // PERSEPHONE-PC-menu
          list = list.concat(elist);
        }
        menuList = list.sort((a, b) => a.sort - b.sort);
        // menuList = menuList.sort((a, b) => (a.sort - b.sort));
        menuList = menuList.filter(item => item.code !== 'reports');
      }
      console.log(menuList);
      return menuList;
    },
    setMenuListWithStation(menu) {
      return menu;
    },
    getPermissionList(data) {
      if (!data?.user?.systemIdPermissionMap) return [];
      let list = [];
      for (let item in data.user.systemIdPermissionMap) {
        list.push(data.user.systemIdPermissionMap[item][item + '-PC']);
      }
      return list.filter(Boolean).flat();
    },
    goHomePage(res, loading) {
      if (res.data && res.data.passwordWarning) {
        this.$message({
          message: this.$transMsg(res.data.passwordWarning),
          type: 'warning',
          duration: 3000,
          showClose: true
        });
      }
      let allMenuList = this.getMenuList(res);
      // allMenuList = this.setMenuListWithStation(allMenuList);
      // 缓存导航数据
      localStorage.removeItem('tagViews');
      this.ruleForm.userName &&
        localStorage.setItem('userName', this.ruleForm.userName);
      localStorage.setItem('MenuList', JSON.stringify(allMenuList));
      localStorage.setItem(
        'SubsystemList',
        JSON.stringify(res.data.user.subsystemList)
      );
      // session
      GET('/api/coreresource/auth/getModuleMenus/v1')
        .then(async sessionData => {
          if (sessionData.code === 0) {
            this.setSystemConfig();            
          }
        })
        .catch(() => {
          loading && loading.close();
          this.loading = false;
        });
    },
    async login() {
      if (this.loginType == '2' || this.loginType == '4') {
        const obj = this.OAuth[0];
        const {
          redirectUrl,
          clientId,
          responseType,
          getCodeUrl,
          scope
        } = obj.list[0];
        if (this.PKEC && !this.codeChallenge) {
          const { codeVerifier, codeChallenge } = await generateCodeChallenge();
          this.codeChallenge = codeChallenge;
          localStorage.setItem(
            'codeVerifier',
            JSON.stringify({ codeVerifier, codeChallenge })
          );
        }
        this.$nextTick(() => {
          if (this.PKEC) {
            window.location.href = `${getCodeUrl}?scope=${scope}&client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUrl}&code_challenge=${this.codeChallenge}&code_challenge_method=S256`;
          } else {
            window.location.href = `${getCodeUrl}?scope=${scope}&client_id=${clientId}&response_type=${responseType}&redirect_uri=${redirectUrl}`;
          }
        });
        return;
      }

      const { userName, password } = this.ruleForm;
      this.loading = true;
      localStorage.removeItem('tagViews');
      POST('/api/coreresource/auth/login/v1', {
        subsystemCode: 'ALL',
        userName,
        password,
        curLanguage: this.currentLangVal
      })
        .then(res => {
          if (res.code === 0) {
            localStorage.setItem('userName', userName);
            this.goHomePage(res);
          }
        })
        .finally(() => this.loading = false);
    },
    setSystemConfig() {
      let config = {};
      GET(
        `/api_v1/sysparam/query?paramKey=system_units_transfer&__rnd=${+new Date()}`
      ).then(res => {
        if (res.code === 0) {
          res.data.recordList.forEach(e => {
            config[e.paramKey] = JSON.parse(e.paramValue || '{}');
          });
          localStorage.setItem('SYSTEM_CONFIG_DEFAULT', JSON.stringify(config));
          this.$router.push({ path: '/middle'});
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
//.el-dropdown-menu{
//  .el-dropdown-link{
//    font-size: 30px;
//  }
//}
.new-dropdown-menu {
  width: 120px;
  li {
    text-align: center;
    font-size: 18px;
  }
}
.geek-station-login {
  .el-dropdown-link {
    font-size: 20px;
  }
  ::v-deep .el-button--primary {
    background-color: #449aff;
    border-color: #449aff;
  }

  @mixin base-width-change($zoom: 1) {
    $box-width: 603px * $zoom;
    $base-font: 80px * $zoom;
    .login-box {
      right: $base-font * 1.4;
      width: $box-width;
      .login-title {
        margin-bottom: $base-font * 1.2;
        .login-tit-large {
          font-size: $base-font;
          line-height: $base-font * 1.25;
        }
        .login-tit-small {
          font-size: $base-font * 0.4;
          line-height: $base-font * 0.4 * 1.25;
          font-weight: bold;
        }
      }
      ::v-deep .el-form-item {
        margin-bottom: $base-font * 0.5;
        .el-form-item__content {
          .el-input {
            font-size: $base-font * 3 / 8;
            .el-input__inner {
              height: $base-font;
              line-height: $base-font;
              padding-left: $base-font;
              font-size: $base-font * 3 / 8;
              border-radius: $base-font * 0.2;
            }
          }
          .el-button {
            font-size: $base-font * 9 / 20;
            padding: ($base-font / 4 + 1px) ($base-font / 4);
            border-radius: $base-font * 0.2;
          }
          .el-input--prefix {
            .el-input__prefix {
              left: $base-font * 3 / 8;
              .el-input__icon {
                line-height: $base-font;
              }
            }
          }
        }
        .el-form-item__error {
          font-size: $base-font * 0.25;
        }
      }

      .login-btn {
        padding-top: $base-font * 0.25;
      }
    }
  }
  position: relative;
  background-color: #161616;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  .login-type {
    margin-bottom: 20px;
  }
  .loginTypeRadioGroup {
    display: flex;
    height: 50px;
    label {
      width: 50%;
    }
    ::v-deep .el-radio-button__inner {
      width: 100%;
      height: 100%;
      line-height: 23px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .loginType {
    color: rgba(255, 255, 255, 0.6);
    font-size: 24px;
    margin-bottom: 8px;
  }
  .geek-logo {
    position: fixed;
    left: 40px;
    top: 50px;
    width: 200px;
    img.logo-img {
      display: block;
      width: 100%;
    }
  }
  .lang-change {
    position: absolute;
    right: 20px;
    top: 45px;
    padding: 10px 30px;
    color: #fff;
    cursor: pointer;
  }
  .login-box {
    position: absolute;
    right: 114px;
    top: 156px;
    width: 603px;
    .login-title {
      margin-bottom: calc(100% / 192 * 92);
      .login-tit-large {
        font-size: 80px;
        line-height: 100px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.7);
        font-family: Montserrat-SemiBold, Montserrat;
        margin: 0;
      }
      .login-tit-small {
        font-size: 32px;
        line-height: 40px;
        font-weight: bold;
        color: rgba(255, 255, 255, 0.6);
        font-family: Montserrat-Regular, Montserrat;
        margin: 18px 0 0 2px;
      }
    }
    ::v-deep .el-tabs__nav-wrap.is-scrollable {
      padding: 0 40px;
    }
    ::v-deep .el-tabs__nav-prev,
    ::v-deep .el-tabs__nav-next {
      width: 40px;
      text-align: center;
      font-size: 24px;
      top: 6px;
    }
    ::v-deep .el-tabs__nav-prev.is-disabled:hover,
    ::v-deep .el-tabs__nav-next.is-disabled:hover {
      color: #909399;
    }
    ::v-deep .el-tabs__nav-prev:hover,
    ::v-deep .el-tabs__nav-next:hover {
      color: #449aff;
    }
    ::v-deep .el-tabs__nav {
      padding-top: 8px;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      bottom: auto;
      top: 0;
      height: 4px;
      background: rgba(255, 255, 255, 0.48);
    }
    ::v-deep .el-tabs__active-bar {
      bottom: auto;
      top: 0;
      height: 4px;
      background: #449aff;
    }
    ::v-deep .el-tabs__item {
      color: rgba(255, 255, 255, 0.7) !important;
      font-size: 24px;
    }
    ::v-deep .el-tabs__item.is-active {
      color: #449aff !important;
      font-size: 24px;
    }
    ::v-deep .el-form-item {
      text-align: center;
      margin-bottom: 40px;
      .el-form-item__content {
        .lang-icon {
          position: absolute;
          left: 25px;
          top: 50%;
          transform: translateY(-50%);
          color: #c0c4cc;
          z-index: 999;
          background: rgb(232, 240, 254);
          width: 27px;
          height: 27px;
        }
        @media screen and (max-width: 1920px) {
          .lang-icon {
            width: 30px;
            height: 30px;
          }
        }
        @media screen and (max-width: 1680px) {
          .lang-icon {
            width: 27px;
            height: 27px;
          }
        }
        @media screen and (max-width: 1440px) {
          .lang-icon {
            width: 24px;
            height: 24px;
          }
        }
        @media screen and (max-width: 1280px) {
          .lang-icon {
            width: 21px;
            height: 21px;
            left: 22px;
          }
        }
        .el-input {
          font-size: 30px;
          .el-input__inner {
            height: 80px;
            line-height: 80px;
            padding-left: 80px;
            font-size: 21px;
            font-weight: 400;
            border-radius: 16px;
            border: none;
            background-color: #3a3a3a;
            color: #ffffff;
            box-shadow: -24px 24px 29px 0px rgba(0, 0, 0, 0.8);
          }
        }
        .el-button {
          font-weight: 500;
          font-size: 36px;
          padding: 21px 20px;
          border-radius: 16px;
          box-shadow: -24px 24px 29px 0px rgba(0, 0, 0, 0.8);
        }
        .el-input--prefix {
          .el-input__prefix {
            left: 30px;
            .el-input__icon {
              line-height: 80px;
            }
          }
        }
      }
      .el-form-item__error {
        font-size: 20px;
      }
    }

    .el-select,
    .el-button {
      width: 100%;
    }
    .login-btn {
      padding-top: 20px;
    }
  }
  @media screen and (max-width: 1920px) {
    @include base-width-change();
  }
  @media screen and (max-width: 1680px) {
    @include base-width-change(0.9);
  }
  @media screen and (max-width: 1440px) {
    @include base-width-change(0.8);
  }
  @media screen and (max-width: 1280px) {
    @include base-width-change(0.7);
  }
}
</style>
