import localforage from 'localforage'

const PID = 'GPS';
const i18STORE = 'i18n';
const STORE = 'session';

export const i18dropStore = () => localforage.dropInstance({
  name: PID,
  storeName: i18STORE,
});

export const dropStore = () => localforage.dropInstance({
  name: PID,
  storeName: STORE,
});

export default {
  i18n: localforage.createInstance({
    name: PID,
    storeName: i18STORE,
  }),
  session: localforage.createInstance({
    name: PID,
    storeName: STORE,
  }),
};

// 重写localStorage.setItem，异常抛出
const setItem = localStorage.setItem
localStorage.setItem = (key, value) => {
    try {
        setItem.call(localStorage, key, value)
    } catch (e) {
        if (e instanceof DOMException && (e.code === 22 || e.code === 1014 || e.name === 'QuotaExceededError' || e.name === 'NS_ERROR_DOM_QUOTA_REACHED')) {
            console.error('空间不足，请清理缓存或联系管理员')
        } else {
            console.error('缓存数据失败，请联系管理员')
        }
    }
}
