export const format = (date, formatter = 'YYYY-MM-DD HH:mm:ss') => {
  let d = date
  if (d === '0' || d === 0 || d === '') {
    return ''
  } else if (typeof d === 'string') {
    if (/^\d+$/.test(d)) {
      d = new Date(+d)
    } else {
      d = new Date(d)
    }
  } else if (Number.isInteger(d)) {
    d = new Date(d)
  }

  if (d instanceof Date && !isNaN(d.getTime())) {
    const year = d.getFullYear()
    const month = d.getMonth() + 1
    const day = d.getDate()
    const h = d.getHours()
    const m = d.getMinutes()
    const s = d.getSeconds()

    return formatter
      .replace(/yyyy/i, year)
      .replace(/yy/i, year.toString().substr(2))
      .replace('MM', month.toString().padStart(2, '0'))
      .replace('M', month)
      .replace(/dd/i, day.toString().padStart(2, '0'))
      .replace(/d/i, day)
      .replace(/hh/i, h.toString().padStart(2, '0'))
      .replace('mm', m.toString().padStart(2, '0'))
      .replace(/ss/i, s.toString().padStart(2, '0'))
  }
  return date ?? ''
}
