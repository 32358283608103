import axios from "axios";
const BASEURL = '';

/**
 * 基础数据
 * @param {Object}
 */
export function initParams(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-log/init-page-param`,
    method: 'get',
  });
}
/**
 * 分页
 * @param {Object}
 */
export function getTable(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-log/page`,
    method: 'get',
    params,
  });
}

/**
 * 异常日志下载页面提交提取日志请求
 * @param {Object}
 */
export function generate(data) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-log/generate`,
    method: 'post',
    data,
  });
}

