<template>
  <div class="shelf-exit-dialog">
    <div class="dialog-main">
      <el-form :model="formOne" ref="formOne" :rules="rules" label-position="top" style="width:100%">
        <div class="steps-procedure">
            <div class="step-procedure">
                <div class="step-indicator">
                    <div class="step-indicator_num active">1</div>
                    <div class="step-indicator_line"></div>
                </div>
                <div class="step-container">
                    <div class="step-container_title">
                        <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}1</span>
                        <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfIn.chooseShelf')}}</span>
                        <el-tooltip class="item" effect="dark" :content="$t('lang.wes.fed.shelfClassCheck.tips')" placement="right">
                          <el-button type="text" size="medium" icon="el-icon-question" style="font-size: 20px"><i></i></el-button>
                        </el-tooltip>
                    </div>
                    <div class="step-operate">
                          <el-row :gutter="20">
                              <el-col :span="8">
                                  <el-form-item prop="shelfClassCode">
                                      <el-select ref="shelfClassCode" v-model="formOne.shelfClassCode" @change="selectChange('formOne')" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                          <el-option
                                          v-for="(item, index) in shelfList"
                                          :key="index"
                                          :label="item.label"
                                          :value="item.value">
                                          </el-option>
                                      </el-select>
                                  </el-form-item>
                              </el-col>
                          </el-row>
                    </div>
                </div>
            </div>
            <div class="step-procedure">
                <div class="step-indicator">
                    <div class="step-indicator_num active">2</div>
                    <div class="step-indicator_line"></div>
                </div>
                <div class="step-container">
                    <div class="step-container_title">
                        <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}2</span>
                        <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfIn.scanLocationCellCode')}}</span>
                    </div>
                    <div class="step-operate">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item prop="locationCellCode" :rules="rules.isRequireInput">
                                    <el-input ref="mapPoint" clearable v-model="formOne.locationCellCode" multiple :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
            <div class="step-procedure">
                <div class="step-indicator">
                    <div class="step-indicator_num active">3</div>
                    <div class="step-indicator_line"></div>
                </div>
                <div class="step-container">
                    <div class="step-container_title">
                        <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}3</span>
                        <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfIn.otherInfo')}}</span>
                    </div>
                    <div class="step-operate">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item prop="placementCellCode" :label="$t('lang.wms.fed.shelfIn.placementCellCode')">
                                    <el-input v-model="formOne.placementCellCode" ref="placementCellCode" clearable  :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="designLogicAreaId" :label="$t('lang.wms.fed.shelfIn.specifyArea')" :rules="rules.EightLongInt">
                                    <el-input v-model="formOne.designLogicAreaId" clearable :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item prop="strategyCoding" :label="$t('lang.wms.dict.shelf_angle')">
                                    <el-select v-model="formOne.shelfAngle" clearable @change="selectChange()" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                        <el-option
                                        v-for="(item, index) in dict.shelfAngle"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
              <div class="step-procedure">
                <div class="step-indicator">
                    <div class="step-indicator_num active">4</div>
                    <!-- <div class="step-indicator_line"></div> -->
                </div>
                <div class="step-container">
                    <div class="step-container_title">
                        <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}4</span>
                        <span class="step-container_subtitle">{{$t('lang.wms.fed.shelfIn.scanAnyBinCode')}}</span>
                    </div>
                    <div class="step-operate">
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item prop="shelfCode" :rules="rules.isRequireInput">
                                    <el-input v-model="formOne.shelfCode" ref="shelfCode" :placeholder="$t('lang.wms.fed.pleaseEnter')"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="6">
                                <el-button size="medium" type="primary" @click="submit()">{{$t('lang.wms.fed.shelfIn.confirm')}}</el-button>
                            </el-col>
                        </el-row>
                    </div>
                </div>
              </div>
        </div>
      </el-form>
    </div>
    <!-- <div class="dialog-footer">
        <el-button size="medium" plain @click="handleCancel()">{{$t('lang.wms.fed.cancel')}}</el-button>
        <el-button size="medium" type="primary" @click="save()">{{$t('lang.wms.fed.save')}}</el-button>
    </div> -->
  </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
import { initCodeScan } from '@/utils/device'
export default {
  name: 'GeekShelfEnter',
  options: {
    name: '货架入场',
    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
      click: '点击'
    }
  },
  props: {
    drop: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    const positiveInteger0_999999999 = (rule, value, callback) => {
      if (!value) {
        callback();
      }
      let reg = /^([1-9]|[1-9][0-9]{1,7})(\.\d+)?$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t('lang.wms.fed.limitOneADigitalLengthWithin', [8])));
      } else {
        callback();
      }
    };
    return {
      rules: {
        shelfClassCode: [{ required: true, message: this.$t('lang.wms.fed.pleaseCheckOut'), trigger: 'change' }],
        isRequireInput: [{ required: true, message: this.$t('lang.wms.fed.pleaseCheckOut'), trigger: 'blur' }],
        EightLongInt: [{ validator: positiveInteger0_999999999, trigger: 'change' }],
      },
      shelfList: [],
      formOne: {
        shelfCode: '',
        shelfClassCode: '',
        locationCellCode: '', // 入场地图点位
        placementCellCode: '', // 指定老家位置
        designLogicAreaId: '', // 指定逻辑区
        shelfAngle: '' // 货架初始角度
      },
      dict: {
        shelfAngle: [
          {label: '-90', value: -90},
          {label: '0', value: 0},
          {label: '90', value: 90},
          {label: '180', value: 180}
        ]
      },
      tableList: [],
      stationList: [],
      strategyTypes: [],
      name: ''
    }
  },
  created() {
    this.init();
    initCodeScan();
    window.removeEventListener("adminScanEvent", this.onScan, false);
    window.addEventListener("adminScanEvent", this.onScan, false);
  },
  methods: {
    init() {
      // 货架入场
      GET('/api_v1/shelf/getShelfClassListByType').then(res => {
        if (res.code === 0) {
          // this.$message({ type: 'success', message: this.$t('lang.wms.fed.success') });
          this.shelfList = (res.data || []).map(v => {
            return {
              label: v,
              value: v
            }
          });
        } else {
          // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
        }
      })
    },
    submit() {
      this.$refs.formOne.validate((valid) => {
          if (valid) {
            // 货架入场
            POST('/api/shelf/operation/shelfIn', { ...this.formOne }).then(res => {
              if (res.code === 0) {
                this.$message({ type: 'success', message: this.$t('lang.wms.fed.shelfDispatch.success') });
              } else {
                // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
              }
            })
            // 置空 聚焦货位扫码
            this.formOne.shelfCode = '';
            this.formOne.placementCellCode = '';
            this.$nextTick(() => {
              this.$refs.shelfCode.focus();
            })
          }
      })
    },
    selectChange() {
      this.$nextTick(() => {
        if (!this.formOne.locationCellCode) {
          this.$refs.mapPoint.focus()
        } else if (!this.formOne.shelfCode) {
          this.$refs.shelfCode.focus()
        }
      })
    },
    handleCancel() {
      this.$emit('cancel', {})
    },
    setActicve(name) {
      console.log(name)
      this.name = name;
      if (name === 'tab-entry') {
        // this.selectChange();
        this.$nextTick(() => {
          if (!this.formOne.shelfClassCode) {
            this.$refs.shelfClassCode.focus()
          } else if (!this.formOne.locationCellCode) {
            this.$refs.mapPoint.focus()
          } else {
            this.$refs.shelfCode.focus()
          }
          this.$refs.formOne.clearValidate();
        })
      }
    },
    onScan(e) {
      console.log(e)
      if (!this.formOne.locationCellCode) {
        this.formOne.locationCellCode = e?.detail?.code;
        this.$refs.mapPoint.blur();
      }else {
        this.formOne.shelfCode = e?.detail?.code;
      }
    }
  },
  activated() {
    console.log('aenter')
    if (this.name === 'tab-entry' || !this.name) {
      this.$nextTick(() => {
        if (!this.formOne.shelfClassCode) {
          this.$refs.shelfClassCode.focus()
        } else if (!this.formOne.locationCellCode) {
          this.$refs.mapPoint.focus()
        } else {
          this.$refs.shelfCode.focus()
        }
        this.$refs.formOne.clearValidate();
      })
    }
    window.removeEventListener("adminScanEvent", this.onScan, false);
    window.addEventListener("adminScanEvent", this.onScan, false);
  },
  deactivated() {
    console.log('daenter')
    this.$refs.formOne.clearValidate();
    window.removeEventListener("adminScanEvent", this.onScan, false);
  },
  destroyed() {
    window.removeEventListener("adminScanEvent", this.onScan, false);
  },
}
</script>


<style lang="scss" scoped>
.shelf-exit-dialog{
  display: flex;
  flex-direction: column;
  flex:1;
  .dialog-main{
     display: flex;
    flex-direction: row;
    flex:1;
  }
}
.dialog-subTitle{
  font-size: 20px;
}
.step-choose-content{
  min-height: 100px;
  .el-tag{
    margin-right:10px;
  }
}
.steps-procedure{
    width: 100%;
}
.step-procedure{
    // min-height: 100px;
    display: flex;
    position: relative;
    flex-shrink: 1;
}
.step-indicator{
    position: relative;
    width:24px;
    flex-grow: 0;
    .step-indicator_num{
        position: relative;
        display: flex;
        width: 24px;
        height: 24px;
        font-size: 14px;
        border: 2px solid #c0c4cc;
        border-radius: 50%;
        background: #fff;
        justify-content: center;
        align-items: center;
        z-index:1;
        &.active {
            border-color:  #409eff;
            color:  #409eff;
        }
    }
    .step-indicator_line{
        position: absolute;
        top:0;
        bottom: 0;
        left: 11px;
        border: 2px solid #c0c4cc;
        &.active {
            border-color:  #409eff;
        }
    }
}
.step-container{
    padding-left: 10px;
    flex-grow: 1;
    min-height: 200px;
    .step-container_title{
        margin-bottom: 20px;
        span{
            margin-right: 20px;
            font-size: 16px;
        }
        .step-container_subtitle {
            font-size: 14px;
        }
    }
}
.geek-step-procedure {
  display: flex;
  flex-flow: column;
  padding: 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  overflow: hidden;
  .geek-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      align-items: center;
      > * {
        margin-left: 0;
        margin-right: 18px;
        cursor: pointer;
      }
    }
    ::v-deep {
      .el-form-item {
        margin: 0;
      }
    }
  }
  .geek-card-body {
    height: 0;
    flex: 1;
    overflow: auto;
    ::v-deep {
      .geek-table {
        height: 100%;
        margin-bottom: 0;
        .el-table {
          max-height: 100% !important;
          position: relative;
          .el-table__header-wrapper {
            .cell {
              position: relative;
            }
          }
          .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            max-height: 100% !important;
            position: absolute;
            top: 48px;
            bottom: 0;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
.el-form{
  .el-select{
    width: 100%;
  }
}
.dialog-footer{
  margin-top: 20px;
  text-align: center;
}
</style>
