<template>
    <div class="geek-form-table" :class="$classes">
        <div class="geek-form-table-header">
            <div v-if="title" :class="{ title }">{{ $transMsg(title) }}</div>
            <geek-flex-container class="actions" :gutter="10" v-if="actions && actions.length" width="100%">
                <geek-component v-for="(action, i) in actions" :data="action" :key="i + action.$id"></geek-component>
            </geek-flex-container>
        </div>
        <div class="geek-form-table-title">
            <div v-for="columns in wholeColumns" :key="JSON.stringify(columns)">{{$t(columns.label)}}</div>
        </div>
        <div v-if="valueProxy.length" class="geek-form-table-content">
            <div v-for="(item, index) in valueProxy" :key="index" class="geek-form-table-row">
                <div class="geek-form-table-row-body">
                    <div class="geek-form-table-child-row" v-for="(b, j) in item.children" :key="j">
                        <div class="geek-form-table-row-connector">
                            <el-select size="small" class="w-full" filterable v-model="b.connector" :disabled="disabled || !!ruleOption">
                                <el-option v-for="(item, i) in connectorList" :key="i" :value="item.value" :label="$t(item.label)"></el-option>
                            </el-select>
                        </div>
                        <template v-for="columns in computedColumns">
                            <template v-if="[columns.$body].flat().filter(Boolean).length">
                                <template v-for="(d, k) in columns.$body">
                                    <geek-component :key="JSON.stringify(d)" :data="d" v-model="b[d.prop]" :index="k" :$scopeData="b" />
                                </template>
                            </template>
                            <geek-text v-else-if="columns.type == 'expression'" type="expression" :key="JSON.stringify(columns)" :text="columns.expression" :$scopeData="b" />
                            <template v-else>{{ getText(b, columns) }}</template>
                        </template>
                        <div>
                          <el-tooltip class="item" effect="dark" :disabled="!b.key || b.tooltipDisabled" :content="metaData.find(m => b.key == m.code) && metaData.find(m => b.key == m.code).name" placement="top-start">
                            <el-select size="small" class="w-full hidden-ellipsis"  v-model="b.key" :disabled="disabled" filterable @change="handleKeyChange(b)" :ref="`select_${b.key}`">
                                <el-option v-for="(item, i) in metaData" :key="i" :value="item.code" :label="$t(item.name)"></el-option>
                            </el-select>
                          </el-tooltip>
                        </div>
                        <div>
                            <el-select size="small" filterable v-model="b.operator" :disabled="disabled" @change="handleOperatorChange(b)">
                                <el-option v-for="(o, i) in getOperationList(b.key)" :key="i" :value="o.value" :label="$t(o.label)" />
                            </el-select>
                        </div>
                        <div v-if="b.dataModel" class="relative">
                            <template v-if="!b.dataModel.type">-</template>
                            <template v-else-if="b.dataModel.type == 'tree'">
                              <el-popover
                                placement="right"
                                width="400"
                                trigger="hover">
                                <el-tree class="w-full"
                                         :data="b.dataModel.enumeration.list"
                                         :props="{label: b.dataModel.mapping.name,children: (b.dataModel.mapping.children),disabled: disabledFn}"
                                         :ref="b.key"
                                         :default-expanded-keys="b.$treeKey || []"
                                         :node-key="b.dataModel.mapping.code"
                                         show-checkbox
                                         :check-strictly="true"
                                         @check="checkNode"
                                >
                                </el-tree>
                                <!--树的表单传输实际用的下拉框-->
                                <el-select v-if="false"
                                           v-model="b.val"
                                           class="tree-select"
                                           :disabled="disabled"
                                           multiple
                                           no-data-text=""
                                ></el-select>
                                <!--树的表单展示实际用的下拉框-->
                                <el-select slot="reference"
                                           v-model="b.nameVal"
                                           class="tree-select"
                                           :disabled="disabled"
                                           multiple
                                           :placeholder="$t('lang.wms.fed.pleaseChooseGoods')"
                                           :no-data-text="$t('lang.wms.fed.pleaseChooseGoods')"
                                ></el-select>
                              </el-popover>
                            </template>
                            <template v-else-if="'enumeration' in b.dataModel">
                              <el-tooltip class="item" effect="dark" :disabled="b.val === '' || !b.val.length || b.tooltipDisabled" :content="tooltipContent(b)" placement="top-start">
                                <el-select size="small" class="w-full" :disabled="disabled"
                                           :key="b.operator + b.key"
                                           :ref="`select_${b.key}`"
                                           v-model="b.val"
                                           clearable
                                           filterable
                                          :multiple="['in', 'nin'].includes(b.operator)"
                                           @change="handlerTooltips(b)"
                                  >
                                  <el-option v-for="(item, i) in b.dataModel.enumeration.list" :key="i" :value="item.code" :label="$t(item.name)"></el-option>
                                </el-select>
                              </el-tooltip>
                            </template>
                            <!-- 如果类型是text 但又没有枚举，但又要in -->
                            <template v-else-if="['in', 'nin'].includes(b.operator) && b.dataModel.type === 'text'">
                                <el-select size="small" class="w-full" :disabled="disabled"
                                        v-model="b.val"
                                        allow-create
                                        filterable
                                        multiple
                                        default-first-option
                                        :placeholder="$t('lang.wms.fed.pleasePressEnterToSubmitAfterInput')"
                                        :no-data-text="$t('lang.wms.fed.pleasePressEnterToSubmitAfterInput')"
                                >
                                  <el-option v-for="(item, i) in []" :key="i" :value="item" :label="$t(item)"></el-option>

                                </el-select>
                            </template>
                            <template v-else-if="['int', 'long'].includes(b.dataModel.type)">
                                <el-input  size="small" class="w-full" :ref="`input${b.key}${j}`" v-model.number="b.val" :disabled="disabled" @blur="blurInput(b,j)" @input="blurInput(b,j)"/>
                                <div class="el-form-item__error" v-if="b.val && !/^\d+(\.\d+)?$/.test(b.val)">{{$t('lang.wms.fed.pleaseInputDigital')}}</div>
                                <div class="el-form-item__error" v-else-if="showMessage && (`input${b.key}${j}` in validateObject) && !validateObject[`input${b.key}${j}`]">{{$t(b.dataModel.validate.desc)}}</div>
                            </template>
                            <template v-else-if="b.dataModel.type == 'bool'">
                                <el-switch size="small" class="w-full" v-model="b.val" :disabled="disabled" />
                            </template>
                            <template v-else-if="b.dataModel.type == 'date'">
                                <el-date-picker size="small" class="w-full" v-model="b.val" :disabled="disabled" type="date" value-format="timestamp" @change="handlerTimestamp(b)"/>
                            </template>
                            <template v-else-if="b.dataModel.type == 'datetime'">
                                <el-date-picker size="small" class="w-full" v-model="b.val" :disabled="disabled" type="datetime" value-format="timestamp" @change="handlerTimestamp(b)" />
                            </template>
                            <template v-else-if="b.dataModel.type == 'time'">
                                <el-time-select size="small" class="w-full" v-model="b.val" :disabled="disabled" value-format="timestamp" @change="handlerTimestamp(b)" />
                            </template>
                            <template v-else-if="b.dataModel.type == 'text'">
                                <el-input size="small" class="w-full" :ref="`input${b.key}${j}`" v-model="b.val" :disabled="disabled" @blur="blurInput(b,j)" @input="blurInput(b,j)"/>
                                <div class="el-form-item__error" v-if="showMessage && (`input${b.key}${j}` in validateObject) && !validateObject[`input${b.key}${j}`]">{{$t(b.dataModel.validate.desc)}}</div>
                            </template>
                        </div>
                        <div v-else>-</div>
                        <div class="operation-btn">
                            <i class="el-icon-plus" @click="addChildItem(item, b)" v-if="!disabled"></i>
                            <i class="el-icon-minus" @click="removeChildItem(item, b)" v-if="!disabled"></i>
                        </div>
                    </div>
                    <i class="el-icon-error delete-icon" @click="removeItem(item)" v-if="!disabled"></i>
                </div>
                <div class="geek-split-row">
                    <el-select size="small" v-model="item.connector" :disabled="disabled" filterable>
                        <el-option v-for="(item, i) in connectorList" :key="i" :value="item.value" :label="$t(item.label)"></el-option>
                    </el-select>
                    <div class="geek-split-row-line"></div>
                </div>
            </div>
        </div>
        <div class="geek-form-table-footer">
            <geek-button size="small" type="primary" :text="$transMsg('lang.wms.fed.addRules')" @click="addNewGroup" :disabled="disabled || !!(ruleOption && valueProxy.length)"/>
        </div>
    </div>
</template>

<script>
import {API} from 'leivii'
import { format } from '@/utils/dataFormat'
import { transMsg } from '@/utils/utils';
import { handleMetaData, handleOperationList } from '@/utils/metaData';
import { GET, POST } from '@/utils/http'
const OPERATOR_MAP = {
  any: transMsg('lang.wms.fed.AND'), // '或'
  all: transMsg('lang.wms.fed.OR'), // '且'
  gt: ">", // '大于'
  lt: "<", // '小于'
  eq: "=", // '等于'
  ne: "≠", // '不等于'
  ge: "≥", // '大于等于'
  le: "≤", // '小于等于'
  in: "in", //  '属于'
  nin: "nin" // '不属于'
}

export default {
  name: 'GeekFormTable',
  options: {
    name: '表达式表单',
    parents: ['geek-form'], // all
    groups: ['form'], // none
    events: {
      addNewGroup: 'lang.action.addItem',
      removeItem: 'lang.action.removeItem',
      addChildItem: 'lang.action.addItem',
      removeChildItem: 'lang.action.removeItem',
      change: 'lang.action.removeSelection',
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    $classes: {
      type: String,
      clazz: 'Theme',
      label: 'lang.prop.$classes',
      default: 'mb-sm'
    },
    title: {
      type: String,
      clazz: 'I18n',
      label: 'lang.prop.label',
      // ignore: true
    },
    prop: {
      type: String,
      label: 'lang.prop.field'
    },
    actions: {
      type: Array,
      itemClazz: 'ComponentDragger',
      label: 'lang.prop.actions'
    },
    columns: {
      type: Array,
      clazz: 'TableColumns',
      itemClazz: {
        name: {
          type: String,
          label: 'lang.prop.field'
        },
        label: {
          type: String,
          clazz: 'I18n',
          label: 'lang.prop.label',
          title: true
        },
        minWidth: {
          type: Number,
          label: 'lang.prop.minWidth'
        },
        tooltip: {
          type: Boolean,
          label: 'lang.prop.tooltip'
        },
        expand: {
          type: Boolean,
          label: 'lang.prop.expandable',
          isExpression: true
        },
        fixed: {
          type: String,
          options: [{label: 'lang.prop.none', value: ''}, {label: 'lang.prop.left', value: 'left'}, {label: 'lang.prop.right', value: 'right'}],
          default: '',
          label: 'lang.prop.fixedColumn'
        },
        $body: {
          type: Array,
          itemClazz: 'ComponentDragger',
          label: 'lang.prop.$body'
        },
        type: {
          type: String,
          options: [{label: 'lang.prop.text', value: 'text'}, {label: 'lang.prop.time', value: 'time'}, {label: 'lang.prop.dict', value: 'dict'}, {label: 'lang.prop.expression', value: 'expression'}],
          label: 'lang.prop.type'
        },
        dict: {
          type: String,
          label: 'lang.prop.dict',
          visible: `'{{type}}' == 'dict'`
        },
        format: {
          type: String,
          label: 'lang.prop.format',
          visible: `'{{type}}' == 'time'`
        },
        expression: {
          type: String,
          isExpression: true,
          label: 'lang.prop.expression',
          visible: `'{{type}}' == 'expression'`
        }
      },
      label: 'lang.prop.column'
    },
    value: {
      type: Array,
      ignore: true
    },
    disabled: {
      type: Boolean,
      label: 'lang.prop.disable',
    },
    showMessage: {
      type: Boolean,
      label: '显示错误信息',
      default: false
    },
    rulesTypeCode: {
      type: String,
      isExpression: true,
      label: 'lang.prop.params',
      default: 'container-strategy-picking'
    },
    ruleOption: {
      type: String,
      options: [{label: 'lang.wms.fed.onlyAnd', value: 'all'}, {label: 'lang.wms.fed.onlyOr', value: 'any'}],
      default: ''
    }
  },
  computed: {
    list() { // datasource value
      let data = this.getDataSource()
      return  data|| []
    },
    computedColumns() {
      if (this.fitWidth) {
        return this.columns.map(column => ({ ...column, minWidth: this.itemWidth(column) }))
      }
      return this.columns
    },
    wholeColumns(){
        return [{label: 'lang.wms.fed.rule'}, ...this.computedColumns, {label: 'lang.wms.fed.fieldName'}, {label: 'lang.wms.fed.relationalSign'}, {label: 'lang.wms.fed.fieldValue'}, {label: 'lang.wms.fed.operation'}]
    },
    valueProxy: {
      get() {
        return (this.prop && this.dataSource.form?.data[this.prop]) || this.list
      },
      set(v) {
        if (this.prop) {
          this.$emit('change', v)
          if (this.dataSource.form?.data) {
            this.$set(this.dataSource.form.data, this.prop, v)
            this.$set(this.get(this.dataSource.form.id).form, this.prop, v)

            // TODO 树结构的回显
            let that = this
            this.dataSource.form.data[this.prop].forEach(list => {
              list?.children.forEach(item => {
                that.$nextTick(() => {
                  if (item.dataModel.type === "tree") {
                    // 组件树展示
                    let keys = []
                    // 实际页面展示
                    let names = []
                    let drop = item.dataModel.enumeration.drop
                    item.val.forEach(item => {
                      keys = [...new Set([...keys, ...item.split('-')])]
                      names.push(item.split('-').map(id => drop[id]).join('-'))
                    })
                    item.$treeKey = keys
                    item.nameVal = names
                    console.log(this.dataSource.form.data[this.prop]);
                    that.$refs[item.key][0].setCheckedKeys(keys)
                    that.$refs[item.key][0].setDefaultExpandedKeys(keys)
                  }
                })
              })
            })
          }
        }
      }
    },
  },
  watch: {
    list: {
      handler() {
        this.valueProxy = this.list
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      metaData: [],
      operationData: {},
      connectorList: [{label: this.$t('lang.wms.fed.AND'), value: 'all'}, {label: this.$t('lang.wms.fed.OR'), value: 'any'}],
      validateObject: {}
    }
  },
  async created() {
      this.init();
  },
  methods: {
    @API.doc(false)
    disabledFn () {
      return this.disabled
    },
    @API.doc(false)
    init(){
        handleMetaData({rulesTypeCode: this.rulesTypeCode}).then(res => {
            this.metaData = res || [];
            this.setDataSource('metaData', this.metaData);
        })
        this.operationData = handleOperationList() || {};
        this.setDataSource('operationData', this.operationData);
    },
    @API.doc(false)
    getText(row = {}, item) {
      if (item.type === 'text') return this.$transMsg(row[item.name])?.toString()
      if (item.type === 'time') return format(row[item.name], item.format)?.toString()
      if (item.type === 'dict') return this.$d(item.dict, row[item.name])?.toString()
      return isString(row[item.name]) ? this.$transMsg(row[item.name])?.toString() : (row[item.name]?.toString() || '')
    },
    // parseExpr(rule, brackets = true) {
    //   let expr = "";
    //   for (let key in rule) {
    //     if (["all", "any"].includes(key)) {
    //       expr += rule[key].map(sub => this.parseExpr(sub)).join(` ${OPERATOR_MAP[key]} `);
    //     } else {
    //       expr += `${this._transKey(rule[key][0])} ${OPERATOR_MAP[key]} ${JSON.stringify(this.parseTypeFromEntity(this._transKey(rule[key][0]), rule[key][1])).replace(
    //         /"([\u4E00-\uFA29\uE7C7-\uE7F3a-zA-Z0-9_\-（）\(\)\s]+)"/g,
    //         "$1"
    //       )}`;
    //     }
    //   }
    //   if (brackets) {
    //     return `(${expr})`;
    //   }
    //   return `(${expr})`;
    // },
    // _transKey(key) {
    //   return this.metaData.find(item => item.code === key)?.name || key;
    // },
    // parseTypeFromEntity(key, val) {
    //   let model = this.metaData.find(item => item.code === key) || {};
    //   if (Array.isArray(val)) {
    //     const result = val.map(v => {
    //       if ("enumeration" in model) {
    //         let item = model.enumeration.list.find(n => v == n.code);
    //         if (item) return this.$t(item.name);
    //         return v;
    //       }
    //     });
    //     return result;
    //   }
    //   if (/^\[[^\]]*\]$/.test(val)) {
    //     let result;
    //     try {
    //       const arr = JSON.parse(val.replace(/([\u4E00-\uFA29\uE7C7-\uE7F3a-zA-Z0-9_\-（）\(\)\s]+)/g, '"$1"'));
    //       result = arr.map(item => this.parseTypeFromEntity(key, item)).filter(n => n !== null);
    //     } catch {
    //       result = [];
    //     }
    //     return result
    //   }
    //   if (model) {
    //     if ("enumeration" in model) {
    //       let item = model.enumeration.list.find(n => val == n.code);
    //       if (item) val = this.$t(item.name);
    //     }
    //   }
    //   return val.toString();
    // },
    @API.doc(false)
    getOperationList(key) {
      let m = this.metaData.find(item => item.code === key)
      let ol = ['eq', 'ne']
      if (m) {
        if (['int', 'long', 'date', 'datetime', 'time'].includes(m.type))
          ol = ['gt', 'lt', 'ge', 'le', ...ol]
        if ('enumeration' in m || ['text'].includes(m.type)) ol = [...ol, 'in']
      }
      if(m?.code == 'pickingTask.seedingWallId'){
        ol = ['in']
      }
      // 上架策略支持自定义操作符
      if (m?.expression && m.expression.length) {
        ol = m?.expression
      }
      console.log(key, m, ol)
      return ol.map(o => ({value: o, label: OPERATOR_MAP[o]}))
    }, 
    //
    @API.doc(false)
    handleKeyChange(scope) {
      scope.operator = ''
      scope.val = ''
      scope.tooltipDisabled = false
      scope.dataModel = this.metaData.find(n => scope.key == n.code) || {}

      this.handlerTooltips(scope)
    },
    // 下拉框显示不全显示tooltips
    @API.doc(false)
    handlerTooltips (scope) {
      this.$nextTick(() => {
        let $input = this.$refs[`select_${scope.key}`][0].$el.querySelector('.el-input__inner')
        if($input){
          if($input.scrollWidth>$input.offsetWidth){
            scope.tooltipDisabled = false;
            return
          }
        }

        scope.tooltipDisabled = true;
      });
    },
    @API.doc(false)
    // 兼容多选状态下的tooltips
    tooltipContent (b) {
      let arr = []
      // 如果多选
      if (Array.isArray(b.val) && b.val.length) {
        b.dataModel.enumeration.list.map(m => {
          if (b.val.includes(m.code)) {
            arr.push(this.$t(m.name))
          }
        })
        return arr.join(',')
      }

      let item = b.dataModel.enumeration.list.find(m => b.val == m.code)
      return  item && this.$t(item.name)

    },
    @API.doc(false)
    handleOperatorChange(scope) {
      if ([OPERATOR_MAP['in'],OPERATOR_MAP['nin']].includes(scope.operator)) {
        scope.val = scope.val ? Array.isArray(scope.val) ? scope.val : [scope.val] : []
        // scope.val = []
      } else if (Array.isArray(scope.val)) {
        scope.val = ''
      }
    },
    @API.doc(false)
    handlerTimestamp (scope) {
      // 日期格式需要转成字符串--因为元数据拿到会认为是int形
      scope.val = scope.val.toString()
    },
    @API.doc(false)
    addNewGroup(e){
        this.valueProxy.push({connector: 'any', children: [{connector: 0, dataModel: {}, operator: '', key: '', val: ''}]})
        this.$emit('addNewGroup', e);
        this.$forceUpdate()
    },
    @API.doc(false)
    addChildItem(e, child) {
      let that = this;
      return new Promise((resolve, reject) => {
        let defaultConnector = that.ruleOption || 'all'
        let i = this.valueProxy.indexOf(e)
        if (i > -1) {
            let j = that.valueProxy[i].children?.indexOf(child);
            if(j > -1){
              that.valueProxy[i].children?.splice(j+1, 0, {dataModel: {},connector: defaultConnector, operator: '', key: '', val: ''})
            }
            this.$emit('addChildItem', e)
        }
        resolve(e)
      })
    },
    @API.doc(false)
    removeChildItem(e, child) {
      let that = this;
      return new Promise((resolve, reject) => {
        return this.$confirm(this.$transMsg('lang.tip.delete'), this.$transMsg('lang.tip.warning')).then(() => {
          let i = that.valueProxy.indexOf(e)
            if (i > -1) {
                let j = that.valueProxy[i].children?.indexOf(child)
                if(j > -1){
                  if(that.valueProxy[i].children.length == 1){
                    this.valueProxy.splice(i, 1)
                  }else{
                    that.valueProxy[i].children?.splice(j, 1)
                  }
                }
              this.$forceUpdate()
              this.$message({ type: 'success', message: this.$transMsg('lang.tip.deleteSuccess') })
              this.$emit('removeChildItem', e)
            } else {
              this.$message({ type: 'warning', message: this.$transMsg('lang.tip.deleteFailure') })
            }
            resolve(e)
        })
      })
    },
    @API.doc(false)
    removeItem(e) {
      // TODO 添加行index删除方法
      return new Promise((resolve, reject) => {
        return this.$confirm(this.$transMsg('lang.tip.delete'), this.$transMsg('lang.tip.warning')).then(() => {
          let i = this.valueProxy.indexOf(e)
            if (i > -1) {
              this.valueProxy.splice(i, 1)
              this.$forceUpdate()
              this.$message({ type: 'success', message: this.$transMsg('lang.tip.deleteSuccess') })
              this.$emit('removeItem', e)
            } else {
              this.$message({ type: 'warning', message: this.$transMsg('lang.tip.deleteFailure') })
            }
            resolve(e)
        })
      })
    },
    @API.doc(false)
    blurInput (e, j) {
      console.log(e, j);
      if (!this.showMessage) return
      let reg = new RegExp(e.dataModel?.validate?.expression) // /^[1-9][0-9]?$/ // 后端字段--正則
      let el = this.$refs[`input${e.key}${j}`][0].$el.querySelector('input')
      let val = e.val
      // 当前是int\long形 且未配置正则的 手动加数字校验
      if (['int', 'long'].includes(e.dataModel?.type) && !reg) {
        reg = /^\d+(\.\d+)?$/
      }
      if (reg) {
        if (!reg.test(val)) {
          el.style.borderColor = '#f56c6c'
          this.$set(this.validateObject, `input${e.key}${j}`, false)
        } else {
          el.style.borderColor = '#dcdfe6'
          this.$set(this.validateObject, `input${e.key}${j}`, true)
        }
      }
    },
    @API.doc(false)
    changeInput (e,j) {
      this.$nextTick(() => {
        let el = this.$refs[`input${e.key}${j}`][0].$el.querySelector('input');
        el.blur();
        el.focus();
      })
    },
    @API.doc('表单校验')
    validateForm () {
      console.log(this.validateObject);
      return new Promise((resolve, reject) => {
        this.valueProxy.forEach(list => {
          list.children.forEach((item, j) => {
            const type = item.dataModel?.type;
            const typeArr = ['int', 'long', 'text'];
            if (typeArr.includes(type)) {
              // 如果类型是text 但又没有枚举，但又要in\nin
              if(['in', 'nin'].includes(item.operator) && type === 'text') return
              try {
                let reg = item.dataModel?.validate?.expression ? new RegExp(item.dataModel?.validate?.expression) : '';
                let el = this.$refs[`input${item.key}${j}`]?.[0].$el.querySelector('input');
                // 当前是int\long形 且未配置正则的 手动加数字校验
                if (['int', 'long'].includes(item.dataModel?.type) && !reg) {
                  reg = /^\d+(\.\d+)?$/
                }
                if (reg && el) {
                  if (!reg.test(item.val)) {
                    el.style.borderColor = '#f56c6c';
                    this.$set(this.validateObject, `input${item.key}${j}`, false)
                  } else {
                    el.style.borderColor = '#dcdfe6';
                    this.$set(this.validateObject, `input${item.key}${j}`, true)
                  }
                }
              } catch(e) {
                console.log(e)
              }
            }
          })
        })
        let valid = Object.values(this.validateObject).some(item => !item)

        // 规则如果没有不做检验
        if (this.valueProxy?.length === 0) {
          valid = false
        }
        if (!valid) {
          resolve(!valid)
        } else {
          reject('GEEK-FORM::validate error')
        }
      })
    },
    @API.doc(false)
    loadNode(node, resolve){
      let list = node.store.props.dataModel.enumeration.list
      if (node.level === 0) {
        return resolve(list);
      }
      if (node.level >= 1) {
        GET(node.data.url+'=' +node.data.code).then(res => {
          console.log(res)


          let data = res.data.map(item => ({...node.data,name: item[node.data?.mapping?.name], code: item[node.data?.mapping?.code]}))

          return resolve(data)
        })
      };
    },
    @API.doc(false)
    checkNode(data, nodes){
      // 元数据配置的code 对应树结构的node-key  对应后端数据表的columnDataDesp->enumeration->mapping
      let MAPPING = {
        ID: data.mapping.code,
        KEY: data.mapping.key,
        CHILDREN: data.mapping.children
      }
      let thisNode = this.$refs[MAPPING.KEY][0].getNode(data[MAPPING.ID])
      let keys = this.$refs[MAPPING.KEY][0].getCheckedKeys()
      // 拼接关联关系
      function findNode(node,path=''){
        if (keys.includes(node[MAPPING.ID])) {
          path = path ? path + '-' + node[MAPPING.ID] : node[MAPPING.ID]
        }
        if(!node[MAPPING.CHILDREN].length) return [path]
        return node[MAPPING.CHILDREN].map(d=>findNode(d,path)).flat(Infinity)
      }
      // 关联父节点勾选
      if (thisNode.checked) {
        for (let i = thisNode.level;i>1;i--) {
          // if (!thisNode.parent.checked) {
            thisNode = thisNode.parent
            keys.unshift(thisNode.data[MAPPING.ID])
          // }
        }
      }
      this.$refs[MAPPING.KEY][0].setCheckedKeys(keys)
      // 获取node节点
      let checkedNodes = this.$refs[MAPPING.KEY][0].getCheckedNodes()
      let findNodeArr = findNode({[MAPPING.ID]: 0, [MAPPING.CHILDREN]: checkedNodes}).filter(Boolean)
      let findNodeFilter = []
      // 过滤重复关系
      Array.from(new Set(findNodeArr)).forEach(item => {
        // 单独关系id比较
        let sing = findNodeFilter.find(k => k.includes(item))

        findNodeFilter.forEach((k, i) => {
          //8-12-14-16 替换 8-12 12-14  14-16
          let itemIndex = item.indexOf(k)
          // 判斷是否包含上一個，是则替换
          if(itemIndex > -1 && item[itemIndex + k.length] === '-' && item[itemIndex - 1] === '-'  || itemIndex === 0 && item[itemIndex+k.length] === '-' || itemIndex === item.length - k.length && item[itemIndex-1] === '-') {
            findNodeFilter[i] = item
            sing = item
          }
        })
        if (!sing) {findNodeFilter.push(item);return}
        // 获取元素匹配索引
        let singIndex = sing.indexOf(item)
        // 全匹配
        if (sing === item) return
        // 匹配元素在中间
        if(singIndex >-1 && sing[singIndex+item.length] === '-' && sing[singIndex-1] === '-') return
        // 匹配位置在开头
        if(singIndex === 0 && sing[singIndex+item.length] === '-') return
        // 匹配位置在结尾
        if(singIndex === sing.length - item.length && sing[singIndex-1] === '-') return

        findNodeFilter.push(item)
      })

      console.log(findNodeArr);

      // // TODO
      this.valueProxy.forEach(list => {
        list.children.forEach(item => {
          let drop = item.dataModel?.enumeration?.drop
          if (item.dataModel.type === "tree") {
            // 传递后端值
            item.val = findNodeFilter
            // 实际页面展示
            item.nameVal = findNodeFilter.map(name => (name.split('-').map(id => drop[id]).join('-')))
          }
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.geek-form-table{
  .relative {
    position: relative;
  }
    .geek-form-table-header{
        margin-bottom: 12px;
    }
    .geek-form-table-title{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border-bottom: 1px solid #1296db;
        padding: 12px 0;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 14px;
        >div{
            flex: 1;
            margin-right: 20px;
        }
        > div:last-child{
            margin-right: 0;
        }
        > div:nth-child(1){
            max-width: 120px;
        }
    }
    .geek-form-table-content{
        padding-bottom: 12px;
        border-bottom: 1px solid #1296db;

        .geek-form-table-row{
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 70px;
            padding-right: 15px;
            &:last-child{
               margin-bottom: 0; 
               .geek-split-row{
                   display: none;
               }
            }
            &:first-child{
                .delete-icon{
                    top: -25px;
                }
            }
            .geek-form-table-row-connector{
                max-width: 100px;
                margin-right: 20px;
            }
            // &:first-child .geek-form-table-row-connector{
            //     visibility: hidden;
            // }
            .geek-form-table-row-body{
                // background: rgb(243,243,245);
                padding: 10px 20px 0;
                flex: 1;
                .geek-form-table-child-row{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: 22px;
                    >div{
                        flex: 1;
                        margin-right: 20px;
                    }
                    > div:last-child{
                        margin-right: 0;
                    }
                    .operation-btn{
                        min-width: 84px;
                        .el-icon-plus{
                            color: #1296db;
                            font-size: 18px;
                            padding: 10px;
                        }
                        .el-icon-minus{
                            color: #f10;
                            font-size: 18px;
                            padding: 10px;
                        }
                    }
                    &:first-child{
                        .geek-form-table-row-connector{
                            visibility: hidden;
                        }
                    }
                    
                }
            }
            .geek-split-row{
                display: flex;
                align-items: center;
                width: calc(100% - 40px);
                position: absolute;
                bottom: -60px;
                padding: 20px;
                .el-select{
                    width: 100px;
                    margin: 0;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .geek-split-row-line{
                    flex: 1;
                    height: 1px;
                    background: #1296db;
                }
            }
        }
        .delete-icon{
            position: absolute;
            color: #f10;
            right: 0;
            top: -42px;
            font-size: 24px;
            background: #fff;
        }
    }
    .geek-form-table-footer{
        display: flex;
        justify-content: center;
        margin: 16px 0;
    }
    .vis-hidden{
      visibility: hidden;
    }

}
</style>
<style lang="scss">
.tree-select {
  .el-tag.el-tag--info .el-tag__close {display: none}
  .el-select__tags-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.el-select .el-tag {
    display: flex;
    max-width: 100%;
    align-items: center;
  }

}

//.hidden-ellipsis .el-input__inner {
//  width: 140px;
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  overflow: hidden;
//  &::-webkit-input-placeholder {
//
//    width: 140px;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    overflow: hidden;
//    color: red;
//
//    font-weight: 400;
//
//  }
//}
</style>