import Vue from 'vue'
import VueI18n from 'vue-i18n'
import storage from '../utils/storage'
import { handleGetCurLanguage } from '@/utils/langs';

const curLanguage = localStorage.getItem('curLanguage') || 'zh_cn'
const map = {
  zh_cn: 'cn',
  zh_hk: 'hk',
  en_us: 'en',
  ja_jp: 'jp',
  he_il: 'il',
  ko_kr: 'ko'
};
Vue.use(VueI18n)

// 获取当前国际化
const messages = {
  cn: {},
  en: {},
  hk: {},
  jp: {},
  il: {},
  ko: {}
}

// 获取国际化codelist
export async function createI18n() {
  let LangStorage = await storage.i18n.getItem(`LangStorage_${curLanguage}`)
  if(!LangStorage) {
    LangStorage = await handleGetCurLanguage(curLanguage)
  }else {
    handleGetCurLanguage(curLanguage)
  }
  messages[map[curLanguage]] = {...messages[map[curLanguage]], ...LangStorage}

  return new VueI18n({
    locale: map[curLanguage],
    silentTranslationWarn: true,
    messages
  })
}

export default new VueI18n({
  locale: map[curLanguage],
  silentTranslationWarn: true,
  messages
})