<template>
  <div class="overallPadding mh100" style="min-height: 600px;">
    <div class="shelf-wrap mh100" :style="`width: ${shelfWidth}; min-height:500px`">
        <div class="mh100 shelf-col" :span="24">
            <div class="shelf-container mh100">
            <el-form :disabled="true">
                <el-row>
                    <el-col :span="12">
                        <el-form-item  prop="shelfCategory" :label="$t('lang.wms.fed.shelfSpecies')">
                            <el-select filterable v-model="form.shelfCategory" @change="shelfCateChange" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                <el-option
                                v-for="(item, index) in (dict.shelfCategory)"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item  prop="shelfType" :label="$t('lang.wms.fed.shelfType')">
                            <el-select filterable v-model="form.shelfType" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                <el-option
                                v-for="(item, index) in (dict.shelfType)"
                                :key="index"
                                :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div class="sides">
                <div class="side front" @click="switchSide('F')" :class="{active: currSide == 'F'}">F</div>
                <div class="side back" v-if="form.sideNum >= 2" @click="switchSide('B')" :class="{active: currSide == 'B'}">B</div>
                <div class="side back" v-if="form.sideNum == 4" @click="switchSide('L')" :class="{active: currSide == 'L'}">L</div>
                <div class="side back" v-if="form.sideNum == 4" @click="switchSide('R')" :class="{active: currSide == 'R'}">R</div>
            </div>

            <div class="shelf" ref="shelf" :class="{disabled: false}" v-if="floorList && floorList.length && renew" style="min-height:600px;">
                <div class="robot-section" :style="'height: 50px'">
                <div class="line vertical left"></div>
                <div class="line vertical right"></div>
                <div class="line horizontal top"></div>
                </div>
                <div class="floor" v-for="(floor, i) in floorList" :key="i" :style="'height:100px'">
                    <div class="line vertical left"></div>
                    <div class="line vertical right"></div>
                    <div class="line horizontal top"></div>
                    <!-- <div class="line vertical" v-for="(percent, k) in floor.cells.slice(0, floor.cells.length - 1)" :key="k" :style="`left: ${getLeftPx(floor.cells, k)}`"></div> -->
                    <div class="cells">
                        <div class="cell" v-for="(cell, j) in floor.cells" :key="j" :style="`width: ${$px(cell.width)}`" 
                        :class="{
                          'dynamic-saved': cell.shelfBinType == 200
                          }">
                        {{ (cell.binLayer || '') + cell.binColumn || chars[j] }}
                            <div class="corner-box"  
                              v-if="cell.shelfBinType == 300"
                              >
                            <div class="corner-box-txt">{{cell.binCount || ''}}</div></div>
                            <distance mode="horizontal" v-model="cell.width" :disabled="disableEdit" :min="MIN_CELL_WIDTH" />
                        </div>
                    </div>
                    <distance v-model="floor.cells[0].height" :min="MIN_FLOOR_HEIGHT" :disabled="disableEdit" />
                </div>
            </div>
            <!-- <div class="buttons">
                <el-button type="success" :disabled="step != 2 || form.sideNum == 1" @click="copyF2B">{{$t('lang.wms.fed.copyFsideToBside')}}</el-button>
                <el-button type="primary" :disabled="step == 1 || step == 2" @click="step = 2">{{$t('lang.wms.fed.edit')}}</el-button>
                <el-button :disabled="step != 2" @click="step = 3">{{$t('lang.wms.fed.confirm')}}</el-button>
            </div> -->
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import Distance from './distance';
import { POST } from '@/utils/http'

const MIN_CELL_WIDTH = 50;
const MIN_FLOOR_HEIGHT = 50;

export default {
name: 'GeekShelfPreview',
options: {
    name: '货架规格预览',
    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
        cancleEdit: '取消'
    }
},
  components: { Distance },
  props: {
    id: {
      type: String,
      default: ''
    },
    // shelfData: {
    //   type: Object,
    //   function: () => {
    //     return {};
    //   }
    // }
  },
  data() {
    return {
      dict: {
        shelfCategory: [],
        shelfType: []
      },
      shelfCategory: '',
      shelfType: '',
      MIN_CELL_WIDTH,
      MIN_FLOOR_HEIGHT,
      disableEdit: true,
      renew: true,
      form: {
        id: null,
        templateName: '',
        classCode: '',
        className: '',
        sideNum: 4,
        shelfLayer: 1,
        shelfCol: 1,
        shelfTotalHeight: 0,
        robotHeight: 0,
        shelfHeight: 0,
        shelfWidth: 0,
        shelfLength: 0,
        shelfDepth: 0,
        isVirtualShelfContain: 'N',
        weight: null,
        shelfNumber: null,
        shelfIdentifierList: [{ shelfIdentifierStart: '', shelfIdentifierEnd: '' }],
        shelfBinList: [],
        bindesc: null,
        enabled: true,
        inventoryAmountUpperLimit: null,
        skuTypeUpperLimit: 1,
        customerAmountUpperLimit: 1,
        itemStyleAmountUpperLimit: 1,
        volume: null,
        abcType: null,
        useType: null,
        shelfCategory: null,
        shelfType: null,
        binType: null,
        warehouseAreaId: null,
        logicAreaId: null
      },
      sides: ['F', 'B', 'L', 'R'],
      currSide: 'F',
      ratio: 1,
      chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      chars1Z: '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      chars99: 99,
      dialogVisible: false,
      handleType: '',
      loading: false,
      sysVersion: '520',
      choosed: {},
      shelfDataRaw: {},
      shelfDataSides: {
        F: {},
        B: {},
        L: {},
        R: {},
      },
    }
  },
  computed: {
    floorList() {
      let floorList = [];
      this.form.shelfBinList.forEach(item => {
        if (item.side === this.currSide) {
          floorList[item.floor - 1] = floorList[item.floor - 1] || { cells: [] };
          floorList[item.floor - 1].cells.push(item);
        }
      });
      floorList.forEach(floor => {
        floor?.cells?.sort((a, b) => a.index - b.index);
      })
      console.log('floorList', floorList);
      return floorList.filter(item => item);
    },
    shelfWidth() {
      const count = Math.ceil(this.form.shelfCol / 20) || 0;
      return '' + 100 * count + '%' ;
    },
  },
  watch: {
    'dataSource.shelfPreviewData': {
        handler(val) {
          if (val && !val.code) {
            const data = val.data || {};
            const binDesc = data.bindesc ? JSON.parse(data.bindesc) : ''    
            const bins = [];
            this.form.shelfType = data.shelfType;
            this.form.shelfCategory = data.shelfCategory;
            this.form.sideNum = binDesc?.sides.length;
            this.form.shelfHeight = binDesc?.height || 0;
            this.form.shelfLength = binDesc?.length || 0;
            this.form.shelfWidth = binDesc?.width || 0;
            this.form.robotHeight = binDesc?.baseHeight || 0;
            (binDesc?.sides || []).forEach(side => {
                side.layers.forEach(layer => {
                    layer.binGroups.forEach(group => {
                        // const binsFormat = group.bins.map(v => {
                        //     return {
                        //         ...v,
                        //         side: side.sideCode,
                        //         binColumn: group.columnCode,
                        //         floor: layer.layerIndex,
                        //         width: group.binGroupWidth,
                        //         height: group.binGroupHeight,
                        //         index: group.columnIndex - 1,
                        //     }
                        // }) 
                        bins.push({
                          side: side.sideCode,
                          binColumn: group.columnCode,
                          binLayer: layer.layerCode,
                          floor: layer.layerIndex,
                          width: group.binGroupWidth,
                          height: group.binGroupHeight,
                          index: group.columnIndex - 1,
                          shelfBinType: group.binGroupType,
                          binCount: group.binGroupColumnNum * group.binGroupFloorNum
                        });
                    })
                })
            })
            this.form.shelfBinList = Object.assign([], bins);
          }
        },
        deep: true,
        immediate: true,
    },
    // shelfData: {
    //   handler(val) {
    //     if (val) {
    //       // 生成货架列
    //           // checked: false,
    //         // classCode:'X',
    //         // shelfCategory: 0,
    //         // shelfType:1,
    //         // shelfCodeLen: 2,
    //         // shelfSideCodeLen: '',
    //         // shelfLen: '',
    //         // binFormatType: 1, // 0旧 1新
    //         // shelfFloorRule: "1",
    //         // shelfColumnRule: "1",
    //         // shelfFloorMaxNum: 9,
    //         // shelfColumnMaxNum: 9,
    //         // columnAndFloorOrderRule: "1",
    //         // shelfCustomRules: 
    //     //   this.form.robotHeight = val.footHeight ? val.footHeight * 10 : '',
    //     //   this.form.shelfHeight = val.height ? val.height * 10 : '',
    //     //   this.form.shelfDepth = val.length ? val.length *10 : '',
    //     //   nshelfLengthgth =  val.length ? val.length *10 : '',
    //     //   this.form.shelfWidth = val.width ? val.width *10 : '',
    //     //   this.form.weight = val.bearWeight ? val.bearWeight *10 : '',
    //     //   this.form.shelfLayer = val.shelfSize && val.shelfSize.layer || 1; // 当前货架默认层列
    //     //   this.form.shelfCol = val.shelfSize && val.shelfSize.column || 1; // 当前货架默认层列
    //     //   this.currSide = val.shelfSide || 'F'; // 当前面
    //     //   this.form.sideNum = val.sideNum || 2; // 面数
    //     //   this.form.classCode = val.classCode || ''; // 面数
    //     //   if (val.shelfSize) {
    //     //     this.floorChange(val.shelfSize.layer, val.shelfSize.column);
    //     //   }
    //     };
    //   },
    //   immediate: true,
    //   deep: true,
    // }
  },
  async created() {
    await this.getDicts();
    // console.log(bins);
    // this.form.shelfBinList = bins;
  },
  mounted() {
    // this.ratio = this.form.shelfWidth / this.$refs.shelf.offsetWidth;
  },
  methods: {
    async getDicts() {
        let params = {
            objectCodes: [
            'shelf_category', // 货架种类
            'shelf_type', // 货架类型
            ],
            enable: true,
            sortKey: 'sortOrder'
        };
        await POST('/api_v1/dict/batchQuery', params).then(res => {
            if (res.code === 0) {
            const toCamel = e => e.replace(/_(\w)/g, ($0, $1) => $1.toUpperCase());
            // 字典
            Object.keys(res.data).forEach((e) => {
                const key = toCamel(e);
                // if (!this.drop[key]) {
                //     this.drop[key] = {}
                // }
                if (!this.dict[key]) {
                this.dict[key] = []
                }
                this.dict[key] = (res.data[e].details || []).map(v => {
                return {
                    ...v,
                    label: this.$t(v.fieldValue),
                    value: Number(v.fieldCode)
                }
                });
                // console.log(res.data[e].details, key);
                // (res.data[e].details || []).map(v => {
                //     this.drop[key][v.fieldCode] = this.$t(v.fieldValue);
                //     return v;
                // });
                // console.log(this.drop[key]);
            });
            }
        });
    },
    switchSide(side) {
      this.currSide = side;
    },
    $px(mm) {
      return Math.floor(mm / this.form.shelfWidth * 100) + '%';
    },
    getChars(rules) {
      // const rules = this.shelfData[ruleKey];
      if(rules == 1) {
        return this.chars99;
      } else if (rules == 2) {
        return this.chars1Z;
      } else {
        return this.chars;
      }
    },
    getLeftPx(cells, index) {
      let mm = 0;
      for (let i = 0; i <= index; i++) {
        mm += cells[i].width;
      }
      return this.$px(mm);
    },
    back() {
      this.$emit('goBack');
    },
  }
}
</script>

<style lang="scss" scoped>
  .overallPadding {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // z-index: 10;
    background-color: #f4f4f5;
    width: 100%;
    height: 100%;
    overflow: auto;
  }
  .shelf-wrap {
    position: relative;
    background: #fff;
    padding:16px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin:16px;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
      text-decoration: underline;
      &.step2 {
        margin-left: -50px;
      }
    }
    .sides {
      display: flex;
      width: 70%;
      margin: 0 auto 30px;
      .side {
        flex: 1;
        text-align: center;
        padding: 5px 0;
        border: 1px solid #ddd;
        cursor: pointer;
        background: #fff;
        &.front {
          margin-right: -1px;
        }
        &.active {
          color: #fff;
          background: #66b1ff;
        }
      }
    }
    .buttons {
      text-align: center;
    }

    .shelf-col {
      padding-left: 60px;
      padding-right: 100px;
    }
    .shelf-container {
      display: flex;
      flex-flow: column;
      perspective: 1000px;
      .shelf {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        flex-direction: column-reverse;
        .robot-section {
          position: relative;
          width: 100%;
          height: 68px;
        }
        .floor {
          position: relative;
          .operations {
            position: absolute;
            right: -80px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .cells {
          display: flex;
          width: 100%;
          height: 100%;
          .cell {
            position: relative;
            font-size: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #999; 
            &.highLight-cell{
              background: #e39e1fdb;
              color: #fff
            }
            &.dynamic-saved{
              background: pink !important;
            }
          }
        }
        .line {
          position: absolute;
          z-index: 2;
          &.vertical {
            width: 1px;
            height: 100%;
            background: #999;
          }
          &.horizontal {
            width: 100%;
            height: 1px;
            background: #999;
          }
          &.left {
            top: 0;
            left: 0;
          }
          &.right {
            top: 0;
            right: 0;
          }
          &.top {
            top: 0;
            left: 0;
          }
          &.bottom {
            bottom: 0;
            left: 0;
          }
        }
      }
      .corner-box {
        position: absolute;
        top: 0;
        left: 0;
        border: 20px solid transparent;
        border-top: 20px solid #5daf34;
        border-left: 20px solid #5daf34;
      }
      .corner-box-txt {
        position: absolute;
        width: 20px;
        text-align: center;
        top: -15px;
        right: 0;
        color: white;
        font-size:14px;
        scale: 0.8;
        white-space: nowrap;
      }
    }
    .disabled {
      outline: 0 none;
      cursor: default !important;
      opacity: .4;
      filer: alpha(opacity=40);
      -ms-pointer-events: none;
      pointer-events: none;
    }
    .shelf-code {
      margin-bottom: 10px;
    }
    .el-form-item__content{
      line-height: 28px !important;
    }
  }
  .mh100 {
    height: 100%;
  }
</style>
