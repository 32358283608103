import { GET, POST } from '@/utils/http'
import storage from './storage'

export const initLangs = () => {
  let langs = [];
  let currentLangVal = localStorage.getItem('curLanguage') || 'zh_cn';
  localStorage.setItem('curLanguage', currentLangVal);
  return new Promise(resolve => {
    GET('/api/coreresource/i18n/getLanguages/v1').then(data => {
      if (!data.code && data.data.length) {
        data.data.forEach(lang => {
          langs.push(lang)
        })
        resolve(langs)
      }
    })
  })
}

export const handleGetCurLanguage = async (languageCode, needReload = false) => {
  if (!languageCode) return
  let res = await GET('/api/coreresource/i18n/getLangItems/v1', { languageCode })
  if (!res.code) {
    localStorage.setItem('curLanguage', languageCode);
    await storage.i18n.setItem(`LangStorage_${languageCode}`, res.data);
    if(needReload) {
      window.location.reload()
    }
    return res.data
  }
}
