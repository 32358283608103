import router from '@/router';
import EventBus from '@/utils/bus';

let socket = null;
const map = new Map();

const send = msg => {
  if (socket) {
    socket.send(JSON.stringify(msg));
  }
};

const reconnectMax = 10;
let reconnectTimes = 0;

const init = async options => {
  if (reconnectTimes > reconnectMax) {
    return;
  }
  reconnectTimes++;

  const { onmessage, autoReconnect, onopen, openHeartCheck } = options;

  const protocol = location.protocol === 'https:' ? 'wss' : 'ws';
  const url = `${protocol}://${location.host}${process.env.VUE_APP_STATION_API}/api/common/femsg/websocket/v1`;
  if (window.SOCKET) {
    socket = window.SOCKET;
  } else {
    socket = new WebSocket(process.env.NODE_ENV === "development" ? '' : url);
    window.SOCKET = socket
  }

  socket.onclose = e => {
    console.log('onclose', e);
    clearInterval(heartCheck.timer);
    if (autoReconnect) {
      window.SOCKET = null;
      socket = null;
      setTimeout(() => {
        init(options);
      }, 2000)
    }
  }

  socket.onopen = () => {
    reconnectTimes = 0;
    onopen();
    if (openHeartCheck) {
      heartCheck.start();
    }
  };

  socket.onmessage = onmessage

};

const heartCheck = {
  timeout: 20000,
  timer: null,
  start: function () {
    this.timer && setInterval(this.timer);
    this.timer = setInterval(() => {
      send({
        command: 'push',
        clientId: localStorage.getItem('userName'),
        data: {
          type: 'NOTICE',
          content: {
            eventType: 'HEART_CHECK'
          }
        }
      });
    }, this.timeout)
  }
}

export const baseSocketInit = () => {
  try {
    if (router?.currentRoute?.path === '/login') {
      return;
    }

    reconnectTimes = 0;

    init({
      autoReconnect: true,
      openHeartCheck: true,
      onmessage: msg => {
        const data = JSON.parse(msg.data);
        if (data.command === 'messages') {
          for (const message of data.data.messages) {
            let content = '';
            try {
              // eventType = JSON.parse(message.content).eventType;
              if (message.content) {
                content = JSON.parse(message.content);
              }
            } catch (error) {
              console.log('解析content失败', error);
            }
            if (content) {
              EventBus.$emit('socketApolloMsg', { content });
            }
          }
        }
      },
      onopen: () => {
        send({
          command: 'init',
          clientId: window.localStorage.getItem('userName'),
          data: {
            pull: {
              maxCount: 10,
              autoConfirmNotice: true,
              autoIntervalSeconds: 20
            }
          }
        })
      }
    });

  } catch (error) {
    console.log('=============femsg socket error==========', error);
  }
}


export const on = (event, cb) => {
  map.set(cb, e => {
    try {
      const { eventType } = JSON.parse(e?.data?.messages?.[0]?.content)
      // 只关心command为messages的消息 并且eventType不为HEART_CHECK的
      if (e.command === 'messages' && eventType === event && eventType !== 'HEART_CHECK') {
        cb(e);
      }
      // 用来处理异常信息数据
      if (event == 'socketFeErrorMsg') {
        let { status, type } = e?.data?.messages?.[0] || {}
        if (e.command === 'messages' && status === 'NEW' && type == 'NOTICE' && eventType !== 'HEART_CHECK') {
          cb(e);
        }
      }
    } catch (err) {
      console.log(`ws:接收数据异常.  err: ${err},  data: ${e?.data}`)
    }
  });
  socket && socket.event.$on('message', map.get(cb));
};

export const off = (event, cb) => {
  socket && socket.event.$off('message', map.get(cb));
};
