import { GET } from '@/utils/http'
import storage from '@/utils/storage';

const workingModes = ['APOLLO', 'REPORT'];
//欢迎页改成获取首页数据
//更新menulist
async function getModuleMenus() {
    let GPS_MenuData = sessionStorage.getItem('geeklocalsessiondata')
    if(GPS_MenuData) {
        GPS_MenuData = JSON.parse(GPS_MenuData)
    }else {
        GPS_MenuData = await storage.session.getItem('GeekLocalSessionData')
    }
    GPS_MenuData && getSessionData()

    try {
        // 删除本地国际化和GeekLocalSessionData
        for(let key in localStorage) {
            if(/^LangStorage_/g.test(key)) {
                localStorage.removeItem(key)
            }
        }
        localStorage.removeItem('GeekLocalSessionData')
        sessionStorage.removeItem('geeklocalsessiondata')
    }catch (e) {
        console.error('localStorage remove error', e)
    }
    
    if(!window.performance.navigation.type){
        localStorage.setItem('tagViews', JSON.stringify([]))
    }
    await Promise.all(GPS_MenuData ? [
        GET(`/api_v1/sysparam/query?paramKey=system_units_transfer&__rnd=${+new Date()}`),
        GET('/api/coreresource/auth/janus-info/v1')
    ] : [
        GET(`/api_v1/sysparam/query?paramKey=system_units_transfer&__rnd=${+new Date()}`),
        GET('/api/coreresource/auth/janus-info/v1'),
        GET('/api/coreresource/auth/getModuleMenus/v1')
    ]).then(resAll => {
        let [resQuery, info, menuData] = resAll;
        // 系统配置
        let config = {}
        if (resQuery.code === 0) {
            try {
                resQuery.data.recordList.forEach((e) => {
                    config[e.paramKey] = JSON.parse(e.paramValue || '{}')
                })
            } catch (e) {
                console.warn('系统参数异常')
            }
            localStorage.setItem('SYSTEM_CONFIG_DEFAULT', JSON.stringify(config))
        }

        // 用户信息
        localStorage.setItem('userName', info.data.userName)
        localStorage.setItem('logoutUrl', info.data.logoutUrl || '')

        // 权限
        let res = GPS_MenuData ? { data: GPS_MenuData } : menuData;
        if(!res?.data?.user){
            window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
            return;
        }
        res.data.user.permissionList = getPermissionList(res.data)
        const allMenuList = getMenuList(res)
        storage.session.setItem('GeekLocalSessionData', res.data)
        localStorage.setItem('GPS_SessionID', res.data?.sessionId)
        localStorage.setItem('MenuList', JSON.stringify(allMenuList))

    })
};

function getMenuList(res) {
    let list = []
    let menuList = []
    const systemIdPermissionMap = res.data.user.systemIdPermissionMap
    if (workingModes.length > 0 && systemIdPermissionMap) {
        for (let i = 0; i < workingModes.length; i += 1) {
            const el = workingModes[i]
            if (
                !systemIdPermissionMap[el] ||
                !systemIdPermissionMap[el][`${el}-PC-menu`]
            ) {
                // eslint-disable-next-line no-continue
                continue
            }
            const elist = systemIdPermissionMap[el][`${el}-PC-menu`] || [] // APOLLO-PC-menu  // DEMETER-PC-menu // PERSEPHONE-PC-menu
            list = list.concat(elist)
        }
        menuList = list.sort((a, b) => a.sort - b.sort)
        // menuList = menuList.sort((a, b) => (a.sort - b.sort));
        menuList = menuList.filter((item) => item.code !== 'reports')
    }
    console.log(menuList)
    return menuList
};
function getPermissionList(data) {
    if (!data?.user?.systemIdPermissionMap) return []
    let list = []
    for (let item in data.user.systemIdPermissionMap) {
        list.push(data.user.systemIdPermissionMap[item][item + '-PC'])
    }
    return list.filter(Boolean).flat()
};
async function getSessionData() {
    let res = await GET('/api/coreresource/auth/getModuleMenus/v1')
    let GPS_SessionID = localStorage.getItem('GPS_SessionID')
    if(res.data && res.data.sessionId != GPS_SessionID) {
        await storage.session.setItem('GeekLocalSessionData', res.data)
        window.location.reload()
    }
}

export default getModuleMenus;

