<template>
  <div class="geek-menu-dialog" @mousedown.stop>
    <div class="geek-dialog-mask" @click="handleClose"></div>
    <div class="geek-menu-body">
        <div class="geek-menu-left" :class="{'el-menu-showPop': isMenuFix}">
            <div class="search-content">
                <el-input :placeholder="$t('lang.wms.fed.pleaseEnterContents')" v-model="searchStr" @input="handleSearch" @blur="onBlur">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <div class="filter-content" v-if="showFilter">
                    <div class="filter-content-item" v-for="item of filterList" :key="JSON.stringify(item)" @click="handleRouterSelect(item)">
                        <i class="el-icon-star-off"></i>
                        <span>{{$t(item.wholeName)}}</span>
                    </div>
                </div>
            </div>
            <el-menu
                class="el-menu-vertical-list"
                router
                unique-opened
                :default-active="defaultActive"
                mode="vertical"
                menu-trigger="hover"
                :collapse="isMenuFix"
                @open="handleOpen"
                >
                <template v-for="(route, index) in flatMenuList">
                    <el-submenu v-if="route.children && route.children.length" popper-class="" :key="index" :index="route.path">
                            <el-tooltip slot="title" class="item" effect="dark" :disabled="isShowTooltip" :content="$t(route.name)" placement="top">
                                <span class="over-flow" @mouseover="onMouseOver">{{$t(route.name)}}</span>
                            </el-tooltip>
                                <template v-for="(item, i) in route.children">
                                    <el-submenu v-if="item.children && item.children.length" :key="i" :index="i+''">
                                        <el-tooltip slot="title" class="item" effect="dark" :disabled="isShowTooltip" :content="$t(item.name)" placement="top">
                                            <span class="over-flow" @mouseover="onMouseOver">{{$t(item.name)}}</span>
                                        </el-tooltip>
                                        <side-item v-for="item1 in item.children" :key="JSON.stringify(item1)" :route-obj="item1" @select="handleRouterSelect"/>
                                    </el-submenu>
                                    <template v-else>
                                        <side-item class="hasNoChild" :key="i" :route-obj="item" @select="handleRouterSelect"/>
                                    </template>
                                </template>
                    </el-submenu>
                    <template v-else>
                        <div :key="index" class="el-submenu__title first-level-index" style="padding-left: 20px;" @click="handleMenuSelect(route)"><span data-v-c1aefba8="">{{$t(route.name)}}</span></div>
                        <!-- <side-item :key="index" :route-obj="route" @select="handleRouterSelect"/> -->
                    </template>
                </template>

            </el-menu>
            <!-- <div v-if="$store.state.isAddCommon">
                <el-button type="primary" @click="handleSubmit">{{$t("lang.wms.fed.determine")}}</el-button>
                <el-button type="info" @click="handleClose">{{$t("lang.wms.fed.cancel")}}</el-button>
            </div> -->
            <div class="mode-change">
                <span>{{$t('lang.wms.fed.classicMode')}}</span>
                <el-switch v-model="$store.state.menuType" active-value="1" inactive-value="0"></el-switch>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { constantRoutes } from '@/router'
import SideItem from './SideItem'
import { getCommonFunList, addCommonFun } from '@/api/home'

export default {
  name: 'GeekNavMenu',
  components: {
    SideItem
  },
  data() {
    return {
      menuList: [],
      searchStr: '',
      selectData: this.$store.state.isAddCommon ? JSON.parse(localStorage.getItem('commonMenu')) || [] : [],
      filterList: [],
      showFilter: false,
      isMenuFix: true,
      isShowTooltip: true
    }
  },
  props: {

  },
  computed: {
    defaultActive() {
      return this.$route.meta?.inAppEditor ? this.$route.meta.path : this.$route.path
    },
    flatMenuList() {
      let menuList = JSON.parse(JSON.stringify(this.menuList));
      return menuList.map(res => {
        let children = res.children?.map(ele => {
          if (ele.children && ele.children.length) {
            return {
              name: ele.name,
              sort: ele.sort,
              children: ele.children.map(item => ({ ...item, path: `/${res.path}/${ele.path}/${item.path}`, wholeName: `${res.name} > ${ele.name} > ${item.name}` })) }
          }
          return { ...ele, path: `/${res.path}/${ele.path}`, wholeName: `${res.name} > ${ele.name}`, children: [] }
        });
        children.sort((a, b) => b.sort - a.sort )
        console.log({ ...res, children })
        return { ...res, children }
      })
    },
    allMenuList() {
      let menuList = JSON.parse(JSON.stringify(this.menuList));
      return menuList.map(res => {
        let children = res.children?.map(ele => {
          if (ele.children && ele.children.length) {
            return ele.children.map(item => ({ ...item, path: `/${res.path}/${ele.path}/${item.path}`, wholeName: `${this.$t(res.name)} > ${this.$t(ele.name)} > ${this.$t(item.name)}` }))
          }
          return { ...ele, path: `/${res.path}/${ele.path}`, wholeName: `${this.$t(res.name)} > ${this.$t(ele.name)}` }
        }).flat();
        return { ...res, children }
      })
    },
    selectPath() {
      return this.selectData.map(res => res.path)
    }
  },
  mounted() {
  },
  beforeDestory() {
    // EventBus.$off('onSwitchMenuMode')
  },
  created() {
    this.menuList = constantRoutes// .filter(item => item.meta && item.meta.affix)[0].children
  },
  methods: {
    handleClose() {
      this.$store.state.isShowMenu = false;
    },
    handleOpen() {
      this.$store.state.isShowMenu = true
    },
    // 添加常用项选中  /  打开新菜单
    handleRouterSelect(ele) {
      if (this.$store.state.isAddCommon) {
        if (this.selectPath.includes(ele.path)) {
          this.selectData = this.selectData.filter(res => res.path != ele.path);
        } else if (this.selectPath.length > 11) {
          this.$message.warning(this.$t('lang.wms.won.won.maxFunctionCardCheck'))
        } else {
          this.selectData.push({
            name: ele.nameI18,
            path: ele.path,
            userName: localStorage.getItem('userName')
          });
        }
        this.handleSubmit()
        return
      }
      this.$router.push({ path: ele.path })
      this.handleClose();
    },
    // 添加常用项
    async handleSubmit() {
      let res = await addCommonFun(this.selectData);
      if (!res.code) {
        getCommonFunList().then(res => {
          this.$store.state.commonMenu = res.data || []
        })
        this.$message.success(this.$t('lang.wms.common.item0001'))
        this.handleClose();
      }
    },
    // 搜索
    handleSearch(e) {
      let list = this.allMenuList.map(res => res.children).filter(Boolean).flat();
      this.filterList = list.filter(res => res.wholeName.toLowerCase().includes(e.toLowerCase()));
      this.showFilter = true
    },
    // 焦点消失
    onBlur() {
      setTimeout(() => {
        this.showFilter = false;
      }, 200)
    },
    // 超出隐藏的展示tips
    onMouseOver(e) {
      if (e.target?.offsetWidth >= e.target?.parentNode?.offsetWidth - 40) {
        this.isShowTooltip = false
      } else {
        this.isShowTooltip = true
      }
    },
    // 点击首页跳转处理
    handleMenuSelect(e) {
      if (e.path == 'index') {
        this.$router.push({ path: '/homePage' })
        this.handleClose()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.geek-menu-dialog{
    position: absolute;
    display: flex;
    justify-content: start;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    .geek-dialog-mask{
        background: rgba(64, 65, 66, 0.3);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .geek-menu-body{
        // width: calc(100% - 40px);
        height: calc(100% - 120px);
        // min-width: 980px;
        margin-top: 100px;
        display: flex;
        z-index: 1;
        color: #ccc !important;
        // zoom: 0.9;
        .geek-menu-left{
            flex: 1;
            width: 250px;
            margin-left: 20px;
            background: rgb(64, 65, 66);
            border-radius: 10px;
            // text-align: center;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            >ul{
                flex: 1;
                overflow-y: auto;
            }
            &.el-menu-showPop{
                ul{
                    overflow: inherit;
                }
                ::v-deep .el-submenu__title i{
                    display: inline-block !important;
                }
            }
            >div{
                text-align: center;
                padding: 15px 0;
            }
            .el-menu-vertical-list{
                flex: 1;
                overflow: auto !important;
            }
            .mode-change {
                padding: 15px 20px;
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            .over-flow {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin: 0;
                max-width: 100% !important;
            }
        }
        .search-content{
            width: 200px;
            margin-left: 20px;
            position: relative;
            .filter-content{
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                top: 0px;
                left: 240px;
                max-width: 400px;
                min-width: 300px;
                max-height: 300px;
                overflow: auto;
                border: 1px solid #414243;
                border-radius: 3px;
                background-color: #414243;
                margin-left: 0;
                z-index: 2;
                box-sizing: border-box;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                padding: 0 8px;
                .filter-content-item{
                    line-height: 36px;
                    font-size: 13px;
                    color: #ccc;
                    cursor: pointer;
                    i{
                        color: #f90;
                        margin-right: 6px;
                    }
                }
            }
        }
    }
    .el-input {
        .el-input__inner {
            background-color: #565656;
            border: #636161;
            color: #ccc;
        }
    }
}
.el-menu-vertical-list{
    .first-level-index:hover{
        span{
            color: #3F9EFF !important;
        }
    }
}

@media screen and (max-width: 1920px) {
    .geek-menu-right{
        font-size: 20px
    }
}
@media screen and (max-width: 1680px) {
    .geek-menu-right{
        font-size: 18px
    }
}
@media screen and (max-width: 1440px) {
    .geek-menu-right{
        font-size: 16px
    }
}
@media screen and (max-width: 1280px) {
    .geek-menu-right{
        font-size: 14px
    }
}
</style>
<style lang="scss">
.el-submenu {
    position: relative;
}
.el-submenu__title{
    height: 48px !important;
    line-height: 48px !important;
    color: #fff !important;
    font-size: 18px !important;
    overflow: hidden !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    i{
        font-size: 14px;
    }
}

.el-menu--horizontal {
    .el-submenu{
        float: none;
        width: 100%;
        .el-submenu__title{
            border: 0 !important;
        }
    }
}
.el-menu--popup{
    background-color: rgb(64, 65, 66) !important;
    border-radius: 10px !important;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
    max-height: 80vh;
    &.el-menu--popup-right-start{
        position: unset;
        overflow-y: auto;
        li {
            position: unset;
        }
    }
}
</style>
