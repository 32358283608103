<template>
  <div class="geek-print">
    <geek-print-config :code="code"/>
  </div>
</template>

<script>
import { GeekPrintConfig, Print } from 'geek-print-config-470';
import 'geek-print-config-470/lib/geek-print-config.css';

export default {
  name: 'app',
  options: {
    name: '打印模板设置',
    parents: ['geek-page'] // all
    // groups: ['container']
  },
  components: {
    // PrintConfig,
    GeekPrintConfig
  },
  data() {
    return {
      code: this.$route.query.code || ''
    }
  },
  methods: {

  }
}
</script>

<style>
html, body {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}
.geek {
  color: #58666e;
  font-size: .875rem;
}
.flex-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

<style lang="scss">
.geek {
  display: flex;
  flex-flow: column;
  height: 100vh !important;
  background: #F1F1F1;
  overflow: hidden;
  // ::v-deep .property-editor {
  .property-editor {
    position: relative;
    &:hover {
      .fx {
        opacity: 1;
        background-color: rgba(0, 0, 0, .7);
      }
    }
    .fx {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: #ffffff;
      background-color: rgba(0, 0, 0, 0);
      opacity: 0;
      cursor: pointer;
      transition: background-color .3s;
      z-index: 9;
    }
  }
}
</style>
