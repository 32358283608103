<template>
  <div class="geek-menu-dialog" @mousedown.stop>
    <div class="geek-dialog-mask" @click="handleClose"></div>
    <div class="geek-menu-body">
        <div class="geek-menu-left">
            <el-menu
                class="el-menu-vertical-list"
                router
                unique-opened
                :default-active="defaultActive"
                @open="handleOpen"
                @close="handleClose"
                >
                <side-item v-for="(route, index) in menuList" :key="index" :route-obj="route" @select="handleMenuSelect"/>
            </el-menu>
            <div v-if="$store.state.isAddCommon">
                <el-button type="primary" @click="handleSubmit">{{$t("lang.wms.fed.determine")}}</el-button>
                <el-button type="info" @click="handleClose">{{$t("lang.wms.fed.cancel")}}</el-button>
            </div>
            <div v-if="!$store.state.isAddCommon" class="mode-change">
                <span>{{$t('lang.wms.fed.classicMode')}}</span>
                <el-switch v-model="$store.state.menuType" active-value="1" inactive-value="0"></el-switch>
            </div>
        </div>
        <div class="geek-menu-right">
            <div class="search-content">
                <el-input :placeholder="$t('lang.wms.fed.pleaseEnterContents')" v-model="searchStr" @input="handleSearch" @blur="onBlur">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <div class="filter-content" v-if="showFilter">
                    <div class="filter-content-item" v-for="item of filterList" :key="JSON.stringify(item)" @click="handleRouterSelect(item)">
                        <i class="el-icon-star-off"></i>
                        <span>{{$t(item.wholeName)}}</span>
                    </div>
                </div>
            </div>
            <div class="geek-menu-content" ref="scrollMenuContent">
                <div class="geek-menu-content-item" v-for="(item, i) in flatMenuList" :key="i" :ref="'scroll-' + item.path">
                    <div class="geek-menu-content-title" :class="{active: $store.state.menuSrollIndex == 'scroll-' + item.path, canClick: item.path == 'index'}" @click="handleSelectForIndex(item)">{{$t(item.name)}}</div>
                    <div class="geek-menu-content-item-children">
                        <template v-for="ele in item.children">
                            <div class="geek-menu-content-item-card" v-if="ele.children && ele.children.length" :key="JSON.stringify(ele)">
                                <div class="geek-menu-card-title">{{$t(ele.name)}}</div>
                                <span :class="{active: selectPath.includes(k.path)}" v-for="k in ele.children" :key="JSON.stringify(k)" @click="handleRouterSelect(k)"><i class="el-icon-star-off"></i>{{$t(k.name)}}<i v-if="isShowEditor" class="el-icon-edit" @click.stop="handleRouterSelect(k, 1)"></i></span>
                            </div>
                            <span :class="{active: selectPath.includes(ele.path)}" v-else :key="JSON.stringify(ele)" @click="handleRouterSelect(ele)"><i class="el-icon-star-off"></i>{{$t(ele.name)}}<i v-if="isShowEditor" class="el-icon-edit" @click.stop="handleRouterSelect(ele, 1)"></i></span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { constantRoutes } from '@/router'
import SideItem from './SideItem'
import { getCommonFunList, addCommonFun } from '@/api/home'

export default {
  name: 'GeekMenuDialog',
  components: {
    SideItem
  },
  data() {
    return {
      menuList: [],
      searchStr: '',
      selectData: this.$store.state.isAddCommon ? JSON.parse(localStorage.getItem('commonMenu')) || [] : [],
      filterList: [],
      showFilter: false,
      isShowEditor: process.env.VUE_APP_SHOW_EDIT == 'true'
    }
  },
  props: {

  },
  computed: {
    defaultActive() {
      return this.$route.meta?.inAppEditor ? this.$route.meta.path : this.$route.path
    },
    flatMenuList() {
      let menuList = JSON.parse(JSON.stringify(this.menuList));
      return menuList.map(res => {
        let children = res.children?.map(ele => {
          if (ele.children && ele.children.length) {
            return {
              name: ele.name,
              sort: ele.sort,
              children: ele.children.map(item => ({ ...item, path: `/${res.path}/${ele.path}/${item.path}`, wholeName: `${res.name} > ${ele.name} > ${item.name}` })) }
          }
          return { ...ele, path: `/${res.path}/${ele.path}`, wholeName: `${res.name} > ${ele.name}`, children: [] }
        });
        children.sort((a, b) => b.sort - a.sort )
        return { ...res, children }
      })
    },
    allMenuList() {
      let menuList = JSON.parse(JSON.stringify(this.menuList));
      return menuList.map(res => {
        let children = res.children?.map(ele => {
          if (ele.children && ele.children.length) {
            return ele.children.map(item => ({ ...item, path: `/${res.path}/${ele.path}/${item.path}`, wholeName: `${this.$t(res.name)} > ${this.$t(ele.name)} > ${this.$t(item.name)}` }))
          }
          return { ...ele, path: `/${res.path}/${ele.path}`, wholeName: `${this.$t(res.name)} > ${this.$t(ele.name)}` }
        }).flat();
        return { ...res, children }
      })
    },
    selectPath() {
      return this.selectData.map(res => res.path)
    }
  },
  mounted() {
    this.menuHeight = [];
    Object.keys(this.$refs).map(key => {
      if (/^scroll-/g.test(key)) {
        this.menuHeight.push({ top: this.$refs[key][0].offsetTop, key })
      }
    })
    this.$store.state.menuSrollIndex = 'scroll-index';
    this.$refs.scrollMenuContent.onscroll = e => {
      let index = this.menuHeight.findIndex(res => res.top - 220 > e.target.scrollTop);
      index = index - 1
      index != -1 && (this.$store.state.menuSrollIndex = this.menuHeight[index].key);
    }
  },
  beforeDestory() {
    // EventBus.$off('onSwitchMenuMode')
  },
  created() {
    this.menuList = constantRoutes// .filter(item => item.meta && item.meta.affix)[0].children
  },
  methods: {
    handleClose() {
      this.$store.state.isShowMenu = false;
    },
    handleOpen() {
      this.$store.state.isShowMenu = true
    },
    // 左侧菜单点击，跳转滚动
    handleMenuSelect(e) {
      let top = this.$refs['scroll-' + e.path][0].offsetTop - 180;
      this.$refs.scrollMenuContent.scrollTop = top;
      setTimeout(() => {
        this.$store.state.menuSrollIndex = 'scroll-' + e.path
      }, 100)

    },
    // 添加常用项选中  /  打开新菜单
    handleRouterSelect(ele, isEditor) {
      if (this.$store.state.isAddCommon) {
        if (this.selectPath.includes(ele.path)) {
          this.selectData = this.selectData.filter(res => res.path != ele.path);
        } else if (this.selectPath.length > 11) {
          this.$message.warning(this.$t('lang.wms.won.won.maxFunctionCardCheck'))
        } else {
          this.selectData.push({
            name: ele.nameI18,
            path: ele.path,
            userName: localStorage.getItem('userName')
          });
        }
        return
      }
      let path = ele.path;
      if (isEditor) {
        path = path.replace(/.*\//, '/editor/')
      }
      this.$router.push({ path })
      this.handleClose();
    },
    handleSelectForIndex(item) {
      if (item.path === 'index' && !this.$store.state.isAddCommon) {
        this.$router.push({ path: '/homePage' })
        this.handleClose();
      }
    },
    // 添加常用项
    async handleSubmit() {
      let res = await addCommonFun(this.selectData);
      if (!res.code) {
        getCommonFunList().then(res => {
          this.$store.state.commonMenu = res.data || []
        })
        this.$message.success(this.$t('lang.wms.common.item0001'))
        this.handleClose();
      }
    },
    // 搜索
    handleSearch(e) {
      let list = this.allMenuList.map(res => res.children).filter(Boolean).flat();
      this.filterList = list.filter(res => res.wholeName.toLowerCase().includes(e.toLowerCase()));
      this.showFilter = true
    },
    // 焦点消失
    onBlur() {
      setTimeout(() => {
        this.showFilter = false;
      }, 200)
    }
  }
}
</script>

<style lang="scss" scoped>
.geek-menu-dialog{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2099;
    .geek-dialog-mask{
        background: rgba(64, 65, 66, 0.6);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .geek-menu-body{
        width: calc(100% - 40px);
        height: calc(100% - 120px);
        min-width: 980px;
        margin-top: 100px;
        display: flex;
        z-index: 1;
        color: #ccc !important;
        .geek-menu-left{
            flex: 1;
            max-width: 250px;
            background: rgb(64, 65, 66);
            border-radius: 10px;
            // text-align: center;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            >ul{
                flex: 1;
                overflow: auto;
            }
            >div{
                text-align: center;
                padding: 15px 0;
            }
            .mode-change {
                padding: 15px 20px;
                font-size: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .geek-menu-right{
            flex: 3;
            background: rgb(64, 65, 66);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            // text-align: center;
            box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
            padding: 20px 0;
            >div{
                padding: 0 30px
            }
            .search-content{
                position: relative;
                .filter-content{
                    position: absolute;
                    top: 50px;
                    width: 100%;
                    max-height: 300px;
                    overflow: auto;
                    border: 1px solid #414243;
                    border-radius: 3px;
                    background-color: #414243;
                    margin-left: 0;
                    z-index: 2;
                    box-sizing: border-box;
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                    padding: 0 8px;
                    .filter-content-item{
                        line-height: 36px;
                        font-size: 13px;
                        color: #ccc;
                        cursor: pointer;
                        i{
                            color: #f90;
                            margin-right: 6px;
                        }
                    }
                }
            }
            .geek-menu-content{
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow: auto;
                margin-top: 20px;
                .geek-menu-content-item{
                    .geek-menu-content-title{
                        font-weight: bold;
                        padding: 15px 0;
                        font-size: 20px;
                        color: #fff;
                        &.active{
                            color: #f90;
                        }
                        &.canClick{
                            cursor: pointer;
                        }
                    }
                    .geek-menu-content-item-children{
                        display: flex;
                        flex-wrap: wrap;
                        span{
                            width: 30%;
                            padding: 10px;
                            color: #f1f1f1;
                            cursor: pointer;
                            .el-icon-star-off{
                                margin-right: 4px;
                            }
                            &.active{
                                color: #3F9EFF;
                            }
                        }
                        .geek-menu-content-item-card{
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            border: 1px solid #aaaaaa55;
                            border-radius: 8px;
                            padding: 1.2em 10px 10px;
                            margin: 1em 0;
                            color: #fff;
                            position: relative;
                            .geek-menu-card-title{
                                position: absolute;
                                top: 0;
                                left: 20px;
                                transform: translateY(-50%);
                                border: 1px solid #aaa;
                                background: #404142;
                                padding: 5px 20px;
                                border-radius: 6px;
                            }
                        }
                    }
                    .geek-menu-content-item-children span:hover{
                        border-radius: 10px;
                        background-color: rgba(64, 65, 66, 100) !important;
                        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                        color: #3F9EFF;
                    }
                }
            }
        }
    }
    .el-input {
        .el-input__inner {
            background-color: #565656;
            border: #636161;
            color: #ccc;
        }
    }
}


@media screen and (max-width: 1920px) {
    .geek-menu-right{
        font-size: 20px
    }
}
@media screen and (max-width: 1680px) {
    .geek-menu-right{
        font-size: 18px
    }
}
@media screen and (max-width: 1440px) {
    .geek-menu-right{
        font-size: 16px
    }
}
@media screen and (max-width: 1280px) {
    .geek-menu-right{
        font-size: 14px
    }
}
</style>
