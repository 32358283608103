<template>
  <div class="straytegy-dialog">
    <div class="dialog-main">
      <div class="dialog-left">
          <div class="steps-procedure">
              <div class="step-procedure">
                  <div class="step-indicator">
                      <div class="step-indicator_num active">1</div>
                      <div class="step-indicator_line"></div>
                  </div>
                  <div class="step-container">
                      <div class="step-container_title">
                          <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}1</span>
                          <span class="step-container_subtitle">{{$t('lang.wms.web.quote.chooseQuoteObject')}}</span>
                      </div>
                      <div class="step-operate">
                          <el-form :model="formOne" ref="item" :rules="rules" label-position="top">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="objectType">
                                        <el-select v-model="formOne.objectType" @change="selectChange('formOne')" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                            <el-option
                                            v-for="(item, index) in (dict.siteOperationMode || [])"
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="spec">
                                        <el-select
                                        v-model="formOne.spec"
                                        multiple
                                        :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                            <el-option
                                            v-for="(item, index) in (stationList || [])"
                                            :key="index"
                                            :label="item"
                                            :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" :offset="!!formOne.spec ? 0 : 12"></el-col>
                                    <el-button size="medium" type="primary" @click="handleChooseOne('saveOne')">{{$t('lang.wms.web.quote.choose')}}</el-button>
                                </el-col>
                            </el-row>
                            <div class="step-choose-content">
                              <template>
                                <el-tag type="info" closable v-for="(tag, index) in formOne.tags" :key="index" @close="closeTag(tag, index,'formOne')">
                                  {{tag.code}}
                                </el-tag>
                              </template>
                            </div>
                          </el-form>
                      </div>
                  </div>
              </div>
              <div class="step-procedure">
                  <div class="step-indicator">
                      <div class="step-indicator_num active">2</div>
                      <div class="step-indicator_line"></div>
                  </div>
                  <div class="step-container">
                      <div class="step-container_title">
                          <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}2</span>
                          <span class="step-container_subtitle">{{$t('lang.wms.web.quote.chooseStrategy')}}</span>
                      </div>
                      <div class="step-operate">
                          <el-form :model="formTwo" ref="item" :rules="rules" label-position="top">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="strategyType">
                                        <el-select v-model="formTwo.strategyType" @change="selectChange('formTwo')"  :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                          <template v-for="(item, index) in dict.taskStrategyType">
                                            <el-option
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                          </template>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item prop="strategyCoding">
                                        <el-select v-model="formTwo.strategyCoding" multiple :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                            <el-option
                                            v-for="(item, index) in (strategyTypes || [])"
                                            :key="index"
                                            :label="item.strategyName"
                                            :value="item.strategyCode">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6">
                                    <el-button size="medium" type="primary" @click="handleChooseTwo('saveTwo')">{{$t('lang.wms.web.quote.choose')}}</el-button>
                                </el-col>
                            </el-row>
                            <div class="step-choose-content">
                              <el-tag type="info" closable v-for="(tag, index) in formTwo.tags" :key="index"  @close="closeTag(tag, index, 'formTwo')">
                                {{tag.name + '（'+tag.value+'）'}}
                              </el-tag>
                            </div>
                          </el-form>
                      </div>
                  </div>
              </div>
               <div class="step-procedure">
                  <div class="step-indicator">
                      <div class="step-indicator_num active">3</div>
                      <!-- <div class="step-indicator_line"></div> -->
                  </div>
                  <div class="step-container">
                      <div class="step-container_title">
                          <span class="step-container_steptitle">{{$t('lang.wms.web.quote.step')}}3</span>
                          <span class="step-container_subtitle">{{$t('lang.wms.web.quote.addPreviewAndSaveQuote')}}</span>
                      </div>
                      <div class="step-operate">
                          <el-row>
                              <el-col :span="6" :offset="18">
                                  <el-button size="medium" type="primary" @click="handleChooseThree('saveThree')">{{$t('lang.wms.fed.add')}}</el-button>
                              </el-col>
                          </el-row>
                      </div>
                  </div>
               </div>
          </div>
      </div>
      <div class="dialog-right">
        <div class="dialog-subTitle">{{$t('lang.wms.web.quote.previewQuote')}}</div>
        <div style="max-height:600px;overflow:auto;">
          <el-table
            v-for="(table, index) in tableList"
            :key="index"
            :data="table"
            border
            style="width: 100%; margin-bottom: 20px">
              <el-table-column
              prop="priority"
              :label="$t('lang.wms.fed.priority')"
              width="70">
                <template slot-scope="scope">
                  {{scope.$index + 1}}
                </template>
              </el-table-column>
              <el-table-column
              prop="objectType"
              :label="$t('lang.wms.web.quote.quoteObjectType')">
                <template slot-scope="scope">
                  {{drop.siteOperationMode[scope.row.objectType] || scope.row.objectType}}
                </template>
              </el-table-column>
              <el-table-column
              prop="objectCode"
              :label="$t('lang.wes.fed.station')">
              </el-table-column>
              <el-table-column
              prop="strategyCode"
              :label="$t('lang.wms.fed.strategyCoding')">
              <template slot-scope="scope">
                  <span>{{scope.row.strategyCode}}</span>
                </template>
              </el-table-column>
              <el-table-column
              prop="strategyName"
              :label="$t('lang.wms.fed.strategyName')">
              </el-table-column>
              <el-table-column
              prop="strategyType"
              :label="$t('lang.wms.fed.strategyType')">
               <template slot-scope="scope">
                  {{drop.taskStrategyType[scope.row.strategyType] || scope.row.strategyType}}
                </template>
              </el-table-column>
              <el-table-column
              prop="type"
              :label="$t('lang.wms.fed.operation')"
              width="150">
                <template slot-scope="scope">
                    <el-button circle icon="el-icon-delete" @click="handleClick(index, scope.row, scope.$index, 'del')" type="primary" size="small" :title="$t('lang.wms.fed.delete')"></el-button>
                    <el-button :disabled="scope.$index === 0" circle icon="el-icon-top" @click="handleClick(index, scope.row, scope.$index, 'up')" type="primary" size="small"  :title="$t('lang.wms.fed.moveUp')"></el-button>
                    <el-button :disabled="scope.$index === table.length - 1" circle icon="el-icon-bottom" @click="handleClick(index, scope.row, scope.$index, 'down')"  type="primary" size="small" :title="$t('lang.wms.fed.moveDown')"></el-button>
                </template>
              </el-table-column>
            </el-table>
        </div>
      </div>
    </div>
    <div class="dialog-footer">
        <el-button size="medium" plain @click="handleCancel()">{{$t('lang.wms.fed.cancel')}}</el-button>
        <el-button size="medium" type="primary" @click="save()">{{$t('lang.wms.fed.save')}}</el-button>
    </div>
  </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
export default {
  name: 'GeekWallSteps',
  options: {
    name: '墙策略引用弹框',
    parents: ['all'], // all
    groups: ['container'],
    events: {
      click: '点击'
    }
  },
  props: {
    dict: {
      type: Object,
      default: function() {
        return {};
      }
    },
    drop: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      rules: {},
      formOne: {
        wallData: [],
        spec: null,
        objectType: '10',
        tags: []
      },
      formTwo: {
        strategyType: '1',
        strategyCoding: null,
        tags: []
      },
      tableList: [],
      stationList: [],
      strategyTypes: []
    }
  },
  created() {
    this.selectChange('formOne');
    this.selectChange('formTwo');
  },
  methods: {
    closeTag(tag, index, form) {
      this[form].tags.splice(index, 1);
    },
    save() {
      const arr = this.tableList.reduce((prev, curr, index) => {
        const table = curr.map((v, index) => {
          return {
            ...v,
            priority: index + 1
          }
        })
        return [...prev, ...table];
      }, [])
      if (!arr || !arr.length) {
        this.$message({ type: 'error', message: this.$t('lang.wms.web.controller.SiteStrategyController.checkEmpty') });
        return;
      }
      POST('/api/v1/siteStrategyQuote/insert', arr).then(res => {
        if (res.code === 0) {
          this.$message({ type: 'success', message: this.$t('lang.wms.fed.success') });
          this.handleCancel()
        } else {
          // this.$message({ type: 'error', message: this.$transMsg(res.msg || 'lang.wms.fed.failSave')});
        }
      })
    },
    handleChooseOne() {
      if (!this.formOne.objectType || !this.formOne.spec || (Array.isArray(this.formOne.spec) && !this.formOne.spec.length)) {
        return
      }
      const arr = this.formOne.tags.map(v => {
        return v.code
      }).concat(...this.formOne.spec);
      // 去重
      this.formOne.tags = Array.from(new Set(arr)).map(v => {
        return {
          objectType: 1, // this.formOne.objectType,  1-工作站
          code: v
        }
      })
      this.formOne.spec = '';
    },
    handleChooseTwo() {
      if (!this.formTwo.strategyCoding || !this.formTwo.strategyType) {return};

      const fn = () => {
        const arr = this.formTwo.tags.map(v => {return v.value}).concat(...this.formTwo.strategyCoding);
        // 去重
        this.formTwo.tags = Array.from(new Set(arr)).map(v => {
          return {
            name: this.strategyTypes.filter(type => type.strategyCode == v)[0]?.strategyName,
            value: v,
          }
        })
        this.formTwo.strategyCoding = ''
      }

      let objectCodes = this.formOne.tags.map(v => v.code)
      if (!this.formOne.tags.length) {
        this.$message.error(this.$t('lang.wms.web.quote.chooseQuoteObject'))
        return
      }
      POST('/api/v1/siteStrategyQuote/check', {
        "objectCodes": objectCodes,
        "strategyCodes": this.formTwo.strategyCoding
      }).then(res => {
        if (res.code === 0) {
          fn()
        }
      })
    },
    handleChooseThree() {
      const formOneValid = this.formOne.tags.length;
      const formTwoValid = this.formTwo.tags.length;
      if (!formOneValid || !formTwoValid) {
        this.$message({ type: 'error', message: !formOneValid ? this.$t('lang.wms.web.quote.chooseQuoteObject') : this.$t('lang.wms.web.quote.chooseStrategy') });
        return;
      }
      let station = '';
      const table = [];
      if (this.formOne.tags.length) {
        this.formOne.tags.forEach(objectType => {
          // 按工作站号分组
          const sTable = [];
          this.formTwo.tags.forEach(code => {
            station = objectType.code;
            sTable.push({
              objectType: objectType.objectType,
              objectCode: objectType.code,
              strategyType: this.formTwo.strategyType,
              strategyCode: code.value,
              strategyName: code.name
            });
          })
          table.push(sTable);
        })
      }
      const arr = this.tableList.filter(v => v[0]?.objectCode == station);
      if (arr && arr.length) {
        this.tableList = this.tableList.map((rtable, rindex) => {
          let newTable = rtable;
          if (rtable[0]?.objectCode == station) {
            const hasCodes = rtable.map(v => {
              return v.strategyCode
            })
            table.forEach(sTable => {
              sTable.forEach(v => {
                if (hasCodes.includes(v.strategyCode)) {
                  this.tableList[rindex].forEach(row => {
                    if (row.strategyCode == v.strategyCode) {
                      row.red = true;
                    }
                  })
                  this.$message({ type: 'error', message: this.$t('lang.wms.web.quote.checkAddExistQuote') });
                  throw new Error();
                } else {
                  this.tableList[rindex].forEach(row => {
                    if (row.strategyCode == v.strategyCode) {
                      row.red = false;
                    }
                  })
                }
              })
              newTable = rtable.concat(table);
            });
          }
          return newTable.flat();
        });
      } else {
        this.tableList.push(...table);
      }
      this.selectChange('formOne');
      this.selectChange('formTwo');
    },
    selectChange(form) {
      if (!form) {
        return;
      }
      this[form] = Object.assign({}, this[form], {
        wallData: [],
        spec: '',
        tags: [],
        multipleSelection: [],
        strategyCoding: null
      });
      if (form === 'formOne') {
        // GET(`/api/common/stdStation/rest/site/getAllSiteCodes/v1`).then(res => {
        GET(`/api/common/stdStation/rest/site/getSiteCodeByType?siteType=${this.formOne.objectType}`).then(res => {
          // 获取工作站号
          if (res.code === 0) {
            this.stationList = res.data || [];
            // res.data.map(v => {
            //     this.drop.stationList[v.strategyCode] = v.strategyName;
            //     return v;
            // });
          }
        })
      }
      if (form === 'formTwo') {
        // 查询策略
        GET(`/api/v1/siteStrategy/getStrategyByType?strategyType=${this.formTwo.strategyType}&subType=1`).then(res => {
          // GET('/api/v1/siteStrategy/select?strategyType=1').then(res => {
          // 播种墙规格
          if (res.code === 0) {
            this.strategyTypes = res.data || [];
            // res.data.map(v => {
            //     this.strategyTypesDrop[v.strategyCode] = v.strategyName;
            //     return v;
            // });
          }
        })
      }
    },
    // 编辑表格操作
    handleClick(tableIdx, row, index, type) {
      switch (type) {
      case 'del':
        this.tableList[tableIdx].splice(index, 1);
        if (!this.tableList[tableIdx].length) {
          this.tableList.splice(tableIdx, 1);
        }
        break;
      case 'up':
        this.tableList[tableIdx].splice(index, 1);
        this.tableList[tableIdx].splice(index - 1, 0, row);
        break;
      case 'down':
        this.tableList[tableIdx].splice(index, 1);
        this.tableList[tableIdx].splice(index + 1, 0, row);
        break;

      }
    },
    handleCancel() {
      this.$emit('cancel', {})
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.red) {
        return 'error-row';
      }
      return '';
    }
  }
}
</script>


<style lang="scss" scoped>
.straytegy-dialog{
  display: flex;
  flex-direction: column;
  flex:1;
  .dialog-main{
     display: flex;
    flex-direction: row;
    flex:1;
  }
  .dialog-left{
    flex:1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-right: 10px;
    overflow: auto;
  }
  .dialog-right{
    flex:1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-left: 10px
  }
}
.dialog-subTitle{
  font-size: 20px;
}
.step-choose-content{
  min-height: 100px;
  .el-tag{
    margin-right:10px;
  }
}
.steps-procedure{
    width: 100%;
}
.step-procedure{
    // min-height: 100px;
    display: flex;
    position: relative;
    flex-shrink: 1;
}
.step-indicator{
    position: relative;
    width:24px;
    flex-grow: 0;
    .step-indicator_num{
        position: relative;
        display: flex;
        width: 24px;
        height: 24px;
        font-size: 14px;
        border: 2px solid #c0c4cc;
        border-radius: 50%;
        background: #fff;
        justify-content: center;
        align-items: center;
        z-index:1;
        &.active {
            border-color:  #409eff;
            color:  #409eff;
        }
    }
    .step-indicator_line{
        position: absolute;
        top:0;
        bottom: 0;
        left: 11px;
        border: 2px solid #c0c4cc;
        &.active {
            border-color:  #409eff;
        }
    }
}
.step-container{
    padding-left: 10px;
    flex-grow: 1;
    .step-container_title{
        span{
            margin-right: 20px;
            font-size: 16px;
        }
        .step-container_subtitle {
            font-size: 14px;
        }
    }
}
.geek-step-procedure {
  display: flex;
  flex-flow: column;
  padding: 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  overflow: hidden;
  .geek-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .actions {
      display: flex;
      align-items: center;
      > * {
        margin-left: 0;
        margin-right: 18px;
        cursor: pointer;
      }
    }
    ::v-deep {
      .el-form-item {
        margin: 0;
      }
    }
  }
  .geek-card-body {
    height: 0;
    flex: 1;
    overflow: auto;
    ::v-deep {
      .geek-table {
        height: 100%;
        margin-bottom: 0;
        .el-table {
          max-height: 100% !important;
          position: relative;
          .el-table__header-wrapper {
            .cell {
              position: relative;
            }
          }
          .el-table__body-wrapper, .el-table__fixed-body-wrapper {
            max-height: 100% !important;
            position: absolute;
            top: 48px;
            bottom: 0;
            overflow-y: auto;
          }
        }
      }
    }
  }
}
.el-form{
  .el-select{
    width: 100%;
  }
}
.dialog-footer{
  margin-top: 20px;
  text-align: center;
}
</style>
