<template>
  <div :style="cssVars" class="stepStyle">
    <div class="arrowGreen" v-for="(item,index) in getMode" :key="index">{{item.label}}</div>
    <div class="mainMark">{{$transMsg('lang.wms.common.enum.FlowTypeEnum.name')}}</div>
  </div>

</template>

<script>

export default {
  name: 'GeekSteps',
  options: {
    name: '步骤图',
    parents: ['all'], // all
    groups: ['container'] // none
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    height: {
      type: String,
      label: '高度'
    }
  },
  data() {
    return {
      option: []
    }
  },
  created() {},
  // mounted() {
  //   console.log(this.dataSource, this.getDataSource(), this.height)
  // },
  // updated () {
  //   console.log(this.dataSource, this.getDataSource(), this)
  // },
  destroyed() {},
  computed: {
    getMode() {
      let obj = this.dataSource.stepsData
      if (obj && obj[0].label) {
        return obj
      }
      return [];
    },
    cssVars() {
      return {
        '--height': (this.height || 180) + 'px'
      };
    }
  },
  methods: {}
}
</script>
<style>
.stepStyle{
  box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
  background: white;
  height: var(--height);
  display: flex;
}
.shadowStep{
  box-shadow: 0px 5px 10px 2px rgb(0 0 0 / 10%);
  /*background: white;*/
  /*height: 180px;*/
}
.mainMark{
  background: #4da5ff;
  color: white;
  width: 70px;
  height: 70px;
  display: flex;
  align-items: end;
  justify-content: center;
  line-height: 1.1;
  text-align: right;
  position: absolute;
  top: -35px;
  right: -35px;
  transform: rotate(45deg);
}
.arrowGreen{
  height: var(--height);
  flex-grow: 1;
  background-size: cover;
  background: #76c750;
  -webkit-clip-path: polygon(0% 20%, 90% 20%, 100% 50%, 90% 80%, 0% 80%, 10% 50%);
  clip-path: polygon(0% 20%, 90% 20%, 100% 50%, 90% 80%, 0% 80%, 10% 50%);
  color: white;
  text-align: center;
  line-height: 180px;
  font-size: 20px;
}
</style>

