class initGeekDB {
    db = null
    constructor() {
      let indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB;
      if (!indexedDB) {
        console.log('do not support indexedDB')
      } else {
        const request = indexedDB.open('logInfo');
        request.onupgradeneeded = e => {
          console.log('onupgradeneeded')
          this.db = e.target.result;
          let objStore = this.db.createObjectStore('api_log', { keyPath: 'id', autoIncrement: true });
          objStore.createIndex('name', 'name', { unique: false })
        }
        request.onsuccess = async e => {
          console.log('onsuccess')
          this.db = e.target.result;
          let obj = await this.getKey('api_log', 0) || {};
          let time = obj.value || 0;
          let nowTime = new Date().getTime();
          // 记录一下时间，超过一天就清理掉
          if (nowTime - time > 24 * 60 * 60 * 1000) {
            let objStore = this.db?.transaction('api_log', 'readwrite').objectStore('api_log')
            objStore.clear()
            objStore.put({ id: 0, name: 'time', value: nowTime })
          }
        }
        request.onerror = e => {
          console.log('onerror')
          this.db = null
        }
      }
    }
    // 每次必须重新获取
    getDB(key) {
      return this.db?.transaction([key], 'readwrite').objectStore(key)
    }
    getKey(key, id) {
      return new Promise((reslove, reject) => {
        let db = this.getDB(key);
        let requeset = db.get(id)
        requeset.onsuccess = function(event) {
          reslove(event.target.result)
        }
        requeset.onerror = function(event) {
          reject('error')
        }
      })
    }
    // 新增db,不会覆盖
    add(key, value) {
      let db = this.getDB(key);
      db.add(value)
    }
    // 新增db，有重复会覆盖
    put(key, value) {
      let db = this.getDB(key);
      db.put(value)
    }
    // 删除db，有重复会覆盖
    delete(key, id) {
      let db = this.getDB(key);
      if (id) {
        db.delete(id)
      }
      db.clear()
    }
    // 获取数据库数量
    getTotal(key) {
      return new Promise((reslove, reject) => {
        let db = this.getDB(key);
        let requeset = db.count();
        requeset.onsuccess = function(event) {
          let count = event.target.result
          reslove(count)
        }
        requeset.onerror = function(event) {
          reject('error')
        }
      })
    }
}

export default new initGeekDB()
