<template>
  <div class="geek-export-config">
    <div class="flex-wrapper">
      <div class="flex-content">
        <p>{{$t('lang.wms.fed.downloadExcelTempAndFillInfo')}}</p>
        <img src="@/assets/icons/excel-logo.png" alt="">
        <geek-download :text="$t('lang.wms.fed.templateDownload')" :url="downloadUrl"></geek-download>
      </div>
      <div class="flex-content">
        <p>{{$t('lang.wms.fed.uploadCompletedFile')}}</p>
        <img src="@/assets/icons/excel-logo.png" alt="">
        <geek-upload :text="$t('lang.wes.putaway.strategy.importStrategyData')" type="primary" :url="uploadUrl" disShowMsg @success="handlerSuccess" @fail="handleError"></geek-upload>
      </div>
    </div>
    <div class="flex-step-3" v-if="errorFileId">{{$t('lang.wms.fed.importFailAndDownloadFile')}}<a :href="`/beetle/api/putawayStrategyRuleMutexFile/template/download?id=${errorFileId}`" style="color: red" >{{errorFileName}}</a>{{$t('lang.wms.fed.checkDetailedReason')}}</div>
  </div>
</template>

<script>
import { GET } from '../../utils/http';

export default {
  name: 'GeekExportConfig',
  options: {
    name: '导入数据配置',
    parents: ['all'], // all
    // groups: ['container'],
    events: {
      complete: '导入完成'
    }
  },
  components: {
  },
  props: {
    $body: {
      ignore: true
    },
    downloadUrl: {
      type: String,
      isExpression: true,
      label: '下载url',
      default: '/api/putawayStrategyRuleMutexFile/excelModel/download'
    },
    downloadParams: {
      type: Object,
      clazz: 'ParamPicker',
      label: '下载参数'
    },
    uploadUrl: {
      type: String,
      isExpression: true,
      label: '上传url',
      default: '/beetle/api/putawayStrategyRuleMutexFile/asyncUploadFile'
    },
    uploadParams: {
      type: Object,
      clazz: 'ParamPicker',
      label: '上传参数'
    }
  },
  data() {
    return {
      errorFileId: '',
      errorFileName: ''
    }
  },
  methods: {
    handlerSuccess(data) {
      let msg = '';
      let uploadStatus = 0;
      let id = data.id
      this.repeatGetUploadInfo = setInterval(() => {
        GET('/api/putawayStrategyRuleMutexFile/queryUploadFileInfoProcess', { id }).then(res => {
          uploadStatus = res.data.status;
          if (uploadStatus === 2) {
            clearInterval(this.repeatGetUploadInfo)
            // msg = this.$t('lang.wms.fed.uploadSuccessThenClose', 3);
            if (res.data.errorNum !== 0) {
              msg = this.$t('lang.wms.fed.ploadSuccessfulImportedTotalNoReport').replace('{totalCount}', res.data.dealCount).replace('{successCount}', res.data.successCount).replace('{errorCount}', res.data.errorNum);
              this.$message.info(msg)
              this.errorFileId = id
              this.errorFileName = res.data.resultFilename
            }
            this.$emit('complete', res.data)
          }
        })
      }, 1000)
    },
    handleError() {},
    downloadFail() {
      GET('/api/putawayStrategyRuleMutexFile//template/download', { id: this.errorFileId })
    }
  }
}
</script>

<style lang="scss">
.geek-export-config {
  padding: 0 40px;
}
.flex-wrapper {
  display: flex;
  justify-content: space-between;
}
.flex-content {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 80px;
    padding: 20px 0;
  }
}
.flex-step-3 {
  padding-top: 20px;
  text-align: left;
}
</style>
