<template>
  <div class="straytegy-dialog">
    <div class="dialog-main">
      <div class="dialog-left">
        <div class="steps-procedure">
          <div class="step-procedure">
            <div class="step-indicator">
              <div class="step-indicator_num active">1</div>
              <div
                class="step-indicator_line"
                :class="{ active: tableList.length > 0 }"
              ></div>
            </div>
            <div class="step-container">
              <div class="step-container_title">
                <span class="step-container_steptitle"
                  >{{ $t("lang.wms.web.quote.step") }}1</span
                >
                <span class="step-container_subtitle">{{
                  $t("lang.wms.fed.poppick.device.input.sitecode.query.error")
                }}</span>
               
              </div>
              <div class="step-operate">
                <el-form
                  :model="searchErrorParams"
                  ref="searchErrorDom"
                  :rules="rules"
                  :inline="true"
                >
                  <el-row>
                    <el-form-item
                      prop="stationId"
                      :label="$t('lang.wms.fed.workstationCode')"
                    >
                      <el-input
                        v-model="searchErrorParams.stationId"
                        ref="stationId"
                        :placeholder="$t('lang.wms.fed.pleaseEnter')"
                      ></el-input>
                    </el-form-item>
                    <el-form-item
                      prop="boxCode"
                      :label="$t('lang.ws.fed.plcError.containerCode')"
                    >
                      <el-input
                        v-model="searchErrorParams.boxCode"
                        :placeholder="$t('lang.wms.fed.pleaseEnter')"
                      ></el-input>
                    </el-form-item>
                    <el-button
                      size="medium"
                      type="primary"
                      @click="searchError"
                      >{{ $t("lang.wes.fed.search") }}</el-button>
                      <el-button
                      size="medium"
                      @click="searchReset"
                      >{{ $t("lang.wms.fed.reset") }}</el-button>
                  </el-row>
                </el-form>
                <el-row :gutter="20" v-show="!!siteCode">
                  <el-col :span="12"
                    ><span>{{ $t("lang.wms.fed.poppick.rms.station.id") }}</span
                    >:{{ workStationId }}</el-col
                  >
                  <el-col :span="12"
                    ><span>{{ $t("lang.wms.fed.poppick.rms.park.id") }}</span
                    >:{{ parkIds.join(" ") }}</el-col
                  >
                </el-row>
              </div>
            </div>
          </div>
          <div class="step-procedure">
            <div class="step-indicator">
              <div class="step-indicator_num active">2</div>
              <div
                class="step-indicator_line"
                :class="{
                  active: innerDict.ppAbnormalBoxHandleWayFilter.length > 0,
                }"
              ></div>
            </div>
            <div class="step-container step-container-auto">
              <div class="step-container_title">
                <span class="step-container_steptitle"
                  >{{ $t("lang.wms.web.quote.step") }}2</span
                >
                <span class="step-container_subtitle">{{
                  $t("lang.wms.fed.poppick.device.select.tote.first")
                }}</span>
              </div>
              <div class="step-operate">
                <el-row>
                  <el-table
                    ref="mainTable"
                    :data="tableList"
                    border
                    highlight-current-row
                    @row-click="handleSelectionChange"
                    style="width: 100%"
                  >
                    <el-table-column
                      width="100"
                      prop="boxCode"
                      :label="$t('lang.wms.fed.containerCode')"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.boxCode }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="taskId"
                      :label="$t('lang.wms.fed.tote.deliver.rms.task.id')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="instruction"
                      :label="$t('lang.ws.fed.plcError.instructionType')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="startLatticeCode"
                      :label="$t('lang.wms.fed.tote.start.lattice')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="startLatticeType"
                      width="150"
                      :label="$t('lang.wms.fed.tote.start.lattice.type')"
                    >
                      <template slot-scope="scope">
                        {{ latticeTypeMap[scope.row.startLatticeType] || "" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="latticeCode"
                      :label="$t('lang.wms.fed.tote.current.lattice')"
                    >
                    </el-table-column>
                    <el-table-column
                      prop="latticeType"
                      width="200"
                      :label="$t('lang.wms.fed.tote.current.lattice.type')"
                    >
                      <template slot-scope="scope">
                        {{ latticeTypeMap[scope.row.latticeType] || "" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      prop="dispatchErrorId"
                      :label="
                        $t('lang.wms.fed.poppick.device.pending.handle.error')
                      "
                    >
                      <template slot-scope="scope">
                        <el-link
                          @click.native.prevent="
                            openDetail(scope.row, tableList)
                          "
                          type="primary"
                          size="small"
                        >
                          {{
                            scope.row.dispatchErrorId
                              ? $t("lang.wms.fed.details")
                              : ""
                          }}
                        </el-link>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-row>
              </div>
            </div>
          </div>
          <div class="step-procedure">
            <div class="step-indicator">
              <div class="step-indicator_num active">3</div>
              <div
                class="step-indicator_line"
                :class="{ active: !!handleWayKey }"
              ></div>
            </div>
            <div class="step-container">
              <div class="step-container_title">
                <span class="step-container_steptitle"
                  >{{ $t("lang.wms.web.quote.step") }}3</span
                >
                <span class="step-container_subtitle">{{
                  $t("lang.wms.fed.poppick.device.select.handle.way.confirm")
                }}</span>
              </div>
              <div class="step-operate">
                <el-radio-group v-model="handleWayKey">
                  <el-radio
                    class="errorHandleWay"
                    v-for="(t, i) in innerDict.ppAbnormalBoxHandleWayFilter"
                    :key="i"
                    :label="t.value"
                    >{{ t.label }}</el-radio
                  >
                </el-radio-group>
                <div class="cofirmResolve">
                  <el-button
                    size="medium"
                    type="primary"
                    :disabled="!handleWayKey"
                    @click="save"
                    >{{ $t("lang.wms.fed.confirmHandling") }}</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="step-procedure">
            <div class="step-indicator">
              <div class="step-indicator_num active">4</div>
            </div>
            <div class="step-container">
              <div class="step-container_title">
                <span class="step-container_steptitle"
                  >{{ $t("lang.wms.web.quote.step") }}4</span
                >
                <span class="step-container_subtitle">{{
                  $t("lang.wms.fed.poppick.device.repeat.step")
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('lang.wms.fed.tote.error.info')"
      :visible.sync="showDialog"
      width="70%"
      class="error-tips-modal"
      :modal="false"
      :center="true"
    >
      <div class="modal-box-wrap">
        <div class="modal-box">
          <div class="title">
            {{ $t("lang.ws.fed.plcError.containerBaseInfo") }}
          </div>
          <div class="error-tips-modal-form">
            <div v-for="item in formData" :key="JSON.stringify(item)">
              <span>{{ item.label }}：</span>
              <span>{{ $t(rmsMsg[item.code]) || "" }}</span>
            </div>
          </div>
        </div>
        <div class="modal-box">
          <div class="title">
            {{ $t("lang.wms.fed.tote.error.info") }}
          </div>
          <div class="error-form">
            <div>
              <span
                >{{
                  $t("lang.wms.fed.abnormal") + $t("lang.wms.fed.coding")
                }}：</span
              >
              <span class="org-txt">{{ errorData.error_code }}</span>
            </div>
            <div>
              <span>{{ $t("lang.wms.fed.abnormalInformation") }}：</span>
              <span class="org-txt">{{ $t(errorData.error_msg) }}</span>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from "@/utils/http";
export default {
  name: "geekDeviceErrorHandle",
  options: {
    name: "设备故障处理",
    parents: ["all"], // all
    groups: ["自定义页面"],
    events: {
      cancleEdit: "取消",
      closeEdit: "关闭",
    },
  },
  props: {
    drop: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      showDialog: false,
      latticeTypeMap: {},
      innerDict: {
        ppAbnormalBoxHandleWay: [],
        ppAbnormalBoxHandleWayFilter: [],
        dispatchCallBackMsg: [],
        latticeType: [],
      },
      handleWayKey: "",
      tableList: [],
      parkIds: [],
      workStationId: "",
      siteCode: "",
      currentItem: {},
      tableList: [],
      searchErrorParams: {
        stationId: "",
        boxCode: "",
      },
      errorData: {},
      rmsMsg: {},
      formData: [
        {
          label: this.$t("lang.ws.fed.plcError.containerCode"),
          code: "hodCode",
        }, //货箱号
        { label: this.$t("lang.ws.fed.plcError.taskId"), code: "taskId" }, //任务ID
        {
          label: this.$t("lang.ws.fed.plcError.instructionType"),
          code: "instructionStr",
        }, //指令类型
        { label: this.$t("lang.wms.fed.workstation"), code: "siteCode" }, //工作站
        {
          label: this.$t("lang.ws.fed.plcError.missionPhase"),
          code: "taskPhaseStr",
        }, //任务阶段
        { label: this.$t("lang.wms.fed.PLCParkId"), code: "parkId" }, //提升机
        {
          label: this.$t("lang.wms.fed.station.current.lattice"),
          code: "startLatticeCode",
        }, //实时位置
        { label: this.$t("lang.wms.fed.station.lattice.type"), code: "latticeTypeStr" }, //位置类型

        { label: this.$t("lang.wms.fed.startBin"), code: "startLatticeCode" }, //起始货位
        {
          label: this.$t("lang.ws.fed.plcError.destinationLocation"),
          code: "destLatticeCode",
        }, //目的货位
        {
          label: this.$t("lang.ws.fed.plcError.startShelf"),
          code: "startShelfCode",
        }, //起始货架
        {
          label: this.$t("lang.ws.fed.plcError.purposeShelf"),
          code: "destShelfCode",
        }, //目的货架
        {
          label: this.$t("lang.ws.fed.plcError.startShelfSurface"),
          code: "startShelfSide",
        }, //起始货架面
        {
          label: this.$t("lang.ws.fed.plcError.purposeShelfSurface"),
          code: "destShelfSide",
        }, //目的货架面
      ],
      rules: {
        stationId: [
          {
            required: true,
            message: this.$t("lang.wms.fed.pleaseCheckOut"),
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getDicts();
  },
  methods: {
    createRmsMsg(data) {
      let rmsMsg = data?.rms_msg ? JSON.parse(data?.rms_msg) : {};
      rmsMsg.latticeTypeStr = this.innerDict.latticeType.find(res => res.fieldCode == rmsMsg.latticeType)?.fieldValue;
      rmsMsg.instructionStr = this.innerDict.dispatchCallBackMsg.find(
        (res) => res.fieldCode == rmsMsg.instruction
      )?.fieldValue;
      rmsMsg.taskPhaseStr =
        this.innerDict.dispatchCallBackMsg.find(
          (res) => res.fieldCode == rmsMsg.taskPhase
        )?.fieldValue || rmsMsg.taskPhase;
      this.rmsMsg = rmsMsg;
    },
    openDetail(item, tableList) {
      // console.log(item, tableList);
      this.getErrorDetail(item.dispatchErrorId);
    },
    async getErrorDetail(id) {
      let res = await POST(
        "/api/common/datatrickle/getdata/v1?dataTrickleCode=dispatch-plc-error-item-msg",
        { id }
      );
      this.errorData = res.data?.item || {};
      this.createRmsMsg(res.data?.item);
      this.showDialog = true;
    },
    async getDicts() {
      let params = {
        objectCodes: [
          "pp_abnormal_box_handle_way", // 异常处理方式
          "dispatch_call_back_msg",
          "lattice_type",
        ],
        enable: true,
        sortKey: "sortOrder",
      };
      await POST("/api_v1/dict/batchQuery", params).then((res) => {
        if (res.code === 0) {
          const toCamel = (e) =>
            e.replace(/_(\w)/g, ($0, $1) => $1.toUpperCase());
          // 字典
          Object.keys(res.data).forEach((e) => {
            const key = toCamel(e);

            if (!this.innerDict[key]) {
              this.innerDict[key] = [];
            }
            this.innerDict[key] = (res.data[e].details || []).map((v) => {
              return {
                ...v,
                label: this.$t(v.fieldValue),
                value: v.fieldCode,
              };
            });
          });
          let obj = {};
          res.data.lattice_type.details.forEach((item) => {
            obj[item.fieldCode] = this.$t(item.fieldValue);
          });
          this.latticeTypeMap = obj;
        }
      });
    },
    handleSelectionChange(val) {
      // 设置第三步内容 所有查处的内容 必然要有处理方式
      if (val.taskId != this.currentItem.taskId) {
        this.handleWayKey = "";
      }
      this.currentItem = val;
      this.innerDict.ppAbnormalBoxHandleWayFilter =
        this.innerDict.ppAbnormalBoxHandleWay.filter(
          (item) => val.handleWay.indexOf(item.value) > -1
        );
      if (this.innerDict.ppAbnormalBoxHandleWayFilter.length === 1) {
        this.handleWayKey =
          this.innerDict.ppAbnormalBoxHandleWayFilter[0].value;
      }
    },
    save() {
      this.$confirm(this.$t("lang.wms.fed.poppick.device.double.confirm"), this.$t("lang.wms.fed.tips"), {
        confirmButtonText: this.$t("lang.wms.fed.determine"),
        cancelButtonText: this.$t("lang.wms.fed.cancel"),
        type: "warning",
      }).then(() => {
        const { boxCode, taskId, instruction, dispatchErrorId, parkId, stationId } =
          this.currentItem;
        const handleWay = this.handleWayKey;
        POST("/api/site/box/handleBoxAbnormal", {
          siteCode: this.siteCode,
          parkId,
          stationId,
          boxCode,
          taskId,
          instruction,
          dispatchErrorId,
          handleWay,
        }).then((res) => {
          if (res.code === 0) {
            this.$message({
              type: "success",
              message: this.$t("lang.wms.fed.implementationSuccess"),
            });
            // 查询最新状态
            setTimeout(() => {
              window.needErrorTip = true;
              this.searchError();
            }, 1000)
            // this.handleCancel();
          }
        });
      });
    },
    searchReset() {
      this.$refs.stationId?.focus();
      this.tableList = [];
      this.parkIds = [];
      this.workStationId = "";
      this.siteCode = "";
      this.searchErrorParams = {
        stationId: "",
        boxCode: "",
      }
      this.innerDict.ppAbnormalBoxHandleWayFilter = []
      this.$nextTick(() => {
        this.$refs['searchErrorDom'].clearValidate();
      });
    },
    searchError() {
      this.$refs.searchErrorDom.validate((valid) => {
        if (valid) {
          const siteCode = this.searchErrorParams.stationId;
          const boxCode = this.searchErrorParams.boxCode;
          GET(
            `/api/site/box/siteDeviceAbnormal?siteCode=${siteCode}&boxCode=${boxCode}`
          ).then((res) => {
            window.needErrorTip = false;
            if (res.code == 0) {
              let data = res.data;
              this.tableList = data.boxDeliverTask;
              this.parkIds = data.parkIds;
              this.workStationId = data.workStationId;
              this.siteCode = data.siteCode;
            } else {
              this.$refs.stationId?.focus();
              this.tableList = [];
              this.parkIds = [];
              this.workStationId = "";
              this.siteCode = "";
              this.searchErrorParams = {
                stationId: "",
                boxCode: "",
              }
              this.$nextTick(() => {
                this.$refs['searchErrorDom'].clearValidate();
              });
            }
            this.handleWayKey = "";
            this.$refs.mainTable.setCurrentRow();
            this.innerDict.ppAbnormalBoxHandleWayFilter = [];
          })
        }
        return false;
      });
    },
    handleCancel() {
      this.$emit("cancel", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.straytegy-dialog {
  display: flex;
  flex-direction: column;
  flex: 1;

  .dialog-main {
    display: flex;
    flex-direction: row;
    flex: 1;
  }

  .cofirmResolve {
    margin-top: 20px;
    text-align: right;
  }

  .step-operate {
    margin: 20px 0;
  }

  .dialog-left {
    flex: 1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-right: 10px;
    overflow: auto;
  }

  .dialog-right {
    flex: 1;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 20px;
    margin-left: 10px;
  }
}

.dialog-subTitle {
  font-size: 20px;
}

.step-choose-content {
  min-height: 100px;

  .el-tag {
    margin-right: 10px;
  }
}

.errorHandleWay {
  display: block;
  margin: 5px 0;
}

.steps-procedure {
  width: 100%;
}

.step-procedure {
  // min-height: 100px;
  display: flex;
  position: relative;
  flex-shrink: 1;
}

.step-indicator {
  position: relative;
  width: 24px;
  flex-grow: 0;

  .step-indicator_num {
    position: relative;
    display: flex;
    width: 24px;
    height: 24px;
    font-size: 14px;
    border: 2px solid #c0c4cc;
    border-radius: 50%;
    background: #fff;
    justify-content: center;
    align-items: center;
    z-index: 1;

    &.active {
      border-color: #409eff;
      color: #409eff;
    }
  }

  .step-indicator_line {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 11px;
    border: 2px solid #c0c4cc;

    &.active {
      border-color: #409eff;
    }
  }
}

.step-container-auto {
  overflow-x: auto;
}
.step-container {
  padding-left: 10px;
  flex-grow: 1;
  .step-container_title {
    span {
      margin-right: 20px;
      font-size: 16px;
    }

    .step-container_subtitle-warn {
      font-size: 14px;
      color: red;
    }

    .step-container_subtitle {
      font-size: 14px;
    }
  }
}
.org-txt {
  color: #e98f36 !important;
}

.modal-box {
  font-size: 14px;
  color: #535353;
  padding: 10px;
  .error-tips-modal-form {
    display: flex;
    flex-wrap: wrap;
    > div {
      width: 50%;
      font-size: 16px;
      padding: 4px 0;
      > span:first-child {
        width: 100px;
        display: inline-block;
        color: #161616;
      }
    }
  }
  .error-form {
    > div {
      font-size: 16px;
      padding: 4px 0;
      > span:first-child {
        width: 80px;
        display: inline-block;
        color: black;
      }
    }
  }
}
.modal-box-wrap {
  flex: 1;
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .modal-box:first-child {
    width: 60%;
  }
  .modal-box:last-child {
    padding-left: 50px;
    border-left: 1px solid #e0e0e0;
  }
}
.geek-step-procedure {
  display: flex;
  flex-flow: column;
  padding: 15px;
  height: 100%;
  background: #fff;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 10px 1px rgb(0 0 0 / 10%);
  overflow: hidden;

  .geek-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .actions {
      display: flex;
      align-items: center;

      > * {
        margin-left: 0;
        margin-right: 18px;
        cursor: pointer;
      }
    }

    ::v-deep {
      .el-form-item {
        margin: 0;
      }
    }
  }

  .geek-card-body {
    height: 0;
    flex: 1;
    overflow: auto;

    ::v-deep {
      .geek-table {
        height: 100%;
        margin-bottom: 0;

        .el-table {
          max-height: 100% !important;
          position: relative;

          .el-table__header-wrapper {
            .cell {
              position: relative;
            }
          }

          .el-table__body-wrapper,
          .el-table__fixed-body-wrapper {
            max-height: 100% !important;
            position: absolute;
            top: 48px;
            bottom: 0;
            overflow-y: auto;
          }
        }
      }
    }
  }
}

.el-form {
  .el-select {
    width: 100%;
  }
}

.dialog-footer {
  margin-top: 20px;
  text-align: center;
}

.el-table {
  ::v-deep .current-row {
    .cell {
      color: #409eff;
    }
  }

  ::v-deep .error-row {
    background: #fde2e2;
  }
}
</style>
