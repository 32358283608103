<template>
  <div class="layout-wrapper">
    <!-- <composite-menu :iscollapse="drawer" @mousedown.stop/> -->
    <div class="main-container">
      <div :class="{'fixed-header': fixedHeader}">
        <navbar />
        <tags-view ref="tagsView" @updateTagViews="updateTagViews"/>
      </div>
      <app-main :tagViews="tagViews" />
    </div>

    <!-- 菜单弹窗 -->
    <template v-if="$store.state.isShowMenu">
      <GeekMenuDialog v-if="$store.state.menuType == 0 || $store.state.isAddCommon"/>
      <GeekNavMenu v-else/>
    </template>
    <!-- 修改密码弹窗 -->
    <PassWordChange v-if="$store.state.isShowPassWord"></PassWordChange>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import TagsView from './components/TagsView'
import AppMain from './components/AppMain'
import CompositeMenu from './components/CompositeMenu'
import GeekMenuDialog from './components/MenuDialog.vue';
import PassWordChange from './components/PassWordChange.vue';
import GeekNavMenu from './components/NavMenu/index'; // 新
import { GET } from '../../utils/http';

export default {
  components: { Navbar, TagsView, AppMain, CompositeMenu, GeekMenuDialog, GeekNavMenu, PassWordChange },
  data() {
    return {
      authMap: {},
      fixedHeader: true,
      tagViews: [],
    }
  },
  watch: {
    '$route.path': {
      handler() {
        document.title = this.$route.name || 'EOS'
        setTimeout(() => console.log('auth', JSON.stringify(this.authMap[this.$route.meta.auth], null, 2)), 100)
      },
      immediate: true,
      deep: true
    },
    '$store.state.isShowMenu': {
      handler(value) {
        if (!value) {
          this.$store.state.isAddCommon = false;
        }
      },
      deep: true
    }
  },
  created() {
    // document.body.addEventListener('mousedown', () => {
    //   this.drawer = false
    // })
    this._initPermission();
    let that = this;
    // 监听工作站iframe传入退出信息
    window.addEventListener('message', e => {
      if (e.data == 'loginOut') {
        that.$refs.tagsView.closeSelectedTag({
          name: 'lang.wes.fed.station',
          path: '/station',
          meta: {}
        })
      }
      if(e.data == 'openMap'){
        // 工作站触发跳转map页签
        this.$router.push({ path: '/monitor/robotControl'});
      }
    });
  },
  methods: {
    _initPermission() {
      let authMap = {}
      let menuList = JSON.parse(localStorage.getItem('MenuList')) || []
      const getPermission = (arr, obj) => {
        if (obj.btn) {
          arr.push(...obj.btn.map(b => ({ code: `this.$auth('${b.code}')`, name: this.$transMsg(b.name) })))
        }
        if (obj.children) {
          obj.children.forEach(item => {
            getPermission(arr, item)
          })
        }
      }

      for (let i = 0; i < menuList.length; i += 1) {
        const item = menuList[i]
        if (item.type !== 1 && item.type !== 3) {
          console.log(`name:${item.name}, type:${item.type}, - not a btn item. -`)
        } else {
          for (let k = 0; k < item.children.length; k += 1) {
            const children = item.children[k]
            const g = children.children
            const key = `${item.code.trim()}/${children.code.trim()}`
            if (key === 'strategicCenter/restockConfig') {
              authMap[key] = []
              getPermission(authMap[key], children)
            } else if (g && g.length > 0) {
              for (let j = 0; j < g.length; j += 1) {
                const el = g[j]
                authMap[`${children.code.trim()}/${el.code.trim()}`] = el.btn.map(b => ({ code: `this.$auth('${b.code}')`, name: this.$transMsg(b.name) }))
              }
            } else {
              authMap[key] = children.btn.map(b => ({ code: `this.$auth('${b.code}')`, name: this.$transMsg(b.name) }))
            }
          }
        }
      }
      this.authMap = authMap
    },
    updateTagViews(val) {
      this.tagViews = val;
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  background: #000001;
  .main-container {
    height: calc(100% - 100px);
    padding-top: 100px;
    flex: 1;
    overflow: auto;
    .container-cover {
      position: absolute;
      top: 84px;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0.6;
      background: #000000;
      z-index: 2007;
    }
    .fixed-header {
      position: fixed;
      top: 0;
      right: 0;
      z-index: 9;
      width: 100%;
      transition: width 0.28s;
    }
  }
  // ::-webkit-scrollbar {
  //   width: 5px !important;
  //   height: 5px !important;
  // }
  // ::-webkit-scrollbar-thumb {
  //   border-radius: 5px;
  //   background-color: #aaa;
  // }
  // ::-webkit-scrollbar-thumb:hover {
  //   background-color: #ccc;
  // }
  // ::-webkit-scrollbar-button {
  //   display: none;
  // }
}
</style>
