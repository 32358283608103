import axios from "axios";
const BASEURL = '';


/**
 * 基础数据
 * @param {Object}
 */
export function initParams(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-record/init-page-param`,
    method: 'get',
  });
}
/**
 * 分页
 * @param {Object}
 */
export function getTable(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-record/page`,
    method: 'get',
    params,
  });
}

/**
 * 导出
 * @param {Object}
 */
export function exportTable(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-record/export`,
    method: 'get',
    params,
  });
}
/**
 * 上传
 * @param {Object}
 */
export function uploadPdf(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/sop/upload`,
    method: 'post',
    params,
  });
}

/**
 * 获取导出字段
 * @param {Object}
 */
export function getExportFields(params) {
  return axios({
    url: `${BASEURL}/api/common/exception-manage/exception-record/get-export-fields`,
    method: 'get',
    params,
  });
}
