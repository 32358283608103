<template>
  <div class="geek-export-language">

    <el-form ref="form" label-width="auto">
      <el-form-item :label="$t('lang.wms.fed.updateLanguage')">
        <el-select v-model="language" :placeholder="$t('lang.wms.fed.pleaseChoose')">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('lang.wms.fed.exportUpdate')">
        <el-upload
          :class="{'geek-export-language__upload--disabled': !language}"
          ref="upload"
          accept=".xlsx,.xls"
          :file-list="fileList"
          :action="`/beetle/api_v1/language/importLanguagePackage?language=${language}`"
          :disabled="!language"
          :on-change="handleChange"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :on-success="handleSuccess"
          :on-error="handleError"
          :on-exceed="handleExceed"
          :auto-upload="false">
          <el-button slot="trigger" size="small" type="primary">{{$t('lang.wms.fed.selectTheFile')}}</el-button>
          <!--        <div slot="tip" class="el-upload__tip">只能上传xlsx文件，且不超过2M</div>-->
        </el-upload>
      </el-form-item>
      <el-form-item :label="$t('lang.wms.fed.importResults')">
        <template>
          <!--导入loading-->
          <geek-flex-container align="center" v-if="loading"><i class="el-icon-loading fs-xxl"></i>{{$t('lang.wms.fed.pleaseWaitToLoading')}}</geek-flex-container>
          <!--成功-->
          <div v-else-if="code === 0" class="c-success">{{$t('lang.wms.fed.importSuccess2')}}</div>
          <!--格式不对导致的失败-->
          <div v-else-if="code === 1" class="c-danger">{{$t(errorReason)}}</div>
          <!--导入失败 下载错误原因-->
          <div v-else-if="code === 3" >
            <span class="c-danger">{{errorReason}}</span>
            <a :href="`/beetle/api_v1/language/downloadLanguageFailureFile?language=${language}`" >
              {{$t('lang.wms.downloadCheckReason')}}
            </a>
          </div>
          <!--部分成功-->
          <div v-else-if="code === 4">
            <span class="c-warning">{{errorReason}}</span>
            <a :href="`/beetle/api_v1/language/downloadLanguageFailureFile?language=${language}`" >
              {{$t('lang.wms.downloadCheckReason')}}
            </a>
          </div>
        </template>
      </el-form-item>
    </el-form>
    <geek-flex-container align="center" justify="end" class="mt-lg">
      <el-button type="primary" @click="submitUpload" :disabled="disabeldUpload">{{$t('lang.wms.fed.startExport')}}</el-button>
    </geek-flex-container>
  </div>
</template>

<script>
import { GET } from '../../utils/http';
import { debounce } from 'throttle-debounce';

const DELAY_DEBOUCE_DURING = 300

export default {
  name: 'GeekExportLanguage',
  options: {
    name: '导入国际化',
    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
      complete: '导入完成'
    }
  },
  components: {
  },
  props: {
    $body: {
      ignore: true
    }
  },
  data() {
    return {
      language: '',
      options: [],
      fileList: [],
      code: '',
      loading: false,
      disabeldUpload: true
    }
  },
  mounted() {
    this.queryLanguageList()
  },
  methods: {
    queryLanguageList() {
      GET('/api_v1/language/selectAllLanguages').then(res => {
        if (!res.code) {
          this.options = res.data.map(l => ({ value: l, code: l }))
        }
      })
    },
    handleChange(file, fileList) {
      console.log(file, fileList);
      this.fileList = [file] // 限制每次1个 修改后替换当前文件
      let undo = fileList.some(l => l.status === 'ready')
      if (undo) {
        this.code = ''
        this.disabeldUpload = false
      }
    },
    handlePreview(file) {
      console.log(file.raw);
    },
    handleRemove() {
      this.code = ''
    },
    handleSuccess(data) {
      this.loading = false
      this.code = data.code
      if (this.code) {
        // {"p":["lang.wms.downloadCheckReason"],"c":"lang.wms.baseNoteType.PartialSuccessWithReson"}
        this.errorReason = this.$transMsg(data.msg)
      }
      // 上传完成后按钮不可重复点击
      this.disabeldUpload = true
    },
    handleError(e) {
      console.log(e);
    },
    handleExceed(files, fileList) {
    },
    submitUpload: debounce(DELAY_DEBOUCE_DURING, function(e) {
      if (!this.language) {
        this.$message.error(this.$t('lang.wms.fed.pleaseChooseLanguage'))
        return
      }
      this.loading = true
      this.$refs.upload.submit();
    })
  }
}
</script>

<style lang="scss">
.geek-export-language {
  padding: 0 40px;
  .el-upload-list {
     display: table;
  }
  &__upload--disabled {
    button {
      cursor: not-allowed;
      color: #FFF;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>
