<template>
  <div class="distance-wrap" :class="[mode, { hover }]">
    <div class="left-arrow">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <div class="right-arrow">
      <div class="left"></div>
      <div class="right"></div>
    </div>
    <div class="length">
      <input :value="length" :disabled="disabled" @focus="hover = true" @blur="hover = false" @change="changeHandler" />
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'length',
    event: 'change'
  },
  data() {
    return {
      hover: false
    }
  },
  props: {
    length: Number,
    disabled: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'vertical'
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: Number.POSITIVE_INFINITY
    }
  },
  methods: {
    changeHandler(e) {
      let v = +e.target.value;
      if (Number.isInteger(v)) {
        if (v < this.min || v > this.max) {
          this.$message({ message: this.$t('lang.wms.fed.inputError'), type: 'warning' });
        }
        if (v < this.min) v = this.min;
        if (v > this.max) v = this.max;
        this.$emit('change', v);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.distance-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  &:hover,
  &.hover {
    background: #666;
    .left-arrow,
    .right-arrow {
      display: block !important;
    }
  }
  &.vertical {
    top: 0;
    left: -20px;
    width: 1px;
    height: 100%;
    .length {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 45px;
      background: #fff;
      border-radius: 45px;
      input {
        color: blue;
        text-align: center;
        width: 50px;
        &[disabled] {
          color: #343434;
          cursor: not-allowed;
        }
      }
    }
    .left-arrow {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      .left {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(30deg);
        transform-origin: top left;
        background: #343434;
      }
      .right {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(-30deg);
        transform-origin: top left;
        background: #343434;
      }
    }
    .right-arrow {
      display: none;
      position: absolute;
      bottom: 0;
      left: 1px;
      .left {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(150deg);
        transform-origin: top left;
        background: #343434;
      }
      .right {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(-150deg);
        transform-origin: top left;
        background: #343434;
      }
    }
  }
  &.horizontal {
    bottom: 15px;
    left: 0;
    width: 100%;
    height: 1px;
    .length {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 20px;
      background: #fff;
      border-radius: 45px;
      input {
        color: blue;
        text-align: center;
        width: 50px;
        &[disabled] {
          color: #343434;
          cursor: not-allowed;
        }
      }
    }
    .left-arrow {
      display: none;
      position: absolute;
      top: 1px;
      left: 0;
      .left {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(-60deg);
        transform-origin: top left;
        background: #343434;
      }
      .right {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(-120deg);
        transform-origin: top left;
        background: #343434;
      }
    }
    .right-arrow {
      display: none;
      position: absolute;
      top: 0;
      right: 1px;
      .left {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(120deg);
        transform-origin: top left;
        background: #343434;
      }
      .right {
        position: absolute;
        top: 0;
        width: 1px;
        height: 8px;
        transform: rotate(60deg);
        transform-origin: top left;
        background: #343434;
      }
    }
  }
}
</style>
