<template>
    <div class="strategy-container">
        <div class="search-box">
            <el-form :model="form" ref="searchForm" :rules="rules" label-position="top">
                <el-row>
                    <el-col :span="6">
                        <el-form-item prop="strategyType" :label='$t("lang.wms.fed.strategyType")'>
                            <el-select clearable v-model="form.strategyType" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                <el-option
                                v-for="(item, index) in dict.taskStrategyType"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="objectCode" :label='$t("lang.wms.fed.workstationCode")'>
                            <el-select clearable v-model="form.objectCode" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                <el-option
                                v-for="(item, index) in stationList"
                                :key="index"
                                :label="item"
                                :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="status" :label='$t("lang.wms.fed.status")'>
                            <el-select clearable v-model="form.status" :placeholder="$t('lang.wms.fed.pleaseChoose')">
                                <el-option
                                v-for="(item, index) in dict.enable"
                                :key="index"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="strategyName" :label='$t("lang.wms.fed.strategyName")'>
                            <el-input clearable v-model="form.strategyName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item prop="strategyCode" :label='$t("lang.wms.fed.strategyCoding")'>
                            <el-input clearable v-model="form.strategyCode"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <div class="search-btns">
                        <el-button size="mini" plain @click="reset()">{{$t('lang.wms.fed.reset')}}</el-button>
                        <el-button size="mini" type="primary" @click="search()">{{$t('lang.wms.fed.query')}}</el-button>
                    </div>
                </el-row>
            </el-form>
        </div>
        <div class="main-opts">
          <el-button size="medium" type="primary" @click="importFile()"  v-if="$auth('stationQuoteImport:stationTaskManage')">{{$t('lang.wms.fed.import')}}</el-button>
          <el-button size="medium" type="primary" @click="add()"  v-if="$auth('stationQuoteAdd:stationTaskManage')">{{$t('lang.wms.fed.newlyAdded')}}</el-button>
        </div>
        <div class="table-area">
            <div class="left-table">
                <div class="common-diy-title">
                    <div>{{$t('lang.wms.fed.configurationList')}}</div>
                </div>
                <el-table
                    ref="mainTable"
                    :data="tableData"
                    border
                    highlight-current-row
                    @row-click="rowClick"
                    height="500px"
                    style="width: 100%">
                    <el-table-column
                    prop="index"
                    :label="$t('lang.wms.fed.index')"
                    width="50">
                     <template slot-scope="scope">
                        {{scope.$index + 1}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="settingCode"
                    :label="$t('lang.wms.web.quote.settingCode')">
                    </el-table-column>
                    <el-table-column
                    prop="objectCode"
                    :label="$t('lang.wes.fed.station')">
                    </el-table-column>
                    <!-- <el-table-column
                    prop="objectType"
                    :label="$t('lang.wms.web.quote.quoteObjectType')">
                     <template slot-scope="scope">
                        {{drop.strategyTypes[scope.row.strategyType][scope.row.objectType] || scope.row.objectType}}
                    </template>
                    </el-table-column> -->
                    <el-table-column
                    prop="strategyType"
                    :label="$t('lang.wms.fed.strategyType')">
                    <template slot-scope="scope">
                        {{drop.taskStrategyType[scope.row.strategyType] || scope.row.strategyType}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="strategyCount"
                    :label="$t('lang.wms.web.quote.quoteNum')">
                    </el-table-column>
                    <el-table-column
                    prop="status"
                    :label="$t('lang.wms.fed.status')">
                    <template slot-scope="scope">
                        {{drop.enable[scope.row.status] || scope.row.status}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    fixed="right"
                    :label="$t('lang.wms.fed.operation')"
                    width="100">
                    <template slot-scope="scope">
                        <el-button :disabled="isEdit" @click.stop="handleClickMainTable(scope.row, 'stop')" type="text" size="small"  v-if="$auth('stationQuoteDisable:stationTaskManage') && scope.row.status == 1">{{$t('lang.wms.fed.deactivate')}}</el-button>
                        <el-button :disabled="isEdit" @click.stop="handleClickMainTable(scope.row, 'stop')" type="text" size="small"  v-if="$auth('stationQuoteEnable:stationTaskManage') && scope.row.status != 1">{{$t('lang.wms.fed.enable')}}</el-button>
                        <el-button :disabled="isEdit" class="c-danger" @click.stop="handleClickMainTable(scope.row, 'del')"  type="text" size="small"  v-if="$auth('stationQuoteDelete:stationTaskManage')">{{$t('lang.wms.fed.delete')}}</el-button>
                    </template>
                    </el-table-column>
                </el-table>
                <div style="display:flex; justify-content:right;">
                    <el-pagination
                    style="padding: 10px"
                    :current-page="pageData.currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageData.pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="pageData.recordCount"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </div>
            <div class="right-edit" v-if="detailData">
                <div class="common-diy-title">
                    <div>{{$t('lang.wms.web.quote.settingDetail')}}</div>
                    <div class="edit-btns">
                        <el-button v-if="!isEdit && $auth('stationQuoteEdit:stationTaskManage')" size="mini" type="primary" @click="handleEditToggle(true)" >{{$t('lang.wms.fed.edit')}}</el-button>
                        <template v-else-if="isEdit && $auth('stationQuoteEdit:stationTaskManage')">
                            <el-button size="mini" type="primary" @click="handleSave('save')">{{$t('lang.wms.fed.save')}}</el-button>
                            <el-button size="mini" plain @click="handleEditToggle(false)">{{$t('lang.wms.fed.cancel')}}</el-button>
                        </template>
                    </div>
                </div>
                <div class="edit-txt-area">
                    <el-row>
                        <el-col :span="12">
                            <span>{{$t("lang.wms.web.quote.settingCode")}}：</span>
                            <span>{{detailData.settingCode}}</span>
                        </el-col>
                        <el-col :span="12">
                            <span>{{$t('lang.wes.fed.station')}}：</span>
                            <span>{{detailData.objectCode}}</span>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <span>{{$t('lang.wms.fed.strategyType')}}：</span>
                            <span>{{this.drop.taskStrategyType[detailData.strategyType] || detailData.strategyType}}</span>
                        </el-col>
                    </el-row>
                    <el-row v-if="isEdit">
                         <el-col :span="11">
                            <el-select v-model="editStrategyCodes" multiple :placeholder="$t('lang.wms.fed.pleaseChoose')">
                              <el-option
                                v-for="(item, index) in (dict.strategyTypes[detailData.strategyType] || [])"
                                :key="index"
                                :label="item.strategyName"
                                :value="item.strategyCode">
                              </el-option>
                            </el-select>
                        </el-col>
                        <el-col :offset="1" :span="12">
                            <el-button size="medium" type="primary" @click="handleAdd()">{{$t('lang.wms.fed.add')}}</el-button>
                        </el-col>
                    </el-row>
                    <el-table
                    :data="editTableData"
                    border
                    style="width: 100%">
                    <el-table-column
                    prop="index"
                    type="index"
                    :label="$t('lang.wms.fed.priority')"
                    width="100">
                    </el-table-column>
                    <el-table-column
                    prop="objectCode"
                    :label="$t('lang.wes.fed.station')">
                    <template>
                        {{detailData.objectCode}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="strategyCode"
                    :label="$t('lang.wms.fed.strategyCoding')">
                    </el-table-column>
                    <el-table-column
                    prop="strategyName"
                    :label="$t('lang.wms.fed.strategyName')">
                    </el-table-column>
                    <el-table-column
                    prop="strategyType"
                    :label="$t('lang.wms.fed.strategyType')">
                    <template slot-scope="scope">
                        {{drop.taskStrategyType[scope.row.strategyType] || scope.row.strategyType}}
                    </template>
                    </el-table-column>
                    <el-table-column
                    v-if="isEdit"
                    prop="type"
                    :label="$t('lang.wms.fed.operation')"
                    width="150">
                        <template slot-scope="scope">
                            <el-button circle icon="el-icon-delete" @click="handleClick(scope.row, scope.$index, 'del')" class="c-danger" type="primary" size="small" :title="$t('lang.wms.fed.delete')"></el-button>
                            <el-button :disabled="scope.$index === 0" circle icon="el-icon-top" @click="handleClick(scope.row, scope.$index, 'up')" type="primary" size="small"  :title="$t('lang.wms.fed.moveUp')"></el-button>
                            <el-button :disabled="scope.$index === editTableData.length - 1" circle icon="el-icon-bottom" @click="handleClick(scope.row, scope.$index, 'down')"  type="primary" size="small" :title="$t('lang.wms.fed.moveDown')"></el-button>
                        </template>
                    </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>


        <el-dialog :title="$t('lang.wms.fed.newlyAdded')" :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogTableVisible" :width="'80%'">
          <add-dialog :dict="dict" :drop="drop" v-if="dialogTableVisible" @cancel="dialogCancel" style="height: 80%; overflow: hidden;"></add-dialog>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="importdialogVisible" :width="'40%'" :before-close="handleCloseDialog">
          <import-dialog :dict="dict" :drop="drop" :stationList="stationList" v-if="importdialogVisible"></import-dialog>
        </el-dialog>
    </div>
</template>

<script>
import { GET, POST, PUT, DELETE } from '@/utils/http'
import addDialog from './addDialog'
import importDialog from './importDialog'
export default {
  name: 'GeekStationStrategy',
  options: {
    name: '工作站策略引用',

    parents: ['all'], // all
    groups: ['自定义页面'],
    events: {
      click: '点击'
    }
  },
  components: {
    addDialog,
    importDialog
  },
  data() {
    return {
      rules: {},
      dict: {
        quoteObjectType: [],
        taskStrategyType: [],
        enable: [{ label: this.$t('lang.wms.fed.deactivate'), value: '0' }, { label: this.$t('lang.wms.fed.enable'), value: '1' }],
        stations: [],
        strategyTypes: {},
        displayStyle: [],
        siteOperationMode: []
      },
      drop: {
        siteOperationMode: {},
        quoteObjectType: {},
        taskStrategyType: {},
        enable: {
          0: this.$t('lang.wms.fed.deactivate'),
          1: this.$t('lang.wms.fed.enable')
        },
        strategyTypes: {},
        displayStyle: {}
      },
      form: {
        objectType: '1', // 引用对象类型，1工作站，2播种墙，3播种墙规格，4播种墙格口
        WallBinNo: '',
        wallNo: '',
        WallRuleNo: '',
        strategyType: '',
        strategyCode: '',
        strategyName: '',
        status: ''
      },
      isEdit: false,
      pageData: {
        currentPage: 1,
        pageSize: 10,
        recordCount: 0,
        pageCount: 0
      },
      detailData: '',
      editTableData: [],
      tableData: [],
      editStrategyCodes: [],
      stationList: [],
      dialogTableVisible: false,
      importdialogVisible:false,
    }
  },
  created() {
    this.getDicts();
    this.initTable();
    window.addEventListener('resize', this.reLayoutTable);

  },
  methods: {
    getDicts() {
      let params = {
        objectCodes: ['task_strategy_type', 'quote_object_type', 'displayStyle', 'site_operation_mode'],
        enable: true,
        sortKey: 'sortOrder'
      };
      POST('/api_v1/dict/batchQuery', params).then(res => {
        if (res.code === 0) {
          const toCamel = e => e.replace(/_(\w)/g, ($0, $1) => $1.toUpperCase());
          // 字典
          Object.keys(res.data).forEach(e => {
            const key = toCamel(e);
            this.dict[key] = (res.data[e].details || []).map(v => {
              return {
                ...v,
                label: this.$t(v.fieldValue),
                value: v.fieldCode
              }
            });
            console.log(res.data[e].details, key);
            (res.data[e].details || []).map(v => {
              this.drop[key][v.fieldCode] = this.$t(v.fieldValue);
              return v;
            });
            console.log(this.drop[key]);
          });
        }
      });
      this.getDropDownByInterface();
    },
    getDropDownByInterface() {
      //   GET('/beetle/api/common/stdStation/rest/site/getAllSiteCodes/v1').then(res => {
      //     // 工作站号
      //     if (res.code === 0) {
      //         this.dict.stations = res.data;
      //     }
      //   })
      // 查询策略
      const arr = [1, 2, 3];
      arr.forEach(v => {
        this.getStrategyTypes(v);
      })
      GET('/api/common/stdStation/rest/site/getAllSiteCodes/v1').then(res => {
        // 获取工作站号
        if (res.code === 0) {
          this.stationList = res.data || [];
          // res.data.map(v => {
          //     this.drop.stationList[v.strategyCode] = v.strategyName;
          //     return v;
          // });
        }
      })
    },
    getStrategyTypes(v) {
      GET(`/api/v1/siteStrategy/getStrategyByType?strategyType=${v}&subType=1`).then(res => {
        // 播种墙规格
        if (res.code === 0) {
          if (!this.drop.strategyTypes[v]) {
            this.drop.strategyTypes[v] = {};
          }
          if (!this.dict.strategyTypes[v]) {
            this.dict.strategyTypes[v] = [];
          }
          this.dict.strategyTypes[v] = res.data || [];
          res.data.map(stationType => {
            this.drop.strategyTypes[v][stationType.strategyCode] = stationType.strategyName;
            return stationType;
          });
        }
      })
    },
    initTable(detailData) {
      const { pageSize, currentPage } = this.pageData;
      const params = {
        pageSize,
        currentPage,
        ...this.form
      };
      GET('/api/v1/siteStrategyQuote/select', params).then(res => {
        if (res.code === 0) {
          this.tableData = res.data?.recordList || [];
          let index = detailData ? this.tableData.findIndex(v => v.settingCode == detailData.settingCode) : 0;
          this.detailData = this.tableData[index > -1 ? index : 0] || ''
          if (!this.tableData.length && this.pageData.currentPage > 1) {
            // 无数据自动向前翻页
            this.pageData.currentPage = this.pageData.currentPage - 1;
            this.initTable();
            return;
          }
          // 编辑选择策略字段
          if (this.detailData) {
            this.detailData.editCodes = []
          }
          this.editTableData = JSON.parse(JSON.stringify(this.detailData.quoteStrategy || [])).sort((a, b) => a.priority - b.priority);
          this.$refs.mainTable.setCurrentRow(this.detailData)
          this.pageData = { ...res.data };
        }
      })
    },
    search() {
      this.pageData.currentPage = 1;
      this.handleEditToggle(false);
      this.initTable();
    },
    reset() {
      this.$refs.searchForm.resetFields();
      this.handleEditToggle(false);
      this.initTable();
    },
    // 主表格行点击
    rowClick(row, column) {
      this.detailData = row;
      this.editTableData = JSON.parse(JSON.stringify(row.quoteStrategy || [])).sort((a, b) => a.priority - b.priority);
    },
    handleClickMainTable(row, type) {
      switch (type) {
      case 'del':
        // 删除
        POST(`/api/v1/siteStrategyQuote/delete?settingCode=${row.settingCode}`).then(res => {
          if (res.code === 0) {
            this.$message({ type: 'success', message: this.$t('lang.wms.fed.deleteSuccess') });
            this.initTable();
          }
        })
        break;
      case 'stop':
        // 停用、启用
        POST(`/api/v1/siteStrategyQuote/changeStatus?settingCode=${row.settingCode}&status=${row.status == 1 ? 0 : 1}`).then(res => {
          if (res.code === 0) {
            this.$message({ type: 'success', message: this.$transMsg(res.msg || 'lang.wms.fed.success') });
            this.initTable();
          }
        })
        break;
      }
    },
    // 编辑表格操作
    handleClick(row, index, type) {
      console.log(row, index);
      switch (type) {
      case 'del':
        this.editTableData.splice(index, 1);
        break;
      case 'up':
        this.editTableData.splice(index, 1);
        this.editTableData.splice(index - 1, 0, row);
        break;
      case 'down':
        this.editTableData.splice(index, 1);
        this.editTableData.splice(index + 1, 0, row);
        break;

      }
    },
    handleEditToggle(isEdit) {
      this.isEdit = isEdit;
      if (this.isEdit) {
        this.getStrategyTypes(this.detailData.strategyType);
      } else {
        // 取消编辑恢复表格
        this.editTableData = JSON.parse(JSON.stringify(this.detailData.quoteStrategy || []));
      }
    },
    importFile(){
      this.importdialogVisible = true;
    },
    handleCloseDialog(){
      this.importdialogVisible = false;
      this.initTable();
    },
    // 编辑添加
    handleAdd() {
      const hasCodes = this.editTableData.map(v => {
        return v.strategyCode
      })
      const table = [];
      this.editStrategyCodes.forEach(v => {
        console.log(this.drop.strategyTypes[this.detailData.strategyType][v], this.drop.strategyTypes, this.detailData.strategyType)
        if (hasCodes.includes(v)) {
          this.$message({ type: 'error', message: this.$t('lang.wms.web.quote.checkAddExistQuote') });
        } else {
          table.push({
            strategyCode: v,
            strategyName: this.drop.strategyTypes[this.detailData.strategyType][v],
            settingCode: this.detailData.settingCode,
            strategyType: this.detailData.strategyType
          })
          this.editStrategyCodes = '';
        }
      })
      this.editTableData.push(...table);
    },
    // 编辑保存
    handleSave() {
      let table = this.editTableData.map((v, index) => {
        return {
          ...v,
          settingCode: this.detailData.settingCode, // 配置编码
          objectCode: this.detailData.objectCode, // 当objectType=1时，此字段表示工作站号；objectType !=1 时,此字段表示引用对象编码
          objectType: this.detailData.objectType, // 引用对象类型，1工作站，2播种墙
          priority: index + 1, // 优先级
          status: this.detailData.status
        }
      })
      if (!table || !table.length) {
        this.$message({ type: 'error', message: this.$t('lang.wms.web.controller.SiteStrategyController.checkEmpty') });
        return;
      }
      POST('/api/v1/siteStrategyQuote/save', table).then(res => {
        if (res.code == 0) {
          this.isEdit = false;
          this.$message({ type: 'success', message: this.$t('lang.wms.fed.saveSuccess') });
        } else {
          //   this.$message({ type: 'error', message: this.$t('lang.wms.fed.failSave')});
        }
        this.initTable(this.detailData);
      })
    },
    handleSizeChange(val) {
      this.pageData.pageSize = val;
      this.pageData.currentPage = 1;
      this.initTable();
    },
    handleCurrentChange(val) {
      this.pageData.currentPage = val;
      this.initTable();
    },
    add() {
      this.handleEditToggle(false);
      // 获取最新的下拉值
      this.getDropDownByInterface();
      this.dialogTableVisible = true;
    },
    dialogCancel() {
      this.dialogTableVisible = false;
      this.initTable();
    },
    reLayoutTable() {
        this.$refs.mainTable?.doLayout();
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.reLayoutTable);
  }
}
</script>

<style lang="scss" scoped>
.strategy-container{
    background: #fff;
}
.search-box{
    .el-form-item {
        padding-right: 20px;
    }
    ::v-deep {
        .el-form--label-top .el-form-item__label{
            padding: 0;
            line-height:24px;
        }
    }
    .el-select{
        width: 100%;
    }
    .search-btns{
        display: flex;
        flex-direction: row;
        justify-content: right;
    }
}
.main-opts{
  margin: 20px 0;
  padding: 0 20px;
  display: flex;
  justify-content: right;
}
.table-area{
    display: flex;
    flex-direction: row;
    flex:1
}
.left-table{
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    width: 50%;
    flex:1;
    overflow: hidden;
    .el-table {
        width: 100%;

        min-height: 400px;
    }
}
.right-edit {
    width: 50%;
    flex:1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 20px;
    margin-left: 10px
}
.edit-btns{
    display: flex;
    justify-content: right;
}
.edit-txt-area{
    max-height: 500px;
    overflow: auto;
  .el-row {
    margin-bottom: 10px;
  }
  .el-select{
    width:100%;
  }
  .el-table{
    margin-top: 20px;
  }
}
.el-table {
    ::v-deep {
    .current-row{
        .cell{
            color: #409eff;
        }
    }
    .red{
        background: lightcoral;
    }
    }
}

</style>
