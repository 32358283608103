<template>
  <div id="tags-view-container" class="tags-view-container">
    <div class="tags-view-item" @click="toggleSideBar"><i class="el-icon-menu"></i>{{$t('lang.wms.fed.navMenu')}}</div>
    <img class="scroll-icon scroll-icon-left" src="@/assets/home/icon-left.png" @click="scrollMove(0)"/>
    <scroll-pane ref="scrollPane" class="tags-view-wrapper" @scroll="handleScroll">
      <router-link
        v-for="tag in handleVisitedViews"
        ref="tag"
        :key="tag.fullPath + JSON.stringify(tag.meta)"
        :class="isActive(tag) ? 'active' : ''"
        :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }"
        tag="span"
        class="tags-view-item"
        @click.middle.native="closeSelectedTag(tag)"
        @contextmenu.prevent.native="openMenu(tag, $event)">
        <i v-if="tag.meta && tag.meta.inAppEditor" class="el-icon-edit" />
        <!-- {{ tag.query.tagsName || tag.name }} -->
        {{ tag | filterTagName }}
        <span v-if="tag.meta && tag.meta.inAppEditor && !tag.meta.status" class="dot" />
        <span v-if="!['/homePage', '/station'].includes(tag.path)" class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
      </router-link>
    </scroll-pane>
    <img class="scroll-icon scroll-icon-right" src="@/assets/home/icon-right.png" @click="scrollMove(1)" />
    <ul v-show="visible" :style="{left: left+'px', top: top+'px'}" class="contextmenu">
      <li @click="closeOthersTags(selectedTag)">{{$t('lang.wms.fed.closeOthers')}}</li>
      <li @click="closeAllTags(selectedTag)">{{$t('lang.wms.fed.closeAll')}}</li>
    </ul>
  </div>
</template>

<script>
import ScrollPane from './ScrollPane'
import path from 'path'
import { transMsg } from 'leivii'
import { baseSocketInit } from '@/utils/baseSocket';
import EventBus from '@/utils/bus';

export default {
  components: { ScrollPane },
  data() {
    return {
      visible: false,
      top: 0,
      left: 0,
      selectedTag: {},
      visitedViews: JSON.parse(localStorage.getItem('tagViews')) || []
    }
  },
  props: {

  },
  computed: {
    handleVisitedViews() {
      this.processVisitedViews();
      return [{
        path: '/homePage',
        name: 'lang.wms.fed.first',
        meta: {}
      }].concat(this.visitedViews)
    }
  },
  watch: {
    $route() {
      if (this.$route.path === '/redirect') return
      this.addTags()
      this.moveToCurrentTag()
    },
    '$store.state.changeStorage': {
      handler(value) {
        if (value) {
          this.visitedViews = JSON.parse(localStorage.getItem('tagViews')) || []
        }
      },
      deep: true
    },
    '$store.state.leivii.page': {
      handler(value) {
        this.visitedViews.forEach(v => {
          if (v.meta?.id === value.id) {
            v.meta.status = value.status
          }
        })
      },
      deep: true
    },
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },
    visitedViews: {
      handler(v) {
        let tagViews = v.map(res => ({
          name: res.name,
          meta: {},
          path: res.path,
          fullPath: res.fullPath,
          query: res.query
        }))
        localStorage.setItem('tagViews', JSON.stringify(tagViews))
        this.$emit('updateTagViews', tagViews)
      }
      // deep: true,
      // immediate: false
    }
  },
  filters: {
    filterTagName(tag) {
      return transMsg(tag.query?.tagsName || tag.name)
    }
  },
  created() {
    baseSocketInit();
    EventBus?.$on('socketApolloMsg', this.handleSocketFromBack);
  },
  mounted() {
    this.addTags()
  },
  destroyed() {
    EventBus?.$off('socketApolloMsg', this.handleSocketFromBack);
  },
  methods: {
    processVisitedViews() {
      let L = this.visitedViews.findIndex(res => res.path == '/homePage')
      if (L > -1) {
        this.visitedViews.splice(L, 1);
      }
    },
    toggleSideBar() {
      this.$store.state.isShowMenu = !this.$store.state.isShowMenu;
      // this.$emit('getDrawer', this.sidebar.opened)
    },
    addView(view) {
      if (this.visitedViews.find(item => item.path === view.path)) return
      if (view.meta && !view.meta.inAppEditor) view.meta.cache = true
      this.visitedViews.push(view)
    },
    updateView(view) {
      for (let v of this.visitedViews) {
        if (v.path === view.path) {
          Object.assign(v, view)
          break
        }
      }
    },
    delView(view) {
      for (const [i, v] of this.visitedViews.entries()) {
        if (v.path === view.path) {
          if (v.meta) v.meta.cache = false
          this.visitedViews.splice(i, 1)
          break
        }
      }
      let tagViews = this.visitedViews.map(res => ({
          name: res.name,
          meta: {},
          path: res.path,
          fullPath: res.fullPath,
          query: res.query
        }))
        localStorage.setItem('tagViews', JSON.stringify(tagViews))
        this.$emit('updateTagViews', tagViews)
    },
    delOtherViews(view) {
      if (this.visitedViews.some(v => v.path !== view.path && v.meta?.status === 0)) {
        return this.$alert('Please save', 'warning')
      }
      this.visitedViews.forEach((v, i) => {
        if (v.path !== view.path) {
          if (v.meta) v.meta.cache = false
        }
      })
      this.visitedViews = this.visitedViews.filter(v => !v.meta || v.meta.cache || v.path == '/station')
    },
    delAllViews() {
      if (this.visitedViews.some(v => v.meta?.status === 0)) {
        return this.$alert('Please save', 'warning')
      }
      const arr = this.visitedViews.filter(v => v.path != '/station')
      while (arr.length) {
        let item = arr.pop();
        this.visitedViews = this.visitedViews.filter(v => v.path != item.path)
        if (item.meta) item.meta.cache = false
      }
    },

    isActive(route) {
      return route.path === this.$route.path
    },

    addTags() {
      const { name } = this.$route
      if (name) {
        this.addView({ ...this.$route })
      }
      return false
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag
      this.$nextTick(() => {
        for (const tag of tags) {
          if (tag.to.path === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag)
            // when query is different then update
            if (tag.to.fullPath !== this.$route.fullPath) {
              this.updateView(this.$route)
            }
            break
          }
        }
      })
    },
    refreshSelectedTag(view) {
      const { fullPath } = view
      if (view.meta) view.meta.cache = false
      this.$nextTick(() => {
        this.$router.replace({
          path: '/redirect?p=' + fullPath
        })
      })
    },
    closeSelectedTag(view) {
      if (view.meta.inAppEditor && !view.meta.status) {
        return this.$alert(`Please save ${this.$t(view.name)}`, 'warning')
      }
      this.delView(view)
      if (this.isActive(view)) {
        this.toLastView()
      }
    },
    closeOthersTags(view) {
      this.$router.push(this.selectedTag)
      this.delOtherViews(this.selectedTag)
      this.moveToCurrentTag()
    },
    closeAllTags(view) {
      this.delAllViews()
      this.toLastView()
    },
    toLastView() {
      const latestView = this.visitedViews.slice(-1)[0]
      if (latestView) {
        this.$router.push(latestView.fullPath)
      } else {
        this.$router.push('/homePage')
      }
    },
    openMenu(tag, e) {
      const menuMinWidth = 105
      const offsetLeft = this.$el.getBoundingClientRect().left // container margin left
      const offsetWidth = this.$el.offsetWidth // container width
      const maxLeft = offsetWidth - menuMinWidth // left boundary
      const left = e.clientX - offsetLeft // 15: margin right

      if (left > maxLeft) {
        this.left = maxLeft
      } else {
        this.left = left
      }

      this.top = e.clientY
      this.visible = true
      this.selectedTag = tag
    },
    closeMenu() {
      this.visible = false
    },
    handleScroll() {
      this.closeMenu()
    },

    // 标签滚动事件
    scrollMove(e) {
      let { scrollWidth, scrollLeft, clientWidth } = this.$refs.scrollPane.scrollWrapper;
      if (e) {
        let to = Math.min(scrollLeft + clientWidth - 200, scrollWidth);
        this.moveAnimation(to, 100);
      } else {
        let to = Math.max(scrollLeft - clientWidth + 200, 0);
        this.moveAnimation(to, -100);
      }
    },
    // 导航滚动动画
    moveAnimation(to, step = 100, delay = 10) {
      this.timer && clearTimeout(this.timer);
      let flag = step > 0 ? this.$refs.scrollPane.scrollWrapper.scrollLeft < to : this.$refs.scrollPane.scrollWrapper.scrollLeft > to
      if (flag) {
        this.$refs.scrollPane.scrollWrapper.scrollLeft = this.$refs.scrollPane.scrollWrapper.scrollLeft + step;
        this.timer = setTimeout(() => {
          this.moveAnimation(to, step, delay)
        }, delay)
      }
    },
    handleSocketFromBack(msg) {
      if (window.switchUserSelf) {
            return;
      }
      if (!msg || !msg.content || msg.content.eventType == 'HEART_CHECK') {
        return;
      }
      let view = {
        name: 'lang.wms.fed.workstation',
        meta: {},
        path: '/station'
      }
      switch (msg.content.eventType) {
      case 'SITE_LOGOUT':
      case 'SITE_SWITCH':
        this.delView(view)
        if (this.isActive(view)) {
          this.toLastView()
        }
        break;
      case 'ENTER_SHELF':
        // 货架入场
      case 'REMOVE_SHELF':
        // 货架离场
      case 'GO_RETURN':
        // 货架回库
        if (msg.content.labelContext && !msg.content.code) {
          this.$message({ type: 'success', message: this.$t(msg.content.labelContext) })
        }
        if (msg.content.labelContext && msg.content.code) {
          // if (msg.content.eventType === 'ENTER_SHELF') {
          //   this.$message({ type: 'error', message: this.$t(msg.content.labelContext) })
          // } else {
          // }
          this.$alert(this.$t(msg.content.labelContext) , this.$t('lang.wms.fed.tips') , {
            confirmButtonText: this.$t('lang.wms.fed.determine'),
            callback: action => {}
          });
        }
        break;
      default:
        break;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tags-view-container {
  height: 50px;
  padding: 0 20px;
  background: #000001;
  display: flex;
  align-items: center;
  ::v-deep .el-scrollbar__view{
    display: flex;
  }
  .tags-view-item{
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3f4041;
    border-radius: 10px;
    padding: 0 18px;
    margin-right: 10px;
    font-size: 16px;
    color: rgba(187, 187, 187, 100);
    cursor: pointer;
    .el-icon-menu{
      color: #3F9EFF;
      margin-right: 6px;
    }
  }
  .scroll-icon{
    width: 44px;
    height: 44px;
    opacity: 0.3;
    margin-left: 10px;
    cursor: pointer;
  }
  .scroll-icon-left{
    margin: 0 10px 0 0;
  }
  .tags-view-wrapper {
    flex: 1;
    height: 100% !important;
    ::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb{
      display: none;
    }
    ::-webkit-scrollbar{
      height: 0
    }
    .tags-view-item {
      position: relative;
      .el-icon-close {
        width: 16px;
        height: 16px;
        line-height: 19px;
        border-radius: 50%;
        text-align: center;
        transition: all .3s cubic-bezier(.645, .045, .355, 1);
        transform-origin: 100% 50%;
        &:before {
          transform: scale(.8);
          display: inline-block;
          // vertical-align: -3px;
        }
        &:hover {
          background-color: #b4bccc;
          color: #fff;
        }
      }
      // &:first-of-type {
      //   margin-left: 10px;
      // }
      &:last-of-type {
        margin-right: 10px;
      }
      &.active {
        color: #409EFF;
      }
      .dot {
        display: inline-block;
        width: 6px;
        height: 6px;
        margin: 0 2px;
        border-radius: 100%;
        background: #409EFF;
        transform: translateY(-1px);
      }
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 3000;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style lang="scss">
</style>
