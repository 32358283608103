<template>
  <div class="navbar">
    <img class="logo" src="@/assets/home/logo-white.png" alt="geek+" />
    <div class="right-menu">
      <el-dropdown class="right-item" @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-s-custom"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item><i class="el-icon-s-custom"></i>{{userName}}</el-dropdown-item>
          <el-dropdown-item command="loginOut"><i class="el-icon-setting"></i>{{$t('lang.wms.fed.exitLogon')}}</el-dropdown-item>
          <el-dropdown-item command="changePassword"><i class="el-icon-edit"></i>{{$t('lang.wms.fed.modifyThePassword')}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown @command="handleLangCommand">
        <span class="el-dropdown-link">
          <img class="lang-icon" src="@/assets/home/icon-lang.png" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="item in langsList" :key="item.code" :command="item.code">{{item.name}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <full-screen class="right-item"/>
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-phone"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item style="width: 450px">
            <div class="fs-xxl pl-xl mb">{{$t('lang.wms.fed.customServiceHotOnline')}}</div>
            <div style="line-height: 24px">
              <div>{{$t("lang.wms.fed.globalEnglishHotOnline")}}</div>
              <div>{{$t("lang.wms.fed.globalChineseHotOnline")}}</div>
              <div>{{$t("lang.wms.fed.APACHotOnline")}}</div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!-- <div class="right-item" :class="{'is-active':isThemeShow}" v-if="false">
        <div class="thmen-btn" @click="themeCheck" @mousedown.stop>{{$t('lang.wms.fed.changeTheme')}}</div>
      </div> -->
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-question"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="use"><i class="el-icon-s-order"></i>{{$t('lang.wms.fed.dashboard.instructionManual')}}</el-dropdown-item>
          <el-dropdown-item command="version"><i class="el-icon-collection-tag"></i>{{$t('lang.wms.fed.version')}}：
            <div style="padding: 0 20px">
              <div></div>FrontEnd: {{version || '5.2'}}
              <div v-if="backEndversion">BackEnd: {{backEndversion}}</div>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <geek-theme-preview v-if="isThemeShow" @mousedown.stop/>
  </div>
</template>

<script>
import axios from 'axios'
import FullScreen from './FullScreen'
import { initLangs, handleGetCurLanguage } from '@/utils/langs';
import { loginOut, changePassword } from '@/api/home';
import { getVersion } from '@/utils/version';


export default {
  name: 'Navbar',
  components: { FullScreen },
  data() {
    return {
      sidebar: {
        opened: false
      },
      isThemeShow: false,
      langsList: [],
      version: '',
      userName: localStorage.getItem('userName'),
      backEndversion: ''
    }
  },
  mounted() {
    this.initData();
    document.body.addEventListener('mousedown', () => {
      this.isThemeShow = false
    })
  },
  methods: {
    // 初始化
    async initData() {
      this.langsList = await initLangs() || [];
      this.getBackVersion();
      this.version = localStorage.getItem('feVersion') || '';
      getVersion();
    },
    // 选择国际化
    handleLangCommand(e) {
      this.$store.state.homeLoading = true
      handleGetCurLanguage(e, true).finally(res => {
        this.$store.state.homeLoading = false
      })

    },
    themeCheck() {
      this.isThemeShow = !this.isThemeShow
    },
    handleCommand(command) {
      if (command == 'loginOut') loginOut.call(this)
      if (command == 'changePassword') changePassword.call(this)
    },
    getBackVersion() {
      axios({
        url: '/api_v1/basic/common/system/version',
        method: 'get'
      }).then(data => {
        this.backEndversion = data || '';
      })
        .catch(err => {
          this.backEndversion = ''
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  height: 50px;
  background: #000001;
  // box-shadow: 0 1px 4px rgba(0,21,41,.08);
  display: flex;
  justify-content: flex-start;
  .logo{
    margin: auto;
    width: 140px;
    height: 50px;
    margin-left: 5px;
    display: block;
    cursor: pointer;
  }
  .right-menu {
    margin-left: auto;
    // line-height: 50px;
    display: flex;
    align-items: center;
    color: #999;
    margin-right: 20px;
    img{
      margin-right: 10px;
      width: 30px;
      height: 30px;
    }
    .el-icon-question{
      font-size: 30px;
    }
    .el-icon-phone{
      font-size: 30px;
      margin-right: 10px;
    }
    .el-icon-s-custom{
      font-size: 22px;
      padding: 3px;
      border-radius: 50%;
      cursor: pointer;
      overflow: hidden;
      color: #000001;
      background: #606266;
    }
    .right-item {
      margin-right: 10px;
      cursor: pointer;
      &:hover, &.is-active {
        color: #409EFF;
      }
    }
  }
  .person-center {
    line-height: 50px;
    font-size: 15px;
    color: #999;
    cursor: pointer;
    font-family: Arial, "Microsoft Yahei", sans-serif;
    &:hover, &.is-active {
      color: #409EFF;
    }
  }
}
</style>
