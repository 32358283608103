/* eslint-disable no-tabs */

function download(url) {
    let iframe = null;
    iframe = document.createElement('iframe');
    iframe.setAttribute('id', 'download-iframe');
    iframe.style.display = 'none';
    iframe.onload = (e) => {
      let data = '';
      try {
        data = e.target.contentWindow.document.body.innerText && JSON.parse(e.target.contentWindow.document.body.innerText);
        if(data.msg==='toLogin'){
            const logoutUrl = localStorage.getItem('logoutUrl') || ''
            if (logoutUrl) {
                const url = window.location.href
                localStorage.removeItem('logoutUrl')
                window.location.href = `${logoutUrl}?redirect_uri=${encodeURIComponent(url)}`
            } else {
                if (process.env.NODE_ENV !== "development"){
                    window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
                }
            }
        }
      } catch (err) {
        console.error(err);
      }
    };
    iframe.src = url;
    console.log('url==', url);
    document.body.appendChild(iframe);
  }
  
  export default function exportList(params) {
    if (params.path) {
      download(params.path);
      return;
    }
    // eslint-disable-next-line no-throw-literal
    throw 'params must be Object';
  }
  