<template>
  <section class="app-main">
    <!-- <transition name="fade"> -->
      <keep-alive>
        <router-view v-if="$route.meta.cache && !pathIframe.includes($route.path)" :key="$route.fullPath" />
      </keep-alive>
      <router-view v-if="!$route.meta.cache && !pathIframe.includes($route.path)" :key="$route.fullPath" />
      <div class="geek-web-view" v-show="$route.path == '/station'">
        <iframe class="iframe" frameborder="0" :src="iframeUrl" id="iframeStation" width="100%" height="100%" :scrolling="scrolling"  v-if="showIframe" />
      </div>
      <div class="geek-web-view" v-show="$route.path == '/monitor/robotControl'">
        <iframe class="iframe" frameborder="0" :src="'/static/rms/#/monitor/robotControl?onlycontent=1'" id="iframeMap" width="100%" height="100%" :scrolling="scrolling"  v-if="showIframeMap" />
      </div>
    <!-- </transition> -->
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  options: {
    name: 'lang.component.webview',
    parents: ['all'], // all
  },
  props: {
    tagViews: {
      type: Array,
      default: () => {
        return []
      }
    },
    width: {
      type: String,
      label: 'lang.prop.width',
      default: '100%'
    },
    height: {
      type: String,
      label: 'lang.prop.height',
      default: '100%'
    },
    scrolling: {
      type: String,
      label: 'lang.prop.scroll',
      options: [{ label: 'lang.prop.auto', value: 'auto' }, { label: 'lang.prop.yes', value: 'yes' }, { label: 'lang.prop.yes', value: 'no' }]
    }
  },
  data () {
   return {
      iframeName: '',
      iframeMapName: '',
      iframeUrl: '',
      showIframe: false,
      showIframeMap: false,
      count: {
        '/station': 0,
        '/monitor/robotControl': 0
      },
      timer: {
        '/station': null,
        '/monitor/robotControl': null
      },
      stationKeepAliveTime: 0,
      pathIframe: ['/station', '/monitor/robotControl']
    }
  },
  computed: {
      // url() {
      //   if(['/strategicCenter/snStrategyManagement','/strategicCenter/cancelStrategyRouteConfig','/strategicCenter/shelfScheduleConfig'].includes(this.$route.path)){
      //     return window.location.origin + process.env.VUE_APP_STATION_PATH  + this.$route.path
      //   }
      //   return this.$route.query.url && `${this.$route.query.url}${this.$route.query.url.includes('?')?'&':'?'}v=${new Date().getTime()}`
      // },
      name() {
          return this.$route.query.tagsName
      }
  },
  watch: {
    $route: {
      handler(to, from) {
        // console.log('count watch', this.count, to, from);
        if (to.path === '/station') {
          this.showIframe = true;
          this.iframeUrl = this.url();
          this.iframeName = 'iframeStation';
        }else if (to.path === '/monitor/robotControl') {
          this.showIframeMap = true;
          this.iframeName = 'iframeMap';
        }
        this.$nextTick(() => {
          const iframe = document.getElementById(this.iframeName);
          console.log(this.iframeName, 'focus')
          if (iframe) {
            iframe.focus()
            iframe.onload = function () {
              if (to.path === '/monitor/robotControl') {
                // rms 老版本sso传递sessionId
                const sessionId = localStorage.getItem('GPS_SessionID');
                iframe && iframe.contentWindow.postMessage(
                  {'sessionId': sessionId}
                )
              }
              console.log(this.iframeName, 'focusOnload')
              iframe.focus()
            }
          }
          
        })
        if (!this.pathIframe.includes(to.path) || (this.pathIframe.includes(to.path) && from && this.pathIframe.includes(from.path))) {
          // 跳转其他后台页面 || 工作站和地图互跳
          if (from && from.path && this.pathIframe.includes(from.path)) {
            const config = JSON.parse(localStorage.getItem('SYSTEM_CONFIG_FE')) || {};
            // 保持工作站页面时长（分钟）
            this.stationKeepAliveTime = config.stationKeepAliveTime || 30;
            this.count[from.path] = 0;
            this.clearTimers(to.path);
            this.countTimeDuration(from.path);
          }
          return;
        }
        this.clearTimers(to.path);
      },
      immediate: true
    },
    tagViews: {
      handler(val) {
        if(val) {
          this.showIframeMap = val.filter(v => v.path === '/monitor/robotControl').length > 0;
          this.showIframe = val.filter(v => v.path === '/station').length > 0;
          console.log(this.showIframeMap, this.showIframe)
        }
      },
      deep: true
    }
  },
  mounted() {
    let p = this.$el.parentNode
    if (!p.className) {
      if (p.childElementCount > 1) {
        p.style.height = 'auto'
      } else {
        p.style.height = '100%'
      }
    }
    this.$nextTick(() => {
      const iframe = document.getElementById(this.iframeName);
      if (iframe) {
        iframe.focus()
        iframe.onload = function () {
          console.log(this.iframeName, 'focusOnload')
          iframe.focus()
        }
      }
    })
  },
  created() {
    //监听工作站iframe传入退出信息
    window.addEventListener('message', e => {
      if(e.data == 'loginOut'){
        // that.$refs.tagsView.closeSelectedTag({
        //   name: 'lang.wes.fed.station',
        //   path: '/station',
        //   meta: {}
        // })
        this.resetStation('/station');
      } else if (e.data === 'closeMap') {
        this.showIframeMap = false;
        this.resetStation('/monitor/robotControl');
      }
    });
    Object.keys(this.timer).forEach(v => {
      if (this.timer[v]) {
        clearTimeout(this.timer);
        this.timer[v] = null;
      }
      this.count[v] = 0;
    })
    const config = JSON.parse(localStorage.getItem('SYSTEM_CONFIG_FE')) || {};
    // 保持工作站页面时长（分钟）
    this.stationKeepAliveTime = config.stationKeepAliveTime || 30;
  },
  methods: {
    countTimeDuration(route) {
      // console.log('count dddd', this.$route.path, route);
      this.clearTimers(route);
      if (this.count[route] >= this.stationKeepAliveTime * 60) {
        console.log('count', this.count[route], route);
        this.resetStation(route);
        return;
      }
      if (this.timer[route]) return;
      this.timer[route] = setTimeout(() => {
        // console.log('count', this.count[route], route);
        this.count[route]++;
        this.countTimeDuration(route);
      }, 1000)
    },
    url() {
      if(['/strategicCenter/snStrategyManagement','/strategicCenter/cancelStrategyRouteConfig','/strategicCenter/shelfScheduleConfig'].includes(this.$route.path)){
        return window.location.origin + process.env.VUE_APP_STATION_PATH  + this.$route.path
      }

      return this.$route.query.url && `${this.$route.query.url}${this.$route.query.url.includes('?')?'&':'?'}v=${new Date().getTime()}`
    },
    resetStation(route) {
      this.clearTimers(route);
      this.count[route] = 0;
      if (route === '/station') {
        this.showIframe = false;
      } else if (route === '/monitor/robotControl') {
        this.showIframeMap = false;
      }
    },
    clearTimers(route) {
      if (route) {
        clearTimeout(this.timer[route]);
        this.timer[route] = null;
      }
    }
  },
  activated() {
    this.$nextTick(() => {
      const iframe = document.getElementById(this.iframeName);
      if (iframe) {
        console.log(this.iframeName, 'focus')
        iframe.focus()
        iframe.onload = function () {
          console.log(this.iframeName, 'focusOnload')
          iframe.focus()
        }
      }
    })
  },
  destroyed() {
    Object.keys(this.timer).forEach(v => {
      if (this.timer[v]) {
        clearTimeout(this.timer);
        this.timer[v] = null;
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.app-main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.fade-enter-active, .fade-leave-active {
  position: absolute;
  transition: opacity .1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.geek{
  height: 100% !important;
}
.geek-web-view {
  width: 100%;
  height: 100%;
}
</style>
