import axios from 'axios'
import { handleGetCurLanguage } from '@/utils/langs';


export const getVersion = () => {
  axios.create({ baseURL: '' })
    .get(`${process.env.VUE_APP_VIEW_PATH}version.txt?t=${new Date().valueOf()}`)
    .then(data => {
      const currentVersion = data.data;
      const currentLangVal = localStorage.getItem('curLanguage') || 'zh_cn';
      const feVersion = localStorage.getItem('feVersion') || '';
      if (feVersion != currentVersion) {
        localStorage.setItem('feVersion', currentVersion);
        localStorage.setItem('iframeReload', currentVersion);
        console.log('version:' + currentVersion);
        handleGetCurLanguage(currentLangVal, true);
      }
    }).catch(err => {
    })
}
