<template>
    <div class="geek-form-table" :class="$classes">
        <div class="geek-form-table-header">
            <div v-if="title" :class="{ title }">{{ $t(title) }}</div>
            <geek-flex-container class="actions" :gutter="10" v-if="actions && actions.length" width="100%">
                <geek-component v-for="(action, i) in actions" :data="action" :key="i + action.$id"></geek-component>
            </geek-flex-container>
        </div>
        <div class="geek-form-table-title">
            <div v-for="columns in wholeColumns" :key="JSON.stringify(columns)">{{$t(columns.label)}}</div>
        </div>
        <div v-if="valueProxy.length" class="geek-form-table-content">
            <div v-for="(item, index) in valueProxy" :key="index" class="geek-form-table-row">
                <div class="geek-form-table-row-body">
                    <div class="geek-form-table-child-row" v-for="(b, j) in item.children" :key="j">
                        <template v-for="columns in computedColumns">
                            <template v-if="[columns.$body].flat().filter(Boolean).length">
                                <template v-for="(d, k) in columns.$body">
                                    <geek-component :key="JSON.stringify(d)" :data="d" v-model="b[d.prop]" :index="k" :$scopeData="b" />
                                </template>
                            </template>
                        </template>
                        <div class="operation-btn">
                            <i class="el-icon-plus" @click="addChildItem(item, b)" v-if="!disabled && (limit > 0 && item.children.length < limit || !limit)"></i>
                            <i class="el-icon-minus" @click="removeChildItem(item, b)" v-if="!disabled"></i>
                        </div>
                    </div>
                    <i class="el-icon-error delete-icon" @click="removeItem(item)" v-if="!disabled"></i>
                </div>
                <div class="geek-split-row">
                    <el-select size="small" v-model="item.connector" :disabled="disabled" filterable>
                        <el-option v-for="(item, i) in connectorList" :key="i" :value="item.value" :label="$t(item.label)"></el-option>
                    </el-select>
                    <div class="geek-split-row-line"></div>
                </div>
            </div>
        </div>
        <div class="geek-form-table-footer">
            <geek-button size="small" type="primary" :text="$transMsg('lang.wms.fed.addRules')" @click="addNewGroup" :disabled="disabled || !!(ruleOption && valueProxy.length)"/>
        </div>
    </div>
</template>

<script>
import {API} from 'leivii'

export default {
  name: 'GeekFormTableByCustom',
  options: {
    name: '表达式表单-自定义非元数据',
    parents: ['geek-form'], // all
    groups: ['form'], // none
    events: {
      addNewGroup: 'lang.action.addItem',
      removeItem: 'lang.action.removeItem',
      addChildItem: 'lang.action.addItem',
      removeChildItem: 'lang.action.removeItem',
      change: 'lang.action.removeSelection',
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    $classes: {
      type: String,
      clazz: 'Theme',
      label: 'lang.prop.$classes',
      default: 'mb-sm'
    },
    title: {
      type: String,
      clazz: 'I18n',
      label: 'lang.prop.label',
      // ignore: true
    },
    prop: {
      type: String,
      label: 'lang.prop.field'
    },
    actions: {
      type: Array,
      itemClazz: 'ComponentDragger',
      label: 'lang.prop.actions'
    },
    columns: {
      type: Array,
      clazz: 'TableColumns',
      itemClazz: {
        name: {
          type: String,
          label: 'lang.prop.field'
        },
        label: {
          type: String,
          clazz: 'I18n',
          label: 'lang.prop.label'
        },
        minWidth: {
          type: Number,
          label: 'lang.prop.minWidth'
        },
        tooltip: {
          type: Boolean,
          label: 'lang.prop.tooltip'
        },
        expand: {
          type: Boolean,
          label: 'lang.prop.expandable',
          isExpression: true
        },
        fixed: {
          type: String,
          options: [{label: 'lang.prop.none', value: ''}, {label: 'lang.prop.left', value: 'left'}, {label: 'lang.prop.right', value: 'right'}],
          default: '',
          label: 'lang.prop.fixedColumn'
        },
        $body: {
          type: Array,
          itemClazz: 'ComponentDragger',
          label: 'lang.prop.$body'
        },
        type: {
          type: String,
          options: [{label: 'lang.prop.text', value: 'text'}, {label: 'lang.prop.time', value: 'time'}, {label: 'lang.prop.dict', value: 'dict'}, {label: 'lang.prop.expression', value: 'expression'}],
          label: 'lang.prop.type'
        },
        dict: {
          type: String,
          label: 'lang.prop.dict',
          visible: `'{{type}}' == 'dict'`
        },
        format: {
          type: String,
          label: 'lang.prop.format',
          visible: `'{{type}}' == 'time'`
        },
        expression: {
          type: String,
          isExpression: true,
          label: 'lang.prop.expression',
          visible: `'{{type}}' == 'expression'`
        }
      },
      label: 'lang.prop.column'
    },
    value: {
      type: Array,
      ignore: true
    },
    disabled: {
      type: Boolean,
      label: 'lang.prop.disable',
    },
    showMessage: {
      type: Boolean,
      label: '显示错误信息',
      default: false
    },
    ruleOption: {
      type: String,
      options: [{label: 'lang.wms.fed.onlyAnd', value: 'all'}, {label: 'lang.wms.fed.onlyOr', value: 'any'}],
      default: ''
    },
    limit: {
      type: Number,
      label: '限制数量',
      default: 0,
      visible: `!!'{{ruleOption}}'`
    },
  },
  computed: {
    list() { // datasource value
      let data = this.getDataSource()
      return  data|| []
    },
    computedColumns() {
      if (this.fitWidth) {
        return this.columns.map(column => ({ ...column, minWidth: this.itemWidth(column) }))
      }
      return this.columns
    },
    wholeColumns(){
      return [...this.computedColumns, {label: 'lang.wms.fed.operation'}]
    },
    valueProxy: {
      get() {
        return (this.prop && this.dataSource.form?.data[this.prop]) || this.list
      },
      set(v) {
        if (this.prop) {
          this.$emit('change', v)
          if (this.dataSource.form?.data) {
            this.$set(this.dataSource.form.data, this.prop, v)
          }
        }
      }
    },
  },
  watch: {
    list: {
      handler() {
        this.valueProxy = this.list
      },
      deep: true,
      immediate: true
    }
  },
  data() {
    return {
      loading: false,
      metaData: [],
      operationData: {},
      connectorList: [{label: this.$t('lang.wms.fed.AND'), value: 'all'}, {label: this.$t('lang.wms.fed.OR'), value: 'any'}],
      validateObject: {}
    }
  },
  async created() {
  },
  methods: {
    @API.doc(false)
    handleColumnProp () {
      const propArr = this.computedColumns.map(columns => columns.$body.filter(Boolean).map(b => b.prop)).flat()
      const propObj = propArr.reduce((accumulator, currentValue) => {
        accumulator[currentValue] = '';
        return accumulator;
      }, {});

      return propObj
    },
    @API.doc(false)
    addNewGroup(e){
      const propObj = this.handleColumnProp()
      this.valueProxy.push({connector: 'any', children: [{connector: 0, dataModel: {}, operator: '', key: '', val: '', ...propObj}]})
      this.$emit('addNewGroup', e);
      this.$forceUpdate()
    },
    @API.doc(false)
    addChildItem(e, child) {
      let that = this;
      const propObj = this.handleColumnProp()
      return new Promise((resolve, reject) => {
        let defaultConnector = that.ruleOption || 'all'
        let i = this.valueProxy.indexOf(e)
        if (i > -1) {
            let j = that.valueProxy[i].children?.indexOf(child);
            if(j > -1){
              that.valueProxy[i].children?.splice(j+1, 0, {dataModel: {},connector: defaultConnector, operator: '', key: '', val: '', ...propObj})
            }
            this.$emit('addChildItem', e)
        }
        resolve(e)
      })
    },
    @API.doc(false)
    removeChildItem(e, child) {
      let that = this;
      return new Promise((resolve, reject) => {
        return this.$confirm(this.$transMsg('lang.tip.delete'), this.$transMsg('lang.tip.warning')).then(() => {
          let i = that.valueProxy.indexOf(e)
            if (i > -1) {
                let j = that.valueProxy[i].children?.indexOf(child)
                if(j > -1){
                  if(that.valueProxy[i].children.length == 1){
                    this.valueProxy.splice(i, 1)
                  }else{
                    that.valueProxy[i].children?.splice(j, 1)
                  }
                }
              this.$forceUpdate()
              this.$message({ type: 'success', message: this.$transMsg('lang.tip.deleteSuccess') })
              this.$emit('removeChildItem', e)
            } else {
              this.$message({ type: 'warning', message: this.$transMsg('lang.tip.deleteFailure') })
            }
            resolve(e)
        })
      })
    },
    @API.doc(false)
    removeItem(e) {
      // TODO 添加行index删除方法
      return new Promise((resolve, reject) => {
        return this.$confirm(this.$transMsg('lang.tip.delete'), this.$transMsg('lang.tip.warning')).then(() => {
          let i = this.valueProxy.indexOf(e)
            if (i > -1) {
              this.valueProxy.splice(i, 1)
              this.$forceUpdate()
              this.$message({ type: 'success', message: this.$transMsg('lang.tip.deleteSuccess') })
              this.$emit('removeItem', e)
            } else {
              this.$message({ type: 'warning', message: this.$transMsg('lang.tip.deleteFailure') })
            }
            resolve(e)
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.geek-form-table{
  .relative {
    position: relative;
  }
    .geek-form-table-header{
        margin-bottom: 12px;
    }
    .geek-form-table-title{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        border-bottom: 1px solid #1296db;
        padding: 12px 0;
        margin-bottom: 12px;
        font-weight: bold;
        font-size: 14px;
        >div{
            flex: 1;
            margin-right: 20px;
        }
        > div:last-child{
            margin-right: 0;
        }
        //> div:nth-child(1){
        //    max-width: 120px;
        //}
    }
    .geek-form-table-content{
        padding-bottom: 12px;
        border-bottom: 1px solid #1296db;

        .geek-form-table-row{
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            margin-bottom: 70px;
            padding-right: 15px;
            &:last-child{
               margin-bottom: 0; 
               .geek-split-row{
                   display: none;
               }
            }
            &:first-child{
                .delete-icon{
                    top: -25px;
                }
            }
            .geek-form-table-row-connector{
                max-width: 100px;
                margin-right: 20px;
            }
            // &:first-child .geek-form-table-row-connector{
            //     visibility: hidden;
            // }
            .geek-form-table-row-body{
                // background: rgb(243,243,245);
                padding: 10px 20px 0;
                flex: 1;
                .geek-form-table-child-row{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;
                    margin-bottom: 22px;
                    >div{
                        flex: 1;
                        margin-right: 20px;
                        margin-bottom: 0 !important;
                    }
                    > div:last-child{
                        margin-right: 0;
                    }
                    .operation-btn{
                        min-width: 84px;
                        .el-icon-plus{
                            color: #1296db;
                            font-size: 18px;
                            padding: 10px;
                        }
                        .el-icon-minus{
                            color: #f10;
                            font-size: 18px;
                            padding: 10px;
                        }
                    }
                    &:first-child{
                        .geek-form-table-row-connector{
                            visibility: hidden;
                        }
                    }
                    
                }
            }
            .geek-split-row{
                display: flex;
                align-items: center;
                width: calc(100% - 40px);
                position: absolute;
                bottom: -60px;
                padding: 20px;
                .el-select{
                    width: 100px;
                    margin: 0;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .geek-split-row-line{
                    flex: 1;
                    height: 1px;
                    background: #1296db;
                }
            }
        }
        .delete-icon{
            position: absolute;
            color: #f10;
            right: 0;
            top: -42px;
            font-size: 24px;
            background: #fff;
        }
    }
    .geek-form-table-footer{
        display: flex;
        justify-content: center;
        margin: 16px 0;
    }
    .vis-hidden{
      visibility: hidden;
    }

}
</style>
<style lang="scss">
.tree-select {
  .el-tag.el-tag--info .el-tag__close {display: none}
  .el-select__tags-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.el-select .el-tag {
    display: flex;
    max-width: 100%;
    align-items: center;
  }

}

//.hidden-ellipsis .el-input__inner {
//  width: 140px;
//  white-space: nowrap;
//  text-overflow: ellipsis;
//  overflow: hidden;
//  &::-webkit-input-placeholder {
//
//    width: 140px;
//    white-space: nowrap;
//    text-overflow: ellipsis;
//    overflow: hidden;
//    color: red;
//
//    font-weight: 400;
//
//  }
//}
</style>