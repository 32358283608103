<template>
  <div class="geek">
     <el-card shadow="always" style="height:100%;">
        <div slot="header" class="clearfix">
          <span style="font-size: 24px; font-weight:bold">页面模板上传</span>
        </div>
        <el-form ref="formTemp" :label-position="'left'" label-width="80px" :model="form" class="form-wrapper">
          <el-form-item label="页面名称" prop="remark" :rules="[{required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
          <el-form-item label="页面代码" prop="permissionCode" :rules="[{required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input v-model="form.permissionCode"></el-input>
          </el-form-item>
          <el-form-item label="页面版本" prop="version" :rules="[{required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input v-model="form.version"></el-input>
          </el-form-item>
          <el-form-item label="" prop="version" label-width="160px">
            <el-upload
              accept=".txt"
              class="upload-demo"
              action="fakeaction"
              :auto-upload="true"
              :multiple="false"
              :on-change="uplaodChange"
              :before-upload="beforeUpload"
              :http-request="uploadAction">
              <el-button size="small" type="primary">选择模板文件</el-button>
            </el-upload>
              <span style="margin-left: 20px">文件名格式：页面名称_页面code_页面版本_导出时间 (例：<span style="color: blue">单据类型管理_orderTypeManage_1_2022-10-03 14_26_08.txt</span>)</span>
          </el-form-item>
          <el-form-item label="JSON" prop="content" class="item-flex-1" :rules="[{required: true, message: '不能为空', trigger: 'blur'}]">
            <el-input type="textarea" v-model="form.content" class="item-flex-1"></el-input>
              <!-- <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div> -->
          </el-form-item>
           <el-form-item >
            <el-button size="large" type="primary" @click="onSubmit('formTemp')">提交</el-button>
            <el-button size="large" @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
     </el-card>
  </div>
</template>

<script>
import { GET, PUT, POST } from '@/utils/http'

export default {
  data() {
    return {
      loading: false,
      data: null,
      id: this.$route.meta.id,
      meta: this.$route.meta,
      apolloPage: null,
      form: {
        remark: '',
        version: 1,
        content: '',
        permissionCode: ''
      }
    }
  },
  created() {
  },
  activated() {
  },
  deactivated() {
  },
  methods: {
    reset() {
      this.$refs.formTemp.resetFields();
      this.form = {
        remark: '',
        version: 1,
        content: '',
        permissionCode: ''
      }
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          PUT('/api/lowcode', {
            ...this.form,
            content: JSON.stringify(JSON.parse(this.form.content))
          }).then(data => {
            if (data.code == 0) {
              this.$message({ type: 'success', message: '模板更新成功' })
              // 启用
              PUT(`/api/lowcode/${this.form.permissionCode}/active?version=${this.form.version}`)
              this.reset();
            } else {
              // PUT('/api/lowcode', {
              //   ...this.form,
              //   content: JSON.stringify(this.form.content),
              // }).then(data => {
              //   if (data.code == 0) {
              //     this.$message({ type: 'success', message: '模板更新成功' })
              //   } else {

              //   }
              // }).catch(e => console.error(e)).finally(() => loading.close())
              this.createTemp();
            }
          }).catch(e => console.error(e)).finally(() => {})
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    createTemp() {
      POST('/api/lowcode', {
        ...this.form,
        content: JSON.stringify(JSON.parse(this.form.content))
      }).then(data => {
        if (data.code == 0) {
          this.form.version = data.data.version;
          // 启用
          PUT(`/api/lowcode/${this.form.permissionCode}/active?version=${this.form.version}`)
          this.$message({ type: 'success', message: '创建成功' })
          this.reset();
        } else {
          this.$message({ type: 'error', message: data.msg || '创建失败' })
        }
      })
    },
    uplaodChange(file) {
      console.log(file);
    },
    beforeUpload(file) {
      console.log(file);
      const arr = file.name?.split('_');
      this.form.remark = arr[0];
      this.form.permissionCode = arr[1];
      this.form.version = 1;
      let reader = new FileReader();
      reader.readAsText(file, 'UTF-8');
      let _that = this;
      reader.onload = function(e) {
        console.log(e);
        _that.form.content = e.target.result || '';
      }

    },
    uploadAction(file) {
      console.log(file, 'file')
    }
  }
}
</script>

<style lang="scss" scoped>
.geek {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
}
.el-form  {
  margin: auto;
  width: 60%;
}
.el-card{
  display: flex;
  flex-direction: column;
  flex:1;
  ::v-deep .el-card__body{
    display: flex;
    flex-direction: column;
    flex:1;

  }
}
.form-wrapper{
  display: flex;
  flex-direction: column;
  flex:1;
  .el-form-item{
    display: flex;
    flex-direction: row;
    ::v-deep .el-form-item__content{
      display: flex;
      flex:1;
    }
  }
  .el-input{
    display: flex;
    flex: 1;
  }
}
.item-flex-1{
  display: flex;
  flex: 1;

}
</style>
