<template>
  <div class="geek-select-color-bar">
    <el-form-item class="geek-select geek-form-item" :label="$transMsg(label)" :prop="prop">
      <el-color-picker v-model="valueProxy" :predefine="colors" :disabled="disabled"></el-color-picker>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'GeekSelectColorBar',
  options: {
    name: '颜色选择器',
    parents: ['geek-form'], // all
    groups: ['form'], // none
    events: {
      change: 'lang.action.change'
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    $classes: {
      type: String,
      clazz: 'Theme',
      label: 'lang.prop.$classes',
      default: 'mb-sm'
    },
    label: {
      type: String,
      clazz: 'I18n',
      label: 'lang.prop.label'
      // ignore: true
    },
    prop: {
      type: String,
      label: 'lang.prop.field'
    },
    disabled: {
      type: Boolean,
      isExpression: true,
      default: null,
      label: 'lang.prop.disable'
    },
    colors: {
      type: Array,
      default: () => {
        return ['#D0EEFF', '#A4DEFF', '#57FFE5', '#73FFB8', '#78FA5E', '#C8FF69', '#F3FE97', '#FFF36C', '#FCCF47', '#FCAC57']
      }
    },

    value: {
      type: [String, Number],
      isExpression: true,
      label: 'lang.prop.default',
      default: '#D0EEFF'
    }
  },
  computed: {
    valueProxy: {
      get() {
        if (this.prop) {
          if (this.$scopeData) {
            return this.$scopeData?.[this.prop] ?? this.value
          }
          return this.dataSource.form?.data[this.prop] ?? this.value
        }
      },
      set(v) {
        if (this.prop) {
          this.$emit('change', v)
          if (!this.$scopeData && this.dataSource.form?.data) {
            this.$set(this.dataSource.form.data, this.prop, v)
            this.$set(this.get(this.dataSource.form.id).form, this.prop, v)
            this.get(this.dataSource.form.id).setDataSource('form', this.dataSource.form)
          }
        }
      }
    }
  }

}
</script>
<style lang="scss">
.geek-select-color-bar {
  .el-color-picker{
    display: flex;
  }
  .el-color-picker__trigger {
    width: 80px;
    display: flex;

  }
  .el-color-picker__mask {
    width: 78px;
  }

}
.geek-select-color-bar__item {
  display: inline-block;
  width: 100%;
  height: 100%;
}
</style>
