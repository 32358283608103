<template>
  <el-form :model="ruleForm" ref="ruleForm" label-width="150px" class="demo-ruleForm">
    <el-form-item :label="$transMsg('lang.wms.fed.limit')" prop="region">
      <el-select :clearable="true" v-model="ruleForm.autoJudgeRule.config.statisfyType" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
        <el-option :label="$transMsg('lang.wms.fed.whole')" value="0"></el-option>
        <el-option :label="$transMsg('lang.wms.fed.either')" value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-button type="primary" icon="el-icon-plus" @click="add">{{$transMsg('lang.wms.fed.newlyAdded')}}</el-button>
    <el-form-item :label="$transMsg('lang.wes.fed.option')" v-for="(item,index) in ruleForm.autoJudgeRule.config.ruleItems" :key="index">
      <el-row :gutter="20">
        <el-col :span="5">
          <el-select :clearable="true" v-model="item.field" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
            <el-option v-for="(item,index) in fieldList" :key="index"  :label="item.fieldDescription" :value="item.fieldName" ></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select :clearable="true" v-model="item.symbol" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
            <el-option label=">" value=">"></el-option>
            <el-option label="=" value="="></el-option>
            <el-option label="<" value="<"></el-option>
            <el-option label="<=" value="<="></el-option>
            <el-option label=">=" value=">="></el-option>
            <el-option label="!=" value="!="></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select :clearable="true" v-model="item.type" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')">
            <el-option :label="$transMsg('lang.wms.fed.value')" value="0"></el-option>
            <el-option :label="$transMsg('lang.wms.fed.fieldName')" value="1"></el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-select :clearable="true" v-model="item.target" :placeholder="$transMsg('lang.wms.fed.pleaseChoose')" v-if="item.type === '1'">
            <el-option v-for="(item,index) in fieldList" :key="index"  :label="item.fieldDescription" :value="item.fieldName" ></el-option>
          </el-select>
          <el-input v-model="item.target" v-else></el-input>
        </el-col>
        <el-col :span="3">
          <el-button type="danger" icon="el-icon-delete" circle @click="del($event,item, index)"></el-button>
        </el-col>
      </el-row>
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  data() {
    return {
      ruleForm: {
        autoJudgeRule: {
          executeType: 'default',
          config: {
            ruleItems: [
              {
                type: '0',
                target: 'true',
                field: 'mark',
                symbol: '='
              }
            ],
            statisfyType: '0'
          }
        }
      }
    };
  },
  props: {
    fieldList: {
      type: Array,
      default: () => []
    },
    ruleData: {
      type: Object,
      default: () => {}
    }
  },
  // created() {
  //
  // },
  // watch: {
  //   ruleData(val) {
  //     this.ruleData = Object.assign({},val);
  //     console.log('ruleData', val)
  //   }
  // },
  methods: {
    add() {
      this.ruleForm.autoJudgeRule.config.ruleItems.push({
        type: '0',
        target: 'true',
        field: 'mark',
        symbol: '='
      })
    },
    del(e, item, index) {
      this.ruleForm.autoJudgeRule.config.ruleItems.splice(index, 1)
      console.log(e, item, index);
    }
  }
};
</script>

<style scoped>

</style>
