<template>
  <div class="geek geek-homepage" v-loading="loading" >
    <div class="geek-grid-row geek-grid-row-top">
      <!-- 模块一 /常用功能 -->
      <div class="geek-grid-row-item geek-grid-row-left">
        <div class="home-model-title">
          {{$t('lang.wms.fed.dashboard.commonFunctions')}}
          <i v-if="isCommonEditor" class="el-icon-success" @click="handleCommonFun"></i>
          <i v-else class="el-icon-edit-outline" @click="handleCommonFun"></i>
        </div>
        <div class="admin-menu-model">
          <div
            v-for="item in commonMenu.filter((res, i) => i< 12)"
            :key="JSON.stringify(item)"
            class="admin-menu-model-item commonModel"
            :class="{isDisabled: !menuCodeList.includes(item.path)}"
            @click="handleRouterSelect(item)"
          >
            <i v-if="isCommonEditor" class="el-icon-remove" @click="deleteCommon(item)"></i>
            <i class="el-icon-star-off"></i>
            <span>{{ $t(item.name) }}</span>
          </div>
          <div v-if="isCommonEditor" class="admin-menu-model-item-add" @click="addCommonFunc">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <!-- 模块二 /用户信息 -->
      <div class="geek-grid-row-item geek-grid-row-right">
        <div class="avatar-model">
          <img class="avatar-icon" src="../assets/home/admin.png" />
          <div class="avatar-text">
            <span>{{userName}}</span>
            <div class="avatar-text-identity">
              <span>{{$t('lang.wms.fed.dashboard.yourCurrentIdentity')}}</span>
              <el-tooltip class="item" effect="dark" :content="userRole" placement="right">
                  <span class="over-flow">{{userRole}}</span>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="today-info" @click="handleTodayProcess">
            <template v-if="hasTodayProcess">
                <p>{{$t('lang.wms.fed.dashboard.youHaveToday')}}</p>
                <div class="geek-flex-row">
                    <div class="today-data-model">
                        <el-tooltip class="overflow-txt" effect="dark" :content="$t('lang.wms.fed.dashboard.finishPicking')" placement="top">
                          <div>{{$t('lang.wms.fed.dashboard.finishPicking')}}</div>
                        </el-tooltip>
                      <div><Strong>{{completeCount.picking}}</Strong><span> {{$t('lang.wms.fed.piece')}}</span></div>
                    </div>
                    <div class="today-data-model">
                        <el-tooltip class="overflow-txt" effect="dark" :content="$t('lang.wms.fed.dashboard.finishReplenishment')" placement="top">
                          <div>{{$t('lang.wms.fed.dashboard.finishReplenishment')}}</div>
                        </el-tooltip>
                      <div><Strong>{{completeCount.putaway}}</Strong><span> {{$t('lang.wms.fed.piece')}}</span></div>
                    </div>
                    <div class="today-data-model">
                        <el-tooltip class="overflow-txt" effect="dark" :content="$t('lang.wms.fed.dashboard.finishStockTake')" placement="top">
                          <div>{{$t('lang.wms.fed.dashboard.finishStockTake')}}</div>
                        </el-tooltip>
                      <div><Strong>{{completeCount.stocktake}}</Strong><span> {{$t('lang.wms.fed.piece')}}</span></div>
                    </div>
                </div>
            </template>
            <div class="noData" v-else>
                <div>{{$t('lang.wms.fed.dashboard.todayIs')}}{{today}}</div>
                <div>{{$t('lang.wms.fed.dashboard.goodLuckToday')}}</div>
            </div>
        </div>
      </div>
    </div>
    <div class="geek-grid-row geek-grid-row-bottom">
      <!-- 模块三 /工作站 -->
      <div class="geek-grid-row-item geek-grid-row-left">
        <div class="home-model-title">
          <span>{{$t('lang.wms.fed.dashboard.currentChoosenStation')}}</span>
          <strong class="home-current-stationid">{{$store.state.stationId}}</strong>
            <!-- <div class="station-model-item" v-if="$store.state.stationId"> -->
            <!-- </div> -->
        </div>
        <div class="home-model-title">
            <span>{{$t('lang.wms.fed.commonStation')}}</span>
            <div class="el-icon-con">
              <i v-if="isCommonStationIdEditor&&$store.state.commonStationId.length" class="el-icon-success" @click="handleStationIdSelect('success')"></i>
              <i v-if="!$store.state.commonStationId.length||!isCommonStationIdEditor" class="el-icon-edit-outline" @click="handleStationIdSelect('edit')"></i>
            </div>
            <div class="admin-menu-model">
              <div
                v-for="(item,index) in $store.state.commonStationId"
                :key="index"
                style="width:140px"
                class="admin-menu-model-item"
                @click="handleChooseCommonStation(item)"
              >
                <i v-if="isCommonStationIdEditor" class="el-icon-remove" @click.stop="deleteCommonStationId(item)"></i>
                <i class="el-icon-star-off"></i>
                <span>{{ $t(item) }}</span>
              </div>
              <div v-if="isCommonStationIdEditor&&$store.state.commonStationId.length<4&&$store.state.commonStationId.length!==0" class="admin-menu-model-item-add" @click="handleStationIdSelect('moreCommon')">
                <i class="el-icon-plus"></i>
              </div>
            </div>
            <div class="home-model-title-station">
            <el-button type="text" @click="handleStationIdSelect('more')">{{$t('lang.wms.fed.dashboard.moreStation')}}</el-button>
          </div>
        </div>
        <!-- 通用工作站模块 -->
        <div class="geek-flex-row" v-if="$auth('common')">
          <span>{{ $t("lang.wms.fed.beInCommonUse") }}</span>
          <div class="admin-menu-model">
            <template v-for="(item, index) of commonStationList">
              <div v-if="$auth(item.code)"
                :key="index"
                class="admin-menu-model-item station-menu-model"
                :loginStr="$t('lang.wms.fed.dashboard.currentLogin')"
                :class="{isLogin: currentStation == item.value}"
                @click="handleStationSelect(item)"
              >
                <i :class="item.icon"></i>
                <span>{{ $t(item.name) }}</span>
              </div>
            </template>
          </div>
        </div>
        <!-- 货箱通用工作站模块 -->
        <div class="geek-flex-row" v-if="$auth('totecommon')">
          <span>{{ $t("lang.wms.common.permission.totecommon") }}</span>
          <div class="admin-menu-model">
            <template v-for="(item, index) of commonBoxPickStationList">
              <!-- 源箱直上菜单模式如果配置了2种 -->
              <template v-if="item.code === 'totecommon:rootContainerUp' && $auth(item.code) && rootContainerUpWorkMode.length > 1">
                <el-dropdown :key="index" @command="handleCommand($event, item)">
                  <div class="admin-menu-model-item station-menu-model station-menu-model__containerUp"
                       :loginStr="$t('lang.wms.fed.dashboard.currentLogin')"
                       :class="{isLogin: currentStation == item.value}"
                  >
                    <i :class="item.icon"></i>{{ $t(item.name) }}
                  </div>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="YXZS">{{$t('lang.wes.fed.rootContainerUp')}}</el-dropdown-item>
                    <el-dropdown-item command="SSYT">{{$t('lang.wms.fed.receiptOrderPutaway')}}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>

              <div
                v-else-if="$auth(item.code)"
                :key="index"
                class="admin-menu-model-item station-menu-model"
                :loginStr="$t('lang.wms.fed.dashboard.currentLogin')"
                :class="{isLogin: currentStation == item.value}"
                @click="handleStationSelect(item)"
              >
                <i :class="item.icon"></i>
                <span>{{ $t(item.name) }}</span>
              </div>
            </template>
          </div>
        </div>
        <!-- poppick工作站模块 -->
        <div class="geek-flex-row" v-if="$auth('poppick')">
          <span>{{ $t("lang.ws.site.std.fe.popPickStation") }}</span>
          <div class="admin-menu-model">
            <template v-for="(item, index) of popPickStationList">
              <div
                v-if="$auth(item.code)"
                :key="index"
                class="admin-menu-model-item station-menu-model"
                :loginStr="$t('lang.wms.fed.dashboard.currentLogin')"
                :class="{isLogin: currentStation == item.value}"
                @click="handleStationSelect(item)"
              >
                <i :class="item.icon"></i>
                <span>{{ $t(item.name) }}</span>
              </div>
            </template>
          </div>
        </div>
      </div>
      <!-- 模块四 /消息提醒 -->
      <div class="geek-grid-row-item geek-grid-row-right">
        <div class="home-model-title">{{ $t("lang.wms.fed.homePage.notification") }}</div>

        <div class="error-msg-model">
          <div v-for="item in msgList" :key="JSON.stringify(item)" class="error-msg-item" :class="{hasPermission: !item.hasPermission}" @click="handleErrorMsg(item)">
            <el-image v-if="item.type == 2" :src="require('../assets/home/lock-bin.png')" fit="contain" />
            <el-image v-if="item.type == 1" :src="require('../assets/home/lock-package.png')" fit="contain" />
            <el-image v-if="item.type == 3" :src="require('../assets/home/lock-shelf.png')" fit="contain" />
            <div v-html="item.html"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 模式切换 -->
    <el-dialog :visible.sync="showModeSwitch" class="station-item station-dialog" width="580px" :close-on-click-modal="false" :show-close="false">
      <div v-if="checkData.switchCode == 1" class="poppick-switch-mode">
        <h2>{{$t('lang.wms.fed.stationModeSwitchWaiting')}}</h2>
        <div v-for="items in dialogMode" :key="items.value" class="poppick-switch-mode-txt" :class="{active: items.value == checkData.switchMode}" >
          {{ items.name }}
        </div>
      </div>
      <div v-else class="poppick-switch-mode">
        <h2>{{$t('lang.wms.fed.stationModeSwitchError')}}</h2>
        <div class="poppickPoint">
          <div class="poppickPoint-item" v-for="item in Object.keys(checkData.CHANGE_PARK_BUS_MODEL)" :key="JSON.stringify(item)">
            <div v-if="checkData.CHANGE_PARK_BUS_MODEL[item] != 0" class="poppickPoint-item-box isFail"><i class="el-icon-warning"></i> {{$t('lang.wms.fed.stationModeSwitchFail')}}</div>
            <div v-else class="poppickPoint-item-box"><i class="el-icon-success"></i> {{$t('lang.wms.station.web.StationController.item0157')}}</div>
            <div>{{item}}</div>
          </div>
        </div>
        <div v-for="item in errorMsgArr" :key="item" class="errorMsg">{{$t('lang.wms.common.exceptionCode')}}：{{item.label}},  {{$t(item.name)}}</div>
        <div class="poppickPoint-footer">
          <el-button type="info" @click="showModeSwitch = false">{{$t("lang.wms.fed.exit")}}</el-button>
          <el-button type="primary" @click="handleReTry">{{$t("lang.wms.fed.determine")}}</el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 工作站选择弹窗 -->
    <StationSelect v-if="$store.state.isShowStation" :item="loginInData" :commonStationIdArr="$store.state.commonStationId"
    :isSearchSetStationId="isSearchSetStationId" :isCommonShowStation="isCommonShowStation" @loginInRootContainerUp="handleStationSelectRootContainerUp" @loginIn="handleStationSelect" @setCommonStation="handleSetCommonStation" @close="getSiteEntity"></StationSelect>
  </div>
</template>

<script>
import axios from 'axios'
import { GET, POST } from '@/utils/http'
import { getSystemParams, getCommonFunList, getSiteList, delCommonFun, getUserRole, getStationComplete, loginOut } from '@/api/home'
import { format } from '@/utils/dataFormat'
import StationSelect from './layouts/components/StationSelect.vue';

export default {
  components: { StationSelect },
  data() {
    return {
      loading: false,
      isLoading: false,
      config: null,
      isCommonEditor: false,
      isCommonStationIdEditor: false,
      isCommonShowStation:false,
      isSearchSetStationId:false,
      // commonStationId:'',
      localCommonStationId:'',
      hasTodayProcess: false,
      isShowTooltip: false,
      userName: localStorage.getItem('userName'),
      currentStation: '',
      commonMenu: [],
      iconList: [
        'el-icon-tickets',
        'el-icon-document',
        'el-icon-document-add',
        'el-icon-notebook-1',
        'el-icon-set-up',
        'el-icon-location'
      ],
      commonStationList: [
        { value: '3', type: 'putaway', path: 'wes/putaway', name: 'lang.wms.fed.theShelves', icon: 'el-icon-sell', code: 'common:putaway' },
        { value: '1', type: 'pick', path: 'wes/pick', name: 'lang.wms.fed.pickup', icon: 'el-icon-shopping-cart-2', code: 'common:picking' },
        { value: '2', type: 'stockTake', path: 'wes/stockTake', name: 'lang.wms.fed.stocktake', icon: 'el-icon-notebook-2', code: 'common:stocktake' },
        { value: '8', type: 'receipt', path: 'wes/receipt', name: 'lang.wms.fed.receipt', icon: 'el-icon-receiving', code: 'common:receipt'},
      ],
      commonBoxPickStationList: [
        {value: '4', type: 'DIALOG', path:'wes/rootContainerUp', name: 'lang.wes.fed.rootContainerUp', icon: 'el-icon-suitcase', code: 'totecommon:rootContainerUp'},
        {value:'7', type: 'ECL_IN', path:'wes/emptyContainerEntrance', name: 'lang.wes.fed.emptyContainerEntrance', icon: 'el-icon-box', code: 'totecommon:emptyContainerEntrance'},
        {value:'9', type: 'EMPTY_OUT', path:'wes/emptyContainerOut', name: 'lang.wes.fed.emptyContainerOut', icon: 'el-icon-suitcase-1', code: 'totecommon:emptyContainerOut'},
        // {value:'11', type: 'CONTAINER_ADJUSTMENT', path:'wes/containerAdjustment', name: 'lang.wms.common.permission.ContainerAdjustment', icon: 'el-icon-receiving', code: 'poppick:containerAdjust'},
      ],
      popPickStationList: [
        {value:'11', type: 'CONTAINER_ADJUSTMENT', path:'wes/containerAdjustment', name: 'lang.wms.common.permission.ContainerAdjustment', icon: 'el-icon-receiving', code: 'poppick:containerAdjust'},
        {value:'21', path:'wes/upAndDown', name: 'lang.wes.fed.site.type.upDownIntegration', icon: 'el-icon-sort', code: 'poppick:upDownIntegration'}
      ],
      dialogMode: [
        { name: this.$t('lang.wms.fed.stationModeSwitchTransferMode'), value: 'TRANSFER' },
        { name: this.$t('lang.wms.fed.stationModeSwitchModeMulti'), value: 'MULTIPLE' },
        { name: this.$t('lang.wms.fed.stationModeSwitchModeEnter'), value: 'ENTER' }
      ],
      userRole: '',
      completeData: [],
      showModeSwitch: false,
      checkData: {
        CHANGE_PARK_BUS_MODEL: { }
      },
      today: '',
      loginInData: {},
      msgList: [],
      menuCodeList: [],
      rootContainerUpWorkMode: [] // 源箱直上工作模式
    };
  },
  watch: {
    '$store.state.commonMenu': {
      handler(v) {
        this.commonMenu = v;
        localStorage.setItem('commonMenu', JSON.stringify(v || []));
      },
      immediate: true,
      deep: true
    },
    '$store.state.stationId': {
      handler(v) {
        this.getSiteData();
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    errorMsgArr() {
      let arr = [];
      if (!this.checkData?.CHANGE_PARK_BUS_MODEL) return arr
      for (let item in this.checkData?.CHANGE_PARK_BUS_MODEL) {
        if (!arr.includes(this.checkData?.CHANGE_PARK_BUS_MODEL[item])) arr.push(this.checkData?.CHANGE_PARK_BUS_MODEL[item])
      }
      return arr.map(res => ({ name: this.checkData[res], label: res })) || [];
    },
    stationList(){
      return [].concat(this.commonStationList, this.popPickStationList,this.commonBoxPickStationList)
    },
    completeCount() {
      let obj = {};
      this.completeData?.map(res => {
        obj[res.putaway] = res.amount;
      });
      return obj || {}
    }
  },
  created() {
    this.$store.state.stationId = localStorage.getItem('stationId') || '';
    this.localCommonStationId = localStorage.getItem('commonStationId')|| '';
    this.$store.state.commonStationId = this.localCommonStationId?this.localCommonStationId.split(','):'';
    this.today = format(new Date(), 'YYYY-MM-DD');
    this.getMenuCodeList()
  },
  mounted() {
    this.initPage();
  },
  activated() {
    this.getSiteData();
    this.getCommonFunList();
    this.initErrorMgs();
  },
  deactivated() {
    this.errorMsgTimer && clearInterval(this.errorMsgTimer)
  },
  methods: {
    getMenuCodeList() {
      this.menuCodeList = JSON.parse(localStorage.getItem('menuCodeList')) || [];
      console.log(this.menuCodeList)
    },
    // 获取系统参数，判断是否展示数据
    async getSystemParams() {
      // {paramKeyLike: 'base_work_info_show'}
      let res = await getSystemParams({ paramKeyLike: 'base_work_info_show' });
      if (!res.code) {
        this.hasTodayProcess = res.data?.recordList?.find(res => res.paramKey == 'base_work_info_show')?.paramValue == 'true';
      }
    },
    // 获取工作站编码
    async getSiteCodeList() {
      let res = await getSiteList();
      let stationIdList = res.data || [];
      this.$store.state.stationIdList = stationIdList.sort((a, b) => a - b);
    },
    // 查询查用功能
    async getCommonFunList() {
      let res = await getCommonFunList();
      this.commonMenu = res.data || [];
    },
    // 编辑常用功能
    handleCommonFun() {
      this.isCommonEditor = !this.isCommonEditor
    },
    // 删除常用功能
    async deleteCommon(item) {
      let res = await delCommonFun(item.id);
      if (!res.code) {
        this.commonMenu = this.commonMenu.filter(res => res.path != item.path);
        localStorage.setItem('commonMenu', JSON.stringify(this.commonMenu || []));
      }
    },
    // 添加常用功能
    addCommonFunc() {
      this.$store.state.isAddCommon = true
      this.$store.state.isShowMenu = true
    },
    // 获取用户角色
    async getUserRole() {
      let res = await getUserRole();
      this.userRole = res.data || ''
    },
    // 获取完成数据
    async getStationComplete() {
      let res = await getStationComplete();
      this.completeData = res.data || [];
    },
    // 切换今日数据
    handleTodayProcess() {
      // this.hasTodayProcess = !this.hasTodayProcess;
    },
    getQueryString(name) {
      let r = null
      const param = window.location.hash.split('?')[1]
      const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
      if (param) {
        r = param.match(reg)
      }
      if (r !== null) return unescape(r[2])
      return null
    },
    // 页面跳转
    handleRouterSelect(ele) {
      if (this.isCommonEditor) return
      if (!this.menuCodeList.includes(ele.path)) return
      this.$router.push({ path: ele.path });
    },
    // 初始化数据
    initPage() {
      this.getSiteEntity ()
      this.getSiteData();
      this.getSiteCodeList();
      this.getCommonFunList();
      this.getUserRole();
      this.getStationComplete();
      this.getSystemParams();
    },
    async getSiteEntity() {
      if (!this.$store.state.stationId) return;
      await axios
        .get(`/api/common/stdStation/rest/site/getSiteEntity/v1?siteCode=${this.$store.state.stationId}`)
        .then(res => {
          this.rootContainerUpWorkMode = res?.data?.taskConfig?.FCL_IN?.taskConfig.workMode || []
        })
    },
    // 获取当前用户登录工作站
    async getSiteData() {
      if (!this.$store.state.stationId) return;
      let res = await POST('/api/common/connector/rest/site/status/v1', { siteCode: this.$store.state.stationId });
      this.currentStation = res?.data?.currentFunctionType;
      localStorage.setItem("curTransactionType", res?.data?.currentFunctionType);
    },
    handleChooseCommonStation(code){
      this.$store.state.stationId = code;
      localStorage.setItem('stationId', code);
    },
    deleteCommonStationId(stationId){
      let arrStationId = localStorage.getItem('commonStationId').split(',');
      localStorage.setItem('commonStationId', arrStationId.filter((item)=>item!==stationId).join(','));
      this.$store.state.commonStationId = localStorage.getItem('commonStationId').length?localStorage.getItem('commonStationId').split(','):[];
      this.$forceUpdate()
    },
    // 工作站模块切换
    handleStationTypeChange({ type }) {
      let orderReceiveMode = 0;
      switch (type) {
      case 'pick':
        orderReceiveMode = localStorage.getItem('pick.orderReceiveMode')
        break
      case 'stockTake':
        orderReceiveMode = localStorage.getItem('stockTake.orderReceiveMode')
        break
      case 'putaway':
      case 'EMPTY_OUT':
        orderReceiveMode = localStorage.getItem('putaway.orderReceiveMode')
        break
        // orderReceiveMode 暂时写死
      case 'FCL_IN':
        orderReceiveMode = '0'
        break
      case 'ECL_IN':
        orderReceiveMode = '0'
        break
      case 'FCL_OUT':
        orderReceiveMode = '1'
        break
      case 'CONTAINER_ADJUSTMENT':
        orderReceiveMode = '1'
        break
      }
      return orderReceiveMode;
    },
    //选择工作站id
    handleStationIdSelect(str){
      if(!this.$store.state.commonStationId.length||str==='moreCommon'){
        this.isCommonShowStation = true
        this.loginInData = {};
        this.isSearchSetStationId = true;
        this.$store.state.isShowStation = true
      }
      if(str==='more'){
        this.isCommonShowStation = false
        this.loginInData = {};
        this.isSearchSetStationId = false;
        this.$store.state.isShowStation = true
      }
      if((str==='edit'||str==='success')){
        this.isCommonStationIdEditor = !this.isCommonStationIdEditor
      }
    },
    handleSetCommonStation(arr){
      localStorage.setItem('commonStationId',arr.join(','))
      this.$store.state.commonStationId = arr;
      this.isCommonStationIdEditor = false;
    },
    // 源箱直上的工作模式额外处理
    async handleStationSelectRootContainerUp(item) {
      await this.getSiteEntity()

      if (this.rootContainerUpWorkMode.length < 2) {
        this.handleStationSelect(item)
      } else {
        this.$confirm('', this.$t('lang.wms.fed.pleaseChoose') + this.$t('lang.wms.fed.stationWorkMode'), {
          confirmButtonText: this.$t('lang.wms.fed.receiptOrderPutaway'),
          cancelButtonText: this.$t('lang.wms.fed.containerToPeople'),
          type: 'warning',
          showClose: false,
          closeOnClickModal: false,
          center: true
        })
          .then(() => {
            this.handleStationSelect({...item, alise: 'receipt'})
          })
          .catch(() => {
            this.handleStationSelect(item)
          });
      }
    },
    // 选择工作站
    async handleStationSelect(item) {
      this.loginInData = item;
      if (!this.$store.state.stationId) {
        this.$store.state.isShowStation = true
        return;
      }
      if (this.currentStation != item.value && this.currentStation) {
        let current = this.stationList.find(res => res.value == this.currentStation)
        this.$message.error(this.$t('lang.wms.station.web.StationController.item0164', [this.$t(current?.name)]))
        return
      }
      if (this.isLoading) return;
      this.isLoading = true;

      let that = this;
      this.orderReceiveMode = this.handleStationTypeChange(item);
      let res = await this.checkPopPickModeSwitch({ siteCode: this.$store.state.stationId, functionType: item.value });

      // 当返回的switchCode == 1时，定时轮询
      async function IntervalCheck() {
        let result = await that.getPopPickModeSwitchCallBack({ siteCode: that.$store.state.stationId, functionType: item.value });
        if (result.data.switchCode == 0) {
          that.showModeSwitch = false;
          that.loginStation(item);
        } else if (result.data.switchCode == 2) {
          that.showModeSwitch = true;
          that.isLoading = false;
          that.checkData = result.data || {};
        } else if (result.data.switchCode == 1) {
          setTimeout(IntervalCheck, 1000)
        } else {
          that.showModeSwitch = false;
          that.isLoading = false;
          that.$message.error(that.$transMsg(result.data.errorMsg))
        }
      }

      if (res.code == 0) {
        if (res.data.switchCode == 0) {
          this.loginStation(item);
          return
        } else if (![0, 1, 2].includes(res.data.switchCode)) {
          this.isLoading = false;
          this.$message.error(this.$transMsg(res.data.errorMsg))
          return
        } else if (res.data.switchCode == 1) {
          IntervalCheck()
        }
        this.isLoading = false;
        this.showModeSwitch = true;
        this.checkData = res.data || {};
        return
      }
      this.isLoading = false;
    },
    // 选择源箱直上下拉框
    handleCommand (e, item) {
      if (e === 'YXZS') {
        this.handleStationSelect(item)
      } else if (e === 'SSYT') {
        this.handleStationSelect({...item, alise: 'receipt'})
      }
    },

    // 工作站登录
    async loginStation(item) {
      // 如果已登录。先退出工作站
      // if(this.currentStation && this.currentStation!=item.value){
      //   let result = await axios.post('/api/common/connector/rest/site/logout/v1', { siteCode: this.$store.state.stationId});
      //   localStorage.removeItem('pick.orderReceiveMode');
      //   localStorage.removeItem('putaway.taskConfig.emptyContainersEntry');
      //   if(result.code) return;
      // }
      this.showModeSwitch = false
      let res = await axios.post('/api/common/connector/rest/site/login/v1', { siteCode: this.$store.state.stationId, siteFunctionType: item.value, orderReceiveMode: this.orderReceiveMode || '0' })
      if (res.code) {
        this.isLoading = false
        return
      }
      await this.getSiteData()
      this.isLoading = false
      let origin = ['common:picking', 'common:stocktake', 'common:putaway', 'common:receipt', 'totecommon:rootContainerUp'].includes(item.code) ? window.location.origin + process.env.VUE_APP_STATION_NEW_PATH : window.location.origin + process.env.VUE_APP_STATION_PATH
      if (item.code === 'common:picking') {

        // 获取拣货配置 是否拣放合一
        let res = await axios.get(`/api/common/stdStation/rest/site/getSiteEntity/v1?siteCode=${this.$store.state.stationId}`)
        this.isLoading = false;
        if (res?.data?.taskConfig?.picking?.taskConfig?.pickPutFlag) {
          // 是否开启自动播种
          let res1 = await axios.get(`/api/pickingAutomaticSeeding/queryConfig?siteCode=${this.$store.state.stationId}`);
          if(res1.data.automaticSeedingEnable){
            this.$router.push({ path: '/station',query: {url: origin + 'wes/pickAutoSeeding', tagsName: item.name} })
          }else{
            this.$router.push({ path: '/station', query: { url: origin + 'wes/pickput', tagsName: item.name } })
          }
        } else {
          this.$router.push({ path: '/station', query: { url: origin + item.path, tagsName: item.name } })
        }
      } else if (item.code === 'totecommon:rootContainerUp') {
        // 获取拣货配置 是否拣放合一
        let res = await axios.get(`/api/common/stdStation/rest/site/getSiteEntity/v1?siteCode=${this.$store.state.stationId}`)
        this.isLoading = false
        // 1-原箱直上 2-收上一体
        const receiptWorkMode = res?.data?.taskConfig?.FCL_IN?.taskConfig?.workMode || [];
        console.log(receiptWorkMode)
        if (item.alise === 'receipt' || (receiptWorkMode.length === 1 && receiptWorkMode[0] == 2)){
          this.$router.push({ path: '/station',query: { url: origin + 'wes/receiptRootContainerUp', tagsName: item.name } })
        } else {
          this.$router.push({ path: '/station', query: { url: origin + item.path, tagsName: item.name } })
        }
      } else {
        this.isLoading = false
        this.$router.push({ path: '/station', query: { url: origin + item.path, tagsName: item.name } })
      }
    },
    // 检查模式切换
    async checkPopPickModeSwitch(params) {
      return await GET('/api/mode/switch/check', params);
    },
    // 轮询poppick检查
    async getPopPickModeSwitchCallBack(params) {
      return await GET('/api/mode/switch/callback', params);
    },
    // 登录重试
    handleReTry() {
      this.handleStationSelect(this.loginInData)
    },
    // 判断常用功能是否有弹窗
    onMouseOver(str) {
      const parentWidth = this.$refs[str][0].parentNode.offsetWidth;
      const contentWidth = this.$refs[str][0].offsetWidth;
      // 判断是否开启tooltip功能
      if (contentWidth >= parentWidth - 80) {
        this.isShowTooltip = false;
      } else {
        this.isShowTooltip = true;
      }
    },
    initErrorMgs() {
      let config = JSON.parse(localStorage.getItem('SYSTEM_CONFIG_FE')) || {};
      let errorInterTime = config.home_notification_refresh_interval || 60
      this.getMsgList();
      this.errorMsgTimer = setInterval(() => {
        this.getMsgList();
      }, errorInterTime * 1000)
    },
    // 获取消息提醒，需要轮询
    async getMsgList() {
      let res = await GET('/api_v1/notification/list');
      if (res.code) return;
      this.msgList = res.data.map(ele => {
        let name = this.$t(ele.text).split(/{\d*}/) || [];
        let html = '';
        let i = 0;
        name.map(k => {
          html = html + k + (ele.params[i] ? `<strong style="color: yellow">${ele.params[i]}</strong>` : '');
          i++
        });
        let menuList = localStorage.getItem('MenuList');
        let hasPermission = this.checkPermission(ele.url, menuList)
        return { ...ele, html, hasPermission }
      }) || []
    },
    // 处理异常信息弹窗
    handleErrorMsg(item) {
      if (item.url && item.hasPermission) {
        this.$router.push({ path: item.url });
      }
    },
    // 检查页面权限
    checkPermission(path, menuList) {
      path = path.match(/(?<=\/)[^\/]*$/g)
      if (menuList.includes(path)) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
}
.geek-flex-row {
  display: flex;
  > span {
    width: 195px;
    min-width: 150px;
    font-size: 28px;
    color: #9c9999;
    line-height: 80px;
    margin-top: 30px;
  }
}
.geek-grid-row-bottom {
  flex: 1;
  min-height: 40%;
}
.geek-homepage {
  background: url("../assets/home/background.png") no-repeat bottom !important;
  background-size: cover !important;
  word-break: break-all;
  i{
      cursor: pointer;
  }
  .geek-grid-row {
    display: flex;
    margin: 0 20px 12px 20px;
    font-size: 28px;
    overflow: auto;
    max-height: 70%;
    .geek-grid-row-item {
      background: rgba(63, 64, 65, 0.5);
      border-radius: 10px;
      padding: 20px;
      color: #ccc;
      overflow: auto;
    }
    .geek-grid-row-left {
      flex: 2;
      margin-right: 14px;
      overflow: auto;
    }
    .geek-grid-row-right {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
    .home-model-title {
      font-size: 28px;
      display: flex;
      align-items: center;
      line-height: 30px;
      .el-icon-con{
        padding: 0 15px 0 15px;
      }
      > i {
        margin-left: 22px;
        &.el-icon-success{
          color: #3F9EFF;
        }
      }
      span{
        word-break:break-word;
      }
      .home-model-title-station{
        margin-left: auto;
        .el-button{
          font-size: 28px;
        }
      }
      .home-current-stationid{
        color: #3F9EFF;
        margin-left: 20px;
        font-size: 32px;
      }
      .station-model-item{
        display: flex;
        align-items: center;
        background: #414243;
        border-radius: 10px;
        padding: 15px;
        font-size: 20px;
        margin-left: 20px;
        cursor: pointer;
        >i {
            margin-right: 10px;
            color: #3F9EFF;
        }
        strong{
          font-size: 28px;
          margin-left: 10px;
        }
      }
    }
    .admin-menu-model {
      display: flex;
      flex-wrap: wrap;
      .admin-menu-model-item {
        display: flex;
        align-items: center;
        background: #414243;
        width: 220px;
        margin-right: 33px;
        margin-top: 17px;
        border-radius: 10px;
        padding: 18px 12px;
        font-size: 20px;
        position: relative;
        box-sizing: border-box;
        cursor: pointer;
        &.isDisabled{
          filter: grayscale(1);
          cursor: not-allowed;
        }
        .el-icon-remove{
            position: absolute;
            top: 4px;
            right: 4px;
            color: #FFFFFF !important;
            margin-right: 0;
        }
        i {
          font-size: 28px;
          margin-right: 10px;
          color: #3F9EFF;
        }
        &.isLogin::after{
            content: attr(loginStr);
            display: block;
            position: absolute;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            right: 0;
            top: 0;
            padding: 0 15px;
            color: #fff;
            background: #04B8FA;
            border-radius: 0 5px 0 5px;
            overflow: hidden;
        }
        &.commonModel{
            padding-right: 20px;
            // width: 30%;
            i{
                color: #05DDFB;
            }
            span{
              word-break:break-word;
            }
        }
        &.station-menu-model{
          margin-top: 30px;
          height: 80px;
          color: #ccc;
          span {
            word-break: break-word;
          }
          &:last-child:not(.station-menu-model__containerUp) {
            margin-right: 0;
          }
        }
      }
      .admin-menu-model-item-add{
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        min-width: 200px;
        margin-right: 33px;
        margin-top: 17px;
        border-radius: 10px;
        padding: 18px 12px;
        font-size: 20px;
        position: relative;
        border: 1px dashed #fff;
        cursor: pointer;
      }
    }
    .avatar-model {
      display: flex;
      border-bottom: 1px solid #646060;
      padding-bottom: 8px;
      .avatar-icon {
        width: 100px;
        height: 100px;
        margin-right: 14px;
      }
      .avatar-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        overflow: auto;
        .avatar-text-identity {
          display: flex;
          align-items: center;
          font-size: 16px;
          span:first-child {
            margin-right: 15px;
            padding-right: 15px;
            border-right: 1px solid #bbbbbb;
          }
        .over-flow {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin: 0;
            color: #3F9EFF;
            // max-width: 200px;
            flex: 1;
        }
        }
      }
      .avatar-option {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-left: 20px;
        .el-button {
          margin: 0;
        }
      }
      .el-button{
          background-color: #414243;
          border-color: #414243;
          color: #BBBBBB;
      }
    }
    .today-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
      > p {
        text-align: center;
        margin: 15px 0;
      }
      > div {
        margin-right: -20px;
        Strong {
          font-size: 40px;
          + span {
            font-size: 20px;
          }
        }
      }
      .today-data-model {
        background: rgba(82, 82, 82, 0.25);
        flex: 1;
        margin: 15px 20px 0 0;
        border-radius: 10px;
        padding: 15px 12px;
        text-align: center;
        position: relative;
        max-width: calc(33% - 40px);
        >div{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .noData{
          padding: 20px 0;
          text-align: center;
      }
    }
    .error-msg-model{
      overflow-y: auto;
      .error-msg-item{
        display: flex;
        padding: 10px 0;
        border-bottom: 1px solid yellow;
        font-size: 20px;
        cursor: pointer;
        &.hasPermission{
          border-bottom: 1px solid red;
          cursor: not-allowed;
        }
        .el-image{
          width: 40px;
          height: 30px;
          margin-right: 5px;
        }
      }
    }
  }
  .geek-grid-row-top{
    min-height: 40%;
  }
}
.poppick-switch-mode{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    padding-bottom: 20px;
  }
  .poppick-switch-mode-txt{
    padding: 20px;
    color: #ccc;
    &.active{
      color: #FE9802;
      border-radius: 4px;
      border: 1px solid #FE9802;
    }
  }
  .poppickPoint{
    display: flex;
    .poppickPoint-item{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      color: #333;
      font-weight: bold;
      .poppickPoint-item-box{
        border-radius: 4px;
        border: 1px solid #57B06C;
        color: #57B06C;
        padding: 12px 20px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        i{
          margin-right: 6px;
        }
        &.isFail{
          border: 1px solid #FE9802;
          color: #FE9802;
        }
      }
      .poppickPoint-footer{
        display: flex;
        justify-content: center;
      }
    }
  }
  .errorMsg{
    margin: 12px 0;
  }
}

@media screen and (max-width: 1920px) {
    .geek-homepage{
        zoom: 1;
    }
}
@media screen and (max-width: 1680px) {
    .geek-homepage{
        zoom: 0.9;
    }
    .admin-menu-model-item{
        min-width: 180px !important;
    }
}
@media screen and (max-width: 1440px) {
    .geek-homepage{
        zoom: 0.8;
    }
}
@media screen and (max-width: 1280px) {
    .geek-homepage{
        zoom: 0.75;
    }
    .admin-menu-model-item{
        min-width: 160px !important;
    }
}
</style>

