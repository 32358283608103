<template>
  <div id="app" v-loading="$store.state.homeLoading" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.6)">
    <router-view/>
  </div>
</template>

<script>
import { GET, POST } from '@/utils/http'

export default {
  async created() {
    await this.getCustomDataTrickle()
    this.setAllSystemConfig()
  },
  methods: {
    // 获取所有配置参数
    setAllSystemConfig() {
      let config = {}
      GET(`/api_v1/sysparam/query?group=fe&pageSize=1000&currentPage=1&__rnd=${+new Date()}`).then(res => {

        if (res.code === 0) {
          let file_export_format = 1
          res.data.recordList.forEach(e => {
              let val = e.paramValue;
              if (val === "true") val = true;
              if (val === "false") val = false;
              config[e.paramKey] = val;
              if(e.paramKey == 'file_export_format'){
                file_export_format = e.paramValue
              }
          });
          localStorage.setItem('SYSTEM_CONFIG_FE', JSON.stringify(config));
          localStorage.setItem('file_export_format', file_export_format == '2'? 'CSV':'XLSX');
        }
      })
    },
    async getCustomDataTrickle() {
      // 滚动条宽度会影响element表格固定列bottom属性，接口回调之后再添加属性无法生效，如果是接口返回需要刷新一下
      if(sessionStorage.getItem('fe-GPS-style')){
        this.$customScript(sessionStorage.getItem('fe-GPS-style'))
        return
      }
      await this.$getCustomScript('fe-GPS-custom');
      let GPSStyle = this.$store.state.ctxScript['fe-GPS-style'];
      if(GPSStyle) {
        sessionStorage.setItem('fe-GPS-style', GPSStyle)
        this.$customScript(GPSStyle)
        window.location.reload()
      }
    }
  }

}
</script>


<style lang="scss">
html, body, #app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Micro YamHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.geek-table {
  .table-header {
    th {
      background-color: rgb(235, 247, 255) !important;
    }
  }
  .el-table__row {
    >td {
      padding: 4px 0 !important;
    }
  }
}
.geek-virtual-table{
  .table-header {
    th {
      border-right: 0;
    }
  }
  .el-table__row {
    >td {
      padding: 0 !important;
      border-right: 0;
    }
  }
}
.geek-page{
  overflow-x: hidden !important;
}
/* scrollbar */
::-webkit-scrollbar{
    width:10px;
    height: 10px;
}
::-webkit-scrollbar-corner{
  background: #f6f6f6;
}
::-webkit-scrollbar-thumb{
    border-radius: 10px;
    border-style: dashed;
    border-color: transparent;
    border-width: 2px;
    background-color: rgba(157, 165, 183, 0.4);
    background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover{
    background: rgba(157, 165, 183, 0.7);
}
::-webkit-scrollbar-button{
    display: none;
}
::-webkit-scrollbar-track {
    background: #f6f6f6;
    border-radius: 2px;
}
.el-table__body .el-form-item {
  margin-bottom: 0;
}
.el-loading-spinner{
  .el-icon-loading{
    font-size: 60px !important;
  }
}

// .v-modal,
.el-dialog__wrapper.geek-dialog{
  .is-fullscreen{
    top: 100px !important;
    max-height: calc(100vh - 100px) !important;
  }
  .el-dialog__body{
    padding: 10px 20px !important;
  }
}
.geek-form-item{
  .el-form-item__label{
    word-break: break-word;
    // line-height: 20px;
  }
  &.geek-form-item-text {
    .el-form-item__label{
      font-weight: bold!important;
    }
  }
  &.weight-normal {
    .el-form-item__label{
      font-weight: normal !important;
    }
  }
}

.el-dialog__headerbtn[aria-label="Close"] {
  font-size: 20px;
}

.geek-table .cell.el-tooltip{
  > div {
    text-overflow: ellipsis;
  }
}

.el-transfer-panel .el-transfer-panel__header {
  background: rgb(235, 247, 255) !important;
}

</style>
