<!-- 左导 -->
<template>
  <div class="site-menu-item">
    <template>
      <el-menu-item>
        <template slot="title">
          <el-tooltip class="item" effect="dark" :disabled="isShowTooltip" :content="routeObj.name" placement="right">
            <p class="over-flow" :class="{active: $store.state.menuSrollIndex == 'scroll-' + routeObj.path}"  @mouseover="onMouseOver($t(routeObj.name))" @click="handleMenuSelect(routeObj)">
              {{$t(routeObj.name)}}
            </p>
          </el-tooltip>
          <!-- <i v-if="showEditor" class="el-icon-edit" @click.stop.prevent="editPage(routeObj)"></i> -->
        </template>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
export default {
  name: 'SideItem',
  props: {
    routeObj: {
      type: Object,
      default() {
        return {}
      }
    },
    parentPath: {
      type: String,
      default: ''
    }
  },
  computed: {
    getRouteQuery() {
      return this.$route.fullPath.indexOf('?') > -1 ? `?${this.$route.fullPath.split('?')[1]}` : ''
    },
    getRoutePath() {
      if (!this.parentPath) {
        return this.routeObj.path.startsWith('/') ? this.routeObj.path : '/' + this.routeObj.path
      } else if (this.parentPath === '/') {
        return '/' + this.routeObj.path
      } else {
        return this.parentPath + '/' + this.routeObj.path
      }
    }
  },
  data() {
    return {
      showEditor: process.env.VUE_APP_SHOW_EDIT == 'true',
      isShowTooltip: true
    }
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  methods: {
    editPage(route) {
      let path = `/editor/${route.meta.id}`
      if (this.$route.path !== path) {
        this.$router.push(path)
      }
    },
    handleMenuSelect(e) {
      this.$emit('select', e)
    },
    onMouseOver(str) {
      // const parentWidth = this.$refs[str].parentNode.offsetWidth;
      // const contentWidth = this.$refs[str].offsetWidth;
      // // 判断是否开启tooltip功能
      // if (contentWidth > parentWidth) {
      //   this.isShowTooltip = false;
      // } else {
      //   this.isShowTooltip = true;
      // }
    }
  }
}
</script>
<style lang="scss">
// .el-menu{
//   background-color: transparent !important;
//   border-right: 0 !important;
//   .site-menu-item {
//     background: transparent;
//     .el-submenu__title{
//       color: #fff;
//       font-size: 18px;
//     }
//     .el-menu-item {
//       padding-right: 20px !important;
//       color: #ccc;
//       font-size: 18px;
//       height: 48px;
//       line-height: 48px;
//       color: #fff;
//       a{
//         color: #ccc;
//         text-decoration: none;
//       }
//       .el-icon-edit {
//         display: none;
//         position: absolute;
//         top: 50%;
//         right: 4px;
//         transform: translateY(-50%);
//         color: #fff;
//       }
//       &:hover {
//         .el-icon-edit {
//           display: block;
//         }
//       }
//       .active{
//         color: #f90;
//       }
//     }
//     .over-flow {
//       overflow: hidden;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//       margin: 0;
//     }
//   }
// }
// .el-submenu__title, .el-menu-item {
//     color: #fff;
//     font-weight: bold;
// }
// .el-menu-item:focus,
// .el-menu-item:hover,
// .el-submenu__title:hover{
//     border-radius: 10px;
//     background-color: rgba(64, 65, 66, 100) !important;
//     box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
//     a{
//         color: #3F9EFF !important;
//     }
// }
</style>
