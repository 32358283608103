<template>
  <div class="geek-download" :title="$transMsg(text)">
    <el-dropdown split-button type="primary" :hide-on-click="false" @click="download(params, url)">
      <!-- 导出 -->
      {{$transMsg(text)}}
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="download(item.params, item.url)" v-for="(item, index) in list" :key="index">{{$transMsg(item.text)}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { Parsers } from 'leivii';

export default {
  name: 'GeekCSelectDownloadBtn',
  options: {
    name: '选择下载框',
    parents: ['all'], // all
    events: {
      click: 'lang.action.click'
    }
  },
  props: {
    url: {
      type: String,
      isExpression: true,
      label: 'lang.prop.downloadUrl'
    },
    text: {
      type: String,
      clazz: 'I18n',
      label: 'lang.prop.text'
    },
    list: {
      type: Array,
      default: () => [],
      label: '下拉选项',
      itemClazz: {
        url: {
          type: String,
          isExpression: true,
          label: 'lang.prop.downloadUrl'
        },
        text: {
          type: String,
          clazz: 'I18n',
          label: 'lang.prop.text'
        },
        params: {
          type: Object,
          clazz: 'ParamPicker',
          label: 'lang.prop.params'
        }
      }
    },
    type: {
      type: String,
      options: [{ label: 'lang.prop.theme.primary', value: 'primary' }, { label: 'lang.prop.theme.success', value: 'success' }, { label: 'lang.prop.theme.warning', value: 'warning' }, { label: 'lang.prop.theme.danger', value: 'danger' }, { label: 'lang.prop.theme.info', value: 'info' }, { label: 'lang.prop.theme.text', value: 'text' }],
      label: 'lang.prop.type'
    },
    size: {
      type: String,
      options: [{ label: 'lang.prop.size.mini', value: 'mini' }, { label: 'lang.prop.size.small', value: 'small' }, { label: 'lang.prop.size.medium', value: 'medium' }],
      label: 'lang.prop.size'
    },
    params: {
      type: Object,
      clazz: 'ParamPicker',
      label: 'lang.prop.params'
    },
    // child: {
    //   type: Object,
    //   clazz: 'ComponentDragger',
    //   placeholder: 'lang.prop.dragComponent',
    //   label: 'lang.prop.$body'
    // },
    delay: {
      type: Number,
      label: 'lang.prop.delay',
      default: 3
    },
    authCode: {
      type: String,
      clazz: 'Auth',
      label: 'lang.prop.auth'
    },
    disabled: {
      type: Boolean,
      isExpression: true,
      default: null,
      label: 'lang.prop.disable'
    },
    interval: {
      type: Number,
      label: 'lang.prop.interval',
      default: 500
    }
  },
  data() {
    return {
      loading: false,
      optDisabled: false
    }
  },
  created() {
    // this.downloadAction = debounce(this.interval, true, this.downloadAction.bind(this));
  },
  methods: {
    download(params, downLoadUrl) {
      let downParams = params || this.params;
      let downUrl = downLoadUrl || this.downLoadUrl;
      if (this.$store.state.leivii?.mode === 1 || !downUrl) return
      this.optDisabled = true;
      this.timer = setTimeout(() => {
        this.optDisabled = false;
      }, this.delay * 1000)
      if (this.$handlers.length) {
        this.$emit('click')
        return
      }
      this.downloadAction(downParams, downUrl);
    },
    async downloadAction(params, downLoadUrl) {
      let url = downLoadUrl || this.url;
      let newParams = params || this.params;

      if (newParams && Object.keys(newParams).length) {
        let query = Object.entries(await Parsers.paramsParser(newParams, this) || {}).map(([k, v]) => `${k}=${v}`).join('&')
        query && (url += '?' + query)
      }
      let iframe = null
      iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'download-iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframe.onload = e => {
        this.timer && clearTimeout(this.timer)
        this.optDisabled = false
        try {
          let data = JSON.parse(e.target.contentWindow.document.body.innerText);
          this.$message({ type: 'error', message: this.$transMsg(data.msg) })
          document.body.removeChild(iframe);
        } catch (error) {
          document.body.removeChild(iframe);
        }
      };
      iframe.src = url;
    },
    dropdownClick(params, url) {
      this.downloadAction(params, url);
    }
  }
}
</script>
