import { GET, POST } from '@/utils/http'
import { transMsg } from 'leivii'
import Vue from 'vue'

const ruleType = {
  rulesTypeCode: 'container-strategy-picking',
  rulesTypeName: '下架任务绑箱策略配置',
  rulesDesp: {
    output: {
      code: 'container-strategy-picking',
      type: 'json'
    },
    rules: {
      'execute-type': 'spel',
      entityTypes: [
        {
          entityDepth: 2,
          code: 'pickingTask',
          columns: [
            'businessType',
            'orderType',
            'details',
            'details.ownerCode'
          ]
        }
      ],
      type: 'expression',
      rulesMaxCount: 100
    }
  }
}
const em = {
  entityTypeCode: 'pickingTask',
  entityTypeName: 'lang.wms.fed.offTask',
  hitColumnCodes: [
    'taskCode'
  ],
  showColumnCodes: [
    'taskCode',
    'externalTaskCode',
    'status'
  ],
  processor: 'spring-bean:com.geekplus.kronos.demo.metadata.processor.PickingTaskProcessor',
  processorBean: {},
  creator: '',
  creationTime: 0,
  modifier: '',
  modifyTime: 0,
  status: 0,
  autoUpdateTime: 1634624398334,
  columns: [
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'pickingTaskCode',
      columnName: 'lang.wms.fed.taskCode',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398087,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'externalTaskCode',
      columnName: 'lang.wms.fed.outOrderNo',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398155,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'warehouseCode',
      columnName: 'lang.wms.fed.warehouseCode',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624397883,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'warehouse',
      columnName: 'lang.wms.fed.warehouse',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'entity',
      columnDataDesp: {
        entityTypeCode: 'warehouse'
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398088,
      columnDataEntity: {
        entityTypeCode: 'warehouse',
        entityTypeName: 'lang.wms.fed.warehouse',
        hitColumnCodes: [
          'warehouseCode'
        ],
        showColumnCodes: [
          'warehouseName'
        ],
        processor: 'spring-bean:com.geekplus.kronos.demo.metadata.processor.WarehouseProcessor',
        processorBean: {},
        creator: '',
        creationTime: 0,
        modifier: '',
        modifyTime: 0,
        status: 0,
        autoUpdateTime: 1634624398292,
        columns: [
          {
            entityTypeCode: 'warehouse',
            columnCode: 'warehouseCode',
            columnName: 'lang.wms.fed.warehouseCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624397883,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'warehouse',
            columnCode: 'warehousePropertyCode',
            columnName: 'lang.wms.fed.warehousePropertyCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398083,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'warehouse',
            columnCode: 'warehouseName',
            columnName: 'lang.wms.fed.warehouseName',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398084,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'warehouse',
            columnCode: 'isSampling',
            columnName: 'lang.wms.fed.isSampling',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'bool',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398085,
            columnDataEntity: null
          }
        ]
      }
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'status',
      columnName: 'lang.wms.fed.state',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {
        enumeration: {
          originalType: 'dict',
          dict: 'wes_picking_task_status',
          type: 'dict',
          list: [
            {
              code: '100',
              name: 'lang.wes.picking.status.new'
            },
            {
              code: '110',
              name: 'lang.wes.picking.status.allocated'
            },
            {
              code: '120',
              name: 'lang.wes.picking.status.bind_container'
            },
            {
              code: '140',
              name: 'lang.wes.picking.status.picking'
            },
            {
              code: '150',
              name: 'lang.wes.picking.status.picked'
            },
            {
              code: '9999',
              name: 'lang.wes.picking.status.completion'
            }
          ]
        }
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398089,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'priority',
      columnName: 'lang.wms.fed.priority',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398189,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation1',
      columnName: 'lang.wms.fed.reservation1',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398166,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation2',
      columnName: 'lang.wms.fed.reservation2',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398167,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation3',
      columnName: 'lang.wms.fed.reservation3',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398168,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation4',
      columnName: 'lang.wms.fed.reservation4',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398169,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation5',
      columnName: 'lang.wms.fed.reservation5',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398170,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation6',
      columnName: 'lang.wms.fed.reservation6',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398171,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation7',
      columnName: 'lang.wms.fed.reservation7',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398172,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation8',
      columnName: 'lang.wms.fed.reservation8',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398173,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation9',
      columnName: 'lang.wms.fed.reservation9',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398174,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'reservation10',
      columnName: 'lang.wms.fed.reservation10',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398175,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'subTaskType',
      columnName: 'lang.wms.fed.waveType',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398189,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'businessType',
      columnName: 'lang.wms.fed.businessType',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {
        enumeration: {
          originalType: 'dict',
          dict: 'business_type_out',
          type: 'dict',
          list: [
            {
              code: '2070',
              name: 'lang.wms.common.enum.BusinessTypeEnum.B2BCK'
            },
            {
              code: '2010',
              name: 'lang.wms.common.enum.BusinessTypeEnum.DDCK'
            },
            {
              code: '2020',
              name: 'lang.wms.common.enum.BusinessTypeEnum.TCCK'
            },
            {
              code: '2030',
              name: 'lang.wms.common.enum.BusinessTypeEnum.XNCK'
            },
            {
              code: '2040',
              name: 'lang.wms.common.enum.BusinessTypeEnum.DBCK'
            },
            {
              code: '2050',
              name: 'lang.wms.common.enum.BusinessTypeEnum.JTCK'
            },
            {
              code: '2060',
              name: 'lang.wms.common.enum.BusinessTypeEnum.LYCK'
            }
          ]
        }
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398157,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'waveType',
      columnName: 'lang.wms.fed.waveType',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {
        enumeration: {
          originalType: 'dict',
          dict: 'wave_type',
          type: 'dict',
          list: [
            {
              code: '209999',
              name: 'lang.wms.common.enum.WaveTypeEnum.HHZB'
            },
            {
              code: '0',
              name: 'lang.wms.common.enum.WaveTypeEnum.DDCK'
            },
            {
              code: '1',
              name: 'lang.wms.common.enum.WaveTypeEnum.DBCK'
            },
            {
              code: '3',
              name: 'lang.wms.common.enum.WaveTypeEnum.TCCK'
            },
            {
              code: '4',
              name: 'lang.wms.common.enum.WaveTypeEnum.QTCK'
            },
            {
              code: '2',
              name: 'lang.wms.common.enum.WaveTypeEnum.YDCK'
            },
            {
              code: '7',
              name: 'lang.wms.common.enum.WaveTypeEnum.ZTCK'
            },
            {
              code: '10',
              name: 'lang.wms.common.enum.WaveTypeEnum.LHCK'
            },
            {
              code: '11',
              name: 'lang.wms.common.enum.WaveTypeEnum.BHCK'
            },
            {
              code: '19',
              name: 'lang.wms.common.enum.WaveTypeEnum.FPCK'
            },
            {
              code: '20',
              name: 'lang.wms.common.enum.WaveTypeEnum.JITCK'
            },
            {
              code: '21',
              name: 'lang.wms.common.enum.WaveTypeEnum.KLTHCK'
            },
            {
              code: '22',
              name: 'lang.wms.common.enum.WaveTypeEnum.KLFXCK'
            },
            {
              code: '23',
              name: 'lang.wms.common.enum.WaveTypeEnum.KLZCCK'
            },
            {
              code: '25',
              name: 'lang.wms.common.enum.WaveTypeEnum.KLTGCK'
            },
            {
              code: '26',
              name: 'lang.wms.common.enum.WaveTypeEnum.KLPOPCK'
            },
            {
              code: '30',
              name: 'lang.wms.common.enum.WaveTypeEnum.QSDDCK'
            },
            {
              code: '40',
              name: 'lang.wms.common.enum.WaveTypeEnum.HGCYDDCK'
            },
            {
              code: '202001',
              name: 'lang.wms.common.enum.WaveTypeEnum.PKCK'
            },
            {
              code: '301001',
              name: 'lang.wms.common.enum.WaveTypeEnum.KHFB'
            },
            {
              code: '302001',
              name: 'lang.wms.common.enum.WaveTypeEnum.YBYD'
            },
            {
              code: '302002',
              name: 'lang.wms.common.enum.WaveTypeEnum.RF_SJYD'
            },
            {
              code: '302003',
              name: 'lang.wms.common.enum.WaveTypeEnum.ZCYHW'
            },
            {
              code: '303001',
              name: 'lang.wms.common.enum.WaveTypeEnum.LBYD'
            },
            {
              code: '303002',
              name: 'lang.wms.common.enum.WaveTypeEnum.KNLHYD'
            },
            {
              code: '303003',
              name: 'lang.wms.common.enum.WaveTypeEnum.JJBHYD'
            },
            {
              code: '303004',
              name: 'lang.wms.common.enum.WaveTypeEnum.RCBHYD'
            },
            {
              code: '303005',
              name: 'lang.wms.common.enum.WaveTypeEnum.ZTTZYD'
            },
            {
              code: '303006',
              name: 'lang.wms.common.enum.WaveTypeEnum.PCTZYD'
            },
            {
              code: '304001',
              name: 'lang.wms.common.enum.WaveTypeEnum.KCPDD'
            },
            {
              code: '305001',
              name: 'lang.wms.common.enum.WaveTypeEnum.PCTZD'
            },
            {
              code: '306001',
              name: 'lang.wms.common.enum.WaveTypeEnum.ZTTZD'
            },
            {
              code: '307001',
              name: 'lang.wms.common.enum.WaveTypeEnum.JJBH'
            },
            {
              code: '307002',
              name: 'lang.wms.common.enum.WaveTypeEnum.RCBH'
            },
            {
              code: '308001',
              name: 'lang.wms.common.enum.WaveTypeEnum.KNLH'
            },
            {
              code: '201019',
              name: 'lang.wms.common.enum.NoteTypeEnum.FPCK'
            },
            {
              code: '8',
              name: 'lang.wms.common.enum.NoteTypeEnum.MDDD'
            },
            {
              code: '37',
              name: 'lang.wms.common.enum.NoteTypeEnum.Normal'
            },
            {
              code: '31',
              name: 'lang.wms.common.enum.NoteTypeEnum.Urgent'
            },
            {
              code: '32',
              name: 'lang.wms.common.enum.NoteTypeEnum.Retail'
            },
            {
              code: '33',
              name: 'lang.wms.common.enum.NoteTypeEnum.Implantation'
            },
            {
              code: '34',
              name: 'lang.wms.common.enum.NoteTypeEnum.Manual'
            },
            {
              code: '35',
              name: 'lang.wms.common.enum.NoteTypeEnum.Opening'
            },
            {
              code: '36',
              name: 'lang.wms.common.enum.NoteTypeEnum.Special'
            },
            {
              code: '48',
              name: 'lang.wms.common.enum.WaveTypeEnum.HHCK'
            },
            {
              code: '49',
              name: 'lang.wms.common.enum.WaveTypeEnum.BFCK'
            },
            {
              code: '38',
              name: 'lang.wms.common.enum.WaveTypeEnum.TZDDCK'
            },
            {
              code: '39',
              name: 'lang.wms.common.enum.WaveTypeEnum.CNJGDDCK'
            },
            {
              code: '50',
              name: 'lang.wms.common.enum.NoteTypeEnum.QDDD'
            },
            {
              code: '5',
              name: 'lang.wms.common.enum.WaveTypeEnum.XNCK'
            },
            {
              code: '-1',
              name: 'lang.wms.common.enum.WaveTypeEnum.NONE'
            },
            {
              code: '60',
              name: 'lang.wms.common.enum.BusinessTypeEnum.B2CCK'
            }
          ]
        }
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398198,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'waveStructure',
      columnName: 'lang.wms.fed.waveStructure',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {
        enumeration: {
          originalType: 'dict',
          dict: 'wave_structure',
          type: 'dict',
          list: [
            {
              code: '1',
              name: 'lang.wms.common.enum.WaveStructureEnum.ONE_BY_ONE'
            },
            {
              code: '2',
              name: 'lang.wms.common.enum.WaveStructureEnum.SINGLE_PRODUCT_SINGLE_ITEM_SAME'
            },
            {
              code: '3',
              name: 'lang.wms.common.enum.WaveStructureEnum.SINGLE_PRODUCT_SINGLE_ITEM_DIFFERENT'
            },
            {
              code: '4',
              name: 'lang.wms.common.enum.WaveStructureEnum.SINGLE_PRODUCT_MULTIPLE_ITEM_SAME'
            },
            {
              code: '5',
              name: 'lang.wms.common.enum.WaveStructureEnum.SINGLE_PRODUCT_MULTIPLE_ITEM_DIFFERENT'
            },
            {
              code: '6',
              name: 'lang.wms.common.enum.WaveStructureEnum.MULTIPLE_PRODUCT_MULTIPLE_ITEM_SAME'
            },
            {
              code: '7',
              name: 'lang.wms.common.enum.WaveStructureEnum.MULTIPLE_PRODUCT_MULTIPLE_ITEM_DIFFERENT'
            }
          ]
        }
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398198,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'cutOffDate',
      columnName: 'lang.wms.fed.expectedFinishDate',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'long',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398200,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'expectedFinishDate',
      columnName: 'lang.wms.fed.expectatCompleteTime',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'long',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398201,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'planSkuCategoryAmount',
      columnName: 'lang.wms.fed.planSkuCategoryAmount',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398218,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'factSkuCategoryAmount',
      columnName: 'lang.wms.fed.factSkuCategoryAmount',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'int',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398219,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'orderType',
      columnName: 'lang.wms.fed.orderType',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {
        enumeration: {
          originalType: 'dict',
          dict: 'note_type_out',
          type: 'dict',
          list: [
            {
              code: '8',
              name: 'lang.wms.common.enum.NoteTypeEnum.MDDD'
            },
            {
              code: '37',
              name: 'Normal'
            },
            {
              code: '31',
              name: 'Urgent'
            },
            {
              code: '32',
              name: 'Retail'
            },
            {
              code: '33',
              name: 'Implantation'
            },
            {
              code: '34',
              name: 'Manual'
            },
            {
              code: '35',
              name: 'Opening'
            },
            {
              code: '36',
              name: 'Special'
            },
            {
              code: '0',
              name: 'lang.wms.common.enum.NoteTypeEnum.DDCK'
            },
            {
              code: '1',
              name: 'lang.wms.common.enum.NoteTypeEnum.DBCK'
            },
            {
              code: '3',
              name: 'lang.wms.common.enum.NoteTypeEnum.TCCK'
            },
            {
              code: '4',
              name: 'lang.wms.common.enum.NoteTypeEnum.QTCK'
            },
            {
              code: '2',
              name: 'lang.wms.common.enum.NoteTypeEnum.YDCK'
            },
            {
              code: '7',
              name: 'lang.wms.common.enum.NoteTypeEnum.ZTCK'
            },
            {
              code: '10',
              name: 'lang.wms.common.enum.NoteTypeEnum.LHCK'
            },
            {
              code: '11',
              name: 'lang.wms.common.enum.NoteTypeEnum.BHCK'
            },
            {
              code: '19',
              name: 'lang.wms.common.enum.NoteTypeEnum.FPCK'
            },
            {
              code: '20',
              name: 'lang.wms.common.enum.NoteTypeEnum.JITCK'
            },
            {
              code: '21',
              name: 'lang.wms.common.enum.NoteTypeEnum.KLTHCK'
            },
            {
              code: '22',
              name: 'lang.wms.common.enum.NoteTypeEnum.KLFXCK'
            },
            {
              code: '23',
              name: 'lang.wms.common.enum.NoteTypeEnum.KLZCCK'
            },
            {
              code: '25',
              name: 'lang.wms.common.enum.NoteTypeEnum.KLTGCK'
            },
            {
              code: '26',
              name: 'lang.wms.common.enum.NoteTypeEnum.KLPOPCK'
            },
            {
              code: '30',
              name: 'lang.wms.common.enum.NoteTypeEnum.QSDDCK'
            },
            {
              code: '40',
              name: 'lang.wms.common.enum.NoteTypeEnum.HGCYDDCK'
            },
            {
              code: '6',
              name: 'lang.wms.common.enum.NoteTypeEnum.PKCK'
            },
            {
              code: '48',
              name: 'lang.wms.common.enum.NoteTypeEnum.HHCK'
            },
            {
              code: '49',
              name: 'lang.wms.common.enum.NoteTypeEnum.BFCK'
            },
            {
              code: '38',
              name: 'lang.wms.common.enum.NoteTypeEnum.TZDDCK'
            },
            {
              code: '39',
              name: 'lang.wms.common.enum.NoteTypeEnum.CNJGDDCK'
            },
            {
              code: '50',
              name: 'lang.wms.common.enum.NoteTypeEnum.QDDD'
            }
          ]
        }
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398202,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'shopCode',
      columnName: 'lang.wms.fed.shopCode',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398204,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'platformCode',
      columnName: 'lang.wms.fed.platformCode',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398207,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'carrierCode',
      columnName: 'lang.wms.fed.carrierCoding',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398210,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'shopName',
      columnName: 'lang.wms.fed.shopName',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398213,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'platformName',
      columnName: 'lang.wms.fed.platformName',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398214,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'carrierName',
      columnName: 'lang.wms.fed.nameOfCarrier',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398215,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'brandCode',
      columnName: 'lang.wms.fed.brandCode',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398216,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'brandName',
      columnName: 'lang.wms.fed.brandName',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'text',
      columnDataDesp: {},
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398217,
      columnDataEntity: null
    },
    {
      entityTypeCode: 'pickingTask',
      columnCode: 'details',
      columnName: 'lang.wms.fed.details',
      columnGroup: '',
      columnIntr: '',
      columnDataType: 'entity',
      columnDataDesp: {
        entityTypeCode: 'pickingTaskDetail'
      },
      columnBindCode1: '',
      columnBindCode2: '',
      columnBindCode3: '',
      columnBindCode4: '',
      creator: '',
      creationTime: 0,
      modifier: '',
      modifyTime: 0,
      status: 0,
      autoUpdateTime: 1634624398220,
      columnDataEntity: {
        entityTypeCode: 'pickingTaskDetail',
        entityTypeName: 'lang.wms.fed.pickingTaskDetail',
        hitColumnCodes: [
          'pickingTaskDetailId'
        ],
        showColumnCodes: [
          'skuName',
          'planQty',
          'status'
        ],
        processor: 'spring-bean:com.geekplus.kronos.demo.metadata.processor.PickingTaskDetailProcessor',
        processorBean: {},
        creator: '',
        creationTime: 0,
        modifier: '',
        modifyTime: 0,
        status: 0,
        autoUpdateTime: 1634624398335,
        columns: [
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'pickingTaskDetailId',
            columnName: 'lang.wms.fed.pickingTaskDetailId',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'long',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398098,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'externalTaskDetailCode',
            columnName: 'lang.wms.fed.externalTaskDetailCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398099,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'ownerCode',
            columnName: 'lang.wms.fed.ownerCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398085,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'owner',
            columnName: 'lang.wms.base.base.spOwner.owner',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'owner',
              hitColumns: [
                'ownerCode'
              ]
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398090,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'skuCode',
            columnName: 'lang.wms.fed.skuCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398089,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'sku',
            columnName: 'lang.wms.fed.commodity',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'sku'
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398099,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'pickingTaskCode',
            columnName: 'lang.wms.fed.taskCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398087,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'task',
            columnName: 'lang.wms.fed.task',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'pickingTask'
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398100,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'warehouseCode',
            columnName: 'lang.wms.fed.warehouseCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624397883,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'warehouse',
            columnName: 'lang.wms.fed.warehouse',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'warehouse'
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398088,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'binCode',
            columnName: 'lang.wms.fed.binCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398101,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'containerCode',
            columnName: 'lang.wms.fed.containerCoding',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398102,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'packingCode',
            columnName: 'lang.wms.fed.packagingCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398179,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'packingSpec',
            columnName: 'lang.wms.fed.packagingSpecification',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398105,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty01',
            columnName: 'lang.wms.common.dict.inventory_batch_property01',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398106,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty02',
            columnName: 'lang.wms.common.dict.inventory_batch_property02',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398107,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty03',
            columnName: 'lang.wms.fed.batchPorperty03',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398108,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty04',
            columnName: 'lang.wms.fed.batchPorperty04',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398109,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty05',
            columnName: 'lang.wms.fed.batchPorperty05',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398110,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty06',
            columnName: 'lang.wms.fed.batchPorperty06',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398111,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty07',
            columnName: 'lang.wms.fed.batchPorperty07',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398112,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty08',
            columnName: 'lang.wms.fed.batchPorperty08',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398113,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty09',
            columnName: 'lang.wms.fed.batchPorperty09',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398114,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty10',
            columnName: 'lang.wms.fed.batchProperty10',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398116,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty11',
            columnName: 'lang.wms.fed.batchPorperty11',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398117,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'batchProperty12',
            columnName: 'lang.wms.fed.batchPorperty12',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398118,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'inputDate',
            columnName: 'lang.wms.fed.dateOfEntry',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'long',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398119,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation1',
            columnName: 'lang.wms.fed.reservation1',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398166,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation2',
            columnName: 'lang.wms.fed.reservation2',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398167,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation3',
            columnName: 'lang.wms.fed.reservation3',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398168,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation4',
            columnName: 'lang.wms.fed.reservation4',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398169,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation5',
            columnName: 'lang.wms.fed.reservation5',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398170,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation6',
            columnName: 'lang.wms.fed.reservation6',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398171,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation7',
            columnName: 'lang.wms.fed.reservation7',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398172,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation8',
            columnName: 'lang.wms.fed.reservation8',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398173,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation9',
            columnName: 'lang.wms.fed.reservation9',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398174,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'reservation10',
            columnName: 'lang.wms.fed.reservation10',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398175,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'inventoryStatus',
            columnName: 'lang.wms.fed.inventoryStatus',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {
              enumeration: {
                type: 'list',
                list: [
                  {
                    code: 10,
                    name: 10
                  },
                  {
                    code: 20,
                    name: '20'
                  },
                  {
                    code: 30,
                    name: '30'
                  },
                  {
                    code: 40,
                    name: '40'
                  }
                ]
              }
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398148,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'planQty',
            columnName: 'lang.wms.fed.plannedQuantity',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398149,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'fetchQty',
            columnName: 'lang.wms.fed.fetchQty',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398150,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'putQty',
            columnName: 'lang.wms.fed.putQty',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398151,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'lackQty',
            columnName: 'lang.wms.fed.lackQuantity',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398152,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'cancelQty',
            columnName: 'lang.wms.fed.cancellNumber',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398152,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'operatorAccount',
            columnName: 'lang.wms.fed.operatorAccount',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398153,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'status',
            columnName: 'lang.wms.fed.state',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398089,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'orderType',
            columnName: 'lang.wms.fed.orderType',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'int',
            columnDataDesp: {
              enumeration: {
                originalType: 'dict',
                dict: 'note_type_out',
                type: 'dict',
                list: [
                  {
                    code: '8',
                    name: 'lang.wms.common.enum.NoteTypeEnum.MDDD'
                  },
                  {
                    code: '37',
                    name: 'Normal'
                  },
                  {
                    code: '31',
                    name: 'Urgent'
                  },
                  {
                    code: '32',
                    name: 'Retail'
                  },
                  {
                    code: '33',
                    name: 'Implantation'
                  },
                  {
                    code: '34',
                    name: 'Manual'
                  },
                  {
                    code: '35',
                    name: 'Opening'
                  },
                  {
                    code: '36',
                    name: 'Special'
                  },
                  {
                    code: '0',
                    name: 'lang.wms.common.enum.NoteTypeEnum.DDCK'
                  },
                  {
                    code: '1',
                    name: 'lang.wms.common.enum.NoteTypeEnum.DBCK'
                  },
                  {
                    code: '3',
                    name: 'lang.wms.common.enum.NoteTypeEnum.TCCK'
                  },
                  {
                    code: '4',
                    name: 'lang.wms.common.enum.NoteTypeEnum.QTCK'
                  },
                  {
                    code: '2',
                    name: 'lang.wms.common.enum.NoteTypeEnum.YDCK'
                  },
                  {
                    code: '7',
                    name: 'lang.wms.common.enum.NoteTypeEnum.ZTCK'
                  },
                  {
                    code: '10',
                    name: 'lang.wms.common.enum.NoteTypeEnum.LHCK'
                  },
                  {
                    code: '11',
                    name: 'lang.wms.common.enum.NoteTypeEnum.BHCK'
                  },
                  {
                    code: '19',
                    name: 'lang.wms.common.enum.NoteTypeEnum.FPCK'
                  },
                  {
                    code: '20',
                    name: 'lang.wms.common.enum.NoteTypeEnum.JITCK'
                  },
                  {
                    code: '21',
                    name: 'lang.wms.common.enum.NoteTypeEnum.KLTHCK'
                  },
                  {
                    code: '22',
                    name: 'lang.wms.common.enum.NoteTypeEnum.KLFXCK'
                  },
                  {
                    code: '23',
                    name: 'lang.wms.common.enum.NoteTypeEnum.KLZCCK'
                  },
                  {
                    code: '25',
                    name: 'lang.wms.common.enum.NoteTypeEnum.KLTGCK'
                  },
                  {
                    code: '26',
                    name: 'lang.wms.common.enum.NoteTypeEnum.KLPOPCK'
                  },
                  {
                    code: '30',
                    name: 'lang.wms.common.enum.NoteTypeEnum.QSDDCK'
                  },
                  {
                    code: '40',
                    name: 'lang.wms.common.enum.NoteTypeEnum.HGCYDDCK'
                  },
                  {
                    code: '6',
                    name: 'lang.wms.common.enum.NoteTypeEnum.PKCK'
                  },
                  {
                    code: '48',
                    name: 'lang.wms.common.enum.NoteTypeEnum.HHCK'
                  },
                  {
                    code: '49',
                    name: 'lang.wms.common.enum.NoteTypeEnum.BFCK'
                  },
                  {
                    code: '38',
                    name: 'lang.wms.common.enum.NoteTypeEnum.TZDDCK'
                  },
                  {
                    code: '39',
                    name: 'lang.wms.common.enum.NoteTypeEnum.CNJGDDCK'
                  },
                  {
                    code: '50',
                    name: 'lang.wms.common.enum.NoteTypeEnum.QDDD'
                  }
                ]
              }
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398202,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'shopCode',
            columnName: 'lang.wms.fed.shopCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398204,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'shop',
            columnName: 'lang.wms.fed.shop',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'shop',
              hitColumns: [
                'shopCode'
              ]
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398206,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'platformCode',
            columnName: 'lang.wms.fed.platformCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398207,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'platform',
            columnName: 'lang.wms.fed.platform',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'platform',
              hitColumns: [
                'platformCode'
              ]
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398208,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'carrierCode',
            columnName: 'lang.wms.fed.carrierCoding',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398210,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'carrier',
            columnName: 'lang.wms.fed.carrier',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'entity',
            columnDataDesp: {
              entityTypeCode: 'carrier',
              hitColumns: [
                'carrierCode'
              ]
            },
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398211,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'shopName',
            columnName: 'lang.wms.fed.shopName',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398213,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'platformName',
            columnName: 'lang.wms.fed.platformName',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398214,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'carrierName',
            columnName: 'lang.wms.fed.nameOfCarrier',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398215,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'brandCode',
            columnName: 'lang.wms.fed.brandCode',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398216,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'brandName',
            columnName: 'lang.wms.fed.brandName',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398217,
            columnDataEntity: null
          },
          {
            entityTypeCode: 'pickingTaskDetail',
            columnCode: 'logicAreaId',
            columnName: 'lang.wms.base.base.bssSku.logicAreaId',
            columnGroup: '',
            columnIntr: '',
            columnDataType: 'text',
            columnDataDesp: {},
            columnBindCode1: '',
            columnBindCode2: '',
            columnBindCode3: '',
            columnBindCode4: '',
            creator: '',
            creationTime: 0,
            modifier: '',
            modifyTime: 0,
            status: 0,
            autoUpdateTime: 1634624398177,
            columnDataEntity: null
          }
        ]
      }
    }
  ]
}
export const handleMetaDataMock = params => {
  return new Promise((resolve, reject) => {
    let { entityTypes = [] } = ruleType?.rulesDesp?.rules || []
    const specColumns = entityTypes.flatMap(entity => entity.columns?.map(c => entity.code + '.' + c)).filter(Boolean);
    let model = [em].flatMap(item => {
      return item && getModel(item.columns, [item.entityTypeCode], [transMsg(item.entityTypeName)])
    }).filter(Boolean).filter(item => specColumns.includes(item.code))
    resolve(model)

  })
}

export const handleMetaData = params => {
  return new Promise(resolve => {
    GET('/api/common/rules/type/getRuleType/v1', params).then(data => {
      let { entityTypes = [] } = data.data?.rulesDesp?.rules || []
      Promise.all(
        entityTypes.map(
          entity =>
            new Promise(resolve =>
              GET('/api/common/metadata/entityTypeTree/v1', {
                entityTypeCode: entity.code,
                entityDepth: entity.entityDepth
              }).then(e => resolve(e.data))
            )
        )
      ).then(data => {
        const specColumns = entityTypes.flatMap(entity => entity.columns?.map(c => entity.code + '.' + c)).filter(Boolean)
        let model = data.flatMap(item => item && getModel(item.columns,
          [item.entityTypeCode],
          [transMsg(item.entityTypeName)]
        )
        ).filter(Boolean).filter(item => specColumns.includes(item.code))
        Promise.all(model.map(res => {
          if (res.enumeration?.type == 'http') {
            return getServiceData(res)
          } else if (res.type == 'int') {
                      res.enumeration?.list?.map(res => res.code = parseInt(res.code))
          }
          return res
        })).then(res => {
          resolve(res)
        })
        // console.log(model)
        // resolve(model)
      })
        .catch(() => {
          resolve()
          Vue.prototype.$message({
            type: 'warning',
            message: transMsg('lang.wms.fed.operationFailed')
          })
        })
    })
      .catch(() => {
        resolve()
        Vue.prototype.$message({
          type: 'warning',
          message: transMsg('lang.wms.fed.operationFailed')
        })
      })
  })
}

export const handleMetaDataSingle = params => {
  return new Promise(resolve => {
    GET('/api/common/metadata/entityTypeTree/v1', params).then(data => {
      if (!data.code && data.data) {
        let model = getModel(
          data.data.columns,
          [data.data.entityTypeCode],
          [transMsg(data.data.entityTypeName)]
        ).filter(Boolean);
        resolve(model)
      }
    });
  })
}


function getModel(models, ns, nns) {
  return models.flatMap(model => {
    const item = {
      code: [...ns, model.columnCode].join('.'),
      name: [...nns, transMsg(model.columnName)].join('.'),
      type: model.columnDataType,
      validate: model.columnDataDesp?.validate || {},
      ...model.columnDataDesp
    }
    if (model.columnDataDesp?.enumeration?.type == 'http') {
      return { ...item, option: { mapping: model.columnDataDesp?.enumeration?.mapping || {}, ...model.columnDataDesp?.enumeration?.rest } }
    } else if (model.columnDataType === 'entity') {
      if (Array.isArray(model.columnDataEntity?.columns)) {
        return getModel(
          model.columnDataEntity.columns,
          [...ns, model.columnCode],
          [...nns, transMsg(model.columnName)]
        )
      }
      return null
    }
    return item
  })
}

const DictAjax = { get: GET, post: POST };
function getServiceData({ option, type, key, ...rest }) {
  return new Promise((resolve, reject) => {
    DictAjax[option.method](option.url, option.params || {}).then(data => {
      if (type === 'tree') {
        // 平铺树结构返回drop格式
        function flatMap(nodes) {
          let drop = {}
          let children = option.mapping.children
          nodes.forEach(n => {
            drop[n[option.mapping.code]] = transMsg(n[option.mapping.name])
            n[option.mapping.name] = transMsg(n[option.mapping.name])
            // 遍历增加mapping对象
            n.mapping = { ...option.mapping, key: rest.code }
            if (n[children] && n[children].length) {
              drop = { ...drop, ...flatMap(n[children]) }
            }
          })
          return drop
        }
        let treeDrop = flatMap(data.data)
        let tree = {
          drop: treeDrop,
          list: data.data,
          type: 'http'
        }
        console.log({ ...rest, ...option, type, enumeration: tree })
        resolve({ ...rest, ...option, type, enumeration: tree })
      }
      let arr = null
      if (option.object) {
        data = Object.entries(data.data).map(([key, value]) => ({ key, value }))
        option.mapping.name = 'value'
        option.mapping.code = 'key'
      } else {
        arr = option.list.split('.');
        arr.map(res => {
          data = data[res];
        })
      }
      let isUrl = data.find(res => String(res[option?.mapping?.code]).includes(','))
      let list = data?.map(res => {
        let code = res[option?.mapping?.code] || '';
        if (isUrl || res.code == 'pickingTask.seedingWallId') {
          if (type == 'int') {
            code = code?.split(',').map(res => res && parseInt(res))
          } else {
            code = code?.split(',')
          }
        }

        return { name: res[option?.mapping?.name], code, tooltipDisabled: false }
      })
      let enumeration = {
        list,
        type: 'http'
      }
      resolve({ ...rest, type, enumeration })
    })
  })
}

export const handleOperationList = () => {
  return {
    list1: ['eq', 'ne'],
    list2: ['eq', 'ne', 'gt', 'lt', 'ge', 'le'],
    list3: ['eq', 'ne', 'in', 'nin']
  }
}
