<template>
    <div class="exception-container">
			<div class="search-box">
				<el-form :model="form" ref="searchForm" :rules="rules" :label-position="'left'">
					<el-row>
                        <el-col :span="6">
							<el-form-item prop="exceptionCode" :label='$t("lang.common.exceptionManage.exceptionCode")'>
								<el-input clearable v-model="form.exceptionCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
                        <el-col :span="6">
							<el-form-item prop="exceptionName" :label='$t("lang.common.exceptionManage.exceptionName")'>
								<el-input clearable v-model="form.exceptionName" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="systemStr" :label='$t("lang.common.exceptionManage.system")'>
								<el-select multiple clearable v-model="form.systemStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item  prop="moduleStr" :label='$t("lang.common.exceptionManage.module")'>
								<el-select multiple clearable v-model="form.moduleStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.moduleList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row  v-if="!newFold">
                        <el-col :span="6">
							<el-form-item  prop="subModuleStr" :label='$t("lang.common.exceptionManage.subModule")'>
								<el-select multiple clearable v-model="form.subModuleStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item  prop="exceptionType" :label='$t("lang.common.exceptionManage.exceptionType")'>
								<el-select clearable v-model="form.exceptionType" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.exceptionTypeList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item  prop="severityStr" :label='$t("lang.common.exceptionManage.severity")'>
								<el-select multiple clearable v-model="form.severityStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.severityList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item  prop="priorityStr" :label='$t("lang.common.exceptionManage.priority")'>
								<el-select multiple clearable v-model="form.priorityStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.priorityList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="!newFold">
                        <el-col :span="6">
							<el-form-item  prop="selfHealingStr" :label='$t("lang.common.exceptionManage.selfHealing")'>
								<el-select multiple clearable v-model="form.selfHealingStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.selfHealingList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="needReportStr" :label='$t("lang.common.exceptionManage.needReport")'>
								<el-select multiple clearable v-model="form.needReportStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.needReportList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="reportSystemStr" :label='$t("lang.common.exceptionManage.reportSystem")'>
								<el-select multiple clearable v-model="form.reportSystemStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="status" :label='$t("lang.common.exceptionManage.status")'>
								<el-select clearable v-model="form.status" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.statusList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>

					<el-row v-if="!newFold">
						<el-col :span="6">
							<el-form-item prop="creator" :label='$t("lang.common.exceptionManage.creator")'>
								<el-input clearable v-model="form.creator" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="createAtStr" :label='$t("lang.common.exceptionManage.createAt")'>
								<el-date-picker
									v-model="form.createAtStr"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('lang.common.exceptionManage.startTime')"
									:end-placeholder="$t('lang.common.exceptionManage.startTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="updator" :label='$t("lang.common.exceptionManage.updator")'>
								<el-input clearable v-model="form.updator" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="updateAtStr" :label='$t("lang.common.exceptionManage.updateAt")'>
								<el-date-picker
									v-model="form.updateAtStr"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('lang.common.exceptionManage.startTime')"
									:end-placeholder="$t('lang.common.exceptionManage.startTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<div class="search-btns">
							<el-button size="medium" plain @click="reset()">{{$t('lang.common.exceptionManage.reset')}}</el-button>
							<el-button size="medium" type="primary" @click="search()">{{$t('lang.common.exceptionManage.query')}}</el-button>
							<div v-if="newFold" class="fold-wrapper">
								<span class="switch-up" @click="foldBox"><i class="el-icon-arrow-down"></i></span>
							</div>
							<div v-else class="fold-wrapper">
								<span class="switch-up" @click="foldBox"><i class="el-icon-arrow-up"></i></span>
							</div>
						</div>
					</el-row>
				</el-form>
			</div>
			<div class="table-area">
				<!-- <div class="main-opts">
					<el-button size="medium" type="primary" @click="upload()">{{$t('处理方案上传')}}</el-button>
					<el-button size="medium" type="primary" @click="exportFile()">{{$t('导出')}}</el-button>
				</div> -->
				<el-table
					ref="mainTable"
					:data="tableData"
                     border
                    highlight-current-row
					height="500"
					style="width: 100%">
					<el-table-column
					prop="index"
					:label="$t('lang.common.exceptionManage.index')"
						width="50">
						<template slot-scope="scope">
							{{scope.$index + 1}}
					</template>
					</el-table-column>
					<el-table-column
					width="150"
					prop="exceptionCode"
					:label="$t('lang.common.exceptionManage.exceptionCode')">
					<template slot-scope="scope">
						<el-link
							@click.native.prevent="openDetail(scope.row, tableData)"
							type="primary"
							size="small">
							{{scope.row.exceptionCode}}
						</el-link>
					</template>
					</el-table-column>
					<el-table-column
					prop="exceptionCode"
					:label="$t('lang.common.exceptionManage.exceptionName')">
					<template slot-scope="scope">
							{{drop.exceptionCodesHandleOptions[scope.row.exceptionCode] || scope.row.exceptionCode}}
					</template>
					</el-table-column>
					<el-table-column
					prop="taskCode"
					:label="$t('lang.common.exceptionManage.system')">
					</el-table-column>
					<el-table-column
					width="150"
					prop="parentTaskCode"
					:label="$t('lang.common.exceptionManage.module')">
					</el-table-column>
					<el-table-column
					prop="deviceType"
					:label="$t('lang.common.exceptionManage.subModule')">
					<template slot-scope="scope">
						{{drop.deviceTypeList[scope.row.deviceType] || scope.row.deviceType || ''}}
					</template>
					</el-table-column>
					<el-table-column
					prop="deviceCode"
					:label="$t('lang.common.exceptionManage.exceptionType')">
					</el-table-column>
					<el-table-column
					prop="exceptionCode"
					:label="$t('lang.common.exceptionManage.exceptionCode')">
					</el-table-column>
                    <el-table-column
					prop="severityStr"
					:label="$t('lang.common.exceptionManage.severity')">
					<template slot-scope="scope">
							{{drop.severityList[scope.row.severity] || scope.row.severity}}
					</template>
					</el-table-column>
					<el-table-column
					prop="priorityStr"
					:label="$t('lang.common.exceptionManage.priority')">
					<template slot-scope="scope">
							{{drop.priorityList[scope.row.priority] || scope.row.priority}}
					</template>
					</el-table-column>
	
					<!-- <el-table-column
					prop="selfHealing"
					:label="$t('可执行操作')">
					<template slot-scope="scope">
							{{drop.selfHealingList[scope.row.selfHealing] || scope.row.selfHealing}}
					</template>
					</el-table-column> -->
					<el-table-column
					prop="selfHealing"
					:label="$t('lang.common.exceptionManage.selfHealing')">
					<template slot-scope="scope">
							{{drop.selfHealingList[scope.row.selfHealing] || scope.row.selfHealing}}
					</template>
					</el-table-column>
					<el-table-column
					prop="needReport"
					:label="$t('lang.common.exceptionManage.needReport')">
					<template slot-scope="scope">
							{{drop.needReportList[scope.row.needReport] || scope.row.needReport}}
					</template>
					</el-table-column>
					<el-table-column
					prop="reportSystemStr"
					:label="$t('lang.common.exceptionManage.reportSystem')">
					<template slot-scope="scope">
							{{drop.systemList[scope.row.reportSystem] || scope.row.reportSystem}}
					</template>
					</el-table-column>
                    <el-table-column
					prop="statusStr"
					:label="$t('lang.common.exceptionManage.status')">
					<template slot-scope="scope">
							{{drop.statusList[scope.row.status] || scope.row.status}}
					</template>
					</el-table-column>
                    <el-table-column
					prop="remark"
					:label="$t('lang.common.exceptionManage.remark')">
					</el-table-column>
					<el-table-column
					prop="creator"
					:label="$t('lang.common.exceptionManage.creator')">
					<template slot-scope="scope">
							{{scope.row.creator}}
					</template>
					</el-table-column>
					<el-table-column
					prop="createAtStr"
					width="150"
					:label="$t('lang.common.exceptionManage.createAt')">
					<template slot-scope="scope">
						{{format(scope.row.createAt)}}
					</template>
					</el-table-column>
					<el-table-column
					prop="updator"
					:label="$t('lang.common.exceptionManage.updator')">
					<template slot-scope="scope">
							{{scope.row.updator}}
					</template>
					</el-table-column>
					<el-table-column
					prop="updateAtStr"
					width="150"
					:label="$t('lang.common.exceptionManage.updateAt')">
					<template slot-scope="scope">
						{{format(scope.row.updateAt)}}
					</template>
					</el-table-column>
					<!-- <el-table-column
					fixed="right"
					:label="$t('lang.common.exceptionManage.operation')"
					width="100">
					<template slot-scope="scope"> -->
							<!-- <el-button  @click.stop="handleManul(scope.row)" type="text" size="small">{{$t('手动处理')}}</el-button> -->
							<!-- <el-button  @click.stop="reSend(scope.row)" type="text" size="small">{{$t('重新下发')}}</el-button>
							<el-button  @click.stop="cancel(scope.row)"  type="text" size="small">{{$t('取消')}}</el-button>
							<el-button  @click.stop="resetRow(scope.row)"  type="text" size="small">{{$t('重置')}}</el-button> -->
					<!-- </template>
					</el-table-column> -->
				</el-table>
				<div style="display:flex; justify-content:right;">
						<el-pagination
						style="padding: 10px"
						border
						:current-page="pageData.pageNo"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="pageData.pageSize"
						layout="total, sizes, prev, pager, next"
						:total="pageData.recordCount"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange">
						</el-pagination>
				</div>
			</div>

		</div>
</template>

<script>
// import { Message } from 'geekplus-ui';
import axios from "axios";
import { initParams, getTable, exportTable, getExportFields } from '../../../api/exceptionDict'
export default {
	name: 'GeekExceptionDict',
    options: {
        name: '异常字典',
        parents: ['all'], // all
        groups: ['自定义页面'],
        events: {
            cancleEdit: '取消',
            closeEdit: '关闭'
        }
    },
	data() {
		return {
			detail: {},
			params: {},
            rules: {},
			drop: {
				"exceptionTypeList": {},
				"severityList": {},
				"priorityList": {},
				"selfHealingList": {},
				"needReportList": {},
				"statusList": {},
				"exceptionCodesHandleOptions": {},
				"systemList": {},
				"taskPhaseList": {},
				"deviceTypeList": {},
				"warehouseAreaList": {},
				"logicAreaList": {}
			},
			handleForm: { // 手动处理
				row: {},
				reason: ''
			},
			form: {
				exceptionCode: '',
                exceptionName: '',
                systemStr: '',
                moduleStr: '',
                subModuleStr: '',
                exceptionType: '',
                severityStr: '',
                priorityStr: '',
                selfHealingStr: '',
                needReportStr: '',
                reportSystemStr: '',
                status: '',
                creator: '',
                createAtStr: '',
                updator: '',
                updateAtStr: '',
			},
			pageData: {
				"pageNo": 1,
				"pageSize": 10,
				"recordCount": 0,
				"pageCount": 0,
			},
			detailData: '',
			tableData: [],
			dialogTableVisible: false,
			showManualDialog: false,
			showExportDialog: false,
			showManualUpload: false,
			isIndeterminate: false,
			checkAll: false,
			fileList: [],
			newFold: true,
			fileName: '',
			activeName: 'first',
			tableList: [],
		}
	},
	async created (){
		// 获取基础数据
		await initParams().then(res => {
			console.log(res, 1111)
			if(!res.code) {
				this.params = res.data || {};
				for (let key in this.params) {
					const keyMap = {}
					this.params[key].map(v => {
						keyMap[v.code] = this.$t(v.name);
						return v;
					})
					this.drop[key] = keyMap;
				}
			}
		})
		this.initTable();
	},
	methods: {
		handleTabClick() {

		},
		// 折叠
		foldBox() {
			this.newFold = !this.newFold;
		},
		handleManualClose() {
			this.dialog.showManualDialog = false;
		},
		handleManualUploadClose() {
			this.dialog.showManualUpload = false;
		},
		handleManualConfirm() {

		},
		initTable(detailData) {
			const {pageSize, pageNo} = this.pageData;
			const keys = Object.keys(this.form);
			const transParams = {};
			keys.map(v => {
				let data = this.form[v] || '';
				let val = data;
				if (data && Array.isArray(data)) {
					val = data.join(',')
				};
				transParams[v] = val;
				return v
			})
			const params = {
				pageSize, 
				pageNo, 
				...transParams,
				createAtStr: this.form.createAtStr.length ? this.form.createAtStr.map(v => new Date(v).getTime()).join('-') : '',
				updateAtStr: this.form.updateAtStr.length ? this.form.updateAtStr.map(v => new Date(v).getTime()).join('-') : '',
			};
			getTable(params).then(res => {
				console.log(res)
				if(!res.code) {
					this.tableData = res.data?.recordList || [];
					this.pageData.pageNo = res.data?.currentPage;
                    this.pageData.recordCount = res.data?.recordCount
				}
			})
		},
		search() {
			this.pageData.pageNo = 1;
			this.initTable();
		},
		reset() {
			this.$refs.searchForm.resetFields();
			// this.initTable();
		},
		// 手动处理弹框关闭
		handleCancel() {
			this.showManualDialog = false;
			this.handleForm = {
				row: '',
				reason: ''
			};
		},
		// 重新下发异常
		reSend(row, type){
		},
		// 取消异常
		cancel(row, type){
		},
		// 重置异常
		resetRow(row, type){
		},
		handleSizeChange(val) {
			this.pageData.pageSize = val;
			this.pageData.pageNo = 1;
			this.initTable();
		},
		handleCurrentChange(val) {
			this.pageData.pageNo = val;
			this.initTable();
		},
		// 关闭异常信息详情
		dialogCancel() {
			this.detail = {};
			this.dialogTableVisible = false;
		},
		// 打开异常信息详情
		openDetail(row) {
			this.detail = row;
			this.dialogTableVisible = true;
		},
		handleEmit() {

		},
		handleCheckAllChange() {

		},
		// 导出按钮
		exportFile() {
			this.showExportDialog = true;
		},
		// 导出确定按钮
		exportFileSubmit() {
			const fields = this.formExport.fileds.join(',')
			let searchKeys = '';
			for (let key in this.form) {
				searchKeys += '' + key + this.form[key];
			};
			console.log(fields)
			this.exportList(`/beetle/api/common/exception-manage/exception-record/export?params=${searchKeys}&exportFields=${fields}`);
			this.closeExport();
		},
		closeExport() {
			this.showExportDialog = false;
			this.formExport = {
				fileType: 'excel',
				fileds: [],
			}
		},
		upload() {
			this.showManualUpload = true;
		},
		// 上传服务器
		submitUpload() {
			if (this.$refs.upload.uploadFiles.length < 1) {
				this.$message({
					message: this.$t('lang.common.exceptionManage.selectFile'),
					type: 'error',
					duration: 3000,
				});
				return;
			}
			this.$refs.upload.submit();
		},
		// 文件上传时
		beforeUpload() {
		},
		// 上传成功
		uploadSuccess(response, file, fileList) {
			if (response.code === 0) {
				const resData = response.data;
				this.$message({
					message: this.$t('lang.wms.fed.importSuccess'),
					type: 'success',
					duration: 3000,
				});
				this.showManualUpload = false;
			} else {
				this.$message({
					message: this.$t('lang.wms.fed.importSuccess'),
					type: 'error',
					duration: 3000,
				});
				this.fileList = [];
			}
		},
		// 上传失败
		chooseFile(file, fileList) {
			console.log(file, fileList);
			this.fileName = file.name
		},
		beforeRemove() {

		},
		handleExceed() {

		},
		handlePreview() {

		},
		handleRemove() {

		},
		// TODO: 打开处理方案
		openPdf(row) {
			// let url = (`/beetle/api/common/exception-manage/sop/download?system=${window.system || 'GPS'}&languageCode=${window.curLanguage || localStorage.getItem('curLanguage') || 'zh_cn'}#${row.exceptionCode}`);
			// this.exportList(url)
			window.open(`/beetle/api/common/exception-manage/sop/download?system=${window.system || 'GPS'}&languageCode=${window.curLanguage || localStorage.getItem('curLanguage') || 'zh_cn'}#${row.exceptionCode}`);
		},
		// 下载
		exportList(url) {
			if (document.querySelector('#download-iframe')) {
				document.getElementById('download-iframe').remove();
			}
			const iframe = document.createElement('iframe');
			iframe.onload = (e) => {
				const data = JSON.parse(e.target.contentWindow.document.body.innerText);
				this.$message({
					message: this.$t(data.msg),
					type: 'error',
					duration: 3000,
					showClose: true,
				});
			};
			iframe.setAttribute('id', 'download-iframe');
			iframe.src = url;
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		},
		format(date, formatter='YYYY-MM-DD HH:mm:ss') {
			let d = date
			if (d === 0 || d === '') {
			return ''
			} else if (typeof d === 'string') {
			if (/^\d+$/.test(d)) {
				d = new Date(+d)
			} else {
				d = new Date(d)
			}
			} else if (Number.isInteger(d)) {
			d = new Date(d)
			}
		
			if (d instanceof Date && !isNaN(d.getTime())) {
			const year = d.getFullYear()
			const month = d.getMonth() + 1
			const day = d.getDate()
			const h = d.getHours()
			const m = d.getMinutes()
			const s = d.getSeconds()
		
			return formatter
				.replace(/yyyy/i, year)
				.replace(/yy/i, year.toString().substr(2))
				.replace('MM', month.toString().padStart(2, '0'))
				.replace('M', month)
				.replace(/dd/i, day.toString().padStart(2, '0'))
				.replace(/d/i, day)
				.replace(/hh/i, h.toString().padStart(2, '0'))
				.replace('mm', m.toString().padStart(2, '0'))
				.replace(/ss/i, s.toString().padStart(2, '0'))
			}
			return date ?? ''
		}
		
	},
	destroyed() {
	}
}
</script>

<style lang="scss">
.except-dialog{
	.el-dialog__body{
		padding-top: 0;
	}
}

.exception-message{
	z-index: 9999 !important;
}
.exception-container{
	.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
		width: 100% !important;
		line-height: 40px !important;
		height: 40px !important;
	}
	.export-key-list {
		margin-left: 1%;
		.el-checkbox{
			width: 16%;
			margin-right: 2%;
		}
	}
	.el-table .cell {
		white-space: nowrap;
		// overflow: inherit;
		text-overflow: initial;
		word-wrap: break-word;
		word-break: break-all;
	}
	.switch-up{
		color: #409eff;
		cursor: pointer;
	}
	.fold-wrapper{
		margin-left: 10px;
		line-height: 40px;
	}
}
</style>
<style lang="scss" scoped>
.dialog-footer{
	text-align: center;
}
.el-form-item{
	text-align: left;
}
.exception-container{
    background: #eee;
}
.search-box{
	padding: 20px;
	background: #fff;
	.el-form-item {
			padding-right: 20px;
	}
	::v-deep {
			.el-form--label-top .el-form-item__label{
					padding: 0;
					line-height:24px;
			}
	}
	.el-select{
			width: 100%;
	}
	.search-btns{
			display: flex;
			flex-direction: row;
			justify-content: right;
	}
}
.main-opts{
	margin-bottom: 10px;
  display: flex;
  justify-content: right;
}
.table-area{
	margin: 20px 0;
	padding: 20px;
	background: #fff;
	// display: flex;
	// flex-direction: column;
	// flex:1;
	width: 100%;
	box-sizing: border-box;
} 
.el-table {
	::v-deep .current-row{
			.cell{
					color: #409eff;
			}
	}
}
.except-title{
	padding-right: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.except-detail-dialog{
  display: flex;
  flex-direction: column;
  flex:1;
	.except-title{
		display: flex;
		justify-content: space-between;
	}
  .dialog-main{
     display: flex;
    flex-direction: row;
    flex:1;
  }
  .log-info{
    min-height: 300px;
    max-height: 600px;
    overflow: auto;
  }
}
.gp-form{
  .gp-select{
    width: 100%;
  }
}
.dialog-footer{
  margin-top: 20px;
  text-align: center;
}
</style>