<template>
    <el-dialog :title="$t('lang.wms.fed.modifyThePassword')"
    visible="true"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
     width="500px"
    @close="close">
      <el-form class="pasword-content" ref="form" :rules="rules" :model="form" size="small" label-width="auto">
        <el-form-item :label="$t('lang.wms.fed.oldPassword')" prop="oldPassword">
              <el-input v-model="form.oldPassword" maxlength="32" minlength="6" :placeholder="$t('lang.wms.fed.pleaseEnter')" style="width: 250px"></el-input>
            </el-form-item>

            <el-form-item :label="$t('lang.wms.fed.newPassword')" prop="password">
              <el-input v-model="form.password" maxlength="32" minlength="6" :placeholder="$t('lang.wms.fed.pleaseEnter')" style="width: 250px"></el-input>
            </el-form-item>
        <el-form-item :label="$t('lang.wms.fed.duplicatePassword')" prop="rePassword">
              <el-input v-model="form.rePassword" maxlength="32" minlength="6" :placeholder="$t('lang.wms.fed.pleaseEnter')" style="width: 250px"></el-input>
            </el-form-item>
        <el-row>
          <el-col  :span="24">
            <div class="container-dialog-btns">
              <el-button size="menium" @click="close">{{ $t('lang.wms.fed.cancel') }}</el-button>
              <el-button type="primary" size="menium"  @click="submitForm">{{ $t('lang.wms.fed.save') }}</el-button>
            </div>
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
</template>

<script>
import { GET, POST } from '@/utils/http'

export default {
  data() {
    return {
      form: {
        oldPassword: '',
        password: '',
        rePassword: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: this.$t('lang.wms.fed.pleaseInputAPassword'), trigger: 'change' }
        ],
        password: [
          { required: true, message: this.$t('lang.wms.fed.pleaseEnterANewPassword'), trigger: 'change' },
          {
            min: 6,
            message: this.$t('lang.wms.fed.lengthMustNotBeShorterThan') + 6
          },
          {
            max: 32,
            message: this.$t('lang.wms.fed.limitNumWithinCharacterLength', { num: 32 })
          }
        ],
        rePassword: [
          { required: true, message: this.$t('lang.wms.fed.pleaseRepeatTheNewPassword'), trigger: 'change' },
          {
            min: 6,
            message: this.$t('lang.wms.fed.lengthMustNotBeShorterThan') + 6
          },
          {
            max: 32,
            message: this.$t('lang.wms.fed.limitNumWithinCharacterLength', { num: 32 })
          }
        ]
      }
    }
  },
  created() {
  },
  methods: {
    close() {
      this.$store.state.isShowPassWord = false
    },
    submitForm() {
      if (this.form.password && this.form.password != this.form.rePassword) {
        this.$message.error(this.$t('lang.wms.fed.reSetPasswordNotMatch'));
        return;
      }
      this.$refs.form.validate(valid => {
        if (valid) {
          POST('/api/coreresource/auth/user/updatePassword/v1', this.form).then(res => {
            if (!res.code) {
              this.$message.success(this.$t('lang.wms.fed.saveSuccess'));
              this.close();
              // this.$emit('submit', params);
            }
          })
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.pasword-content{
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
