<template>
  <div :class="{ 'geek': true, 'geek-iframe': geekIframe}" id="geek-container" v-loading="loading" >
    <leivii v-if="data" :data="data" />
    <iframe v-else-if="apolloPage" :src="apolloPage" class="iframe" frameborder="0" @load="handleLoad" />
  </div>
</template>

<script>
import axios, { GET } from '@/utils/http'

export default {
  data() {
    return {
      loading: false,
      data: null,
      id: this.$route.meta.id,
      meta: this.$route.meta,
      apolloPage: null,
      geekIframe: false,
    }
  },
  created() {
    this.initPage(this.$route.query?.v);
    // this.loadDataView(this.id)
  },
  activated() {
    const scrollTop = this.$route.meta.scrollTop || 0;
    const $content = document.querySelector('.geek-page');
    if (scrollTop && $content) {
      $content.scrollTop = scrollTop;
    }
    // this.initPage(this.$route.query?.v);
  },
  deactivated() {
    !this.meta.cache && this.clearCache()
  },
  methods: {
    loadDataView(name) {
      axios.create({ baseURL: '' })
        .get(`${process.env.VUE_APP_VIEW_PATH}${name}.json`)
        .then(data => {
          this.data = Object.assign({}, data.data)
          // 如果是iframe页面
          if(data.data?.$body[0]?.$type == 'geek-web-view') {
            this.geekIframe = true;
          }
        })
        .catch(err => {
          this.data = null
          this.$message({ type: 'warning', message: this.$t('lang.wms.fed.noThisPage') })
        })
    },
    initPage(version) {
      // 换肤可通过scss变量
      // window.document.getElementsByTagName('body')[0].style.setProperty('--systemTheme', 'yellow')
      const loading = this.$loading()
      GET(`/api/lowcode/${this.id}${version ? '?version=' + version : ''}`).then(data => {
        try {
          if (data.data.content && data.data?.active) {
            this.data = JSON.parse(data.data.content)
          } else {
            this.loadDataView(this.id);
          }
        } catch {
          this.loadDataView(this.id);
        }
      }).catch(e => {
        this.loadDataView(this.id);
      }).finally(() => loading.close())
    },
    handleLoad() {
      this.loading = false
    },
    clearCache() {
      if (this.$vnode.data.keepAlive) {
        if (this.$vnode.parent?.componentInstance?.cache) {
          if (this.$vnode.componentOptions) {
            let key = this.$vnode.key == null ? this.$vnode.componentOptions.Ctor.cid + (this.$vnode.componentOptions.tag ? `::${this.$vnode.componentOptions.tag}` : '') : this.$vnode.key
            let cache = this.$vnode.parent.componentInstance.cache
            let keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                let index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
  }
}
</script>

<style lang="scss" scoped>
.geek {
  width: 100%;
  height: 100%;
  padding: 8px;
  box-sizing: border-box;
  &.geek-iframe {
    padding: 0px;
  }
  .iframe {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>
