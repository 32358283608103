<template>
  <div class="geek-qr-code" :style="styles">
    <vue-qr :text="textNew" :size="size"></vue-qr>
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  name: 'GeekVueQr',
  options: {
    name: '二维码',
    parents: ['all'], // all
    groups: ['container']
  },
  components: {
    VueQr
  },
  computed: {
    styles() {
      return [
        'display: flex',
        `justify-content: ${this.justify}`,
        `align-items: ${this.align}`,
        `width: ${this.width}`,
        `height: ${this.height}`
      ].join(';')
    },
    textNew() {
      console.log('text', this.text);
      return this.text
    }
  },
  props: {
    $body: {
      ignore: true
    },
    text: {
      type: String,
      clazz: 'Expression',
      label: 'lang.prop.text',
      default: ''
    },
    size: {
      type: Number,
      label: 'lang.prop.size',
      default: 200
    },
    justify: {
      type: String,
      default: 'center',
      label: 'lang.prop.justify'
    },
    align: {
      type: String,
      default: 'top',
      label: 'lang.prop.align'
    },
    width: {
      type: String,
      default: 'auto',
      label: 'lang.prop.width'
    },
    height: {
      type: String,
      default: '100%',
      label: 'lang.prop.height'
    }
  }
}
</script>

<style>
.geek-qr-code {

}
</style>
