<template>
  <span class="fn-item item-fullScreen" :class="{'is-active': isFullScreen}" @click="handleFullScreen" :title="isFullScreen ? $t('lang.wms.fed.exitFullScreen') : $t('lang.wms.fed.fullScreen')">
    <i class="el-icon-full-screen" />
  </span>
</template>

<script>
export default {
  name: 'FullScreen',
  data() {
    return {
      isFullScreen: false
    }
  },
  mounted() {
    this.getScreenResult()
    window.addEventListener('resize', () => {
      setTimeout(this.getScreenResult, 300)
    })
  },
  methods: {
    handleFullScreen() {
      this.isFullScreen ? this.exitFullscreen() : this.fullScreen()
    },
    fullScreen() {
      const ele = document.querySelector('body')
      if (ele.requestFullscreen) {
        ele.requestFullscreen()
      } else if (ele.mozRequestFullScreen) {
        ele.mozRequestFullScreen()
      } else if (ele.webkitRequestFullscreen) {
        ele.webkitRequestFullscreen()
      } else if (ele.msRequestFullscreen) {
        ele.msRequestFullscreen()
      }
    },
    exitFullscreen() {
      if (document.exitFullScreen) {
        document.exitFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    },
    getScreenResult() {
      this.isFullScreen = document.fullscreen ||
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen ||
        document.msFullScreen
    }
  }
}
</script>

<style lang="scss">
.item-fullScreen {
  i {
    font-size: 28px;
    color: #999;
    cursor: pointer;
    line-height: 50px;
    &:hover {
      color: #409EFF;
    }
  }
  &.is-active {
    i {
      color: #409EFF;
    }
  }
}
</style>
