import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import { createI18n } from './i18n'
import axios from './utils/http'
import getAuth from './utils/auth'
import geekDB from './utils/geekDB'
import { transMsg } from './utils/utils';
import GeekScriptEditor from 'geek-script-editor'
import 'geek-script-editor/lib/geek-script-editor.css'
import storage from './utils/storage';
import { handleMetaData, handleMetaDataSingle, handleOperationList } from './utils/metaData';
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/validator'
import { Print, checkPrint } from 'geek-print-config-470'
import VueClipboard from 'vue-clipboard2';
// import Charts from './views/components/Charts'
import TableCard from './views/components/TableCard'
import PrintModelConfig from './views/components/printModelConfig'
import vueQr from './views/components/vueQr'
import CopyBtn from './views/components/CopyBtn'
import expressionRuleTable from './views/components/expressionRuleTable'
import expressionRuleTableByCustom from './views/components/expressionRuleTableByCustom'
import ExportConfig from './views/components/ExportConfig'
import ExportLanguage from './views/components/ExportLanguage'
import seedWall from './views/components/seedWall'
import SelectColorBar from './views/components/SelectColorBar'
import SelectDownloadBtn from './views/components/SelectDownloadBtn'
import Crontab from './views/components/Crontab/Crontab'
import DynamicInput from './views/components/dynamicInput'
import DiffReason from './views/components/diffReason'
// 自定义页面
import shelfEnter from './views/diyPages/shelfExitAndEnter/shelfEnter'
import shelfExit from './views/diyPages/shelfExitAndEnter/shelfExit'
import exceptionManage from './views/diyPages/exceptionManage'
import exceptionLog from './views/diyPages/exceptionLog'
import exceptionDict from './views/diyPages/exceptionDict'
import deviceErrorHandle from './views/diyPages/deviceErrorHandle'
import wallStraytegy from './views/diyPages/wallStrategy'
import stationStraytegy from './views/diyPages/stationStrategy'
import shelfMaintain from './views/diyPages/shelfMaintain'
import shelfPreview from './views/diyPages/shelfMaintain/shelfPreview'
import resetFlow from './views/components/resetFlow/index'
import flowChart from './views/components/flowChart/index'
import Steps from './views/components/Steps'
import G6Editor from 'gk-flow-chart';
import Auth from './views/properties/Auth'
import LeiviiEditor, { Leivii } from 'leivii-editor'
import 'leivii-editor/lib/leivii-editor.css'
import './assets/icons'
import './theme/index.scss'
import 'gk-flow-chart/lib/gk-flow-chart.css'
import { GET } from "./utils/http";
import { UTable, UTableColumn } from 'umy-table'
import getModuleMenus from "./middle.js";

async function waitLoginCreateApp() {
  let i18n
  try {
    await getModuleMenus();
    i18n = await createI18n()
  }catch(e) {
    console.error('get authority and language error', e)
  }
  Vue.config.productionTip = false

  Vue.use(Element)
  Vue.use(UTable)
  Vue.use(UTableColumn)
  Vue.use(VueClipboard)
  Vue.use(G6Editor)
  Vue.use(GeekScriptEditor)

  Leivii.API.registPlugin('print', {
    @Leivii.API.doc('获取打印机列表')
    getPrinterList() {
    return new Promise((resolve, reject) => {
      try {
        checkPrint.getCLodop().then(_ => {
          let PRINTER_COUNT = LODOP.GET_PRINTER_COUNT()
          let printerList = []
          for (let i = 0; i < PRINTER_COUNT; i++) {
            let pitName = LODOP.GET_PRINTER_NAME(i)
            printerList.push({ label: pitName, value: i.toString() })
          }
          resolve(printerList)
        })
      } catch (error) {
        resolve('')
      }

    })
  },
    @Leivii.API.doc('打印', { label: '模板列表', type: Array }, { label: '打印参数', type: Object, clazz: 'ParamPicker' }, { label: '打印张数', type: Object, clazz: 'ParamPicker' })
    print(tempate, params, pageSize) {
    let handle =[];
    for(let j = 0; j<pageSize; j++){
    [tempate].flat().map(i => {
      [params].flat().map(res => {
        handle.push(new Promise(resolve => {
          console.log(i, res)
          Print(i, res).finally(data => {
            console.log("打印完毕", data)
            resolve(data)
          }).catch(e => {
            e && this.$message({ type: 'warning', message: this.$t(e) })
          })
        }))
      })
    })
  }
  return Promise.all(handle).then(res => {
    resolve(res)
  })
},
@Leivii.API.doc('打印检查', { label: '模板列表', type: Array }, { label: '打印参数', type: Object, clazz: 'ParamPicker' })
printCheck(tempateList, params) {
  let printInfo = window.localStorage.getItem('printerInfo');
  if (printInfo) {
    printInfo = JSON.parse(printInfo);
    if (tempateList.every(tempType => printInfo.some(info => tempType === +info.printType))) {
      return true
    }
    // printInfo = printInfo.find(function (item) {
    //   return item.printType == tempType;
    // });
  }
  this.$message({ type: 'warning', message: this.$t('lang.wms.fed.completpletePrintConfiguration') });
  throw new Error();
}
  }, '打印')

Leivii.API.registPlugin('metaData', {
    @Leivii.API.doc('获取元数据列表(多个)', { label: '传入参数', type: Object, clazz: 'ParamPicker' })
    getMetaData(params) {
  return handleMetaData(params)
},
  @Leivii.API.doc('获取元数据列表(单个)', { label: '传入参数', type: Object, clazz: 'ParamPicker' })
    getMetaDataSingle(params) {
  return handleMetaDataSingle(params)
},
  @Leivii.API.doc('获取操作符对象')
    operationList() {
  return handleOperationList();
}
  }, '元数据')

const map = {
  zh_cn: 'cn',
  zh_hk: 'hk',
  en_us: 'en',
  ja_jp: 'jp',
  he_il: 'il',
  ko_kr: 'ko',
};
Vue.use(LeiviiEditor, {
  store,
  locale: map[localStorage.getItem('curLanguage') || 'zh_cn'],
  i18n: (...args) => i18n.t(...args),
  fetch: { axios },
  componentOptions: {
		download: {
			fileType: {
				override: true,
				value: {
            "type": "expression",
            "target": "",
            "action": "{{localStorage.getItem('file_export_format') || 'XLSX'}}"
        }
			}
		}
	},
  dialog: {
    cancelText: 'lang.action.cancel',
    confirmText: 'lang.wms.fed.save'
  },
  dict: {
    url: '/api_v1/dict/batchQuery',
    method: 'post',
    params: {
      enable: true,
      sortKey: 'sortOrder'
    },
    key: 'objectCodes', // 请求key字段
    root: 'details', // 返回值列表字段
    label: 'fieldValue', // 显示字段
    value: 'fieldCode', // 值字段
  },
  scf: {
    getData(code, params) {
      return new Promise((resolve, reject) => {
        this.get(code).then(data => {
          // GET('/api/common/datatrickle/getdata/v1', { dataTrickleCode: data.dataCode, ...params }).then(data => {
          GET('/api/common/datatrickle/getdata/v1', { dataTrickleCode: data.dataCode, ...params }).then(data => {
            if (data.code) return reject(data)
            resolve(data)
          }).catch(reject)
        }).catch(reject)
      })
    },
  }
})

// Leivii.API.registComponent(Charts)
Leivii.API.registComponent(TableCard)
Leivii.API.registComponent(PrintModelConfig)
Leivii.API.registComponent(vueQr)
Leivii.API.registComponent(CopyBtn)
Leivii.API.registComponent(expressionRuleTable)
Leivii.API.registComponent(expressionRuleTableByCustom)
Leivii.API.registComponent(ExportConfig)
Leivii.API.registComponent(seedWall)
Leivii.API.registComponent(SelectColorBar)
Leivii.API.registComponent(SelectDownloadBtn)
Leivii.API.registComponent(Crontab)
Leivii.API.registComponent(DynamicInput)
Leivii.API.registComponent(DiffReason)
Leivii.API.registComponent(Steps)
Leivii.API.registComponent(flowChart)
Leivii.API.registComponent(resetFlow)
// 自定义页面
Leivii.API.registComponent(wallStraytegy);
Leivii.API.registComponent(stationStraytegy);
Leivii.API.registComponent(ExportLanguage)
Leivii.API.registComponent(shelfMaintain)
Leivii.API.registComponent(shelfPreview)
Leivii.API.registComponent(exceptionManage)
Leivii.API.registComponent(exceptionLog)
Leivii.API.registComponent(exceptionDict)
Leivii.API.registComponent(shelfEnter)
Leivii.API.registComponent(shelfExit)
Leivii.API.registComponent(deviceErrorHandle)

Leivii.API.registProp('Auth', Auth)

Vue.prototype.$auth = function (code) {
  return getAuth(this.$route.meta.auth, code)
}
Vue.prototype.$eosT = transMsg;
Vue.prototype.$geekDB = geekDB;
Vue.prototype.$storage = storage;

// 只存当前模块的脚本，在公共组件中可以做区分
Vue.prototype.$getCustomScript = async function (dataTrickleCode) {
  this.$store.state.ctxScript = {};
  let data = await axios.get('/api/common/datatrickle/getdata/v1', {
      params: {
      dataTrickleCode
      }
  })
  if (!data.code) {
    for (let key in data.data) {
      this.$store.state.ctxScript[key] = data.data[key]?.expression;
    }
  }
};
Vue.prototype.$customScript = async function (actions) {
  if (!actions) { return true; };
  return await eval("(async () => {" + actions + "})()");
};

new Vue({
  i18n,
  store,
  router,
  render: h => h(App),
  created() {
    let that = this;
    window.addEventListener('message', function(event) {
      // 确保消息来自 iframe
      if (event.source === document.querySelector('iframe')?.contentWindow) {
        // 获取 iframe 内部页面发送的路由路径
        const routePath = event.data.routePath;
        if (routePath?.match(/\/wes\/.*putaway/i)) {
          that.$store.state.changeStorage = false
          // 更新父页面的 URL，但不刷新页面
          let targetPath = routePath.split(/[/?]/)[2]; // 提取 "noOrderPutaway"
          console.log(targetPath);
          let newHash = window.location.hash.replace(/(wes%2F).*?(&)/, `$1${targetPath}$2`);
          const newUrl = window.location.origin + window.location.pathname + newHash
          window.history.replaceState(null, null, newUrl);
          let visitedViews = JSON.parse(localStorage.getItem('tagViews')) || []
          for (let v of visitedViews) {
            if (v.path === '/station') {
              Object.assign(v, {
                fullPath: window.location.hash.replace('#', ''),
                query: {
                  ...v.query,
                  url: v.query.url.replace(/(.*wes\/).*?$/, `$1${targetPath}`)
                }
              })
              break
            }
          }

          localStorage.setItem('tagViews', JSON.stringify(visitedViews))
          that.$store.state.changeStorage = true
        }
      }
    });
  }
}).$mount('#app')
}

waitLoginCreateApp();




