<template>
  <div class="geek-menu-dialog" @mousedown.stop>
    <div class="geek-dialog-mask" @click="handleClose"></div>
    <div class="geek-station-body">
        <span class="geek-station-body-title">{{isCommonShowStation?$t('lang.wms.fed.pleaseChooseCommonStation'):$t('lang.wms.fed.pleaseSelectWorkstation')}}</span>
        <div class="input-content">
            <el-autocomplete
            ref="auto"
            v-model="stationId"
            :fetch-suggestions="querySearchAsync"
            :trigger-on-focus="false"
            :clearable="true"
            :popper-append-to-body="false"
            :popper-class="'auto-complete'"
            :placeholder="$t('lang.wms.fed.pleaseEnterContents')"
            @select="handleSelectStation"
            @input="handleInput"
            @clear="clearSelect">
                <i class="el-icon-search el-input__icon"
                    slot="prefix"></i>
                <template slot-scope="{ item }">
                    <div>{{ item }}</div>
                </template>
            </el-autocomplete>
        </div>
        <div class="station-content" ref="scrollStationContent">
            <div class="station-content-left">
                 <div class="station-content-item" :class="{active: (!isCommonShowStation&&item == stationIndex)||(isCommonShowStation&&commonStationIdArr.indexOf(item)>-1)}" :id="index%9==0?'stationGroup'+Math.ceil(index/9):''" 
                 v-for="(item, index) of stationList" :key="item" @click="handleStationSelect(item)">{{item}}</div>
                 <div style="width: 100%; height: 10px"></div>
            </div>
            <div class="station-content-right">
            </div>
        </div>
        <!-- 工作站分组固定在右侧 -->
        <div class="station-content-fix">
            <div class="station-content-group" :class="{active: item.index == groupIndex}" v-for="item in stationGroup" :key="item.index" @click="handleGroupSelect(item.index)">
                <span class="title-span">{{item.min+'-'+item.max}}</span>
            </div>
        </div>
        <div class="station-footer">
            <el-button type="info" @click="handleClose">{{$t("lang.wms.fed.cancel")}}</el-button>
            <el-button type="primary" @click="handleSubmit">{{$t("lang.wms.fed.determine")}}</el-button>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeekStationSelect',
  data() {
    return {
      stationList: this.$store.state.stationIdList || [],
      groupIndex: 0,
      stationIndex: this.$store.state.stationId,
      stationId:'',
      commonStationIdArr:this.$store.state.commonStationId?this.$store.state.commonStationId:[],
    }
  },
  props: {
      item:{
        type: Object,
        default: {}
      },
      isCommonShowStation:{
        type: Boolean,
        default: false,
      },
      isSearchSetStationId:{
        type: Boolean,
        default: false,
      }
  },
  computed: {
    defaultActive() {
      return this.$route.meta?.inAppEditor ? this.$route.meta.path : this.$route.path
    },
    stationGroup() {
      let list = [];
      for (let i = 0; i < Math.ceil(this.stationList.length / 9); i++) {
        let min = this.stationList[i * 9];
        let L = Math.min(this.stationList.length - 1, i * 9 + 8)
        let max = this.stationList[L];
        list.push({ index: i, max, min });
      }
      return list
    }
  },
  created() {
  },
  mounted() {
    this.$refs.scrollStationContent.onscroll = e => {
      console.log(e.target.scrollTop)
      this.groupIndex = Math.max(0, Math.ceil((e.target.scrollTop - 100) / 330));
    }
  },
  methods: {
    // 关闭弹窗
    handleClose() {
        this.$store.state.isShowStation = false;
        this.$store.state.commonStationId = (localStorage.getItem('commonStationId')|| '')?(localStorage.getItem('commonStationId')|| '').split(','):'';
        this.$emit('close')
    },
    querySearchAsync(queryString, cb){
        var stationList=this.stationList;
        var results = queryString ? stationList.filter(this.createFilter(queryString)) : stationList;
        // 调用 callback 返回建议列表的数据
        cb(results);
        setTimeout(()=>{
            let dom = document.querySelectorAll('.el-autocomplete-suggestion');
            if(results.length){
                dom[0].style.display = 'block';
                dom[0].style.position = 'absolute';
                dom[0].style.top = '40px';
                dom[0].style.left = '0px';
                dom[0].setAttribute('x-placement','bottom-start')
            }else{
                dom[0].style.display = 'none';
            }
        },100)
    },
    createFilter(queryString) {
        return (stationList) => {
          return (stationList.indexOf(queryString) === 0);
        };
    },
    handleInput(val){
        if(!val){
            let dom = document.querySelectorAll('.el-autocomplete-suggestion');
            dom[0].style.display = 'none';
        }
    },
    clearSelect(){
        this.$refs.auto.activated = true;
    },
    handleSelectStation(val){
        if(!this.isSearchSetStationId){
            this.stationIndex = val
            this.handleSubmit();
        }else{
            if(this.commonStationIdArr.indexOf(val) === -1){
                this.commonStationIdArr.push(val)
            }
        }
    },
    // 左侧菜单点击，跳转滚动
    handleGroupSelect(e) {
      this.groupIndex = e
      this.$refs.scrollStationContent.scrollTop = e * 330;
    },
    handleStationSelect(e) {
        if(this.isCommonShowStation){
            if(this.commonStationIdArr.indexOf(e)===-1){
                this.commonStationIdArr.push(e);
            }else{
                this.commonStationIdArr.splice(this.commonStationIdArr.indexOf(e),1)
            }
        }else{
            this.stationIndex = e
        }
    },
    handleSubmit(ele) {
        if(this.isCommonShowStation){
            if(this.commonStationIdArr.length>4){
                this.$message({ type: 'warning', message: this.$t('lang.wms.fed.maxFourCommonStationAdd') })
                return
            }else{
                this.$emit('setCommonStation', this.commonStationIdArr);
            }
        }else{
            this.$store.state.stationId = this.stationIndex;
            localStorage.setItem('stationId', this.stationIndex);
            JSON.stringify(this.item) !== '{}' && this.$emit(this.item.code === 'totecommon:rootContainerUp' ? 'loginInRootContainerUp' : 'loginIn', this.item);
        }
        this.handleClose();
    },
  }
}
</script>

<style lang="scss" scoped>
.geek-menu-dialog{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    .geek-dialog-mask{
        background: rgba(64, 65, 66, 0.6);
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .geek-station-body{
        width: 60%;
        max-height: 70%;
        min-width: 600px;
        display: flex;
        z-index: 1;
        background: rgb(64, 65, 66);
        border-radius: 10px;
        box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
        display: flex;
        flex-direction: column;
        color: #ccc !important;
        position: relative;
        .geek-station-body-title{
            text-align: center;
            color: #ccc !important;
            margin: 30px 0;
            font-size: 30px;
        }
        .input-content{
            .el-autocomplete{
                display: block;
                width: 75%;
                margin-left: 20px;
            }
        }
        .station-content-fix{
            position: absolute;
            top: 125px;
            right: 20px;
            width: 18%;
            max-height: 75%;
            padding: 10px 0;
            overflow: auto;
            &::-webkit-scrollbar-track, &::-webkit-scrollbar-thumb{
            display: none;
            }
            .station-content-group{
                color: rgba(210, 206, 206, 100);
                font-size: 28px;
                text-align: center;
                margin-top: 20px;
                padding: 6px 0;
                cursor: pointer;
                &.active{
                    border-radius: 20px;
                    background-color: rgba(116, 117, 118, 100);
                    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                }
                span{
                    color: #D2CECE;
                }
            }
        }
        .station-content{
            display: flex;
            overflow: auto;
            position: relative;
            margin-top: 20px;
            .station-content-left{
                flex: 4;
                display: flex;
                flex-wrap: wrap;
            }
            .station-content-right{
                flex: 1;
                position: relative;
            }
            .station-content-item{
                width: 30%;
                height: 80px;
                line-height: 80px;
                margin: 0 1.5% 30px;
                border-radius: 20px;
                background-color: rgba(99, 100, 102, 100);
                color: rgba(255, 255, 255, 100);
                font-size: 28px;
                text-align: center;
                box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
                border: 3px solid rgba(99, 100, 102, 100);
                box-sizing: border-box;
                cursor: pointer;
                &.active{
                    border: 3px solid rgba(63, 158, 255, 100);
                    color: rgba(63, 158, 255, 100);
                }
            }
        }
        .station-footer{
            display: flex;
            justify-content: center;
            padding: 20px;
            .el-button{
                width: 140px;
                height: 60px;
                font-size: 28px;
            }
        }
    }
}
::v-deep .auto-complete{
    .el-autocomplete-suggestion__wrap{
        max-height:400px!important;
        width:auto!important;
    }
}
</style>
