import { GET, POST } from '@/utils/http'
import { i18dropStore, dropStore } from '@/utils/storage'

/**
 * 获取系统参数
 */
export function getSystemParams(params) {
  return GET('/api_v1/sysparam/query', params)
}

/**
 * 获取常用功能列表
 */
export function getCommonFunList(params) {
  return new Promise((resolve, reject) => {
    GET("/api_v1/commonMenu/select", params).then(res => {
      localStorage.setItem('commonMenu', JSON.stringify(res.data || []));
      resolve(res)
    })
  })
}

/**
 * 新增功能列表
 */
export function addCommonFun(params) {
  return POST('/api_v1/commonMenu/save', params);
}

/**
 * 删除功能列表
 */
export function delCommonFun(params) {
  return POST("/api_v1/commonMenu/delete?id=" + params)
}

/**
 * 获取工作站
 */
export function getSiteList(params) {
  return GET('/api_v1/stationConfigController/selectAllSite?layout=3', params);
}

/**
 * 获取用户角色
 */
export function getUserRole(params) {
  return GET('/api_v1/user/userIdentity', params);
}

/**
 * 获取完成数量
 */
export function getStationComplete(params) {
  return GET('/api_v1/user/completedAmount', params);
}

/**
 * 获取url参数
 */
export function getQueryString(name) {
  let r = null
  const param = window.location.hash.split('?')[1]
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i')
  if (param) {
    r = param.match(reg)
  }
  if (r !== null) return unescape(r[2])
  return null
}

/**
 * 退出登录
 */
export function loginOut(params) {
  this.$store.state.homeLoading = true;
  // 查询当前用户登录工作站
  GET(`/api/common/stdStation/rest/site/userStatus/v1?userAccount=${localStorage.getItem('userName')}`).then(res => {
    if (!res.data?.siteCode) {
      loginOutAdmin.call(this, params);
      return;
    }
    // 退出工作站
    POST("/api/common/connector/rest/site/logout/v1?frontSign=logout", { siteCode: res.data.siteCode }).then(res => {
      if (res.code === 0) {
        loginOutAdmin.call(this, params);
      } else {
        this.$store.state.homeLoading = false
      }
    }).catch(() => this.$store.state.homeLoading = false)
  }).catch(() => this.$store.state.homeLoading = false)
}

// 退出后台
function loginOutAdmin(params) {
  Promise.all([GET('/api/coreresource/auth/logout/v1', params), GET('/api/coreresource/auth/logout/v1', params)])
    .finally(() => {
      this.$store.state.homeLoading = false;
      const lang = localStorage.getItem('curLanguage') || 'zh_cn'
      localStorage.removeItem('SYSTEM_CONFIG_FE')
      localStorage.removeItem(`LangStorage_${lang}`)
      localStorage.removeItem('GeekLocalSessionData')
      localStorage.removeItem('errorMsgList')
      i18dropStore()
      dropStore()
      const params = ['isStation', 'station'].map(item => [item, getQueryString(item)]).filter(item => item[1]).map(item => (`${[item[0]]}=${item[1]}`))
      const queries = params.length ? `?${params.join('&')}` : ''
      const logoutUrl = localStorage.getItem('logoutUrl') || ''
      if (logoutUrl) {
        //第三方登出业务逻辑
        const url = window.location.href
        localStorage.removeItem('logoutUrl')
        window.location.href = `${logoutUrl}?redirect_uri=${encodeURIComponent(url)}`
      } else {
        if(process.env.NODE_ENV === "development"){
          this.$router.push({ path: '/login'});
          // window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/admin/#/middle`
        }else{
          window.top.location.href = `${window.location.origin}/beetle/auth/index.html#/login?redirect_url=${window.location.origin}/static/apollo/admin/#/homePage`
        }
      }
    })
}

/**
 * 修改密码
 */
export function changePassword(params) {
  this.$store.state.isShowPassWord = true;
}
