let authMap = {}
let authInited = false
let menuList = []

export default (path, code) => {
  if (authInited) return authMap[path]?.includes(code) ?? false
  menuList = JSON.parse(localStorage.getItem('MenuList')) || []

  if (!menuList.length) return false

  authInited = true
  for (let i = 0; i < menuList.length; i += 1) {
    const item = menuList[i]
    if (item.code == 'index') {
      let list = []
      item.children?.map(el => {
        list.push(el.code);
        list = list.concat(el.btn?.map(b => b.code))
      })
      authMap.homePage = list
    } else if (item.type !== 1 && item.type !== 3) {
      console.log(`name:${item.name}, type:${item.type}, - not a btn item. -`)
    } else {
      for (let k = 0; k < item.children.length; k += 1) {
        const children = item.children[k]
        const g = children.children
        const key = `${item.code.trim()}/${children.code.trim()}`
        if (key === 'strategicCenter/restockConfig') {
          // 子集是tab页而不是界面时 使用
          authMap[key] = []
          getPermission(authMap[key], children)
        } else if (g && g.length > 0) {
          for (let j = 0; j < g.length; j += 1) {
            const el = g[j]
            authMap[`${children.code.trim()}/${el.code.trim()}`] = el.btn.map(b => b.code)
          }
        } else {
          authMap[key] = children.btn.map(b => b.code)
        }
      }
    }
  }
  console.log('auth', authMap, authMap[path], code, authMap[path]?.includes(code) ?? false)
  return authMap[path]?.includes(code) ?? false
}

/* 处理多层数据 */
function getPermission(arr, obj) {
  if (obj.btn) {
    arr.push(...obj.btn.map(b => b.code))
  }
  if (obj.children) {
    obj.children.forEach(item => {
      getPermission(arr, item)
    })
  }
}
