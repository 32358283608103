<template>
  <div class="geek" v-loading="loading">
    <leivii v-if="config" :data="config" />
  </div>
</template>

<script>
import { GET } from '@/utils/http'

export default {
  data() {
    return {
      config: null,
      loading: false
    }
  },
  created() {
    this.initPage();
  },
  methods: {
    // 欢迎页改成获取首页数据
    initPage(version) {
      const loading = this.$loading()
      GET('/api/lowcode/index').then(data => {
        try {
          if (data.data.content) {
            this.config = JSON.parse(data.data.content)
          }
        } catch {
          this.goApolloPage()
        }
      }).catch(e => this.goApolloPage()).finally(() => loading.close())
    },
    goApolloPage() {
      this.loading = true
    }
  }
}
</script>

<style lang="scss">
.home {
  width: 100%;
  height: 100%;
}
</style>

