<template>
  <div class="overallPadding" ref="overallPadding" id="shelfBox">
  <div class="shelf-wrap mh100" ref="shelfWidth" :style="`width: ${shelfWidth()}`">
    <el-row class="mh100">
      <el-col class="mh100 shelf-col" :span="24">
        <div class="shelf-container mh100">
          <div class="shelf" ref="shelf" :class="{disabled: false}" v-if="floorList && floorList.length && renew">
            <div class="robot-section" :style="'height: 50px'">
              <div class="line vertical left"></div>
              <div class="line vertical right"></div>
              <div class="line horizontal top"></div>
            </div>
            <div class="floor" v-for="(floor, i) in floorList" :key="i" :style="`height: ${$pxHeight(floor.cells[0].height)}; min-height: 100px`">
              <div class="line vertical left"></div>
              <div class="line vertical right"></div>
              <div class="line horizontal top"></div>
              <!-- <div class="line vertical" v-for="(percent, k) in floor.cells.slice(0, floor.cells.length - 1)" :key="k" :style="`left: ${getLeftPx(floor.cells, k)}`"></div> -->
              <div class="cells">
                <div class="cell" v-for="(cell, j) in floor.cells" :key="j"
                  :style="`width: ${$px(cell.width)};min-width:70px`"
                  :class="{
                    'highLight-cell': canChoose && (choosed[''+cell.side+i+j]),
                    'highLight-saved': canChoose && !isDetail && saveChoosedBins && saveChoosedBins[''+cell.side+i+j],
                    'dynamic-saved': canChoose && !isDetail && saveChoosedBins && saveChoosedBins[''+cell.side+i+j] && (saveChoosedBins[''+cell.side+i+j].binGroupType == 200),
                    'dynamic-saved-detail': isDetail && saveChoosedBins && saveChoosedBins[''+cell.side+i+j] && (saveChoosedBins[''+cell.side+i+j] && saveChoosedBins[''+cell.side+i+j].binGroupType == 200)
                  }"
                  @click="choose(floor.cells, i, cell, j)">
                  <!-- :class="{
                    'highLight-cell': canChoose && (choosed[''+cell.side+i+j] ||  !singleChoose && cell.isChoosed),
                    'highLight-saved': canChoose && !isDetail && saveChoosedBins && saveChoosedBins[''+cell.side+i+j],
                  }" -->
                  {{ (cell.binLayer || layerChars[i]) + (cell.binColumn || chars[j]) }}
                  <i class="el-icon-circle-check" v-if="!isDetail && saveChoosedBins && saveChoosedBins[''+cell.side+i+j]"></i>
                  <div class="corner-box"
                    v-if="saveChoosedBins && saveChoosedBins[''+cell.side+i+j] && (saveChoosedBins[''+cell.side+i+j].shelfBinType == 300 || saveChoosedBins[''+cell.side+i+j].formThree && saveChoosedBins[''+cell.side+i+j].formThree.shelfBinType == 300)"
                    >
                  <div class="corner-box-txt">{{!saveChoosedBins[''+cell.side+i+j].formThree ? (saveChoosedBins[''+cell.side+i+j].binGroupColumnNum * saveChoosedBins[''+cell.side+i+j].binGroupFloorNum) : saveChoosedBins[''+cell.side+i+j].formThree.layer * saveChoosedBins[''+cell.side+i+j].formThree.column}}</div></div>
                  <distance mode="horizontal" v-model="cell.width" :disabled="disableEdit || isDetail || floor.cells.length == 1 || floor.cells.length - 1 == j" :min="1"  @change="cellChange(i, j)" />
                </div>
              </div>
              <div class="operations" v-if="!disableEdit && !isDetail">
                <el-button size="mini" icon="el-icon-plus" :disabled="floor.cells.length >= (cols || 99)" circle @click="addCell(floor)" />
                <el-button size="mini" icon="el-icon-minus" :disabled="floor.cells.length <= 1" circle @click="removeCell(floor)" />
              </div>
              <distance v-model="floor.cells[0].height" :min="1" :disabled="disableEdit || isDetail || i == floorList.length - 1" @change="floorHeightChange(floor)" />
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  </div>
</template>

<script>
import data2xml from 'data2xml';
import Distance from './distance';

const MIN_CELL_WIDTH = 50;
const MIN_FLOOR_HEIGHT = 100;
const form = {
  id: '',
  templateName: '',
  classCode: '',
  className: '',
  sideNum: 2,
  shelfLayer: 1,
  shelfCol: 1,
  shelfTotalHeight: 0,
  robotHeight: 340,
  shelfHeight: 1860,
  shelfWidth: 1200,
  shelfLength: 1000,
  shelfDepth: 500,
  weight: '',
  shelfNumber: '',
  shelfBinList: [],
  bindesc: '',
  enabled: true,
  volume: '',
  shelfCategory: '',
  shelfType: '',
  binType: ''
};

export default {
  components: { Distance },
  props: {
    id: {
      type: String,
      default: ''
    },
    disableEdit: { // 禁用编辑格口
      type: Boolean,
      default: false
    },
    isDetail: { // 详情模式不可操作
      type: Boolean,
      default: false
    },
    canChoose: { // 是否允许选中, 默认否
      type: Boolean,
      default: false
    },
    singleChoose: { // 格口单选，默认多选
      type: Boolean,
      default: false
    },
    shelfData: {
      type: Object,
      function: () => {
        return {};
      }
    },
    saveChoosedBins: {
      type: Object,
      function: () => {
        return {};
      }
    },
    threeDimensionRules: {
      type: Object,
      function: () => {
        return {};
      }
    }
  },
  data() {
    return {
      MIN_CELL_WIDTH,
      MIN_FLOOR_HEIGHT,
      step: 1,
      drop: {},
      renew: true,
      form: {
        id: null,
        templateName: '',
        classCode: '',
        className: '',
        sideNum: 2,
        shelfLayer: 1,
        shelfCol: 1,
        shelfTotalHeight: 0,
        robotHeight: 0,
        shelfHeight: 0,
        shelfWidth: 1000,
        shelfLength: 0,
        shelfDepth: 0,
        weight: null,
        shelfNumber: null,
        shelfBinList: [],
        bindesc: null,
        enabled: true,
        shelfCategory: null,
        shelfType: null,
        binType: null
      },
      sides: ['F', 'B', 'L', 'R'],
      currSide: 'F',
      ratio: 1,
      chars: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      chars1Z: '123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      chars99: 99,
      layerChars: 99,
      dialogVisible: false,
      handleType: '',
      loading: false,
      sysVersion: '520',
      choosed: {}, // 选中货位
      shelfDataRaw: {},
      shelfDataSides: {
        F: {},
        B: {},
        L: {},
        R: {}
      }
    }
  },
  computed: {
    floorList() {
      let floorList = [];
      this.form.shelfBinList.forEach(item => {
        if (item.side === this.currSide) {
          floorList[item.floor - 1] = floorList[item.floor - 1] || { cells: [] };
          floorList[item.floor - 1].cells.push(item);
        }
      });
      floorList.forEach(floor => {
        floor.cells = floor?.cells?.sort((a, b) => a.columnTrans - b.columnTrans);
      })
      floorList = floorList.sort((a, b) => a.cells[0].floorTrans - b.cells[0].floorTrans)
      console.log(floorList, 'floorList')
      return floorList.filter(item => item);
    },
    cols() {
      return this.shelfData.shelfColumnMaxNum || (this.shelfData.shelfColumnRule == 1 ? 99 : this.shelfData.shelfColumnRule == 2 ? 35 : this.shelfData.shelfColumnRule == 3 ? 26 : 99)
    }
  },
  watch: {
    shelfData: {
      handler(val) {
        if (val) {
          this.form.robotHeight = val.footHeight ? val.footHeight * 10 : '',
          this.form.shelfHeight = val.height ? val.height * 10 : '',
          this.form.shelfDepth = val.length ? val.length * 10 : '',
          this.form.shelfLength = val.length ? val.length * 10 : '',
          this.form.shelfWidth = val.width ? val.width * 10 : 1000,
          this.form.weight = val.bearWeight ? val.bearWeight * 10 : '',
          this.form.shelfLayer = val.shelfSize && val.shelfSize.layer || 1; // 当前货架默认层列
          this.form.shelfCol = val.shelfSize && val.shelfSize.column || 1; // 当前货架默认层列
          this.currSide = val.shelfSide || 'F'; // 当前面
          this.form.sideNum = val.sideNum || 2; // 面数
          this.form.classCode = val.classCode || ''; // 面数
          this.form.shelfType = val.shelfType || ''; // 面数
          if (val.shelfBinList && val.shelfBinList.length && !this.form.shelfBinList.length) {
            this.form.shelfBinList = val.shelfBinList;
          }
          if (val.shelfSize && !val.tabClick) {
            this.floorChange(val.shelfSize.layer, val.shelfSize.column);
          }
        }
      },
      immediate: true,
      deep: true
    },
    saveChoosedBins: {
      handler() {
        this.renew = false;
        this.$nextTick(() => {
          this.renew = true;
        })
      },
      immediate: true,
      deep: true
    },
    floorList: {
      handler() {
        if (this.$refs.shelfWidth) {
          this.$nextTick(() => {
            this.$refs.shelfWidth.style.width = this.shelfWidth();
          })
        }
      }
    },
    immediate: true
  },
  created() {
    if (this.id) {
    } else {
      // this.floorChange(this.form.shelfLayer, this.form.shelfCol);
      // this.floorChange(this.form.shelfLayer, 2);
    }
  },
  mounted() {
    // this.ratio = this.form.shelfWidth / this.$refs.shelf.offsetWidth;
  },
  methods: {
    $px(mm) {
      // return Math.floor(mm / this.form.shelfWidth * 100) + '%';
      const num = mm / this.form.shelfWidth * 100;
      return num ? num + '%' : '70px';
    },
    $pxHeight(mm) {
      return (mm / this.form.shelfHeight * 100) + '%';
    },
    shelfWidth() {
      const domWidth = document.getElementById('shelfBox')?.offsetWidth;
      const width = domWidth || window.innerWidth || 0;
      const count = Math.max(...this.floorList.map(v => {
        return v.cells?.length || 1;
      }));
      const num = Math.max(count, this.form.shelfCol);
      const widthShelf = Math.floor(width / num) < 100 ? num * 100 + 160 + 'px' : '100%';
      console.log(width, 'shelfWidth', count, widthShelf, Math.floor(width / Math.max(count, this.form.shelfCol)))
      return widthShelf ;
    },
    floorChange(floors, cols, binList) {
      // 层均高、第一层高度
      const side = this.currSide;
      let avaHeight = Math.floor(this.form.shelfHeight / floors);
      const remainHeight = this.form.shelfHeight - avaHeight * floors;
      const remainHeightPerCell = Math.floor(remainHeight / floors) || 1;
      let currentSideBins = [];
      const shelfBinListCurrSide = this.form.shelfBinList.filter(cell => cell.floor <= floors && cell.side === side);
      const newGen = shelfBinListCurrSide.length !== (this.shelfData?.shelfSize?.layer || 0) * (this.shelfData?.shelfSize?.column || 0);
      // 循环面处理数据
      // this.sides.slice(0, this.form.sideNum).forEach(side => {
      //   console.log('side', side, this.form.sideNum)
      // });
      if (!newGen || binList && binList.length) {
        // 重新生成
        currentSideBins = this.form.shelfBinList.filter(cell => cell.side === side)
      }
      this.form.shelfBinList = this.form.shelfBinList.filter(cell => cell.side !== side)
      let floorsList = {};
      let remainHeightCount = remainHeight;
      for (let i = 1; i <= this.form.shelfLayer; i++) {
        // 循环层
        floorsList[i] = [];
        // let cells = this.form.shelfBinList.filter(cell => cell.floor == i && cell.side === side);
        let height = avaHeight || 0;
        // if (i === 1) {
        //   height = firstHeight;
        // } else {
        //   height = avaHeight;
        // }
        if (remainHeightCount > 0) {
          height = remainHeightCount - remainHeightPerCell >= 0 ? avaHeight + remainHeightPerCell : avaHeight + remainHeightCount;
          remainHeightCount = remainHeightCount - remainHeightPerCell;
        }
        // if (cells.length && !newGen) {
        //   // 原有格口高度保留
        //   cells.forEach(cell => cell.height = cell.height || height);
        // } else {
        //   }
        let avaWidth = Math.floor(this.form.shelfWidth / (cols || 1));
        let firstWidth = this.form.shelfWidth - avaWidth * ((cols || 1) - 1);
        const remainWidth = this.form.shelfWidth - avaWidth * cols;
        const remainWidthPerCell = Math.floor(remainWidth / cols) || 1;
        // 获取列号生成规则
        const chars = this.getChars(this.shelfData.shelfColumnRule);
        this.layerChars = this.getChars(this.shelfData.shelfFloorRule || 1);
        // console.log(chars, 'binColumn chars')
        let remainWidthCount = remainWidth;
        let colsTotal = currentSideBins.length ? currentSideBins.filter(v => (v.layerIndex || v.floor) == i).length : cols
        console.log('currentSideBins', currentSideBins, newGen, colsTotal)
        // this.form.shelfBinList.push(currentSideBins);
        for (let j = 0; j < colsTotal; j++) {
          let binLayer = this.layerChars[i - 1];
          let binColumn = chars[j]
          if (this.shelfData.shelfColumnRule == 1 || chars == 99) {
            // 00 - 99
            binColumn = j + 1;
            binColumn = binColumn.toString().padStart(2, '0');
          } else if (this.shelfData.shelfColumnRule == 2) {
            // 1-9A-Z
            // binColumn = binColumn;
          } else if (this.shelfData.shelfColumnRule == 3) {
            // A-Z
          }
          if (this.shelfData.shelfFloorRule == 1 || this.layerChars == 99) {
            // 00 - 99);
            binLayer = i.toString().padStart(2, '0');
          }
          // console.log(binColumn, 'binColumn')
          const floorTrans = this._genLayerIndex(i, this.form.shelfLayer, this.shelfData.columnAndFloorOrderRule);
          const columnTrans = this._genColIndex(j + 1, cols, this.shelfData.columnAndFloorOrderRule);
          const bin = {
            side,
            binColumn: binColumn,
            columnIndex: j + 1,
            binLayer,
            floor: i,
            floorTrans: floorTrans,
            columnTrans: columnTrans,
            width: j === 0 ? firstWidth : avaWidth,
            height,
            index: j
            // ruleIndex:
          };
          if (remainWidthCount > 0) {
            bin.width = remainWidthCount - remainWidthPerCell >= 0 ? avaWidth + remainWidthPerCell : avaWidth + remainWidthCount;
            remainWidthCount = remainWidthCount - remainWidthPerCell;
          }
          bin.width = currentSideBins && currentSideBins.length && currentSideBins.filter(v => v.binColumn == bin.binColumn && v.binLayer == bin.binLayer)?.[0]?.width || bin.width
          const hasBin = currentSideBins && currentSideBins.length && currentSideBins.filter(v => v.binColumn == bin.binColumn && v.binLayer == bin.binLayer).length;
          console.log('hasBin', hasBin, currentSideBins, newGen)
          if (currentSideBins.length && hasBin) {
            this.form.shelfBinList.push(bin);
            floorsList[i].push(bin);
          } else if (!currentSideBins.length) {
            this.form.shelfBinList.push(bin);
            floorsList[i].push(bin);
          }
        }
      }
      console.log('生成层列', floorsList)
    },
    // 根据规则返回层列数据编码
    getChars(rules) {
      // const rules = this.shelfData[ruleKey];
      if (rules == 1) {
        return this.chars99;
      } else if (rules == 2) {
        return this.chars1Z;
      } else {
        return this.chars;
      }
    },
    getLeftPx(cells, index) {
      let mm = 0;
      for (let i = 0; i <= index; i++) {
        mm += cells[i].width;
      }
      return this.$px(mm);
    },
    floorHeightChange(floor) {
      let total = 0, lastFloorHeight = 0;
      this.floorList.forEach(item => {
        if (item.cells[0].floor != this.floorList.length) {
          total += item.cells[0].height;
        } else {
          lastFloorHeight = item.cells[0].height;
        }
      });
      if (this.form.shelfHeight < total) {
        this.$message({ message: this.$t('lang.wms.fed.inputError'), type: 'warning' });
        floor.cells[0].height = this.form.shelfHeight - lastFloorHeight - (total - floor.cells[0].height);
        return;
      } else {
        lastFloorHeight = this.form.shelfHeight - total;
      }

      this.form.shelfBinList.forEach(cell => {
        if (cell.floor == floor.cells[0].floor) cell.height = floor.cells[0].height;
        if (cell.floor == this.floorList.length) cell.height = lastFloorHeight;
      });
    },
    addCell(floor) {
      let cellNum = floor.cells.length + 1;
      let avaWidth = Math.floor(this.form.shelfWidth / cellNum);
      let firstWidth = this.form.shelfWidth - avaWidth * (cellNum - 1);
      floor.cells.forEach((cell, i) => {
        if (i === 0) cell.width = firstWidth;
        else cell.width = avaWidth;
      });
      const chars = this.getChars(this.shelfData.shelfColumnRule || 1);
      const charsFloor = this.getChars(this.shelfData.shelfFloorRule || 1);
      const currFloor = floor.cells[0].floor;
      this.form.shelfBinList.push({
        side: this.currSide,
        binColumn: chars == 99 ? cellNum.toString().padStart(2, '0') : chars[cellNum - 1],
        floor: currFloor,
        binLayer: charsFloor == 99 ? currFloor.toString().padStart(2, '0') : charsFloor[currFloor - 1],
        width: avaWidth,
        height: floor.cells[0].height
      });
    },
    removeCell(floor) {
      let cellNum = floor.cells.length - 1;
      if (cellNum <= 0) return;
      let avaWidth = Math.floor(this.form.shelfWidth / cellNum);
      let firstWidth = this.form.shelfWidth - avaWidth * (cellNum - 1);
      floor.cells.forEach((cell, i) => {
        if (i === 0) cell.width = firstWidth;
        else cell.width = avaWidth;
      });
      const chars = this.getChars(this.shelfData.shelfColumnRule || 1)
      this.form.shelfBinList.slice().forEach((cell, i) => {

        if (cell.side === this.currSide && cell.floor == floor.cells[0].floor) {
          const binColCode = chars === 99 ? floor.cells.length.toString().padStart(2, '0') : chars[floor.cells.length - 1]
          if (binColCode === cell.binColumn) {
            this.form.shelfBinList.splice(i, 1);
          }
        }
      });
    },
    cellChange(floorIndex, cellIndex) {
      let cells = this.floorList[floorIndex].cells;
      let total = 0;
      for (let i = 0; i < cells.length - 1; i++) {
        total += cells[i].width;
      }
      let lastCellWidth = this.form.shelfWidth - total;
      if (lastCellWidth < 1) {
        cells[cellIndex].width = this.form.shelfWidth - total - cells[cells.length - 1].width + cells[cellIndex].width;
        this.$message({ message: this.$t('lang.wms.fed.inputError'), type: 'warning' });
      } else {
        cells[cells.length - 1].width = this.form.shelfWidth - total;
      }
    },
    _getXML(data) {
      this.shelfDataRaw = data;
      if (data.shelfDataSides) {
        this.shelfDataSides = data.shelfDataSides;
      }
      let convert = data2xml({ xmlDecl: false });
      // return convert('shelf', {
      //   classCode: this.form.classCode,
      //   length: this.form.shelfWidth,
      //   width: this.form.shelfWidth,
      //   height: this.form.shelfHeight,
      //   sides: {
      //     _attr: { class: 'linked-list' },
      //     side: ['F', 'B'].map(sideCode => this._getSide(sideCode))
      //   }
      // })
      // 根据面数截取面tab数据
      const sides = ['F', 'B', 'L', 'R'].splice(0, this.form.sideNum);
      // 组装货架编码规则
      let rules = this.shelfDataRaw.shelfExpress.reduce((total, cur, index, arr) => {
        if (cur.key !== 'connect') {
          const connect = arr[index + 1]?.key === 'connect' ? arr[index + 1].label : '';
          console.log(connect, arr, 'connect')
          total.push({
            field: cur.key,
            index: index,
            connect: connect
          })
        }
        return total;
      }, []).map((v, idx) => {
        return {
          ...v,
          index: idx
        }
      });
      // console.log(
      //   'shelf',
      //   {
      //     classCode: this.form.classCode,
      //     length: this.form.shelfWidth,
      //     width: this.form.shelfWidth,
      //     height: this.form.shelfHeight,
      //     baseHeight: this.form.robotHeight,
      //     binCodeRuleList: {
      //       _attr: { class: 'linked-list' },
      //       binCodeRule: rules || [],
      //     },
      //     sides: {
      //       _attr: { class: 'linked-list' },
      //       side: sides.map(sideCode => this._getSide(sideCode))
      //     },
      //     binGroupFloorRule: this.threeDimensionRules.binLayerRule || this.shelfDataRaw?.binData?.binLayerRule, // 货位层规则
      //     binGroupColumnRule: this.threeDimensionRules.binColRule || this.shelfDataRaw?.binData?.binColRule, // 货位列规则
      //     binGroupColumnAndFloorOrderRule: this.threeDimensionRules.columnAndFloorOrderRule
      //   }
      // )
      return convert('shelf', {
        classCode: this.form.classCode,
        shelfType: this.form.shelfType,
        length: this.form.shelfLength,
        width: this.form.shelfWidth,
        height: this.form.shelfHeight,
        baseHeight: this.form.robotHeight,
        binCodeRuleList: {
          _attr: { class: 'linked-list' },
          binCodeRule: rules || []
        },
        sides: {
          _attr: { class: 'linked-list' },
          side: sides.map(sideCode => this._getSide(sideCode))
        },
        binGroupFloorRule: this.threeDimensionRules.binLayerRule || this.shelfDataRaw?.binData?.binLayerRule, // 货位层规则
        binGroupColumnRule: this.threeDimensionRules.binColRule || this.shelfDataRaw?.binData?.binColRule, // 货位列规则
        binGroupColumnAndFloorOrderRule: this.threeDimensionRules.columnAndFloorOrderRule || this.shelfDataRaw?.binData?.columnAndFloorOrderRule || ''
      })
    },
    _getSide(sideCode) {
      const sideData = this.shelfDataSides[sideCode] || {};
      let obj = {
        sideAliasCode: sideData.sideAliasCode || sideCode,
        sideWidth: this.form.shelfWidth,
        sideHeight: this.form.shelfHeight
      }
      // 生成层列并排序
      return {
        sideCode,
        ...obj,
        layers: {
          _attr: { class: 'linked-list' },
          // layerIndex
          // layerCode
          // layerHeight
          layer: Array.from({ length: this.shelfDataSides[sideCode]?.shelfSize?.layer || this.form.shelfLayer }).map((item, i) => {
            if ( this.sysVersion === '520' ) {
              return this._getBinGroup(sideCode, i + 1)
            } else {
              return this._getLayer(sideCode, i + 1)
            }
          }).sort((a, b) => {
            return a.layerIndex - b.layerIndex
          })
        }
      };
    },
    _getBinGroup(sideCode, layerCode) {
      // 生成抽屉编码规则
      // let rules = this.shelfDataRaw.binExpress.reduce((total, cur, index, arr) => {
      //   if (cur.key !== 'connect') {
      //     const connect = arr[index + 1]?.key === 'connect' ?  arr[index + 1].label : '';
      //     // console.log(connect, arr, 'connect')
      //     total.push({
      //       field: cur.key,
      //       index: index,
      //       connect: connect,
      //     })
      //   }
      //   return total;
      // }, []).map((v, idx) => {
      //   return {
      //     ...v,
      //     index: idx
      //   }
      // });
      const layerChars = this.getChars(this.shelfData.shelfFloorRule || 1);
      const colChars = this.getChars(this.shelfData.shelfColumnRule || 1);
      const bins = this.form.shelfBinList.filter(sb => sb.side === sideCode && +sb.floor === layerCode);
      const defaultShelfType = this.checkDefaultBinType(this.form.shelfType);
      let offsetX = 0;
      let offsetY = 0;
      let height = 0;
      let binGroups = {
        _attr: { class: 'linked-list' },
        binGroup: bins.map(sb => {
          const binInfo = this.saveChoosedBins && (this.saveChoosedBins[sideCode + (layerCode - 1) + sb.index]?.formThree || this.saveChoosedBins[sideCode + (layerCode - 1) + sb.index]) || ''
          let binGroup = {
            columnIndex: this._genColIndex(sb.index + 1, bins.length, this.shelfData.columnAndFloorOrderRule),
            columnCode: sb.binColumn, // 根据三种规则
            depthIndex: this.shelfData.shelfLen || 1, // 货格深位
            binGroupDisplayCode: '' + (layerChars == 99 ? layerCode.toString().padStart(2, '0') : layerChars[sb.floor - 1]) + (colChars == 99 ? (sb.index + 1).toString().padStart(2, '0') : colChars[sb.index]),
            binGroupType: !defaultShelfType.canEdit ? defaultShelfType.shelfBinType : this.saveChoosedBins['' + sideCode + (sb.floor - 1) + sb.index]?.formThree?.shelfBinType || this.saveChoosedBins['' + sideCode + (sb.floor - 1) + sb.index]?.shelfBinType || defaultShelfType.shelfBinType || 100, // 没有保存的货位类型，默认100-固定
            // binGroupFloorRule: this.saveChoosedBins['' + sideCode + layerCode + sb.index]?.formThree?.binLayerRule || this.shelfData.binLayerRule, // 货位层规则
            // binGroupColumnRule: this.saveChoosedBins['' + sideCode + layerCode + sb.index]?.formThree?.binColRule || this.shelfData.binColRule, // 货位列规则
            // binGroupFloorMaxNum: this.saveChoosedBins['' + sideCode + layerCode + sb.index]?.formThree?.layer || this.shelfData.formThree.layer, // 货位层数
            // binGroupColumnMaxNum: this.saveChoosedBins['' + sideCode + layerCode + sb.index]?.formThree?.column || this.shelfData.formThree.column, // 货位列数
            binGroupFloorNum: binInfo?.layer || binInfo?.binGroupFloorNum || 1, // 货位层数
            binGroupColumnNum: binInfo?.column || binInfo?.binGroupColumnNum || 1, // 货位列数
            // binGroupColumnAndFloorOrderRule: this.shelfData.columnAndFloorOrderRule, // 货架层列排序规则
            // threeDimensionalBinCodeRuleList: { // 3D货位编码
            //   _attr: { class: 'linked-list' },
            //   binCodeRule: rules || [],
            // },
            offset: offsetX,
            binGroupWidth: sb.width,
            binGroupHeight: Math.max(height, sb.height),
            bins: {
              _attr: { class: 'linked-list' },
              bin: {
                // binDisplayCode: '' + (layerChars == 99 ? i : layerChars[sb.floor]) + (colChars == 99 ? sb.index.toString().padStart(2,'0') : colChars[sb.index]),
                binDisplayCode: '0101',
                offsetX: offsetX,
                offsetY: offsetY,
                binWidth: sb.width,
                binHeight: Math.max(height, sb.height),
                binDepth: this.form.shelfDepth,
                binLayerIndex: 1, // 单货位层1
                binColumnIndex: 1 // 单货位列1
                // binBearWeight: this.shelfData?.formThree.binBearWeight || 0,
              }
            }
          }
          // 抽屉货位 生成货位数据
          if (binGroup.binGroupType == 300) {
            binGroup.bins.bin = this._getBins(sideCode, layerCode, sb.index, Math.max(height, sb.height), sb.width);
          } else {
            binGroup.binGroupFloorNum = 1;
            binGroup.binGroupColumnNum = 1;
          }
          height = Math.max(height, sb.height);
          offsetX += sb.width;
          offsetY += Math.max(height, sb.height);
          return binGroup;
        }).sort((a, b) => {
          return a.columnIndex - b.columnIndex
        })
      };
      let layerIndex = layerCode;
      layerIndex = this._genLayerIndex(layerCode, this.shelfDataSides[sideCode]?.shelfSize?.layer || this.form.shelfLayer, this.shelfData.columnAndFloorOrderRule);
      return { layerIndex, layerCode: layerChars == 99 ? layerCode.toString().padStart(2, '0') : layerChars[layerCode - 1], layerHeight: height, binGroups };
    },
    // 根据规则生成层序号
    _genLayerIndex(layerIndexOriginal = 1, layerLength = 1, columnAndFloorOrderRuleOrigin = 1) {
      // 1-层数从小到大、列数从小到大、
      // 2-层数从小到大、列数从大到小、
      // 3-层数从大到小、列数从小到大、
      // 4-层数从大到小、列数从大到小。
      let layerIndex = layerIndexOriginal;
      const columnAndFloorOrderRule = columnAndFloorOrderRuleOrigin || 1;
      switch (columnAndFloorOrderRule) {
      case 1:
      case 2:
        layerIndex = layerIndexOriginal;
        break;
      case 3:
      case 4:
        layerIndex = layerLength - layerIndexOriginal + 1; // 从1开始
        break;
      default:
        layerIndex = layerIndexOriginal;
        break;
      }
      // console.log('层规则', columnAndFloorOrderRule, layerIndex);
      return layerIndex;
    },
    // 根据规则生成列序号
    _genColIndex(columnIndexOriginal = 1, colLength = 1, columnAndFloorOrderRuleOrigin) {
      // 1-层数从小到大、列数从小到大、
      // 2-层数从小到大、列数从大到小、
      // 3-层数从大到小、列数从小到大、
      // 4-层数从大到小、列数从大到小。
      let colIndex = columnIndexOriginal;
      const columnAndFloorOrderRule = columnAndFloorOrderRuleOrigin || 1;
      switch (columnAndFloorOrderRule) {
      case 2:
      case 4:
        colIndex = colLength - columnIndexOriginal + 1;
        break;
      case 1:
      case 3:
        colIndex = columnIndexOriginal;
        break;
      default:
        colIndex = columnIndexOriginal;
        break;
      }
      // console.log('列规则', columnAndFloorOrderRule, colIndex);
      return colIndex;
    },
    _getLayer(sideCode, layerCode) {
      let offset = 0, height = 0;
      let bins = {
        _attr: { class: 'linked-list' },
        bin: this.form.shelfBinList.filter(sb => sb.side === sideCode && +sb.floor === layerCode).map(sb => {
          let bin = {
            columnCode: sb.binColumn,
            offset,
            width: sb.width,
            depth: this.form.shelfDepth
          }
          height = Math.max(height, sb.height);
          offset += sb.width;
          return bin;
        })
      };
      return { layerCode, height, bins };
    },
    _getBins(sideCode, layerCode, col, height, width) {
      // binGroupFloorRule: this.shelfData.formThree.binLayerRule, // 格口层规则
      // binGroupFloorMaxNum: this.shelfData.formThree.layer, // 格口层数
      // binGroupColumnRule: this.shelfData.formThree.binColRule, // 格口列规则
      // binGroupColumnMaxNum: this.shelfData.formThree.column, // 格口列数
      // binGroupColumnAndFloorOrderRule: this.shelfData.formThree.binColRule, // 货格层列排序规则
      const bins = [];
      // 当前货格的层列规则
      const rules = this.threeDimensionRules || this.saveChoosedBins && this.saveChoosedBins['' + sideCode + (layerCode - 1) + col]?.formThree || '';
      const binInfo = this.saveChoosedBins && this.saveChoosedBins['' + sideCode + (layerCode - 1) + col]?.formThree || this.saveChoosedBins && this.saveChoosedBins['' + sideCode + (layerCode - 1) + col] || ''
      console.log(rules, '货位层列规则');
      // 抽屉层列编码规则
      const layerChars = this.getChars(rules?.binLayerRule || this.shelfData.formThree.binLayerRule || 1);
      const colChars = this.getChars(rules?.binColRule || this.shelfData.formThree.binColRule || 1);
      console.log(layerChars, colChars, 'chars')
      // 暂不考虑深位 this.shelfData.shelfLen
      const layers = binInfo?.layer || binInfo?.binGroupFloorNum || 1;
      const cols = binInfo?.column || binInfo?.binGroupColumnNum || 1;
      const avaHeight = Math.floor(height / (layers * cols));
      const avaWidth = Math.floor(width / (layers * cols));
      for (let i = 0; i < layers; i++) {
        for (let j = 0; j < cols; j++) {
          // 有深位时，层列index 固定1
          const bin = {
            binDisplayCode: '' + (layerChars == 99 ? (i + 1).toString().padStart(2, '0') : layerChars[i]) + (colChars == 99 ? (j + 1).toString().padStart(2, '0') : colChars[j]),
            offsetX: 0,
            offsetY: 0,
            binWidth: avaWidth,
            binHeight: avaHeight,
            // remainVolume: 0,
            // binBearWeight: rules?.bearWeight || 0,
            binDepth: this.form.shelfDepth / (this.form.sideNum > 1 ? this.form.sideNum : 1) || 0, // 双面深度除以2 四面除以4
            binDepthIndex: this.shelfData.shelfLen > 1 ? i : this.shelfData.shelfLen,
            binLayerIndex: this.shelfData.shelfLen > 1 ? 1 : i + 1,
            binColumnIndex: this.shelfData.shelfLen > 1 ? 1 : j + 1
          };
          // 货位排序
          let layerIndex = i + 1;
          let colIndex = j + 1;
          if (this.shelfData.shelfLen < 2) {
            // 无深位
            layerIndex = this._genLayerIndex(layerIndex, layers, this.shelfData.formThree?.columnAndFloorOrderRule || 1);
            colIndex = this._genColIndex(colIndex, cols, this.shelfData.formThree?.columnAndFloorOrderRule || 1);
            bin.binLayerIndex = layerIndex;
            bin.binColumnIndex = colIndex;
          }
          // 计算货位容积
          // bin.remainVolume = bin.binWidth * bin.binHeight * bin.binDepth || 0;
          bins.push(bin)
        }
      }
      return bins.length === 1 ? bins[0] : bins;
    },
    checkDefaultBinType(shelfType) {
      const val = Number(shelfType);
      let data = {
        shelfBinType: 100,
        canEdit: true
      };
      switch (true) {
      case val < 120 && val > 99 :
      case val < 160 && val > 139 :
      case val < 201 && val > 199 :
      case val < 400 && val > 299 :
      case val < 940 && val > 919 :
        data.shelfBinType = 200;
        data.canEdit = false;
        break
      case val < 140 && val > 119 :
        data.shelfBinType = 200;
        data.canEdit = true;
        break
      case val == 1 :
      case val == 3 :
      case val < 900 && val > 800 :
        data.shelfBinType = 100;
        data.canEdit = true;
        break
      case val == 5 :
      case val == 2 :
        data.shelfBinType = 300;
        data.canEdit = false;
        break;
      case val < 920 && val > 899 :
        data.shelfBinType = 100;
        data.canEdit = true;
        break;
      }
      return data;
    },
    getShelfData() {
      return this.form;
    },
    setShelfData(form) {
      this.currSide = form.currSide || 'F';
      this.form = Object.assign({}, this.form, form);
    },
    back() {
      this.$emit('goBack');
    },
    // 记录选中格口 面-层-列 作为唯一key
    choose(floor, findex, cell, cindex, refill) {
      if (!this.canChoose) {
        // 是否允许选择格口
        return;
      }
      if (this.choosed[`${cell.side}${findex}${cindex}`]) {
        // 反选格口
        delete this.choosed[`${cell.side}${findex}${cindex}`];
      } else if (this.singleChoose) {
        // 单选格口
        this.choosed = {
          [`${cell.side}${findex}${cindex}`]: { floor, findex, cell, cindex, ...this.saveChoosedBins[`${cell.side}${findex}${cindex}`] || {} }
        }
        this.$emit('singleChooseEvent', `${cell.side}${findex}${cindex}`);
      } else {
        // 多选格口
        this.choosed[`${cell.side}${findex}${cindex}`] = { floor, findex, cell, cindex, ...this.saveChoosedBins[`${cell.side}${findex}${cindex}`] || {} };
      }
      // console.log(111111111, this.choosed['' + cell.side + findex + cindex], this.saveChoosedBins[`${cell.side}${findex}${cindex}`]);
      this.form.shelfBinList.filter(v => v.side === cell.side && v.floor === cell.floor && v.binColumn === cell.binColumn)[0].isChoosed = !!this.choosed[`${cell.side}${findex}${cindex}`];
      console.log(this.form.shelfBinList)
      this.$emit('chosedCells', { ...this.choosed, refill });
      this.renew = false;
      this.renew = true;
    },
    reset() {
      this.$set(this.form, form);
      this.currSide = 'F';
      this.shelfDataSides = {
        F: {},
        B: {},
        L: {},
        R: {}
      };
    },
    resetChoose() {
      this.choosed = {};
    },
    // 复制货架面数据
    copyShlefData(copyData) {
      const copyTo = copyData.copyTo;
      const copyFrom = copyData.copyFrom;
      const copySideBins = this.form.shelfBinList.filter(v => v.side === copyFrom);
      const copySideBinsWithoutToSide = this.form.shelfBinList.filter(v => !copyTo.includes(v.side));
      let copyedBins = [...copySideBinsWithoutToSide];
      copyTo.forEach(side => {
        const copySide = copySideBins.map(v => {
          return {
            ...v,
            side
          }
        })
        copyedBins.push(...copySide);
      })
      this.form.shelfBinList = [...copyedBins];
    }
  }
}
</script>

<style lang="scss">
.shelf-custom-dialog{
  height: 100% !important;
  >.el-row{
    height: 100% !important;
  }
}
</style>
<style lang="scss" scoped>
  .overallPadding {
    // position: absolute;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // z-index: 10;
    // background-color: #f4f4f5;
    width: 100%;
    height: 100%;
    max-height: 800;
    overflow: auto;
  }
  .shelf-wrap {
    position: relative;
    background: #fff;
    padding:16px;
    // box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin:16px;
    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 30px;
      text-decoration: underline;
      &.step2 {
        margin-left: -50px;
      }
    }
    .sides {
      display: flex;
      width: 70%;
      margin: 0 auto 30px;
      .side {
        flex: 1;
        text-align: center;
        padding: 5px 0;
        border: 1px solid #ddd;
        cursor: pointer;
        background: #fff;
        &.front {
          margin-right: -1px;
        }
        &.active {
          color: #fff;
          background: rgb(2, 92, 151);
        }
      }
    }
    .buttons {
      text-align: center;
    }

    .shelf-col {
      padding-left: 60px;
      padding-right: 100px;
    }
    .shelf-container {
      display: flex;
      flex-flow: column;
      perspective: 1000px;
      .shelf {
        flex: 1;
        display: flex;
        flex-flow: column;
        justify-content: flex-end;
        flex-direction: column-reverse;
        .robot-section {
          position: relative;
          width: 100%;
          height: 68px;
        }
        .floor {
          position: relative;
          .operations {
            position: absolute;
            right: -80px;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .cells {
          display: flex;
          width: 100%;
          height: 100%;
          min-height: 100px;
          .cell {
            position: relative;
            font-size: 24px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #999;
            white-space: nowrap;
            &.highLight-saved{
              background: #e39e1fdb;
              color: #fff;
              filter: brightness(1);
              i {
                position: absolute;
                top:10px;
                right:5px;
              }
            }
            &.highLight-cell{
              background: #e39e1fdb;
              color: #fff;
              filter: brightness(1.2);
            }
            &.dynamic-saved{
              background: pink !important;
            }
            &.dynamic-saved-detail{
              background: pink !important;
            }
          }
        }
        .line {
          position: absolute;
          z-index: 2;
          &.vertical {
            width: 1px;
            height: 100%;
            background: #999;
          }
          &.horizontal {
            width: 100%;
            height: 1px;
            background: #999;
          }
          &.left {
            top: 0;
            left: 0;
          }
          &.right {
            top: 0;
            right: 0;
          }
          &.top {
            top: 0;
            left: 0;
          }
          &.bottom {
            bottom: 0;
            left: 0;
          }
        }
      }
      .corner-box {
        position: absolute;
        top: 0;
        left: 0;
        border: 20px solid transparent;
        border-top: 20px solid #5daf34;
        border-left: 20px solid #5daf34;
      }
      .corner-box-txt {
        position: absolute;
        width: 20px;
        text-align: center;
        top: -15px;
        right: 0;
        color: white;
        font-size:14px;
        scale: 0.8;
        white-space: nowrap;
      }
    }
    .disabled {
      outline: 0 none;
      cursor: default !important;
      opacity: .4;
      filer: alpha(opacity=40);
      -ms-pointer-events: none;
      pointer-events: none;
    }
    .shelf-code {
      margin-bottom: 10px;
    }
    .el-form-item__content{
      line-height: 28px !important;
    }
  }
  .mh100 {
    height: 100%;
  }
</style>
