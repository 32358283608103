<template>
    <div class="exception-container">
			<div class="search-box">
				<el-form :model="form" ref="searchForm" :rules="rules" :label-position="'left'">
					<el-row>
						<el-col :span="6">
							<el-form-item prop="sourceSystemStr" :label='$t("lang.common.exceptionManage.sourceSystem")'>
								<el-select multiple clearable v-model="form.sourceSystemStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
							<el-col :span="6">
								<el-form-item prop="taskCode" :label='$t("lang.common.exceptionManage.taskCode")'>
									<el-input clearable v-model="form.taskCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
								</el-form-item>
						</el-col>
						<el-col :span="6">
								<el-form-item prop="parentTaskCode" :label='$t("lang.common.exceptionManage.parentTaskCode")'>
									<el-input clearable v-model="form.parentTaskCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
								</el-form-item>
						</el-col>
						<el-col :span="6">
								<el-form-item multiple prop="deviceType" :label='$t("lang.common.exceptionManage.deviceType")'>
									<el-select clearable v-model="form.deviceType" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
										<el-option
										v-for="(item, index) in (params.deviceTypeList || [])"
										:key="index"
										:label="$t(item.name)"
									    :value="item.code">
										</el-option>
									</el-select>
								</el-form-item>
						</el-col>
					</el-row>
					<el-row  v-if="!newFold">
						<el-col :span="6">
							<el-form-item prop="deviceCode" :label='$t("lang.common.exceptionManage.deviceCode")'>
								<el-input clearable v-model="form.deviceCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="containerCode" :label='$t("lang.common.exceptionManage.containerCode")'>
								<el-input clearable v-model="form.containerCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="exceptionType" :label='$t("lang.common.exceptionManage.exceptionType")'>
								<el-select multiple clearable v-model="form.exceptionType" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.exceptionTypeList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="severityStr" :label='$t("lang.common.exceptionManage.severity")'>
								<el-select multiple clearable v-model="form.severityStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.severityList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="!newFold">
						<el-col :span="6">
							<el-form-item prop="priorityStr" :label='$t("lang.common.exceptionManage.priority")'>
								<el-select multiple clearable v-model="form.priorityStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.priorityList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="exceptionCode" :label='$t("lang.common.exceptionManage.exceptionCode")'>
								<el-input clearable v-model="form.exceptionCode" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="status" :label='$t("lang.common.exceptionManage.status")'>
								<el-select multiple clearable v-model="form.statusStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.statusList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="warehouseAreaStr" :label='$t("lang.common.exceptionManage.warehouseArea")'>
								<el-select multiple clearable v-model="form.warehouseAreaStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.warehouseAreaList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="!newFold">
						<el-col :span="6">
							<el-form-item prop="logicAreaStr" :label='$t("lang.common.exceptionManage.logicArea")'>
								<el-select multiple clearable v-model="form.logicAreaStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.logicAreaList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="selfHealingStr" :label='$t("lang.common.exceptionManage.selfHealing")'>
								<el-select multiple clearable v-model="form.selfHealingStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.selfHealingList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="needReportStr" :label='$t("lang.common.exceptionManage.needReport")'>
								<el-select multiple clearable v-model="form.needReportStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.needReportList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="reportSystemStr" :label='$t("lang.common.exceptionManage.reportSystem")'>
								<el-select multiple clearable v-model="form.reportSystemStr" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')">
									<el-option
									v-for="(item, index) in (params.systemList || [])"
									:key="index"
									:label="$t(item.name)"
									:value="item.code">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row v-if="!newFold">
						<el-col :span="6">
							<el-form-item prop="creator" :label='$t("lang.common.exceptionManage.creator")'>
								<el-input clearable v-model="form.creator" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="createAtStr" :label='$t("lang.common.exceptionManage.createAt")'>
								<el-date-picker
									v-model="form.createAtStr"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('lang.common.exceptionManage.startTime')"
									:end-placeholder="$t('lang.common.exceptionManage.endTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="updator" :label='$t("lang.common.exceptionManage.updator")'>
								<el-input clearable v-model="form.updator" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item prop="updateAtStr" :label='$t("lang.common.exceptionManage.updateAt")'>
								<el-date-picker
									v-model="form.updateAtStr"
									type="datetimerange"
									range-separator="-"
									:start-placeholder="$t('lang.common.exceptionManage.startTime')"
									:end-placeholder="$t('lang.common.exceptionManage.endTime')">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<div class="search-btns">
							<el-button size="medium" plain @click="reset()">{{$t('lang.common.exceptionManage.reset')}}</el-button>
							<el-button size="medium" type="primary" @click="search()">{{$t('lang.common.exceptionManage.query')}}</el-button>
							<div v-if="newFold" class="fold-wrapper">
								<span class="switch-up" @click="foldBox"><i class="el-icon-arrow-down"></i></span>
							</div>
							<div v-else class="fold-wrapper">
								<span class="switch-up" @click="foldBox"><i class="el-icon-arrow-up"></i></span>
							</div>
						</div>
					</el-row>
				</el-form>
			</div>
			<div class="table-area">
				<div class="main-opts">
					<el-button size="medium" type="primary" @click="upload()">{{$t('lang.common.exceptionManage.sopDocUpload')}}</el-button>
					<el-button size="medium" type="primary" @click="exportFile()">{{$t('lang.common.exceptionManage.export')}}</el-button>
				</div>
				<el-table
					ref="mainTable"
					:data="tableData"
                     border
                    highlight-current-row
					height="500"
					style="width: 100%">
					<el-table-column
					prop="index"
					fixed
					:label="$t('lang.common.exceptionManage.index')"
						width="50">
						<template slot-scope="scope">
							{{scope.$index + 1}}
					</template>
					</el-table-column>
					<el-table-column
					width="150"
					prop="code"
					fixed
					:label="$t('lang.common.exceptionManage.exceptionRecordNo')">
					<template slot-scope="scope">
						<el-link
							@click.native.prevent="openDetail(scope.row, tableData)"
							type="primary"
							size="small">
							{{scope.row.exceptionRecordNo}}
						</el-link>
					</template>
					</el-table-column>
					<el-table-column
					prop="sourceSystem"
					width="120"
					:label="$t('lang.common.exceptionManage.sourceSystem')">
						<template slot-scope="scope">
							{{drop.systemList[scope.row.sourceSystem] || scope.row.sourceSystem}}
						</template>
					</el-table-column>
					<el-table-column
					prop="taskCode"
					:label="$t('lang.common.exceptionManage.taskCode')">
					</el-table-column>
					<el-table-column
					width="180"
					prop="parentTaskCode"
					:label="$t('lang.common.exceptionManage.parentTaskCode')">
					</el-table-column>
					<el-table-column
					prop="deviceType"
					:label="$t('lang.common.exceptionManage.deviceType')">
					<template slot-scope="scope">
						{{drop.deviceTypeList[scope.row.deviceType] || scope.row.deviceType || ''}}
					</template>
					</el-table-column>
					<el-table-column
					prop="deviceCode"
					:label="$t('lang.common.exceptionManage.deviceCode')">
					</el-table-column>
					<el-table-column
					prop="containerCode"
					width="150"
					:label="$t('lang.common.exceptionManage.containerCode')">
					</el-table-column>
					<el-table-column
					prop="taskPhase"
					width="150"
					:label="$t('lang.common.exceptionManage.taskPhase')">
					<template slot-scope="scope">
							{{drop.taskPhaseList[scope.row.taskPhase] || scope.row.taskPhase}}
					</template>
					</el-table-column>
					<el-table-column
					prop="exceptionCode"
					:label="$t('lang.common.exceptionManage.exceptionCode')">
					</el-table-column>
					<el-table-column
					width="150"
					prop="exceptionCode"
					:label="$t('lang.common.exceptionManage.exceptionName')">
					<template slot-scope="scope">
							{{scope.row.exceptionName}}
					</template>
					</el-table-column>
					<el-table-column
					width="120"
					prop="status"
					:label="$t('lang.common.exceptionManage.sopDoc')">
					<template slot-scope="scope">
						<el-link
							@click.native.prevent="openPdf(scope.row, tableData)"
							type="primary"
							size="small">
							{{$t('lang.common.exceptionManage.detail')}}
						</el-link>
					</template>
					</el-table-column>
					<el-table-column
					prop="exceptionType"
					:label="$t('lang.common.exceptionManage.exceptionType')">
					<template slot-scope="scope">
							{{drop.exceptionTypeList[scope.row.exceptionType] || scope.row.exceptionType}}
					</template>
					</el-table-column>
					<el-table-column
					prop="severityStr"
					:label="$t('lang.common.exceptionManage.severity')">
					<template slot-scope="scope">
							{{drop.severityList[scope.row.severity] || scope.row.severity}}
					</template>
					</el-table-column>
					<el-table-column
					prop="priorityStr"
					:label="$t('lang.common.exceptionManage.priority')">
					<template slot-scope="scope">
							{{drop.priorityList[scope.row.priority] || scope.row.priority}}
					</template>
					</el-table-column>
					<el-table-column
					prop="warehouseAreaStr"
					width="150"
					:label="$t('lang.common.exceptionManage.warehouseArea')">
					<template slot-scope="scope">
							{{drop.warehouseAreaList[scope.row.warehouseArea] || scope.row.warehouseArea}}
					</template>
					</el-table-column>
					<el-table-column
					prop="logicAreaStr"
					width="150"
					:label="$t('lang.common.exceptionManage.logicArea')">
					<template slot-scope="scope">
							{{drop.logicAreaList[scope.row.logicArea] || scope.row.logicArea}}
					</template>
					</el-table-column>
					<el-table-column
					prop="selfHealing"
					:label="$t('lang.common.exceptionManage.selfHealing')">
					<template slot-scope="scope">
							{{drop.selfHealingList[scope.row.selfHealing] || scope.row.selfHealing}}
					</template>
					</el-table-column>
					<el-table-column
					prop="needReport"
					:label="$t('lang.common.exceptionManage.needReport')">
					<template slot-scope="scope">
							{{drop.needReportList[scope.row.needReport] || scope.row.needReport}}
					</template>
					</el-table-column>
					<el-table-column
					width="150"
					prop="reportSystemStr"
					:label="$t('lang.common.exceptionManage.reportSystem')">
					<template slot-scope="scope">
						<span>{{drop.systemList[scope.row.reportSystem] || scope.row.reportSystem}}</span>
					</template>
					</el-table-column>
					<el-table-column
					prop="exceptionTxt"
					width="200"
					:label="$t('lang.common.exceptionManage.exceptionInfo')">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" placement="top">
							<div slot="content" v-if="scope.row.exceptionInfo"><div style="max-width:300px;">{{scope.row.exceptionInfo}}</div></div>
							<div style="overflow: hidden;width: 180px;display: block;text-overflow: ellipsis;">{{scope.row.exceptionInfo}}</div>
						</el-tooltip>
					</template>
					</el-table-column>
					<el-table-column
					prop="log"
					:label="$t('lang.common.exceptionManage.log')">
						<template slot-scope="scope">
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content" v-if="scope.row.log"><div style="max-width:300px;">{{scope.row.log}}</div></div>
								<div style="overflow: hidden;width: 180px;display: block;text-overflow: ellipsis;">{{scope.row.log}}</div>
							</el-tooltip>
						</template>
					</el-table-column>
					<el-table-column
					prop="remark"
					:label="$t('lang.common.exceptionManage.remark')">
					</el-table-column>
					<el-table-column
					prop="statusStr"
					:label="$t('lang.common.exceptionManage.status')">
					<template slot-scope="scope">
							{{drop.statusList[scope.row.status] || scope.row.status}}
					</template>
					</el-table-column>
					<el-table-column
					prop="handleType"
                    width="120"
					:label="$t('lang.common.exceptionManage.handleType')">
					<template slot-scope="scope">
							{{drop.exceptionCodesHandleOptions[scope.row.handleType] || scope.row.handleType}}
					</template>
					</el-table-column>
					<el-table-column
					prop="handleInfo"
					:label="$t('lang.common.exceptionManage.handleInfo')">
					</el-table-column>
					<el-table-column
					prop="time"
					width="150"
					:label="$t('lang.common.exceptionManage.createAt')">
					<template slot-scope="scope">
							{{format(scope.row.createAt)}}
					</template>
					</el-table-column>
					<el-table-column
					prop="handler"
					:label="$t('lang.common.exceptionManage.handler')">
					<template slot-scope="scope">
							{{scope.row.handler}}
					</template>
					</el-table-column>
					<el-table-column
					prop="createAtStr"
					width="150"
					:label="$t('lang.common.exceptionManage.handleAt')">
					<template slot-scope="scope">
						{{format(scope.row.handleAt)}}
					</template>
					</el-table-column>
					<!-- <el-table-column
					prop="updator"
					:label="$t('修改人')">
					<template slot-scope="scope">
							{{scope.row.updator}}
					</template>
					</el-table-column>
					<el-table-column
					prop="updateAtStr"
					width="150"
					:label="$t('lang.common.exceptionManage.updateAt')">
					<template slot-scope="scope">
						{{format(scope.row.updateAt)}}
					</template>
					</el-table-column> -->
					<el-table-column
					fixed="right"
					:label="$t('lang.common.exceptionManage.operation')"
					width="100">
					<template slot-scope="scope"  v-if="scope.row.status !== 9">
							<el-button v-for="(item, idx) in (scope.row.exceptionCode && drop.exceptionCodesHandleOptions[scope.row.exceptionCode] || [])" :key="idx"  @click.stop="handleManul(scope.row, item.code || item.handleCode, item.name)" type="text" size="small">{{ $t(item.name)}}</el-button>
							<!-- <el-button  @click.stop="reSend(scope.row)" type="text" size="small">{{$t('重新下发')}}</el-button> -->
							<!-- <el-button  @click.stop="cancel(scope.row)"  type="text" size="small">{{$t('lang.common.exceptionManage.cancel')}}</el-button>
							<el-button  @click.stop="resetRow(scope.row)"  type="text" size="small">{{$t('重置')}}</el-button> -->
					</template>
					</el-table-column>
				</el-table>
				<div style="display:flex; justify-content:right;">
						<el-pagination
						style="padding: 10px"
						border
						:current-page="pageData.pageNo"
						:page-sizes="[10, 20, 50, 100]"
						:page-size="pageData.pageSize"
						layout="total, sizes, prev, pager, next"
						:total="pageData.recordCount"
						@size-change="handleSizeChange"
						@current-change="handleCurrentChange">
						</el-pagination>
				</div>
			</div>

			
			<el-dialog class="except-dialog" :title="''" :footer="false" show-close :close-on-click-modal="false" :close-on-press-escape="false" :visible.sync="dialogTableVisible" :width="'80%'">
				<div class="except-title" slot="title">
					<div>
						<span>{{$t('lang.common.exceptionManage.exceptionRecordNo')}}：</span>
						<span>{{detail.exceptionRecordNo}}</span>
						<span class="excep-label" v-if="detail.status">{{this.drop.statusList[detail.status] || detail.status}}</span>
					</div>
					<div>
						<!-- <el-button size="mini" plain @click="handleEmit()">{{'重新下发'}}</el-button>
						<el-button size="mini" plain @click="resetRow()">{{'重置'}}</el-button>
						<el-button size="mini" plain @click="dialogTableVisible=false">{{'取消'}}</el-button> -->
					</div>
				</div>
				<div class="except-detail-dialog">
					<div>
						<el-tabs v-model="activeName" @tab-click="handleTabClick" style="padding: 0 20px">
							<el-tab-pane :label="$t('lang.common.exceptionManage.mainInfo')" name="first">
								<div class="exception-descs margin-top" title="" :column="4" direction="vertical">
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.sourceSystem')}}</div><div>{{detail.sourceSystem}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.taskCode')}}: </div><div>{{detail.taskCode}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.parentTaskCode')}}: </div><div>{{detail.parentTaskCode}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.deviceType')}}: </div><div>{{drop.deviceTypeList[detail.deviceType] || detail.deviceType}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.deviceCode')}}: </div><div>{{detail.deviceCode}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.containerCode')}}: </div><div>{{detail.containerCode}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.taskPhase')}}: </div><div>{{drop.taskPhaseList[detail.taskPhase] || detail.taskPhase}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.warehouseArea')}}: </div><div>{{detail.exceptionCode}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.exceptionName')}}: </div><div>{{detail.exceptionName || ''}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.sopDoc')}}</div><div></div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.exceptionType')}}: </div><div>{{drop.exceptionTypeList[detail.exceptionType] || detail.exceptionType}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.severity')}}: </div><div>{{drop.severityList[detail.severity] || detail.severity}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.priority')}}: </div><div>{{drop.priorityList[detail.priority] || detail.priority}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.warehouseArea')}}: </div><div>{{drop.warehouseAreaList[detail.warehouseArea] || detail.warehouseArear}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.logicArea')}}: </div><div>{{drop.logicAreaList[detail.logicArea] || detail.logicArea}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.selfHealing')}}: </div><div>{{drop.selfHealingList[detail.selfHealing] || detail.selfHealing}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.needReport')}}: </div><div>{{drop.needReportList[detail.needReport] || detail.needReport}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reportSystem')}}: </div><div>{{drop.systemList[detail.reportSystem] || detail.reportSystem}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.handleType')}}: </div><div>{{detail.handleType}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.handleInfo')}}: </div><div>{{detail.handleInfo}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation1')}}: </div><div>{{detail.reservation1}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation2')}}: </div><div>{{detail.reservation2}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation3')}}: </div><div>{{detail.reservation3}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation4')}}: </div><div>{{detail.reservation4}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation5')}}: </div><div>{{detail.reservation5}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation6')}}: </div><div>{{detail.reservation6}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation7')}}: </div><div>{{detail.reservation7}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation8')}}: </div><div>{{detail.reservation8}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation9')}}: </div><div>{{detail.reservation9}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.reservation10')}}: </div><div>{{detail.reservation10}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.createAt')}}: </div><div>{{format(detail.createAt)}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.handler')}}: </div><div>{{detail.handler}}</div></div>
									<div class="exception-item"> <div class="exception-label">{{$t('lang.common.exceptionManage.handleAt')}}: </div><div>{{format(detail.handleAt)}}</div></div>
								</div>
							</el-tab-pane>
							<el-tab-pane :label="$t('lang.common.exceptionManage.log')" name="second">
								<div class="log-info">
									{{detail.log || ''}}
								</div>
							</el-tab-pane>
					</el-tabs>
					</div>
				</div>
				<!-- <detail-dialog :dict="dict" :drop="drop" :data="detail" :close-on-click-modal="false" :close-on-press-escape="false" v-if="dialogTableVisible"  @cancel="dialogCancel" style="height: 80%; overflow: hidden;"></detail-dialog> -->
			</el-dialog>

			<el-dialog
				:title="$t(handleForm.name || 'lang.wms.fed.handle')"
				:visible.sync="showManualDialog"
				@close="handleCancel"
				:on-ok="handleManualConfirm">
				<el-form :model="handleForm" ref="handleForm" :rules="rules" :label-position="'right'" label-width="100px">
					<el-form-item :label="$t('lang.common.exceptionManage.handleInfo')" prop="desc" >
						<el-input type="textarea" v-model="handleForm.reason" :rows="10" :placeholder="$t('lang.common.exceptionManage.pleaseEnter')"></el-input>
					</el-form-item>
				</el-form>
				<div class="dialog-footer" slot="footer">
						<el-button size="medium" type="primary" @click="handleSubmit()">{{$t('lang.common.exceptionManage.save')}}</el-button>
						<el-button size="medium" plain @click="handleCancel()">{{$t('lang.common.exceptionManage.cancel')}}</el-button>
				</div>
			</el-dialog>
			<el-dialog
				:title="$t('lang.common.exceptionManage.sopDocUpload')"
				:visible.sync="showManualUpload"
				@click="handleManualUploadClose"
				:on-ok="handleManualConfirm">
				<el-form :model="handleForm" ref="uploadForm" :rules="rules" :label-position="'right'" label-width="100px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="" prop="desc" >
								<el-input  v-model="fileName" :rows="10" :placeholder="$t('lang.common.exceptionManage.pleaseSelect')"></el-input>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item label="" prop="desc" >
								<el-upload
									class="upload-demo"
									ref="upload"
									action="/beetle/api/common/exception-manage/sop/upload"
									:on-preview="handlePreview"
									:on-remove="handleRemove"
									:before-remove="beforeRemove"
									:limit="1"
									:on-exceed="handleExceed"
									:on-success="uploadSuccess"
									:on-change="chooseFile"
									:before-upload = "beforeUpload"
									:show-file-list="false"
									:auto-upload="false">
									<el-button size="medium" type="primary" icon="gp-icon-upload" style="display:flex;align-items:center;">{{$t('lang.common.exceptionManage.selectFile')}}</el-button>
								</el-upload>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="dialog-footer" slot="footer">
						<el-button size="medium" type="primary" @click="submitUpload()">{{$t('lang.common.exceptionManage.upload')}}</el-button>
						<el-button size="medium" plain @click="handleManualUploadClose()">{{$t('lang.common.exceptionManage.cancel')}}</el-button>
				</div>
			</el-dialog>
			<el-dialog
			:title="$t('lang.common.exceptionManage.export')"
			:close="closeExport"
			:visible.sync="showExportDialog"
			:on-ok="handleManualConfirm"
			:width="'60%'">
				<el-form :model="formExport" ref="handleForm" :rules="rules" :label-position="'left'" label-width="100px">
					<el-form-item :label="$t('lang.common.exceptionManage.exportFileFormat')">
						<el-radio-group v-model="formExport.fileType">
							<el-radio label="excel">{{$t('lang.common.exceptionManage.excelFile')}}</el-radio>
							<!-- <el-radio label="csv">CSV文件</el-radio> -->
						</el-radio-group>
					</el-form-item>
					<el-form-item :label="$t('lang.common.exceptionManage.selectExportColumns')" label-width="150px">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">{{$t('lang.common.exceptionManage.selectAll')}}</el-checkbox>
					</el-form-item>
					<el-form-item label="" label-width="10px"> 
						<el-checkbox-group v-model="formExport.fileds" class="export-key-list"  @change="handleCheckedChange">
							<el-checkbox v-for="(item, index) in exportKeyList" :key="index" :label="item.code">{{$t(item.name)}}</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
				<div class="dialog-footer" slot="footer">
						<el-button size="medium" plain @click="closeExport">{{$t('lang.common.exceptionManage.cancel')}}</el-button>
						<el-button size="medium" type="primary" @click="exportFileSubmit()">{{$t('lang.common.exceptionManage.save')}}</el-button>
				</div>
			</el-dialog>
		</div>
</template>

<script>
// import { Message } from 'geekplus-ui';
import axios from "axios";
import { initParams, getTable, exportTable, getExportFields } from '../../../api/exceptionManage'
export default {
	name: 'GeekExceptionManage',
    options: {
        name: '异常管理',
        parents: ['all'], // all
        groups: ['自定义页面'],
        events: {
            cancleEdit: '取消',
            closeEdit: '关闭'
        }
    },
	data() {
		return {
			detail: {},
			params: {},
			drop: {
				"exceptionTypeList": {},
				"severityList": {},
				"priorityList": {},
				"selfHealingList": {},
				"needReportList": {},
				"statusList": {},
				"exceptionCodesHandleOptions": {},
				"systemList": {},
				"taskPhaseList": {},
				"deviceTypeList": {},
				"warehouseAreaList": {},
				"logicAreaList": {}
			},
			handleForm: { // 手动处理
				row: {},
				reason: '',
				code: '',
				name: ''
			},
			formExport: {
				fileType: 'excel',
				fileds: [],
			},
			form: {
				exceptionCode: '',
				exceptionName: '',
				systemStr: [],
				moduleStr: [],
				subModuleStr: [],
				exceptionType: '',
				severityStr: [],
				priorityStr: [],
				selfHealingStr: [],
				needReportStr: [],
				reportSystemStr: [],
				statusStr: [],
				creator: '',
				createAtStr: [],
				updator: '',
				updateAtStr: [],
			},
			rules: {},
			isEdit: false,
			pageData: {
				"pageNo": 1,
				"pageSize": 10,
				"recordCount": 0,
				"pageCount": 0,
			},
			detailData: '',
			tableData: [],
			dialogTableVisible: false,
			showManualDialog: false,
			showExportDialog: false,
			showManualUpload: false,
			isIndeterminate: false,
			checkAll: false,
			exportKeyList: [
				{"code": "exceptionRecordNo", "name": "lang.common.exceptionManage.exceptionRecordNo"},
				{"code": "xxx", "name": "lang.common.exceptionManage.xxx"},
				{"code": "xxx", "name": "lang.common.exceptionManage.xxx"}
			],
			fileList: [],
			newFold: true,
			fileName: '',
			activeName: 'first',
			tableList: [],
		}
	},
	async created (){
		// 获取基础数据
		await initParams().then(res => {
			if(!res.code) {
				this.params = res.data || {};
				for (let key in this.params) {
					const keyMap = {}
					this.params[key].map(v => {
						keyMap[v.code] = this.$t(v.name);
						return v;
					})
					this.drop[key] = keyMap;
				}
				// this.params.exceptionCodesHandleOptions = [{"exceptionCode": "GPS001", "handleOptions": [{"code": "cancel", "name": "取消"}, {"handleCode": "close", "name": "关闭"}]}];
				(this.params.exceptionCodesHandleOptions || []).map(v => {
					if (v.exceptionCode) {
						this.drop.exceptionCodesHandleOptions[v.exceptionCode] = v.handleOptions;
					}
					return v;
				})
			}
		})
		getExportFields().then(res => {
			this.exportKeyList = res.data || [];
		})
		this.initTable();
	},
	methods: {
		handleTabClick() {

		},
		// 折叠
		foldBox() {
			this.newFold = !this.newFold;
		},
		handleManualClose() {
			this.dialog.showManualDialog = false;
		},
		handleManualUploadClose() {
			this.fileName = '';
			this.showManualUpload = false;
			this.$nextTick(() => {
				this.$refs.upload.clearFiles();
			})
		},
		handleManualConfirm() {

		},
		initTable(detailData) {
			const {pageSize, pageNo} = this.pageData;
			const keys = Object.keys(this.form);
			const transParams = {};
			keys.map(v => {
				let data = this.form[v] || '';
				let val = data;
				if (data && Array.isArray(data)) {
					val = data.join(',')
				};
				transParams[v] = val;
				return v
			})
			const params = {
				pageSize, 
				pageNo, 
				...transParams,
				createAtStr: this.form.createAtStr.length ? this.form.createAtStr.map(v => new Date(v).getTime()).join('-') : '',
				updateAtStr: this.form.updateAtStr.length ? this.form.updateAtStr.map(v => new Date(v).getTime()).join('-') : '',
			};
			getTable(params).then(res => {
				console.log(res)
				if(!res.code) {
					this.tableData = res.data?.recordList || [];
					this.pageData.pageNo = res.data?.currentPage;
					this.pageData.recordCount = res.data?.recordCount;
				}
			})
		},
		search() {
			this.pageData.pageNo = 1;
			this.initTable();
		},
		reset() {
			this.$refs.searchForm.resetFields();
			// this.initTable();
		},
		// 手动处理
		handleManul(row, code, name){
			this.handleForm.row = row;
			this.handleForm.code = code;
			this.handleForm.reason = '';
			this.handleForm.name = name;
			this.showManualDialog = true;
		},
		// 手动处理提交
		handleSubmit() {
			axios({
				url: `/api/common/exception-manage/exception-record/handle`,
				method: 'post',
				params: {
					exceptionRecordNo: this.handleForm.row.exceptionRecordNo || '',
					handleCode: this.handleForm.code || '',
				},
				data: {
					body: this.handleForm.row.reason
				}
			}).then(res => {
				this.$message({
					message: res.code ? res.msg : '处理成功',
					type: res.code ? 'error': 'success',
					duration: 30000,
					customClass: 'exception-message'
				});
				if(!res.code){
					this.initTable();
					this.handleCancel();
				}
			})
		},
		// 手动处理弹框关闭
		handleCancel() {
			this.showManualDialog = false;
			this.handleForm = {
				row: '',
				reason: '',
				code: '',
				name: ''
			};
		},
		// 重新下发异常
		reSend(row, type){
		},
		// 取消异常
		cancel(row, type){
		},
		// 重置异常
		resetRow(row, type){
		},
		handleSizeChange(val) {
			this.pageData.pageSize = val;
			this.pageData.pageNo = 1;
			this.initTable();
		},
		handleCurrentChange(val) {
			this.pageData.pageNo = val;
			this.initTable();
		},
		// 关闭异常信息详情
		dialogCancel() {
			this.detail = {};
			this.dialogTableVisible = false;
		},
		// 打开异常信息详情
		openDetail(row) {
			this.detail = row;
			this.dialogTableVisible = true;
		},
		handleEmit() {

		},
		handleCheckAllChange(val) {
            this.formExport.fileds = val ? this.exportKeyList.map(v => {return v.code}) : [];
            this.isIndeterminate = false;
		},
        handleCheckedChange(value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.exportKeyList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.exportKeyList.length;
        },
		// 导出按钮
		exportFile() {
			this.showExportDialog = true;
		},
		// 导出确定按钮
		exportFileSubmit() {
			const fields = this.formExport.fileds.join(',')
			let searchKeys = '';
			for (let key in this.form) {
				searchKeys += '' + key + this.form[key];
			};
			console.log(fields)
			this.exportList(`/beetle/api/common/exception-manage/exception-record/export?params=${searchKeys}&exportFields=${fields}`);
			this.closeExport();
		},
		closeExport() {
			this.showExportDialog = false;
            this.isIndeterminate = false;
			this.formExport = {
				fileType: 'excel',
				fileds: [],
			}
		},
		upload() {
			this.showManualUpload = true;
			this.$nextTick(() => {
				this.$refs.upload.clearFiles();
			})
		},
		// 上传服务器
		submitUpload() {
			// const reg = /^[\w]+-[\w]+-[\w]+\.pdf$/;
			if (this.$refs.upload.uploadFiles.length < 1) {
				this.$message({
					message: this.$t('lang.common.exceptionManage.selectFile'),
					type: 'error',
					duration: 3000,
				});
				return;
			}
			// if (reg.test(this.fileName)) {
			// 	this.$refs.upload.uploadFiles[0].name = this.fileName;
			// 	console.log(this.$refs.upload.uploadFile);
			// } else {
			// 	this.$message({
			// 		message: this.$t('请检查文件名'),
			// 		type: 'error',
			// 		duration: 3000,
			// 	});
			// 	return;
			// }
			this.$refs.upload.submit();
		},
		// 文件上传时
		beforeUpload() {
		},
		// 上传成功
		uploadSuccess(response, file, fileList) {
			if (response.code === 0) {
				this.$message({
					message: this.$t('lang.common.exceptionManage.sopDocUpload'),
					type: 'success',
					duration: 3000,
				});
				this.showManualUpload = false;
				this.fileName = '';
			} else {
				this.$message({
					message: this.$t(response.msg),
					type: 'error',
					duration: 3000,
				});
				this.fileList = [];
			}
		},
		// 上传失败
		chooseFile(file, fileList) {
			console.log(file, fileList);
			this.fileName = file.name
		},
		beforeRemove() {

		},
		handleExceed() {

		},
		handlePreview() {

		},
		handleRemove() {

		},
		// 打开处理方案
		openPdf(row) {
			// let url = (`/beetle/api/common/exception-manage/sop/download?system=${window.system || 'GPS'}&languageCode=${window.curLanguage || localStorage.getItem('curLanguage') || 'zh_cn'}#${row.exceptionCode}`);
			// this.exportList(url)
			window.open(`/beetle/api/common/exception-manage/sop/download?system=${window.system || 'GPS'}&languageCode=${window.curLanguage || localStorage.getItem('curLanguage') || 'zh_cn'}#${row.exceptionCode}`);
		},
		// 下载
		exportList(url) {
			if (document.querySelector('#download-iframe')) {
				document.getElementById('download-iframe').remove();
			}
			const iframe = document.createElement('iframe');
			iframe.onload = (e) => {
				const data = JSON.parse(e.target.contentWindow.document.body.innerText);
				this.$message({
					message: this.$t(data.msg),
					type: 'error',
					duration: 3000,
					showClose: true,
				});
			};
			iframe.setAttribute('id', 'download-iframe');
			iframe.src = url;
			iframe.style.display = 'none';
			document.body.appendChild(iframe);
		},
		format(date, formatter='YYYY-MM-DD HH:mm:ss') {
			let d = date
			if (d === 0 || d === '') {
			return ''
			} else if (typeof d === 'string') {
			if (/^\d+$/.test(d)) {
				d = new Date(+d)
			} else {
				d = new Date(d)
			}
			} else if (Number.isInteger(d)) {
			d = new Date(d)
			}
		
			if (d instanceof Date && !isNaN(d.getTime())) {
			const year = d.getFullYear()
			const month = d.getMonth() + 1
			const day = d.getDate()
			const h = d.getHours()
			const m = d.getMinutes()
			const s = d.getSeconds()
		
			return formatter
				.replace(/yyyy/i, year)
				.replace(/yy/i, year.toString().substr(2))
				.replace('MM', month.toString().padStart(2, '0'))
				.replace('M', month)
				.replace(/dd/i, day.toString().padStart(2, '0'))
				.replace(/d/i, day)
				.replace(/hh/i, h.toString().padStart(2, '0'))
				.replace('mm', m.toString().padStart(2, '0'))
				.replace(/ss/i, s.toString().padStart(2, '0'))
			}
			return date ?? ''
		}
		
	},
	destroyed() {
	}
}
</script>

<style lang="scss">
.except-dialog{
	.el-dialog__body{
		padding-top: 0;
	}
}

.exception-message{
	z-index: 9999 !important;
}
.exception-container{
	.el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner{
		width: 100% !important;
		line-height: 40px !important;
		height: 40px !important;
	}
	.export-key-list {
		margin-left: 1%;
		.el-checkbox{
			width: 16%;
			margin-right: 2%;
		}
	}
	.el-table .cell {
		white-space: nowrap;
		// overflow: inherit;
		text-overflow: initial;
		word-wrap: break-word;
		word-break: break-all;
	}
	.switch-up{
		color: #409eff;
		cursor: pointer;
	}
	.fold-wrapper{
		margin-left: 10px;
		line-height: 40px;
	}
}
</style>
<style lang="scss" scoped>
.dialog-footer{
	text-align: center;
}
.el-form-item{
	text-align: left;
}
.exception-container{
    background: #eee;
}
.search-box{
	padding: 20px;
	background: #fff;
	.el-form-item {
			padding-right: 20px;
	}
	::v-deep {
			.el-form--label-top .el-form-item__label{
					padding: 0;
					line-height:24px;
			}
	}
	.el-select{
			width: 100%;
	}
	.search-btns{
			display: flex;
			flex-direction: row;
			justify-content: right;
	}
}
.main-opts{
	margin-bottom: 10px;
  display: flex;
  justify-content: right;
}
.table-area{
	margin: 20px 0;
	padding: 20px;
	background: #fff;
	// display: flex;
	// flex-direction: column;
	// flex:1;
	width: 100%;
	box-sizing: border-box;
} 
.el-table {
}
.except-title{
	padding-right: 40px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}
.except-detail-dialog{
  display: flex;
  flex-direction: column;
  flex:1;
	.except-title{
		display: flex;
		justify-content: space-between;
	}
  .dialog-main{
     display: flex;
    flex-direction: row;
    flex:1;
  }
  .log-info{
    min-height: 300px;
    max-height: 600px;
	width: 100%;
    overflow: auto;
  }
}
.gp-form{
  .gp-select{
    width: 100%;
  }
}
.dialog-footer{
  margin-top: 20px;
  text-align: center;
}
.exception-descs{
	display: flex;
	flex-flow: wrap;
	margin-top: 20px;
	.exception-item{
		margin-bottom: 20px;
		width: 25%;
		line-height: 1.5;
	}
}
.excep-label{
	margin-left: 10px;
	padding: 0 5px;
	font-size: 12px;
	border:1px solid #409eff;
	border-radius: 20px;
}
</style>