import axios from "axios";
const BASEURL = '';


/**
 * 基础数据
 * @param {Object}
 */
export function initParams(params) {
    return axios({
        url: `${BASEURL}/api/common/exception-manage/exception-code/init-page-param`,
        method: 'get',
    });
}
/**
 * 分页
 * @param {Object}
 */
export function getTable(params) {
    return axios({
        url: `${BASEURL}/api/common/exception-manage/exception-code/page`,
        method: 'get',
        params,
    });
}

/**
 * 导出
 * @param {Object}
 */
export function exportTable(params) {
    return axios({
        url: `${BASEURL}/api/common/exception-manage/exception-record/export`,
        method: 'get',
        params,
    });
}
